.create_modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0202029f;
    /* backdrop-filter: blur(5px); */
}

.EmojiPickerReact li.epr-emoji-category>.epr-emoji-category-label {
    backdrop-filter: blur(53px) !important;
}

.emokjipickerdivimg {
    width: 25px;
    height: 25px;
}

.emokjipickerdivimg img {
    width: 100%;
    height: 100%;
}

.projectaddfrm {
    width: 100%;
    height: auto;
    padding: 130px 0px 20px 0px;
}

.create_modal_content {
    /* background-color: #FFF2C6; */
    background-color: #FFF;
    border-radius: 5px;
    max-width: 450px;
    width: 100%;
    position: relative;
    z-index: 999;
}

.newBulleCreHead {
    color: #263238;
    font-family: 'Plus Jakarta Sans';
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* background-color: #0202029f; */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 27px;
}

.homePageCreModal {
    overflow-y: auto;
    height: 433px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.homePageCreModal::-webkit-scrollbar {
    display: none;
}

.crtimg {
    width: 34px;
    height: 33px;
    margin-right: 15px;
}

.crtimg img {
    width: 100%;
    height: 100%;
    margin-right: 10px;
}

/* Hide scrollbar for IE, Edge and Firefox */
.homePageCreModal {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.newBulleCloseModDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 0 20px;
}

.frm_own {
    padding: 20px;
    max-height: 500px;
    overflow-y: auto;
}

.frm_own::-webkit-scrollbar {
    display: none;
}

.frm_own .form-control {
    /* padding-top: 9px;
    padding-bottom: 20px; */
    /* height: 48px; */
}


.thought_moadal {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: #02020258;
}

.thought_modal_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #f1f1f1;
    border-radius: 5px;
    /* max-width: 707px; */
    /* min-width: 742px; */
    z-index: 999;
    /* height: 50%; */
    width: 100%;
    max-width: 52%;
}

/* .prt_img_flx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3%;
    flex-wrap: wrap;
} */

.thoghts_txt_top_btm {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    /* margin-bottom: 29px; */
}

.thought_moadal_main .thoghts_txt_top_btm .top {
    width: 100%;
    height: auto;
    padding: 6px 18px 6px;
    border-bottom: 1px solid rgba(198, 198, 210, 0.48);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #dec19c;
    border-radius: 5px 5px 0px 0px;
    overflow: hidden;
    position: relative;
}

.thought_moadal_main .thoghts_txt_top_btm .top p {
    color: #000000;
    /* font-family: serif; */
    font-family: "MontHeavy";
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 30px;
    text-align: center;
    margin-right: 30px;
}

.img_thght {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 99px;
    height: 102px;
    /* border: 4px solid yellow; */
    border-radius: 50%;
    border: 6px solid #fff;
    /* margin-top: -40px; */
    position: absolute;
    left: 70px;
    top: 17px;
}

.thought_moadal_main .thoghts_txt_top_btm .bottom {
    /* padding: 19px 21px 14px; */
    max-height: auto;
    overflow-y: auto;
}

agenInnerWrap

/* Hide scrollbar for Chrome, Safari and Opera */
.thought_moadal_main .thoghts_txt_top_btm .bottom::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.thought_moadal_main .thoghts_txt_top_btm .bottom {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.thought_moadal_main .bottom .form-group input {
    width: 100%;
    max-width: 700px;
    height: 45px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 34px;
    padding: 10px 10px;
    border: none;
    box-shadow: none;
    outline: 0;
    color: #000000;
    margin-bottom: 0px;
    margin-top: 10px;
}

.prfl_mnm p {
    margin-bottom: 0;
    color: #000000;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
}

.thought_moadal_main .bottom .profile_img {
    width: 100%;
    max-width: 90px;
    height: 91px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.shrt {
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    padding-top: 10px;
    padding-bottom: 5px;
}

.thought_moadal_main .bottom .profile_img img {
    width: 100%;
    height: 100%;

}

.thought_moadal_main ::placeholder {
    color: #d3a14569;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 34px;
    cursor: pointer;
}

.bsimgicon {
    color: #fac00d;
    font-size: 27px;
    margin-right: 20px;
    /* position: relative; */
}

.txtar {
    height: 50px;
    width: 100%;
    border: 0px solid #ccc;
    resize: none;
    margin-top: 100px;
    margin-bottom: 10px;
    padding: 5px 0px;
}

.dbdyptrtddiv {
    padding: 10px 75px;
}

.thought_moadal_main .close_icon {
    position: absolute;
    right: 0;
    z-index: 99;
    top: 4px;
    padding: 11px 14px;
}

.attch_main_img {
    border: 1px dashed #000000;
    /* max-height: 206px; */
    padding: 5px;
    height: 95px;
    position: relative;
}

.attchfl {
    background-color: #00000018;
    width: 100%;
    height: 84px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-left: 20px; */
    /* margin-right: 20px; */
}

.attchfl input {
    position: relative;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.attch_img {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

aside.EmojiPickerReact.epr-main {
    border-color: var(--epr-picker-border-color);
    border-radius: var(--epr-picker-border-radius);
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 299px !important;
    width: 283px !important;
    z-index: 99;
}

.pht p {
    font-size: 12px;
    color: #000;
    font-weight: 400;
    margin-bottom: 0;
}

.drp p {
    font-size: 12px;
    color: #000;
    font-weight: 400;
    margin-bottom: 0;
    text-align: center;
}

.attach_img_bg {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000008a;
    width: 26px;
    height: 33px;
    margin: auto;
    border-radius: 9px;
    color: #fff;
}

.post_btn {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 10px;
}

.post_btn_main {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bec3c961;
    padding: 10px;
    border-radius: 10px;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
}

.prt_img {
    width: 110px;
    height: 110px;
    position: relative;
    padding-bottom: 15px;
}

.prt_img img {
    width: 100%;
    height: 100%;
    margin: auto;
    object-fit: cover;
}

.prt_img_edt {
    position: absolute;
    /* background-color: #000; */
    z-index: 99;
    top: 10%;
    left: 5%;
}

.edt_btnn_icon {
    background-color: #f1f1f1;
    width: 100%;
    padding: 6px;
    border-radius: 10px;
    color: #000;
}

.edt_btnn_icon i {
    margin-right: 5px;
}

.close_icon_img {
    position: absolute;
    z-index: 99;
    top: 0;
    right: 2px;
    z-index: 99;
}

.addCurLocaBtn {
    background-color: transparent;
    border: 0;
    color: #707283;
    font-size: 20px;
    margin-right: 12px;
}

.modAddLocaInp {
    font-family: 'Inter';
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 0;
    background-color: transparent;
    outline: none;
}

.attachpostflxdiv .EmojiPickerReact .epr-preview {

    height: 40px !important;
}

.attachpostflxdiv .EmojiPickerReact .epr-category-nav {
    padding: 7px !important;
}

.attachpostflxdiv .__EmojiPicker__ {
    width: 20px !important;
    height: 20px !important;
    font-size: 20px !important;
}

.modAddLocaInp::placeholder {
    color: #5E5E5E;
}

.addCurLocaModDiv {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.homePageCreModal>div {
    padding: 0 20px;
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 10px;
    margin-bottom: 12px;
}

.homePageCreModal:first-child {
    border: 0;
}

.homePgMoInps {
    background-color: transparent;
    border: 0;
    outline: none;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #263238;
    width: 100%;
}

.homePgMoInps::placeholder {
    color: #263238;
}

.addNoteLablCreMod {
    display: block;
    color: #263238;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 18px;
}

.addNoteCreModTextArea {
    width: 100%;
    border: 1px dashed #000000;
    border-radius: 11px;
    outline: none;
    color: #263238;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    resize: none;
    background-color: transparent;
    padding: 5px 10px;
}

.homePgCreModSubmitDiv {
    text-align: center;
    border: 0 !important;
}

.homePgCreModSubmitBtn {
    /* background-color: #8d7a7a82; */
    border: 0;
    background: #d3a145;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    padding: 7px 15px;
    border-radius: 8px;
    font-family: 'NoirProLight';
    text-align: center;
    cursor: pointer;
    margin: 7px;
}

.askhdpphr {
    width: 28px;
    height: 30px;
    margin: 0 9px 0 0;
}

.homePgCreModSubmitBtn:hover {
    color: #ffffff;
}

.close_icon {
    /* position: absolute;
    top: 10px;
    right: 12px;
    z-index: 99; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    margin-left: auto;
}

.emplModHead {
    color: #263238;
    font-family: 'Plus Jakarta Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.create_modal_content.crte_own {
    background-color: #FFF;
    border-radius: 5px;
    max-width: 450px;
    width: 100%;
    position: relative;
    /* max-height: 450px;
    overflow-y: auto; */
}

.attach_btn {
    margin-right: 4px;
    display: flex;
    align-items: center;
}

.request_credits {
    /* margin-right: 20px; */
    display: flex;
    align-items: center;
}

.request_credits .btn {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    border: none;
    background-color: transparent;
}

.request_credits img {
    max-width: 28px !important;
}

.Post_Card {
    margin-right: 20px;
    /* display: flex;
    align-items: center; */
}

.jkjkjflsb {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid #00000059;
    padding: 5px 0px;
    align-items: center;
}




.Post_Card .btn {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    border: none;
    background-color: transparent;
}

.Post_Card img {
    max-width: 28px !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.create_modal_content.crte_own::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.create_modal_content.crte_own {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* .shrtatchpst{
    display: flex;
    justify-content: space-between;
    gap:10px
  } */
/* .shrtatchpst .bottom .attach_btn {
    display: flex;
    align-items: center;
} */
.thought_moadal_main .bottom .file_upload {
    margin: 0 9px 0 0;
    position: relative;
    width: 28px;
    height: 30px;
}

.thought_moadal_main .bottom .file_upload input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    background: transparent;
    border: none;
    box-shadow: none;
    opacity: 0;
}

.shrtatchps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(198, 198, 210, 0.48);
    width: 100%;
    padding: 7px 20px;

}

.pstimg {
    width: 28px;
    height: 30px;
    margin-right: 8px;

}

.pstimg img {
    width: 100%;
    height: 100%;
}

.postimgicon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%
}

.attachpostflxdiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* Employee List Modal */
.advocacyModalSection {
    background-color: #00000083;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.empListModal {
    background-color: #fff;
    width: 450px;
    max-height: 500px;
    border-radius: 10px;
    padding: 2rem;
    position: relative;
    z-index: 99;
}

.evModalFig {
    width: 49px;
    min-width: 49px;
    height: 49px;
    border-radius: 50%;
    margin: 0;
}

.evModalFig img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.empNmModal {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #263238;
    padding: 0;
    margin: 0 0 4px 0;
}

.creModText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
    color: #7C7979;
    padding: 0;
    margin: 0;
}

.crypoFig {
    margin: 0;
}

.chatEmpModBtn {
    background-color: transparent;
    border: 0;
    font-size: 18px;
    color: #263238;
    margin: 0 8px;
}

.showAct {
    width: 13px;
    height: 13px;
    background-color: #22BA09;
    border-radius: 50%;
}

.crypoFigDiv {
    display: flex;
    align-items: center;
}

.empCryDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 100%;
}

.empListModLi {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.empListModUl>li {
    border-bottom: 1px solid #3f3c3c4f;
}

.thought_moadal {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: #020202a1;
}

/* add Modal */
.add_moadal {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: #02020258;
}

.add_modal_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #f1f1f1;
    border-radius: 5px;
    /* max-width: 100px;
    min-width: 50px; */
    z-index: 999;
    padding: 20px;
}

.add_moadal_main .close_icon {
    position: absolute;
    z-index: 99;
    right: 11px;
    top: 5px;
    padding: 0px;
}

.add_moadal_main .custom-field.two input {
    border-radius: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background:
        linear-gradient(90deg, #222, #222) center bottom/0 0.15em no-repeat,
        linear-gradient(90deg, #ccc, #ccc) left bottom/100% 0.15em no-repeat,
        linear-gradient(90deg, #fafafa, #fafafa) left bottom/100% no-repeat;
    transition: background-size 0.3s ease;
}

.add_moadal_main .custom-field.two input.dirty,
.add_moadal_main .custom-field.two input:not(:placeholder-shown),
.add_moadal_main .custom-field.two input:focus {
    background-size: 100% 0.15em, 100% 0.1em, 100%;
}

.add_moadal_main .custom-field {
    position: relative;
    font-size: 14px;
    border-top: 20px solid transparent;
    margin-bottom: 5px;
    display: inline-block;
    --field-padding: 12px;
}

.add_moadal_main .custom-field input {
    border: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #f2f2f2;
    padding: var(--field-padding);
    border-radius: 3px;
    width: 250px;
    outline: none;
    font-size: 14px;
}

.add_moadal_main .custom-field .placeholder {
    position: absolute;
    left: var(--field-padding);
    width: calc(100% - (var(--field-padding) * 2));
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    top: 22px;
    line-height: 100%;
    transform: translateY(-50%);
    color: #aaa;
    transition:
        top 0.3s ease,
        color 0.3s ease,
        font-size 0.3s ease;
}

.add_moadal_main .custom-field input.dirty+.placeholder,
.add_moadal_main .custom-field input:focus+.placeholder,
.add_moadal_main .custom-field input:not(:placeholder-shown)+.placeholder {
    top: -10px;
    font-size: 10px;
    color: #222;
}

.add_btn_nn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px;
    background-color: #0E24CC;
    border: none;
    outline: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
    width: 100%;
    max-width: 25%;
    margin: auto;
}

.bggrienvencemain{
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 99;
    position: fixed;
    background-color: #00000070;
}

.bodygrivence{
    width: 100%;
    max-width: 50%;
    background-color: #fff;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    border-radius: 10px;
    padding: 10px;
}

.shredivmaindk {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    /* gap: 0%; */
    align-items: center;
}

.brdttgglebox {
    width: 38px;
    /* border: 2px solid #00000073; */
    height: 19px;
    border-radius: 5px;
    margin-right: 10px;
    background-color: #00000029;
}

.boxyellow {
    position: absolute;
    width: 19px;
    height: 18px;
    background-color: #fac00d;
    border-radius: 5px;
    transform: translateX(0PX);
}

.boxyellow.actv {
    transform: translateX(19PX);
}


/* Add Modal Comment Rating */
.add_moadalcmnt {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: #02020258;
}

.add_modal_cmnt_main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #f1f1f1;
    border-radius: 5px;
    /* max-width: 100px;
    min-width: 50px; */
    z-index: 999;
    padding: 20px;
}

.add_modal_cmnt_main .close_icon {
    position: absolute;
    z-index: 99;
    right: 11px;
    top: 6px;
    padding: 0px;
}

.cmnt textarea {
    border-color: #00000026;
    height: 87px;
    resize: none;
    border-radius: 4px;
    padding: 10px;
    background-color: transparent;
    width: 100%;
}

/* .rtng span{
    color: #171725;
    font-family: 'Plus Jakarta Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
} */
.cmnt p {
    color: #171725;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    font-family: 'Plus Jakarta Sans';
    padding-top: 10px;
    padding-bottom: 10px;
}

.rtng p {
    color: #171725;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    font-family: 'Plus Jakarta Sans';
    padding-top: 5px;
    padding-bottom: 5px;
}

.sbmt_btn-mn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px;
    background-color: #0E24CC;
    border: none;
    outline: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    /* margin-top: 15px; */
    width: 100%;
    max-width: 25%;
    margin: auto;
}

.sbmt {
    margin-top: 10px;
}

/* Agenda Modal Css */
.agenModalSec {
    background-color: #575454b6;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.agendaWhiteMod {
    width: 70%;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    position: relative;
}

.agendaHead {
    text-align: center;
    margin: 0 0 10px 0;
    font-size: 22px;
    font-weight: 600;
}

.agendaCrossBtn {
    background-color: transparent;
    border: 0;
    font-size: 22px;
    position: absolute;
    top: 12px;
    right: 20px;
    color: #D71313;
}

.agendaEvntTable {
    width: 100%;
}

.agendaEvntTableDiv {
    overflow: auto;
    max-height: 500px;
}

.agendaEvntTableDiv::-webkit-scrollbar {
    display: none;
}

.agendaEvntTd {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid #00000061;
    width: 16.66%;
    padding: 3px 0;
    background-color: aliceblue;
}

.agenEmpName {
    font-size: 12px;
    font-weight: 500;
}

.agenInnerWrap {
    width: 79px;
}

.agenInnerTdDiv {
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.agenInnerWrap {
    display: inline-block;
}

.agenInnerTd {
    border: 1px solid #00000061;
}

.agenDateText {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 3px;
}

.agenTimeText {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 3px;
}

.agenRadioDiv {
    display: flex;
    align-items: center;
}

.agenInnerRadio {
    display: flex;
    align-items: center;
}

.agenInnerRadio label {
    font-size: 12px;
    margin: 0;
    font-weight: 500;
}

.agenInnerRadio input {
    width: 11px;
    height: 11px;
    margin-right: 1px;
}

.agenInnerRadio {
    margin-right: 5px;
}

.closeIconEdit {
    position: absolute;
    top: 7px;
    right: -5px;
}
.opentxtdivmainflxncm{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}
.crsiconopentxdivflx{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px; 
    border-bottom: 1px solid #cccc;
    padding: 10px 20px;
}
.Specifydivmain{
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
}
.Specifydivmain textarea{
    border: 1px solid #d3a145;
    padding: 10px;
    height: 96px;
    resize: none;
    width: 100%;
    margin-top: 10px;
    border-radius: 10px;
    overflow: hidden;
}
.Specifydivmain textarea:focus{
    outline: 0;
    border: 1px solid #d3a145;
}
.Specifydivmaintxtgfhpp{
    font-size: 15px;
    font-weight: 600;
    color: #000;
    line-height: 18px;
    padding-bottom: 2px;
    font-family: 'NoirProLight';
}
.Specifydivmaindibscvnbp{
    padding: 10px 20px;
}
.hgbtnnmgnsuggenstdiv {
    border: none;
    background-color: rgb(234, 235, 251);
    padding: 8px 35px;
    border-radius: 5px;
    color: #000;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-family: 'NotoSans';
}
@media(max-width:1399px) {
    .shrt {
        font-size: 16px;
    }

    .newBulleCreHead {
        font-size: 17px;
    }

    .addCurLocaBtn {
        font-size: 15px;
    }
}



@media (max-width: 1199px) {
    .agendaWhiteMod {
        width: 90%;
    }

    .bsimgicon {

        color: #fac00d;
        font-size: 27px;
        margin-right: 8px;
        /* position: relative; */

    }

    .attach_btn {
        margin-right: 10px;
        display: flex;
        align-items: center;
    }

    .emplListDiv {
        margin-top: 0;
        margin-bottom: 0px;
        margin-left: 10px;
        display: flex;
        width: 100%;
        align-items: baseline;
    }

    .pstimg {
        width: 28px;
        height: 30px;
        margin-right: 6px;
    }
}

@media (max-width: 991px) {
    .agendaEvntTable {
        min-width: 992px;
    }

    .thought_moadal_main .thoghts_txt_top_btm .bottom {
        padding: 19px 0px 14px;
        max-height: 450px;
        overflow: auto;
        overflow-x: hidden;
    }

    .thought_modal_content {
        width: 100%;
        max-width: 95%;
    }

    .shrtatchps {
        width: 100%;
        padding: 5px 6px;
    }

    .txtar {
        height: 95px;
        width: 100%;
        border: 0px solid #ccc;
        resize: none;
        margin-top: 70px;
        margin-bottom: 10px;
        padding: 5px 8px;
    }
}

@media(max-width:767px) {

    /* .thought_modal_content {
        max-width: 500px;
        min-width: 100%;
    } */
    .thought_modal_content {
        width: 100%;
        max-width: 96%;
    }

    .shrtatchps {
        margin-left: 5px;
        margin-right: 5px;
        width: 100%;
        /* justify-content: space-between; */
        gap: 4%;
    }

    .thought_modal_content {
        width: 100%;
        max-width: 98%;
    }

    .psttxtdiv {
        display: none;
    }

    .attach {
        display: none;
    }

    .bsimgicon {
        color: #fac00d;
        font-size: 21px;
        margin-right: 9px;
        /* position: relative; */
    }

    .thought_moadal_main .bottom .file_upload {
        margin: 0px 0px 0px 0px;
        position: relative;
        width: 21px;
        height: 21px;
    }

    .pstimg {
        width: 21px;
        height: 21px;
        margin-right: 6px;
    }

    .thought_moadal_main .thoghts_txt_top_btm .bottom {
        padding: 19px 0px 0px;
        max-height: 450px;
        overflow: auto;
        overflow-x: hidden;
    }

    .shrtatchps {
        width: 100%;
        padding: 10px 6px;
    }

}

@media(max-width:576px) {
    .empListSubHead {
        display: none;
    }
}

@media (max-width: 479px) {
    .create_modal {
        padding: 0 10px;
    }

    .attach_btn {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .request_credits {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .Post_Card {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .attach p {
        margin-top: 10px;
    }

    .add_modal_cmnt_main {
        width: 100%;
        min-width: 200px;
    }

    .txtar {
        height: 78px;
        width: 100%;
        border: 0px solid #ccc;
        resize: none;
        margin-top: 43px;
        margin-bottom: 10px;
        padding: 5px 8px;
    }

    .img_thght {
        width: 87px;
        height: 91px;
        left: 25px;
        top: 13px;
        overflow: hidden;
    }

    .thought_moadal_main .bottom .profile_img {
        width: 100%;
        max-width: 77px;
        height: 81px;
    }

    .empListSelectdiv {
        width: 75%;
        font-size: 11px;
    }

    .emplListDiv {
        justify-content: center;
    }

    .brdttgglebox {
        width: 32px;
        /* border: 2px solid #00000073; */
        height: 15px;
    }

    .boxyellow {
        position: absolute;
        width: 17px;
        height: 15px;
        background-color: #fac00d;
        border-radius: 5px;
        transform: translateX(0PX);
    }

    .afntytxtpp {
        font-size: 11px;
        line-height: 18px;
        color: #000;
        font-weight: 700;
    }

    .bsimgicon {
        color: #fac00d;
        font-size: 18px;
        margin-right: 9px;
        /* position: relative; */
    }

    .thought_moadal_main .bottom .file_upload {
        margin: 0px 0px 0px 0px;
        position: relative;
        width: 18px;
        height: 18px;
    }

    .pstimg {
        width: 18px;
        height: 18px;
        margin-right: 6px;
    }
}

@media(max-width:420px) {
    .afntytxtpp {
        font-size: 11px;
        line-height: 18px;
        color: #000;
        font-weight: 700;
        text-align: left;
        white-space: nowrap;
        /* margin-left: 13px; */
    }

    .emplListDiv {
        justify-content: flex-start;
        margin-left: 14px;
    }

    .empListSelectdiv {
        width: 94%;
        font-size: 11px;
    }

    .brdttgglebox {
        width: 43px;
        /* border: 2px solid #00000073; */
        height: 18px;
    }

    .boxyellow {
        position: absolute;
        width: 19px;
        height: 18px;
        background-color: #fac00d;
        border-radius: 5px;
        transform: translateX(0PX);
    }

    .empListSelectdiv {
        width: 100%;
        font-size: 13px;
        font-weight: 600;
        font-family: 'Plus Jakarta Sans';
        outline: none;
        border: 1px solid #00000070;
        border-radius: 5px;
        padding: 4px;
        height: 25px;
    }

    .empListSelectdiv {
        width: 91%;
        font-size: 10px;
        font-weight: 700;
    }

    .img_thght {
        width: 82px;
        height: 87px;
        left: 9px;
        top: 13px;
        overflow: hidden;
    }

    .thought_moadal_main .bottom .profile_img {
        width: 100%;
        max-width: 72px;
        height: 76px;
    }

    .thought_moadal_main .thoghts_txt_top_btm .top p {
        color: #ffffff;
        font-family: serif;
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 26px;
        text-align: center;
    }
}