.tree img {
  width: 50px;
  border-radius: 50%;
}

.tree {
  padding: 130px 0px 50px 0px;
  width: 100%;
  height: auto;

}


.tree ul {
  position: relative;
  padding: 1em 0;
  white-space: nowrap;
  display: flex;
  text-align: center;
  /* overflow: scroll; */
}

.tree ul .inn_line {
  padding: 50px 0 0;
}

.tree ul::after {
  content: '';
  display: table;
  clear: both;
}

.tree li {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 0px 0px 0 0px;
  width: 100%;
}

.tree li::before,
.tree li::after {
  content: '';
  position: absolute;
  top: -25px;
  right: 50%;
  border-top: 3px solid #03A9F4;
  width: 50%;
  height: 26px;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 5px solid #ccc;
}

.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

.tree li:only-child {
  padding-top: 0;
}

.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}

.tree li:last-child::before {
  border-right: 3px solid #03A9F4;
  border-radius: 0 5px 0 0;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 3px solid #03A9F4;
}

.tree li:first-child::after {
  border-radius: 5px 0 0 0;
}

.tree ul ul::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 50%;
  border-left: 3px solid #03A9F4;
  width: 0;
  height: 24px;
}

.tree li a {
  padding: 0;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  color: #333;
  position: relative;
  top: 1px;
}

.tree li a p {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 0;
}

.pimfprlimg {
  width: 100%;
  max-width: 40px;
  min-width: 40px;
  height: 40px;
}

.pimfprlimg img {
  width: 100%;
  height: 100%;
}

.ordgfdtlsdivmain {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 10px;
  padding: 10px;
  column-gap: 15px;
  width: 100%;
  max-width: 240px;
  margin: auto;
  cursor: pointer;
}
.ordgfdtlsdivmaindiffmanng {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 10px;
  padding: 10px;
  column-gap: 10px;
  width: 100%;
  max-width: 200px;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  margin: 5px auto;
}
.tree ul .orgmanagerdivdifff::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 50%;
  border-left: 0px solid #03A9F4;
  width: 0;
  height: 24px;
}
.orgmanagerdivdiffflistli {
  margin-left: 106px;
  position: relative;
}
.tree ul .orgmanagerdivdiffflistli:before {
  border-left: 3px solid #03A9F4;
  height: 100%;
  width: 0;
  top: 0;
  left: 42%;
  content: '';
  position: absolute;
}
.tree ul .orgmanagerdivdiffflistli:last-child::before {
  border-left: 0px solid #03A9F4;
  height: 100%;
  width: 0;
  top: 0;
  left: 42%;
  content: '';
  position: absolute;
}
.tree .orgmanagerdivdifff {
  position: relative;
  padding: 1em 0;
  white-space: nowrap;
  display: flex;
  text-align: center;
  flex-direction: column;
  /* overflow: scroll; */
}
.tree ul .orgmanagerdivdiffflistli:after {
  border-top: 3px solid #03A9F4;
  width: 12px;
  height: 0;
  left: 42%;
  top: 24px;
  content: "";
  content: '';
  position: absolute;
}
.ordgfdtlsdivmainflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.ordgdtklstxtp {
  font-size: 14px;
  color: #000;
  font-family: poppinslight;
  line-height: 16px;
  font-weight: 500;
  text-align: left;
  padding-bottom: 5px;
  width: 100%;
  max-width: 105px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.5s all ease-in;
  position: relative;
}

.ordgdtklstxtpbifh {
  font-size: 16px;
  color: #000;
  font-family: poppinslight;
  line-height: 18px;
  font-weight: 500;
  text-align: left;
  padding-bottom: 5px;
  transition: 0.5s all ease-in;
  display: none;
  left: 62px;
  position: absolute;
  white-space: nowrap;
  width: 100%;
  top: -35px;
  background-color: #fff;
  padding: 2px 10px;
  border-radius: 5px;
}

.ordgdtklstxtp:hover .ordgdtklstxtpbifh{
  display: block;
}



.ordgdtklstxtphhdr {
  font-size: 13px;
  color: #0000009e;
  font-family: poppinslight;
  line-height: 18px;
  font-weight: 600;
  text-align: left;
  padding-bottom: 5px;
}
.mredivmaintxtpp{
  font-size: 12px;
  color: #0000009e;
  font-family: poppinslight;
  line-height: 14px;
  font-weight: 400;
}

.ordgdtklstxtp span {
  font-size: 12px;
  color: #000;
  margin-left: 5px;
}

/* .ordgdtklstxtphhdrdiv{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
  } */
.treecontainer {
  width: 100%;
  max-width: 98%;
  margin: 0 auto;
  overflow-y: auto;
}

.ordgdtklstxtphhdrmrdr {
  font-size: 14px;
  color: #0000009e;
  font-family: poppinslight;
  line-height: 18px;
  font-weight: 600;
  text-align: left;
  /* padding-bottom: 5px; */
}

.tree ul .speccialdiv {
  position: relative;
  padding: 1em 0;
  white-space: nowrap;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.tree ul .speccialdiv::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 11%;
  border-left: none;
  width: 0;
  height: 309px;
}

.tree ul .speccialdivli:before {
  border-left: 3px solid #03A9F4;
  height: 100%;
  width: 0;
  top: 0;
  left: -12px;
  content: '';
  position: absolute;
}

.tree ul .speccialdivli:last-child::before {
  border-left: 0px solid #03A9F4;
  height: 100%;
  width: 0;
  top: 0;
  left: -12px;
  content: '';
  position: absolute;
}

.tree ul .speccialdivli:after {
  border-top: 3px solid #03A9F4;
  width: 12px;
  height: 0;
  left: -12px;
  top: 24px;
  content: "";
  content: '';
  position: absolute;
}

.ordgfdtlsdivmaindiff {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 10px;
  padding: 10px;
  column-gap: 10px;
  width: 100%;
  max-width: 176px;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
}

/* .ordgfdtlsdivmaindiff::after {
  position: absolute;
  right: auto;
  left: 50%;
  border-left: 3px solid #03A9F4;
} */

/* .ordgfdtlsdivmaindiff::before {
  content: '';
  position: absolute;
  top: 26px;
  left: 1%;
  border-left: 3px solid #03A9F4;
  width: 9px;
  height: 2px;
  transform: scaleX(3.5);
} */
.speccialdivli {
  margin-left: 106px;
  position: relative;
}

.ordgfdtlsdivmainnew {
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 10px;
  padding: 10px;
  column-gap: 10px;
  width: 100%;
  max-width: 230px;
  margin-left: 2px;
}

.tree ul .orgchrdtlsdivul::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 50%;
  border-left: 3px solid #03A9F4;
  width: 0;
  height: 48px;
}

.ordgfdtlsdivmaindtlsdiv {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 10px;
  padding: 10px;
  column-gap: 27px;
  width: 100%;
  max-width: 240px;
  margin-top: 30px;
}

.tree ul .inn_linemaindiv {
  padding: 26px 0 0;
}

.ordgfdtlsdivmainnewdrtlsdiv {
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 10px;
  padding: 10px;
  column-gap: 10px;
  width: 100%;
  max-width: 230px;
  margin-left: 2px;
  margin: auto;
  cursor: pointer;
}



.tree ul .speccialdivlidtls::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 32%;
  border-left: 3px solid #03A9F4;
  width: 0;
  height: 309px;
}

.speccialdivlidtsldivmn {
  margin-left: 171px;
}

.treecontainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.treecontainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}