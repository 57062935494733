/* .advocacySection {
    padding-top: 125px;
    margin-bottom: 25px;
} */

.nameFigAdvo {
    width: 100%;
    max-width: 25%;
    /* background-color: #fff; */
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-bottom: 25px; */
    /* height: 172px; */
}

.messagemaindiv {
    width: 25px;
    height: 25px;
}

.messagemaindiv img {
    width: 100%;
    height: 100%;
}

.messagemaindivratingflx {
    /* width: 100%; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 15px;
    align-self: flex-start;
}

.advocayskilldiv {
    width: 100%;
    max-width: 74%;
    /* padding-top: 6px; */

}

.advocayskillengage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 2%;
    padding-top: 10px;
    position: relative;
    padding-bottom: 0px;
}

.advoProFig {
    width: 150px;
    height: 150px;
    margin: 0;
    border-radius: 50%;
    position: absolute;
    top: -55px;
}

.advbhdivmaingh {
    padding-top: 90px;
}

.advoProFig img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.proAdvoName {
    color: #0E24CC;
    font-family: 'Inter';
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 4px 0;
}

.proAdvoDesig {
    color: #263238;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 10px 0;
}

.proAdvoEmpId {
    color: #263238;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.nameTextAdvo {
    text-align: center;
}

.topPicAdvoDiv {
    width: 100%;
    background-color: #fff;
    padding: 19px 0;
}

.topPicHead {
    color: #0E24CC;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0 19px 16px 19px;
    border-bottom: 1px solid #DDDDDD;
}

.topPicUl {
    padding: 0 20px;
    max-height: 290px;
}

.turnTopText {
    color: #000;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 67%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.turnTopFig {
    width: 100px;
    height: 64px;
    margin: 0;
    border-radius: 7px;
}

.turnTopFig img {
    width: 100%;
    height: 100%;
    border-radius: 7px;
}

.topPicLi {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.unAdvoDiv {
    background-color: #fff;
    /* padding: 19px; */
    width: 100%;
    border-radius: 5px;
    margin-top: 0px;
    display: flex;
    margin-bottom: 7px;
}

.unAdvoDivTop {
    display: flex;
    align-items: center;
    padding: 15px 20px 10px 20px;
    border-bottom: 1px solid rgba(198, 198, 210, 0.26);
}

.AdvoDivFig {
    width: 25px;
    height: 25px;
    margin: 0 3px 0 0;
}

.AdvoDivFig img {
    width: 100%;
    height: 100%;
}

.unAdvoDivTopHead {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: normal;
    color: #263238;
    padding: 0;
    margin: 0;
    margin-left: 10px;
}

.unAdvUl {
    padding: 5px 20px 5px 0px;

}

.unAdvLi {
    background-color: #E9E9E9;
    border-radius: 8px;
    padding: 10px 12px;
    margin-top: 0px;
    width: 100%;
}

.unAdvLi span {
    color: #000;
    font-family: 'NoirProRegular';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;

}

.unAdvLiActive {
    background-color: #d3a145;
}

.unAdvLiActive span {
    color: #fff;
}

/* .advoRateSkillDiv {
    width: 74%;
} */

.advoAdvocyDiv {
    width: 100%;
    max-width: 100%;
}

.advocySkillMainDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.myAdvoProTab .react-tabs__tab-list {
    display: flex;
    justify-content: space-around;
    border: 0;
    background-color: #fff;
    padding: 0px 30px;
    border-radius: 10px;
    margin-bottom: 0px;
}

.myAdvoProTab .react-tabs__tab {
    color: #263238;
    font-family: 'Inter';
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
}

.myAdvoProTab .react-tabs__tab--selected {
    background-color: transparent;
    border: 0;
    color: #0E24CC;
}

.myAdvoPanelDiv {
    width: 100%;
    background-color: #fff;
    /* padding: 15px 25px 25px; */
    border-radius: 10px;
    margin-bottom: 30px;
    position: relative;
    padding: 15px;
}

.advoSwipeShortDiv {
    padding: 0px 0px 15px 0px;
}

.resNavHeader .myAdvoSwiper {
    padding-top: 10px;
}

.myAdvoLeftPan {
    width: 70%;
    height: 270px;
    position: relative;
}

.myAdvLFig {
    width: 100%;
    height: 100%;
    margin: 0;
}

.myAdvLFig img {
    width: 100%;
    height: 100%;
}

.myAdvInnerTextDiv {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(43, 43, 43, 0.71);
    padding: 30px 50px;
}

.advLorWhit {
    color: #FFF;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.advLorPicFig {
    width: 40px;
    height: 40px;
    margin: 0 5px 0 0;
    border-radius: 50%;
}

.advLorPicFig img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.advLorNm {
    color: #FFF;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 1px 0;
}

.advLorDt {
    color: #FFF;
    font-family: 'Inter';
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.advLorMainDiv {
    display: flex;
    align-items: center;
    padding-top: 20px;

}

.whitAdvFigDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.advLorPara {
    color: #FFF;
    font-family: 'Inter';
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 10px 0;
}

.advLorSmallPara {
    color: #FFF;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 20px 0;
}

.advLearnText {
    color: #FFF;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 8px;
}

.advLearnIcon {
    font-size: 18px;
    color: #FFB906;
}

.advLearnMainDiv {
    display: flex;
    align-items: center;
}

.myAdvoRightPan {
    width: 29%;
    height: 270px;
    position: relative;
}

.myRghtFig {
    width: 100%;
    height: 100%;
    margin: 0;
}

.myRghtFig img {
    width: 100%;
    height: 100%;
}

.myAdvoPanDiv {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.myAdvoRightText {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(43, 43, 43, 0.71);
    padding: 30px 20px;
}

.engHead {
    color: #FFF;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 40px 0;
}

.csrText {
    color: #FFF;
    font-family: 'Inter';
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 50px 0;
}

.advoSlides {
    width: 100%;
    max-width: 70%;
    min-width: 70%;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 3px;

}

.advoSwiperDiv {
    padding-top: 20px;
}

.advolisflxdiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 15px;
}

.advoSlidesmanydiv {
    width: 100%;
    max-width: 23%;
    min-width: 23%;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 2px;
    overflow: hidden;
    position: relative;
    z-index: 5;
    align-self: flex-start;
}

.advocacycreatemainwidhgdiv {
    width: 100%;
    max-width: 27%;
    min-width: 27%;
}


.flxdivadvosildesdiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flxdivadvosildesdivabs {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
}

.advoSliFig {
    width: 100%;
    height: 380px;
    margin: 0px 0 9px 0;
    transition-property: all;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    /* padding: 5px; */
    position: relative;
    z-index: 1;
}

/* .advoSliFig::after {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #0000006e;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 2;
    transition-property: all;
    overflow: hidden;
    transition-duration: 800ms;
} */

.advoSliFig img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition-duration: 800ms;
    cursor: pointer;
}

.advoSliFig img:hover {
    transform: scale(1.1);
}



.prfladtxtavdocacy {
    color: #000000;
    font-size: 13px;
    line-height: 16px;
    font-family: 'NotoSans';
    font-weight: 500;
    padding-bottom: 4px;
}

/* 
.advoSliFig img:hover {
    transform: scale(1.1);

} */
.advocacyfrmdivmain{
    width: 100%;
    max-width: 84%;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px 70px;
    border-radius: 30px;
}
.bgcontainerupldboxsgwgrdiv {
    /* border: 1px solid #000; */
    /* padding: 7px; */
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 15px 0px 15px 0px;
    box-shadow: 0px 4px 9px 0px #ccc;
}


.bgcontaineruplddivnew {
    width: 100%;
    max-width: 100px;
    min-width: 100px;
    height: 100px;
    background-color: #dedede;
    /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
}
.typstrningwhselctnew {
    width: 100%;
    /* border: 1px solid #000; */
    padding: 17px 15px;
    border-radius: 4px;
    margin: 10px 0px 10px 0px;
    border: none;
    box-shadow: 0px 4px 9px 0px #ccc;
}
.txtdivallartadvcacytfnew {
    width: 100%;
    height: 116px;
    resize: none;
    margin: 10px 0px 10px 0px;
    /* border: 1px solid #000; */
    border-radius: 4px;
    padding: 12px;
    border: none;
    box-shadow: 0px 4px 9px 0px #ccc;
}
.kywrdscrdtfredvdivflx{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 36px;
}
.trainingpageleftdivmainnew {
    padding-bottom: 10px;
    width: 100%;
    max-width: 48%;
}

.imgnmnwhdivadvo {
    border: 1px solid #fff;
    border-radius: 50%;
}

.prflimgtxtflxadvo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 5px 10px;
    background-color: #fff;
    box-shadow: 0px 0px 2px 0px #ccc;
}

.advcydiv {
    width: 100%;
    height: 273px;
    overflow: hidden;
    transition-property: all;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    /* padding: 2%; */
    overflow: hidden;
}

.advcydiv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition-duration: 800ms;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
}

.advcydiv img:hover {
    transform: scale(1.1);
    /* border-radius: 5px; */
    /* overflow: hidden; */
}

.advoSlidesmanydiv .advcydiv img {
    transition: all 0.5s ease-in-out;
}



.advSliLoHeadtxt {
    color: #263238;
    font-family: 'NotoSans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 36px;
    margin: 10px 0px;
}

.prfladtxt {
    color: #000;
    font-size: 15px;
    line-height: 18px;
    font-family: 'NoirProLight';
    font-weight: 700;
    padding-bottom: 4px;
}

.prfldttxt {
    color: #000;
    font-size: 13px;
    line-height: 18px;
    font-family: Noirprolight;
    font-weight: 500;
}

.prfdtlsadvc {
    margin-left: 20px;
}

/* .advSliLoHeadtxt:hover{
    color:  #d3a145;;
} */
.advSliLoHeadtxt:hover.advcydiv {
    color: #d3a145;
}

/* .advoSlideText {
    padding: 0 10px 0px 10px;
} */

.imgnmnwhdiv {
    border: 1px solid #fff;
    border-radius: 50%;

}

.flxdivscrl {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 2%;
    margin: 0;
}

.advoSlidesmanydivsingle {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 2px;
    overflow: hidden;
    position: relative;
    z-index: 5;
    align-self: flex-start;
}

.advocacycreatemaindiv {
    width: 100%;
    padding: 5px 20px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border: 1px solid #ccc;
    /* margin-top: 10px; */
    border-radius: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 7px;
    margin-bottom: 14px;
}

.addimgdiv {
    width: 100%;
    height: 39px;
    max-width: 38px;
    min-width: 38px;
}

.addimgdiv img {
    width: 100%;
    height: 100%;
}

.prflimgtxtflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 5px 10px;
    background-color: #fff;
    box-shadow: 0px 1px 5px 1px #ddd;
    border-radius: 5px;
}

.crtadvocacytxtypp {
    font-size: 15px;
    line-height: 20px;
    color: #000;
    font-weight: 600;
    font-family: noirprolight;
}

.prflsadvctimg {
    width: 100%;
    max-width: 33px;
    min-width: 33px;
    height: 34px;
}

.prflsadvctimg img {
    width: 100%;
    height: 100%;
    border-radius: 50%;

}

.advSliLoHead {
    color: #263238;
    font-family: 'NotoSans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 37px;
    margin: 2px 0 5px 0;
}

.advBlkLorNm {
    color: #000;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 1px 0;
}

.advLorBlkDt {
    color: #000;
    font-family: 'Inter';
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.myAdvoSwiper .swiper-wrapper {
    padding-bottom: 10px;
    padding-left: 16px;
    padding-top: 10px;
}

.advoLoreTwoDiv {
    width: 100%;
    background-color: #fff;
    padding: 25px 38px;
    border-radius: 12px;
}

.advShareFig {
    width: 19px;
    height: 19px;
    margin: 0 10px 0 0;
}

.advShareFig img {
    width: 100%;
    height: 100%;
}

.advShrText {
    color: #3D3D3D;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.advShrMainDiv {
    display: flex;
    align-items: center;
}

.advShrMainDivAn:hover {
    text-decoration: none;
}

.hrsAgoText {
    color: #3D3D3D;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.loreTwoDiv {
    width: 48%;
    background-color: #EFEFEF;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.loreTwoInText {
    color: #000;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 53px;
}

.adcLorPicFig {
    width: 100px;
    height: 89px;
    margin: 0;
    border-radius: 7px;
}

.adcLorPicFig img {
    width: 100%;
    height: 100%;
    border-radius: 7px;
}

.shrAdvDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.loreTwoTextMainDiv {
    width: 70%;
}

.loreTwoDivParent {
    display: flex;
    justify-content: space-between;
}

.testiDiv {
    padding: 0 40px;
}

.testiDivLi {
    margin-bottom: 2px;
}

.testiDivLi span {
    color: #000;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.testiDivUl {
    padding-bottom: 25px;
    border-bottom: 1px solid #000;
    margin-bottom: 30px;
}

.cmntAdvText {
    color: #000;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 20px 0 0;
}

.likeAdFig {
    width: 35px;
    height: 34px;
    margin: 0;
}

.likeAdFig img {
    width: 100%;
    height: 100%;
}

.cmntFigDiv {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.cmntFigAfterDiv {
    width: 100%;
    height: 90px;
    background-color: #D9D9D9;
    margin-bottom: 80px;
}

.advChtTwoFig {
    width: 574px;
    height: 324px;
    margin: 0;
}

.advChtTwoFig img {
    width: 100%;
    height: 100%;
}

/* Advocard part */
.advoCardDiv {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 50px;
    margin-top: 40px;
}

.advoCard {
    /* width: 33%; */
    border-radius: 10px;
    height: auto;
    display: grid;
    gap: 10px;
    background-color: #FFF;
}

.advoCardTop {
    padding: 5px;
}

.advocardTxt {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px;
}

.advoCardtxtLeft {
    width: 70%;
}

.advocardhead {
    color: #263238;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    height: 32px;
}

.advocardpara {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 45px;
}

.advoCardbtn {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #0E24CC;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    border: none;
}

.advoimgwrap {
    width: 100%;
}

.advoimgwrap img {
    width: 100%;
    height: 100%;
}

.advoimgwrap1 {
    grid-area: one;
    height: 75px;
}

.advoimgwrap2 {
    grid-area: two;
    height: 75px;
}

.advoimgwrap3 {
    grid-area: three;
    height: 150px;
}

.advoimgwrap4 {
    grid-area: four;
    height: 150px;
}

.advoimgwrap5 {
    grid-area: five;
    height: 75px;
}

.advorow {
    display: grid;
    /* grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto; */
    grid-template-areas: "one two three"
        "four four three"
        "four four five";
    grid-gap: 10px;
}

.advoSwipeDetailDiv {
    height: auto;
    display: flex;
    align-items: center;
    padding: 20px 0px 0px 0px;
    /* border: 2px solid red; */
}

.advoSwp {
    width: 100%;
    display: flex;
    gap: 30px;
    padding-top: 15px;
}

.advoSwipeLeftFig {
    min-width: 230px;
    max-width: 230px;
    width: 100%;
    height: 230px;
    margin: 0;
}

.advoSwipeLeftFig img {
    width: 100%;
    height: 100%;
}

.advoSwipeRightDiv {
    /* padding-left: 25px;
    padding-top: 15px; */
    width: 100%;
    max-height: 100%;
    height: 100%;
    padding-top: 20px;
}

.icndivbg {
    align-items: center;
    background-color: #01579b;
    border-radius: 50%;
    display: flex;
    height: 30px;
    justify-content: center;
    margin: 10px;
    width: 30px;
}

.icndivbg i {
    color: #fff;
    font-size: 14px;
}

.advoSwipeHead {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 7px 0;
    font-family: 'Inter';
}

.advoSwipeText {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    text-align: justify;
    font-family: 'Inter';
}

.advSwipRightTextDiv {
    max-height: 83%;
    height: 100%;
    overflow-y: auto;
    margin-bottom: 12px;
    overflow-y: auto;
}

.advoBackBtn {
    position: absolute;
    top: 9px;
    left: 24px;
    background-color: transparent;
    border: 0;
    font-size: 17px;
    font-weight: 700;
    color: #0E24CC;
    font-family: 'Inter';
    text-transform: uppercase;
}

.hubNetWorkingSection .card-header {
    padding: 0.1rem 0.2rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.svMsgFig i {
    font-size: 20px;
    color: #8a5a2d;

}

.advoBackBtn i {
    color: #000;
    font-size: 18px;
}

.advoBackBtnDiv {
    text-align: right;
}

.advoSwiperHideDiv {
    display: none;
}

.imgdivmain {
    width: 100%;
    margin: 0 auto;
    height: 400px;
    max-width: 100%;
    min-width: 100%;
}

.shareimggghhj {
    box-shadow: 0px 0px 5px 0px #ccc;
    width: 100%;
    max-width: 3%;
    min-width: 5%;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.sharetdiv {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 12px;
}

.shareimggghhj img {
    width: 25px;
    height: 25px;
}

.imgdivmain img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

/* .advocacySection .swiper-button-prev {
    position: absolute !important;
    left: -1% !important;
    top: 50% !important;
    background-color: #fff;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px #ccc;
}

.advocacySection .swiper-button-prev:after {
    content: 'prev';
    font-size: 22px !important;
    color: red;
    font-weight: 800;
}

.advocacySection .swiper-button-next {
    position: absolute !important;
    right: -1% !important;
    top: 50% !important;
    background-color: #fff;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px #ccc;
}

.advocacySection .swiper-button-next:after {
    content: 'next';
    font-size: 22px !important;
    color: red;
    font-weight: 800;
} */

/* .advocacySection .swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    overflow: clip;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
}

.advocacySection .advoSwiperDiv {
    padding-top: 20px;
    position: relative;
}

.advocacySection .swiper-wrapper {
    padding-bottom: 10px;
    padding-left: 5px;
    padding-top: 10px;
} */
.advocacySection .rts___nav___btn {
    align-items: center;
    background-color: #d3a145;
    border-radius: 50%;
    /* box-shadow: 4px 4px 7px 0 rgba(0, 0, 0, .15), inset 1px 2px 5px 0 rgba(0, 0, 0, .15); */
    color: #fff !important;
    content: "\f105" !important;
    display: flex;
    font-family: FontAwesome !important;
    font-size: 27px !important;
    height: 35px;
    justify-content: center;
    line-height: 1;
    opacity: 1 !important;
    width: 35px;
    border: 1px solid #fff;
}

.advocacySection .rts___tabs___container {
    display: flex;
    overflow: auto;
    align-items: center;
}

.advocacySection .rts___tabs___container {
    display: flex;
    overflow: auto;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 8px;
    border-radius: 10px;
    height: 65px;
    justify-content: flex-start;
}

.advocacySection .rts___tabs {
    display: flex;
    overflow: auto;
    padding: 0px 0px !important;
    justify-content: flex-start !important;
    align-items: center !important;
    column-gap: 23px;
}

.advoSlidesmanydivmainflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 33px;
    row-gap: 20px;
    padding-top: 19px;
    padding-bottom: 15px;
}
.advocacytabpadnhg{
    padding: 20px 0px ;
    width: 100%;
    max-width: 100%;
}

.advocacymainsmalldivflx {
    display: flex;
    justify-content: flex-start;
    /* align-items: baseline; */
    column-gap: 35px;
    /* row-gap: 13px; */
    padding-top: 17px;
}

.advocacySection .rts___tab___selected {
    /* background-color: var(--rts-primary-color); */
    color: var(--rts-white-color);
    box-shadow: #00000029 0 1px 4px;
    background-image: linear-gradient(#ed3b4b, #4c3b92);
}

/* Single Advocacy Page start */
.singleadvocacypagemain {
    width: 100%;
    height: auto;
    padding: 135px 0px 35px 0px;
}

.singleadvocacyimage {
    width: 100%;
    height: 350px;
    position: relative;
}

.singleadvocacyimage img {
    width: 100%;
    height: 100%;
}

.singleadvocacydivbg {
    background-color: #fff;
    width: 100%;
    height: auto;
    max-width: 80%;
    /* margin: auto; */
    padding: 20px;
    position: relative;
    left: 50%;
    transform: translatex(-50%);
    /* bottom: -10%; */
    margin-top: -145px;
}

.advocacytextdivhhdr {
    font-size: 22px;
    line-height: 27px;
    color: #000;
    font-weight: 600;
    font-family: Noirprolight;
    padding-bottom: 32px;
}

.imgnmnwhdivimg {
    width: 100%;
    max-width: 6%;
    min-width: 6%;
}

.shaeredibimgh {
    width: 25px;
    height: 25px;
    border: none;
}

.shaeredibimgh img {
    width: 100%;
    height: 100%;
    margin-left: auto;
}

.rwebsharedivimgsk {
    margin-left: auto;
}

.prflsadvctimgmain {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 49px;
}

.prflsadvctimgmain img {
    width: 100%;
    height: 100%;
}

.loremsingleadvocaytext {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-family: noirprolight;
    font-weight: 500;
    padding-top: 30px;
    /* padding: 0px 12px; */
    padding-left: 20px;
    padding-right: 20px;
}


/* Single Advocacy Page End */
.advocacyaddformmain {
    width: 100%;
    height: auto;
    padding: 130px 0px 20px 0px;
}

.homePgCreModSubmitDivbtnkl {
    padding: 10px 0px;
    margin: auto;
    display: flex;
    justify-content: center;
}

.uploaddocumentbrdravdocay {
    width: 100%;
    max-width: 100%;
    border: 1px solid #000;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 4px;
    margin: 5px 0px 0px 0px;
    height: 131px;
}

.starriconyllw {
    font-size: 20px;
    color: #fac00d;
}

.startnametxextadvocaymndtry {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
}

.txtdivallartadvcacytf {
    width: 100%;
    height: 100px;
    resize: none;
    margin: 10px 0px 0px 0px;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 12px;
}

@media (max-width: 1399px) {
    .advocardhead {
        font-size: 20px;
        height: 25px;
    }

    .advoSlidesmanydiv {
        width: 100%;
        max-width: 30%;
        min-width: 30%;
        border-radius: 5px;
        background: #FFF;
        box-shadow: 0px 0px 5px 0px #ccc;
        padding: 2px;
        overflow: hidden;
        position: relative;
        z-index: 5;
        align-self: flex-start;
    }

    .advoCardbtn {
        padding: 5px 10px;
    }

    .advorow {
        gap: 8px;
    }

    .advoCard {
        gap: 5px;
    }

    .advoCardDiv {
        gap: 40px;
    }

    .advoimgwrap1,
    .advoimgwrap2,
    .advoimgwrap5 {
        height: 55px;
    }

    .advoimgwrap4,
    .advoimgwrap3 {
        height: 110px;
    }

    .unAdvoDivTopHead {
        font-size: 15px;
        white-space: nowrap;
        margin-left: 3px;
    }

    .unAdvoDivTop {
        padding: 5px 20px 5px 20px;
    }
}

@media (max-width: 1199px) {
    .unAdvoDivTopHead {
        font-size: 13px;
    }

    .unAdvoDivTop {
        padding: 5px 18px 5px 13px;
    }

    .AdvoDivFig {
        width: 22px;
        height: 22px;
    }

    .unAdvLi span {
        font-size: 13px;
    }
}

@media (max-width: 991px) {
    .advocardhead {
        font-size: 18px;
        height: 22px;
    }

    .advocacymainsmalldivflx {
        flex-wrap: wrap;
    }

    .advoSlides {
        width: 100%;
        max-width: 100%;
    }

    .advocacycreatemain {
        width: 100%;
        max-width: 100%;
    }

    .advocacycreatemainwidhgdiv {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .advocacycreatemaindiv {
        margin-top: 15px;
    }

    .advoSlidesmanydiv {
        width: 100%;
        max-width: 30%;
        min-width: 30%;
        border-radius: 5px;
        background: #FFF;
        box-shadow: 0px 0px 5px 0px #ccc;
        padding: 15px;
        overflow: hidden;
        position: relative;
        z-index: 5;
    }

    .prfladtxt {
        font-size: 13px;
        line-height: 18px;
    }

    .advcydiv {
        width: 100%;
        height: 170px;
    }

    .advocayskilldiv {
        width: 100%;
        max-width: 100%;
    }

    .odfr {
        order: 2;
    }

    .odrsfr {
        order: 1;
    }

    .unAdvoDiv {

        margin-bottom: 10px;
    }

    .advoCard {
        gap: 0px;
    }

    .advoSwp {
        flex-direction: column;
    }

    .advoSwipeLeftFig {
        margin: 0px auto;
    }
}

@media(max-width:767px) {
    .advocacymainsmalldivflx {
        flex-wrap: wrap;
    }
    .kywrdscrdtfredvdivflx{
        flex-wrap: wrap;
    }
    .trainingpageleftdivmainnew{
        width: 100%;
        max-width: 100%;
    }
    .advocacyfrmdivmain {
        width: 100%;
        max-width: 88%;
        padding: 10px 26px;
    }
    .advocacyaddformmain {
        padding: 130px 0px 80px 0px;
    }

    .advoSlidesmanydiv {
        width: 100%;
        max-width: 47%;
        min-width: 47%;
    }

    /* .imgnmnwhdiv {
        width: 100%;
        max-width: 9%;
        min-width: 9%;
    } */

    .advoSlidesmanydivmainflx {
        column-gap: 25px;
        row-gap: 20px;
        padding-top: 10px;
        padding-bottom: 15px;
    }
}

/* @media(max-width:576px) {
    .advoSlidesmanydiv {
        width: 100%;
        max-width: 31%;
        min-width: 1%;
        border-radius: 5px;
        background: #FFF;
        box-shadow: 0px 0px 5px 0px #ccc;
        padding: 15px;
        overflow: hidden;
        position: relative;
        z-index: 5;
    }
} */

@media (max-width: 480px) {
    .advoSwipeText {
        font-size: 13px;
    }

    .advoSlidesmanydiv {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        border-radius: 5px;
        background: #FFF;
        box-shadow: 0px 0px 5px 0px #ccc;
        padding: 15px;
        overflow: hidden;
        position: relative;
        z-index: 5;
    }

    .advocacySection .swiper-button-prev {
        position: absolute !important;
        left: -1% !important;
        top: 60% !important;
        background-color: #fff;
        width: 40px !important;
        height: 40px !important;
        border-radius: 50%;
        box-shadow: 0px 0px 5px 0px #ccc;
    }

    .advocacySection .swiper-button-next {
        position: absolute !important;
        right: -1% !important;
        top: 60% !important;
        background-color: #fff;
        width: 40px !important;
        height: 40px !important;
        border-radius: 50%;
        box-shadow: 0px 0px 5px 0px #ccc;
    }
    .advocacyfrmdivmain {
        width: 100%;
        max-width: 100%;
        padding: 10px 26px;
    }
    .bgcontaineruplddivnew {
        width: 100%;
        max-width: 70px;
        min-width: 70px;
        height: 70px;
    }
}
