.notiMidThgtDiv {
    min-height: 100vh;
}

.notifiInnerDiv {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
}

.notifiHead {
    font-size: 22px;
    font-weight: 700;
    color: #000;
    margin: 0 0 10px 0;
}

.allNotiTabDiv .react-tabs__tab-list {
    border-bottom: 0;
    display: flex;
    column-gap: 10px;
    margin-bottom: 20px;
}

.allNotiTabDiv .react-tabs__tab {
    font-size: 17px;
    padding: 2px 8px;
    color: #3C424F;
    font-weight: 600;
    background-color: #fff;
}

.allNotiTabDiv .react-tabs__tab--selected {
    border: 0;
    border-radius: 0;
    background-color: #D5DFF6;
    color: #0A4FE2;
}

.allNotiSubHeads {
    color: #3C424F;
    font-size: 17px;
    font-weight: 600;
    margin: 0 0 10px 0;
}

.notiPersonImg {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0;
    position: relative;
}

.notiPersonImg img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.notiActualImgDiv {
    display: flex;
    column-gap: 20px;
    /* align-items: center; */
    padding: 7px 10px;
    margin-bottom: 8px;
    cursor: pointer;
}

.notiBellSpan {
    width: 25px;
    height: 25px;
    background-color: #0A4FE2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 13px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.notiInnerMainHead {
    color: #3C424F;
    font-size: 16px;
    line-height: normal;
    font-weight: 600;
    margin: 0 0 4px 0;
}

.notiInnerMainHead span {
    font-weight: 400;
}

.hourTimeNoti {
    color: #727272;
    font-weight: 400;
    line-height: normal;
    margin-right: 10px;
}

.planNoti {
    color: #727272;
    font-weight: 400;
    line-height: normal;
}

.newNotifiDiv {
    margin-bottom: 12px;
}

.notiSingleSection {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.384);
    display: flex;
    justify-content: center;
    align-items: center;
}

.notisignalInnerDiv {
    max-width: 400px;
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 6px;
    padding: 20px 25px;
    margin-bottom: 8px;
    position: relative;
}

.notImgFig {
    width: 50px;
    height: 50px;
    margin: 0;
    border-radius: 50%;
}

.notImgFig img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.abcNotName {
    font-size: 17px;
    font-weight: 600;
    color: #3C424F;
    margin: 0 0 2px 0;
}

.timeNotSinText {
    font-size: 15px;
    font-weight: 500;
    color: #727272;
    margin: 0;
}

.notSingMainDiv {
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin-bottom: 12px;
}

.loremSingleText {
    font-size: 12px;
    line-height: normal;
    margin: 0 0 10px 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.singlePopFig {
    width: 100%;
    height: 230px;
    margin: 0;
}

.singlePopFig img {
    width: 100%;
    height: 100%;
}

.cancelSingleBtn {
    position: absolute;
    top: 15px;
    right: 27px;
    background-color: transparent;
    border: 0;
    font-size: 21px;
    color: #3C424F;
}

@media(max-width: 479px) {
    .notiInnerMainHead {
        font-size: 12px;
    }

    .hourTimeNoti {
        font-size: 11px;
    }

    .planNoti {
        font-size: 11px;
    }

    .notiActualImgDiv {
        column-gap: 7px;
    }

    .notiPersonImg {
        width: 52px;
        height: 52px;
    }

    .notifiInnerDiv {
        padding: 20px 18px;
    }
}