.allcerifivatesmainsection {
    padding: 120px 0px 50px 0px;
    width: 100%;
    height: auto;
    display: block;
}

.allcerificatebgmain {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border-radius: 15px;
    max-width: 74%;
    margin: auto;
    padding: 20px 30px;
}

.cerificatemainbhbrdr {
    width: 100%;
    max-width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px #cfcfcf;
    border-radius: 10px;
    padding: 15px 30px;
    margin-bottom: 20px;
}

.certificateimage {
    width: 100%;
    max-width: 126px;
    min-width: 126px;
    height: 115px;
}

.certificateimage img {
    width: 100%;
    height: 100%;
    /* border-radius: 50%; */
}

.certificatetyoetxtpp {
    color: #000;
    font-family: noirproregular;
    font-size: 28px;
    font-weight: 800;
    line-height: 32px;
    margin-bottom: 8px;
}

.coursttiltlecertifacttxtp {
    color: #000;
    font-size: 18px;
    line-height: 24px;
    font-family: NoirProLight;
    font-weight: 600;
    margin-bottom: 8px;
}

.coursttiltlecertifacttxtp span {
    color: #000;
    font-family: notosans;
    font-size: 18px;
    font-weight: 800;
    line-height: 22px;
    margin-left: 10px;
}

.cerificatemainbhbrdrdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 25px;
}

.cerificatemaonsectioneduct {
    width: 100%;
    height: auto;
    max-Width: 85%;
    margin: auto;
    padding-Bottom: 24px;
    padding-top: 20px;
    background-color: #fff;
    padding-left: 20px;
    /* margin: 30px; */
    padding-right: 20px;
    margin-bottom: 30px;
    margin-top: 40px;
    border-radius: 10px;

}
.downloaddivimgainknamdivflx{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.downloaddivimgainknam{
    width: 100%;
    max-width: 36px;
    min-width: 36px;
    height: 38px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 50%;
    padding: 6px;
    margin-bottom: 10px;
}
.downloaddivimgainknam img{
    width: 100%;
    height: 100%;
}

.certificateimagebg {
    background-Position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
}

.cerfytvatedallin {
    padding: 50px 0px;
}

.cerfytvatedalmainkft {
    margin-left: 100px;
}

.comaopnayslogoedu {
    background-Position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    text-align: left;
    max-Width: 70px;
    min-width: 70px;
    height: 70px;
}

.certificatenametxtpp {
    color: #b91912;
    font-Size: 100px;
    font-Weight: bolder;
    text-align: left;
    margin-Top: 10px;
    font-Family: "Noto Sans, sans-serif";
    line-Height: normal;
    margin-Bottom: 2px;
}

.certificatenametxtppnich {
    color: #2a4e83;
    font-Size: 35px;
    text-Align: left;
    font-Family: sans-serif;
    line-Height: normal;
    font-Weight: 400;
    letter-Spacing: 10px;
    text-Transform: uppercase;
    margin-Top: 0;
    margin-Bottom: 0;
}
.CertificateGalaSealimg{
    background-Position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    max-Width: 300px;
    min-Width: 300px;
    height: 300px
}
.delightedtxtt{
    font-Size: 21px;
    color: #9d9d9d;
    font-Weight: 400;
    font-Family: "Noto Sans, sans-serif";
    line-Height: normal;
    margin: 30px 0px 20px 0px;
}
.traineeNametxtpp{
    color: #b91912;
    font-Size: 50px;
    font-Weight: bolder;
    margin-Top: 10px;
    font-Family: "goudy-old-style";
    line-Height: normal;
    margin: 30px 0px;
}
.educationttxppcrtt{
    font-Size: 21px;
    color: #9d9d9d;
    font-Weight: 400;
    font-Family: "Noto Sans, sans-serif";
    line-Height: normal;
    margin: 30px 0px 20px 0px
}
.completingtxtppjocertxded{
    color: #2a4e83;
    font-Size: 30px;
    font-Family: "sans-serif";
    line-Height: normal;
    font-Weight: 400;
    text-Transform: uppercase;
    margin-Top: 0;
    margin: 30px 0px 0px 0px;
}
.traimsignbvjshnamamsnbtxtuicerdr{
    color: #2a4e83;
    font-Size: 40px;
    font-Family: "sans-serif";
    line-Height: normal;
    font-Weight: 600;
    text-Transform: uppercase;
    margin-Top: 0px;
    margin: 10px 0px;
}
.certficateeduftrui{
    padding: 50px 0px;
}
.certficateeduftruitr{
    margin: 0; 
    padding: 40px 0px
}
.elitelogoeducrtdfdivimg{
    background-Position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    max-Width: 142px;
    min-Width: 142px;
    height: 76px;
}
.Certificatenotxttpopp{
    font-Size: 18px;
    color: #9d9d9d;
    text-Align: center;
    font-Weight: 400;
    font-Family: "Noto Sans, sans-serif";
    line-Height: normal;
    text-Transform: uppercase;
    margin-Bottom: 12px;
}
.certificateNoppplctrxrtp{
    font-Size: 18px;
    color: #9d9d9d;
    text-Align: center;
    font-Weight: 400;
    font-Family: "Noto Sans, sans-serif";
    line-Height: normal;
    margin: 0
}
.certficateeduftruitbls{
    width:90%;
}

@media(max-width:1070px){
    .CertificateGalaSealimg {
        width: 100%;
        max-Width: 230px;
        min-Width: 230px;
        height: 230px;
    }
    .certificatenametxtpp {
        color: #b91912;
        font-Size: 80px;
    }
    .completingtxtppjocertxded {
        color: #2a4e83;
        font-Size: 24px;
    }
    .cerfytvatedalmainkft {
        margin-left: 75px;
    }
    .traimsignbvjshnamamsnbtxtuicerdr {
        color: #2a4e83;
        font-Size: 32px;
    }
    .traineeNametxtpp {
        color: #b91912;
        font-Size: 44px;
    }
    .elitelogoeducrtdfdivimg {
        width: 100%;
        max-Width: 120px;
        min-Width: 114px;
        height: 66px;
    }
    .Certificatenotxttpopp {
        font-Size: 15px;
        margin-Bottom: 11px;
    }
    .certificateNoppplctrxrtp {
        font-Size: 15px;
    }
}
@media(max-width:860px){
    .CertificateGalaSealimg {
        width: 100%;
        max-Width: 170px;
        min-Width: 170px;
        height: 170px;
    }
    .certificatenametxtpp {
        color: #b91912;
        font-Size: 60px;
    }
    .certificatenametxtppnich {
        color: #2a4e83;
        font-Size: 30px;
    }
    .completingtxtppjocertxded {
        color: #2a4e83;
        font-Size: 19px;
    }
    .traimsignbvjshnamamsnbtxtuicerdr {
        color: #2a4e83;
        font-Size: 26px;
    }
    .traineeNametxtpp {
        color: #b91912;
        font-Size: 36px;
    }
    .cerificatemaonsectioneduct {
        width: 100%;
        height: auto;
        max-Width: 96%;
        margin: auto;
        padding-Bottom: 40px;
        padding-top: 40px;
    }
}
@media(max-width:767px){

        .elitelogoeducrtdfdivimg {
            width: 100%;
            max-Width: 92px;
            min-Width: 91px;
            height: 64px;
        
    }
    .cerfytvatedalmainkft {
        margin-left: 35px;
    }
    .certficateeduftruitbls{
        width:100%
    }
    .cerfytvatedalmainkft {
        margin-left: 20px;
    }
}
@media(max-width:576px){
    .Certificatenotxttpopp {
        font-Size: 12px;
        margin-Bottom: 7px;
    }
    .completingtxtppjocertxded {
        color: #2a4e83;
        font-Size: 17px;
    }
    .certficateeduftrui {
        padding: 15px 0px;
    }
    .certificatenametxtppnich {
        color: #2a4e83;
        font-Size: 21px;
    }
    .CertificateGalaSealimg {
        width: 100%;
        max-Width: 140px;
        min-Width: 140px;
        height: 140px;
    }
    .traineeNametxtpp {
        margin: 10px 0px;
    }
    .educationttxppcrtt {
        margin: 20px 0px 20px 0px;
    }
    .certificatenametxtpp {
        color: #b91912;
        font-Size: 45px;
    }
}
@media(max-width:480px){
    .CertificateGalaSealimg {
        width: 100%;
        max-Width: 115px;
        min-Width: 115px;
        height: 115px;
    }
}