.profileCvrPicDiv {
    width: 100%;
    margin-top: 0px;
    height: 200px;
    /* background-image: url(../../Images/profileCvrPic.png); */
    position: relative;
    border-radius: 10px 10px 0 0;
    margin-bottom: 5px;
}


.profileBackFigure {
    width: 100%;
    height: 200px;
    margin: 0;
    margin-top: 0px;
}

.advocacySection {
    padding: 103px 0px 50px 0px;
    width: 100%;
    height: auto;
}

.profileBackFigure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.proEditCamFig {
    width: 23px;
    height: 22px;
    margin: 0;
}

.proEditCamFig img {
    width: 100%;
    height: 100%;
}

.proEditText {
    color: #000;
    text-align: center;
    font-family: 'Inter';
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.proEditFigText {
    width: 160px;
    border-radius: 5px;
    background-color: #FFFDFD;
    position: absolute;
    right: 14px;
    bottom: 14px;
    cursor: pointer;
    padding: 6px 10px;
}

.proEditFigInp {
    opacity: 0;
    position: relative;
    z-index: 2;
    background-color: transparent;
}

.proEditFigInnerText {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
    padding: 7px 5px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    gap: 2%;
}

.prflimgbrdrcompany {
    border: 4px solid #ffffff;
    border-radius: 50%;
    margin-left: -10px;
    margin-top: -33px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.indstrtviewflxa {
    margin-top: 5px;
}

.introdivprfltxt {
    margin-bottom: 5px;
}

.txtppghtcpmaytxtp {
    color: #000;
    font-size: 19px;
    line-height: 22px;
    font-weight: 600;
    font-family: "NoirProRegular";
    margin-top: 16px;
}

.proCvrTagLine {
    color: #0E24CC;
    font-family: 'Inter';
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: absolute;
    bottom: 28px;
    left: 300px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hobbyPersoDiv {
    margin-top: 1px;
}

.cardgapbgdiv {
    margin: 0px 0px 15px 0px;
}

.aspiringProDiv {
    margin-top: 20px;
}

.hobbyAspiMainDiv {
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    /* padding: 20px 22px; */
}

.hobbyAspHead {
    color: #263238;
    font-family: NoirProRegular;
    font-size: 16px;
    line-height: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.hobbyAspHeadslklvtxt {
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    font-family: 'poppinslight';
    font-weight: 400;
}

.tab-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 50px;
    padding-bottom: 0px;
}

.activeprofilebtnh {
    border: none;
    background: transparent;
    background: linear-gradient(90deg, rgba(237, 59, 75, 1) 15%, rgba(76, 59, 146, 1) 112%);
    padding: 2px 26px;
    border-radius: 50px;
    color: #fff;
    font-weight: 400;
    font-family: poppinslight;
    font-size: 18px;
}

.addSkillsTabBtnmain {
    margin-left: auto;
}

/* .ownpostdivhjhkjhkjl {
    background-color: #fff;
} */


.notactivebtnprfl {
    border: 2px double transparent;
    background-color: transparent;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    -webkit-background-clip: padding-box, border-box;
    background-image: linear-gradient(#fff, #fff), radial-gradient(circle at top left, #ed3b4b, #4c3b92);
    font-size: 18px;
    color: #000;
    font-weight: 400;
    font-family: poppinslight;
    padding: 2px 26px;
    border-radius: 50px;
}

.aspHobClrDivs {
    width: 100%;
    padding: 14px 20px;
    border-radius: 7px;
    position: relative;
}

.delete_hobby_div {
    position: absolute;
    top: 0px;
    right: 1px;
    transform: translate(-50%, 50%);
}

.wrkHobbyText {
    color: #263238;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hobbyAspLi {
    padding: 5px 26px;
}

.mySocalProAllBtnDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 14px;
    border-bottom: 1px solid rgba(198, 198, 210, 0.26);
    margin-bottom: 16px;
}

.mySocalCmtHead {
    color: #263238;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mySocialAllBtn {
    background-color: transparent;
    border: 0;
    color: rgba(38, 50, 56, 0.90);
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.mySocialAllBtnDiv {
    min-width: 40px;
}

.proSocialCmtDiv {
    width: 100%;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.06);
    margin-top: 25px;
}

.proDateText {
    color: #263238;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.proMemText {
    color: #263238;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 20px;
}

.proMemDateChtBtn {
    background-color: transparent;
    border: 0;
    font-size: 18px;
    color: #263238;
}

.proMemDateChtDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 15px;
}

.proPartiText {
    color: rgba(38, 50, 56, 0.66);
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.proPartiTextDiv {
    padding: 0 15px;
    margin-bottom: 20px;
}

.proAllThreeBtnsDiv {
    padding: 0 15px;
    display: flex;
    align-items: center;
}

.rewarddiv {
    background-color: #fff;
    border-radius: 10px;
    /* padding: 20px 22px; */
    width: 100%;
}

.rewarddivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 5px 12px 5px 12px;
}

.profilePageSection .top {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.verticaldotthrf {
    color: rgb(12, 0, 0);
    font-size: 14px;
}

.content_area p {
    font-size: 14px;
    line-height: 17px;
    color: #000;
    font-weight: 500;
    padding: 2px 0px;
}

.bottom_ara {
    padding: 5px 10px 10px 5px;
}

.indstrtviewflxa {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.viewbtndivlk {
    font-family: NotoSans;
    border: none;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 3px 6px;
    color: #000;
    font-size: 10px;
    /* line-height: 12px; */
    font-weight: 700;
    border-radius: 5px;
    background-color: #ffffff;
}

.bulleBoardFigilpading {
    padding: 5px 10px;
    margin: 0px;
}

.aspirehobtpading {
    padding-bottom: 5px;
}

.rewardspoint {
    margin-top: 15px;
}

.proRedLinkBtn {
    background-color: transparent;
    border: 0;
    font-size: 20px;
    color: #263238;
    margin-right: 20px;
}

.proRedChatBtn {
    background-color: transparent;
    border: 0;
    font-size: 20px;
    color: #263238;
    margin-right: 20px;
}

.proRedShareBtn {
    background-color: transparent;
    border: 0;
    font-size: 20px;
    color: #263238;
}

.proAboutSelf {
    color: #263238;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 30px 0;
}

.rateidivc {
    width: 100%;
    max-width: 70%;
    padding-top: 12px;
}

.hbtskiltserdiv {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    gap: 32px;
    padding-top: 26px;
}


.hobyagfondiv {
    width: 100%;
    max-width: 100%;
}

.testdivbfkd {
    width: 100%;
    max-width: 100%;
    padding-top: 23px;
}

.jourChampDiv {
    padding-top: 10px;
}

.icondivcmr {
    position: relative;
}

.profilerightscrngh {
    padding: 20px 0px 0px 0px;
}

.profilemidscrngh {
    padding: 13px 0px 0px 0px;
}

.rateMySkillDiv {
    /* background-color: #fff;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: 25px; */
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    margin-bottom: 8px;
    max-width: 100%;
}

.rateMyFig {
    width: 70px;
    height: 70px;
    min-width: 70px;
    margin: 0 25px 0 0;
    border-radius: 50%;
}

.rateMyFig img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.rateMyFigInpDiv {
    /* display: flex;
    align-items: center;
    margin-bottom: 39px; */
    /* margin-bottom: 9px; */
    /* margin-bottom: 9px; */
    width: 100%;
    height: auto;
    padding: 11px 21px 11px;
    border-bottom: 1px solid rgba(198, 198, 210, 0.48);
    display: flex;
    align-items: center;
    gap: 5%;
}

.rateMyInp {
    font-family: 'Inter';
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #000;
    border: 0;
    outline: none;
    width: 100%;
}

.rateMyInp::placeholder {
    color: rgba(38, 50, 56, 0.45);
}

.rateSkillFig {
    width: 30px;
    height: 30px;
    margin: 0 5px 0 0;
}

.rateSkillFig img {
    width: 100%;
    height: 100%;
}

.rateSkillText {
    color: #238EE5;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.1px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rateSkillDiv {
    display: flex;
    align-items: center;
    /* margin-left: 20px; */
}

.giveCoinFig {
    width: 21px;
    height: 21px;
    margin: 0 5px 0 0;
}

.giveCoinFig img {
    width: 100%;
    height: 100%;
}

.giveCoinText {
    color: #E5237B;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.1px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.writeTestFig {
    width: 25px;
    height: 30px;
    margin: 0 5px 0 0;
}

.writeTestFig img {
    width: 100%;
    height: 100%;
}

.writeTestText {
    color: #A730E6;
    /* font-family: 'Inter'; */
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: NoirProLight;
}

.wrGiRaBtn {
    background-color: #0E24CC;
    border-radius: 5px;
    color: #FFF;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 50px;
    border: 0;
}

.rateGiveWritePostDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 20px 3px;
}

.rateMyInnerDiv {
    width: 31%;
    height: 180px;
    border-radius: 20px;
    border: 1px solid #E2E2EA;
    background: #FFF;
    padding: 25px;
    margin-bottom: 20px;
}

.rateMyNtrkInnerDiv {
    width: 33%;
    height: 180px;
    border-radius: 20px;
    border: 1px solid #E2E2EA;
    background: #FFF;
    padding: 25px;
    margin-bottom: 20px;
}

.rateMyComText {
    color: #92929D;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.56px;
    text-transform: uppercase;
    margin: 0 0 16px 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
}

.rateMyNumHead {
    color: #171725;
    font-family: 'Plus Jakarta Sans';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.rateMybatteFig {
    width: 56px;
    height: 56px;
    margin: 0;
}

.rateMyTropFig {
    width: 60px;
    height: 60px;
    margin: 0;
}

.rateMyNumCirDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rateMyInnerDivParent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 10px;
}

.myVegsDiv {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    /* box-shadow: 0px 0px 5px 0px #CCC; */
    /* background-color: #fff; */
    padding: 10px;
    /* border-radius: 0px; */
    /* border-top-right-radius: 0px; */
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom: 2px solid #fff;
    background: linear-gradient(90deg, rgba(237, 59, 75, 1) 15%, rgba(76, 59, 146, 1) 112%);
}

.myVetFig {
    width: 25px;
    min-width: 25px;
    height: 25px;
    margin: 0 7px 0 0;
}

.myVetFig img {
    width: 100%;
    height: 100%;
}

.myVetTestHead {
    color: #0B0C0C;
    font-family: 'NotoSans';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 15px;
}

.tabListPartDiv {
    width: 83%;
    overflow-x: auto;
}

.followerbtn {
    color: #ffffff;
    font-size: 19px;
    padding: 7px 10px;
    font-family: noirprolight;
    line-height: 18px;
    border: none;
    border-radius: 10px;
    /* margin: 22px 0px 10px 0px; */
    width: 100%;
    text-align: left;
    font-weight: 800;
    background: linear-gradient(181deg, rgba(237, 59, 75, 1) 14%, rgba(76, 59, 146, 1) 92%);
    text-align: center;
}

.followerbtnmainconnetion {
    margin: 13px 0px 12px 0px;
}

.user_aboutnbetsrsdiv {
    width: 100%;
    max-width: 27%;
}

.manndgfacctdibn {
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    column-gap: 20px;
}

.thought_area_mxh-leftprfl {
    width: 100%;
    max-width: 22%;
   
}

.thought_area_mxh-midprfl {
   width: 100%;
   max-width: 54%;
}

.thought_area_mxh_rightprfl {
    width: 100%;
    max-width: 22%;
}
.profileCvrPicDivprflnew {
    width: 100%;
    margin-top: 0px;
    height: 200px;
    /* background-image: url(../../Images/profileCvrPic.png); */
    position: relative;
    border-radius: 10px 10px 0 0;
    margin-bottom: 5px;
}
.addicondivflkj {
    margin-left: auto;
}

.followerbtnrating {

    padding: 7px 10px;

    line-height: 18px;
    border: none;
    border-radius: 10px;
    margin: 21px 0px 10px 0px;

    width: 100%;
    text-align: left;
    font-weight: 800;
    border: double 2px transparent;
    border-radius: 80px;
    /* background-image: linear-gradient(white, white), radial-gradient(circle, rgba(76, 59, 146, 1) 0%, rgba(237, 59, 75, 1) 95%); */
    background-origin: border-box;
    background-image: rgb(76, 59, 146);
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #ed3b4b, #4c3b92);
    background-clip: padding-box, border-box;
    -webkit-background-clip: padding-box, border-box;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;

}

.cntntxtbioplll {
    color: #263238;
    font-family: NoirProLight;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    margin: 0;
    padding: 0;
}

.cntntxtbioplll span {
    color: #263238;
    font-family: Mandaufont;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    margin: 0;
    padding: 0;
    padding-left: 3px;
}

.biobrnmkkjarfbrnm {
    border-bottom: 1px solid #ccc;
    padding: 5px 0px 5px 0px;
}

.folowtxtppphj {
    background: linear-gradient(181deg, rgba(237, 59, 75, 1) 14%, rgba(76, 59, 146, 1) 92%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    font-family: noirprolight;
}

.namedotsthreedivdflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.thredothjdjfvnk {
    margin-left: auto;
    position: relative;
    top: 0;
    /* right: -113px; */
}

.homePgModInnerInpDivsmainnew input {
    border: 1px solid #fac00d;
    width: 100%;
    max-width: 85%;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 18px;
    font-family: NoirProLight;
}

.homePgModInnerInpDivsmainnew {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
    padding-top: 10px;
}

.viwebdbfnmmdibn {
    position: absolute;
    top: 11px;
    right: 9px;
    z-index: 9;
    border: 1px solid #ccc;
    padding: 3px;
    background-color: #fff;
}

.addfolowersicomn {
    width: 100%;
    max-width: 20px;
    min-width: 20px;
    height: 20px;
}

.addfolowersicomn img {
    width: 100%;
    height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tabListPartDiv::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tabListPartDiv {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.tabListPartDiv::-webkit-scrollbar {
    width: 0;
    height: 5px;
}

.tabListPartDiv .react-tabs__tab-list {
    width: 100%;
    display: flex;
    /* justify-content: space-around; */
    margin-bottom: 0;
    border: 0;
}

.addSkillsTabBtn {
    /* width: 14%; */
    background-color: #fff;
    border: 0;
    border-radius: 10px;
    color: #0E24CC;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    padding: 5px 12px;
}

.addSkillsTabBtn span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
    font-size: 12px;
}

.ImportantLinksMyMentordiv {
    padding-top: 20px;
}

.tabListPartDiv .react-tabs__tab {
    color: #656579;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 6px 12px 25px 12px;
    text-wrap: nowrap;
}

.tabListPartDiv .react-tabs__tab--selected {
    background-color: transparent;
    border: 0;
    padding: 6px 12px 25px 12px;
    border-bottom: 5px solid #138BFB;
}

.tabListPartDiv .react-tabs__tab:focus:after {
    background-color: transparent;
}

.tabListAddBtnDiv {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    background-color: #fff;
    box-shadow: 0px -1px 0px 0px #E2E2EA inset;
    border-radius: 10px;
    padding: 10px;

}

.tabPanelPartDiv {
    background-color: #fff;
    margin-top: 5px;
}

/* Rating */

#rating {
    width: 100%;
    height: auto;
    background: #ffffff;
    border-radius: 20px;
    margin-top: 5px;
    padding: 15px 25px 15px 26px;
    border-radius: 10px;
}

#rating .rating_text {
    padding-top: 43px;
}

#rating .rating_text h2 {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 800;
    font-size: 23px;
    line-height: 26px;
    color: #171725;
    margin: 0;
    padding: 0;
    margin-bottom: 2px;
    margin-top: 5px;
}

#rating .rating_text p {
    font-family: 'NoirProLight';
    font-style: normal;
    font-weight: 800;
    font-size: 23px;
    line-height: 26px;
    color: #24243069;
    margin: 0;
    padding: 0;
}

/* #rating .rating_text h3 {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 36px;
    color: #171725;
    margin: 0;
    padding-top: 0px;
} */

#rating .progress_wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px;
}

.totalFiveText {
    font-size: 14px;
    line-height: 17px;
    color: #000;
    font-weight: 600;
    font-family: notosans;
}

#rating .progress {
    max-width: 394px;
    width: 100%;
    height: 15px;
    /* background-color: #e9ecef; */
    background-color: #fff;
}

#rating .progress-bar {
    background: #ffa927;
}

#rating .number {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #171725;
    padding-right: 9px;
}

#rating .icon {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: #ffc700;
    padding-right: 13px;
}

#rating .amount {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #44444f;
    padding-left: 59px;
}

#rating .comment_section {
    display: flex;
    padding-top: 95px;
    width: 100%;
}

#rating .comment_section .comment_right {
    padding-left: 22px;
    width: 100%;
}

#rating .comment_section .comment_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#rating .comment_section .comment_top_left {
    display: flex;
}

#rating .comment_section .comment_person {
    width: 45px;
    height: 45px;
    overflow: hidden;
}

#rating .comment_section .comment_person img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

#rating .comment_right h5 {
    font-family: "notosans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #171725;
    white-space: nowrap;
    margin: 0;
    padding: 0;
}

#rating .comment_right .star {
    margin-left: 36px;
    white-space: nowrap;
}

#rating .comment_right i {
    color: #ffc700;
    font-size: 14px;
}

#rating .comment_right p {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #92929d;
    /* margin-left: 130px; */
    margin-bottom: 0;
    padding: 0;
}

#rating .person_description {
    padding-top: 2px;
}

#rating .person_description p {
    font-family: 'notosans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #44444F;
    padding: 0;
    margin: 0;
}

#rating .rating_btn {
    margin: 0 auto;
    padding-top: 75px;
}

#rating .rating_btn .review_btn {
    width: 319px;
    height: 59px;
    background: #FFFFFF;
    border: 1px solid #E2E2EA;
    border-radius: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 138%;
    color: #171725;
    padding: 0;
    margin: 0;
    box-shadow: none;
    outline: 0;
}

#rating .comment_icon {
    display: flex;
    justify-content: flex-end;
    margin-right: auto;
}

#rating .cmnticnk {
    display: flex;
    align-items: center;
    justify-content: baseline;
    margin-right: 0px;
}

#rating .comment_icon img {
    width: 18px;
    height: 15px;
    object-fit: cover;
    object-position: center;
}

.rateJavaHead {
    color: #171725;
    font-family: 'Plus Jakarta Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}

.rateLinkedinBtn {
    background-color: transparent;
    border: 0;
    font-size: 23px;
    color: #0A66C2;
}

.tabRateIconFig {
    width: 18px;
    height: 18px;
    margin: 5px 0 0 12px;
}

.scrollbarandaddiconflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(90deg, rgba(237, 59, 75, 1) 15%, rgba(76, 59, 146, 1) 112%);
    box-sizing: 0px;
    /* box-shadow: 0px 0px 5px 0px #ccc; */
    /* border-radius: 5px; */
    padding: 8px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.scrollbarandaddiconflx .rts___nav___btn {
    align-items: center;
    background-color: #d3a145;
    border-radius: 50%;
    /* box-shadow: 4px 4px 7px 0 rgba(0, 0, 0, .15), inset 1px 2px 5px 0 rgba(0, 0, 0, .15); */
    color: #fff !important;
    content: "\f105" !important;
    display: flex;
    font-family: FontAwesome !important;
    font-size: 27px !important;
    height: 35px;
    justify-content: center;
    line-height: 1;
    opacity: 1 !important;
    width: 35px;
    border: 1px solid #fff;
}

.scrollbarandaddiconflx .rts___tab___selected {
    background-color: #ffffff !important;
    color: #3F51B5 !important;
    box-shadow: #00000029 0 1px 4px;
}

.scrollbarandaddiconflx .rts___btn {
    cursor: pointer;
    background-color: transparent;
    border-radius: 40px;
    border: 2px solid var(--rts-gray-color);
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    font-family: 'NotoSans';
}

.scrollbarandaddiconflx .rts___tab {
    padding: 3px 40px;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    margin: 0 5px;
}

.somdclassdkko {
    padding-top: 15px;
}

.scrollbarandaddiconflx .rts___svg___icon {
    stroke: #fff9;
}

.rts___tabs___container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabRateIconFig img {
    width: 100%;
    height: 100%;
}

.rateLinkShareDiv {
    display: flex;
    align-items: flex-start;
}

.rateJavaHeadDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.cmntLinkBtn {
    background-color: transparent;
    border: 0;
    margin-right: 8px;
}

.cmntLinkBtn i {
    font-size: 21px !important;
    color: #0A66C2 !important;
}

/* .myTestRatingDiv {
    margin-bottom: 15px;
    margin-top: 15px;
} */

.myJourTabListDiv {
    background-color: #fff;
    padding: 20px;
}

.dltimgdiv {
    width: 100%;
    max-width: 20px;
    min-width: 20px;
    height: 20px;
}

.dltimgdiv img {
    width: 100%;
    height: 100%;
}

.biodetailstxtopnop {
    font-size: 16px;
    line-height: 22px;
    color: #000;
    font-weight: 600;
    font-family: Notosans;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}

.biodetailstxtopnop span {
    font-family: poppinslight;
    font-size: 14px;
    font-weight: 600;
}

.biodetailstxtopnop:first-child {
    margin-top: 10px;
}

/* .details{
 padding: 10px 15px;
} */
.messageflowwingdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.myJourTabListDiv .react-tabs__tab-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* border: 0; */
}

.myJourTabListDiv .react-tabs__tab {
    color: #656579;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 20px;
    text-transform: uppercase;
}

.myJourTabListDiv .react-tabs__tab--selected {
    color: #44444F;
    background-color: transparent;
    border: 0;
    border-bottom: 5px solid #138BFB;
}

.myJourTabListDiv .react-tabs__tab:focus:after {
    background-color: transparent;
}

.jourExpText {
    color: #000;
    font-family: 'Plus Jakarta Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}

.jourAddInfoBtn {
    background-color: #0E24CC;
    border: 0;
    color: #FFF;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px 20px;
    border-radius: 5px;
}

.expAddInfoDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.founder-lft {
    width: 58px;
    min-width: 58px;
    height: 58px;
    margin: 0 10px 0 0;
    border-radius: 50%;
}

.founder-lft img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.foundCeoHead {
    color: #263238;
    font-family: 'Plus Jakarta Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 5px 0;
}

.fractFullText {
    color: #263238;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 8px 0;
}

.jourDecText {
    color: #7B7B7B;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 12px 0;
}

.jourDescription {
    color: #636363;
    font-family: 'Plus Jakarta Sans';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.jourSeeMore {
    color: #263238;
    font-family: 'Plus Jakarta Sans';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.founder-ceo {
    display: flex;
    margin-bottom: 20px;
}

.jourEliteCardFig {
    width: 100%;
    height: auto;
    margin: 0 0 25px 0;
}

.jourEliteCardFig img {
    width: 100%;
    height: 100%;
}

.profilePageSection {
    padding: 103px 0px 40px 0px;
}

.proMentConnecDiv {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 15px;
    margin-top: 20px;
}

.myProMentBtnDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.myProMentPara {
    color: #0E24CC;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.mentProAddBtn {
    background-color: transparent;
    border: 0;
    color: #0E24CC;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.mentProConnecFigsDiv {
    display: flex;
    flex-wrap: wrap;
    column-gap: 7px;
    margin-top: 21px;
    max-height: 217px;
    overflow-y: auto;
}

.mentProConnecFigsDiv::-webkit-scrollbar {
    display: none;
}

.mentProConnecFigInnerDiv {
    width: 23%;
    margin-bottom: 20px;
}

.mentProConnecFig {
    width: 100%;
    height: 51px;
    margin: 0;
}

.mentProConnecFig img {
    width: 100%;
    height: 100%;
}

.aspiringdivmainbackg {
    padding: 8px 12px;
}

.deleticondivbg {
    background-color: #fff;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.deleticondivbgmentor {
    background-color: #fff;
    width: 20px;
    height: 20px;
    display: flex;
    /* justify-content: left; */
    /* align-items: center; */
    border-radius: 4px;
    position: absolute;
    right: 156px;
    color: red;
}

.deleticondivbg i {
    color: red;
    font-size: 14px;
}

.introdivprfltxt h4 {
    font-size: 14px;
    line-height: 17px;
    color: #000;
    font-weight: 500;
    font-family: notosans;

}

.mentProConnText {
    color: #0F044C;
    font-family: 'Roboto';
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 5px 0 0 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 14px;
}

.chatIconFig {
    width: 20px;
    height: 20px;
    margin: 0;
}

.chatIconFig img {
    width: 100%;
    height: 100%;
}

.lkShCmIconDiv {
    width: 20px;
    height: 20px;
    margin: 0 20px 0 0;
}

.lkShCmIconDiv:last-child {
    margin: 0;
}

.lkShCmIconDiv img {
    width: 100%;
    height: 100%;
}

.bg-userdtwht {
    width: 100%;
    height: 159px;
    /* margin-top: -73px; */
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
}

.plthclbtx {
    height: 34%;
    padding-right: 20px;
    padding-top: 0px;
}

.bghhkpp {
    font-family: 'NoirProRegular';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    background: linear-gradient(181deg, rgba(237, 59, 75, 1) 14%, rgba(76, 59, 146, 1) 92%);
    /* background: linear-gradient(90deg, #ed3b4b, #4c3b92); */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    /* color: #ffffff; */
    /* text-align: left; */
    padding-top: 6px;
    margin: 0;
    text-align: end;
    letter-spacing: 1px;
}


.bg-txtppp p {
    font-family: 'NoirProLight';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 20px;
    color: #ffffff;
    text-align: left;
    padding-top: 6px;
    margin: 0;
    text-align: end;
}

.prflimgbrdr {
    border: 4px solid #ffffff;
    /* width: 100%;
    max-width: 34%;
    min-width: 34%;
    height: 83px; */
    border-radius: 50%;
    margin-left: -10px;
    margin-top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tstdfghmrl {
    margin-top: 20px;
}

.clbpp {
    font-family: 'NoirProLight';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    background: linear-gradient(181deg, rgba(237, 59, 75, 1) 14%, rgba(76, 59, 146, 1) 92%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-top: 3px;
    margin: 0;
    text-align: end;
    letter-spacing: 1px;
}

/* 
.divpatyn {
    padding-top: 70px;
} */

.user_aboutnbet {
    width: 100%;
    max-width: 100%;
    /* padding-top: 6px; */
}

.cardtelkkttxhhajphjjksfcbxgm {
    margin-top: 5px;
}

.prflimgfh {
    width: 100%;
    max-width: 80px;
    height: 82px;
    min-width: 80px;
}

.prflimgfh img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.txtppght {
    color: #000;
    font-size: 20px;
    font-weight: 800;
    font-family: "NoirProRegular";
}

.dvjknjpp {
    color: #000;
    font-size: 15px;
    font-weight: 400;
    font-family: "NoirProLight";
}

.rateMySkillDiv .profile_img {
    width: 100%;
    max-width: 76px;
    height: 76px;
    border-radius: 50%;
    padding: 0;
    /* margin: 0 15px 0 0; */
}

.rateMySkillDiv .profile_img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
}

.rateMySkillDiv .profilebrdr {
    border: 4px solid #fac00d;
    width: 86px;
    height: 86px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .tstdfghmrl{
    margin-left: 30px;
} */
.prflimgdiv {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 6%;
    align-items: flex-end;
}

.divpaddingghh {
    padding: 0px 20px;
}

.thounsabpp {
    background: linear-gradient(181deg, rgba(237, 59, 75, 1) 14%, rgba(76, 59, 146, 1) 92%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    font-family: 'NoirProLight';
}

.pointshttx {
    color: #000;
    font-size: 15px;
    font-weight: 500;
    font-family: 'NotoSans';
}

.mainafinitydiv {
    width: 100%;
    height: auto;
    background: rgb(255, 190, 152);
    border-radius: 3px;
    margin-bottom: 5px;
    margin-top: 0px;
    border-radius: 10px;
}

.mainafinitydivbtm {
    width: 100%;
    max-height: 227px;
    padding: 0px 6px 0px 6px;
    overflow-y: hidden;
}

.jobtstgyuiopp {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: #263238;
    padding: 0;
    margin: 0;
}

.detailsjobsvaetxtt p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: #263238;
    padding: 0;
    margin-bottom: 5px;
}

.card_areatraining {
    width: 100%;
    height: auto;
    /* background: #eaebfb; */
    /* border-radius: 10px; */
    overflow: hidden;
    display: flex;
    /* padding: 10px; */
    padding: 7px 5px;
    border-bottom: 1px solid #ccc;
}

.image_areatraing {
    width: 100%;
    max-width: 88px;
    min-width: 88px;
    height: 90px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;


}

.image_areatraingkhj {
    width: 100%;
    max-width: 75px;
    min-width: 75px;
    height: 65px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
}

.hhghihxjggimghsvh {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #263238;
    width: 100%;
    max-width: 170px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* height: 35px; */
    margin-bottom: 0px;
    font-family: NoirProLight;
}

.hjhjdshduikn {
    padding: 5px 0px 0px 5px;
}

.image_areatraingkhj img {
    width: 100%;
    height: 100%;
}

.datetrainghtxt {
    font-family: "Mandaufont";
    font-style: normal;
    font-weight: 400;
    /* font-size: 8px; */
    line-height: 10px;
    color: #263238;
    padding: 0;
    margin: 0 0 5px 0;
    font-family: "Mandaufont";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
}

/* Followers design Start */
.followwerssectionmain {
    padding: 120px 0px 30px 0px;
}

.followerssectiondivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.followerleftdiv {
    width: 100%;
    max-width: 20%;
}

.followerrightdiv {
    width: 100%;
    max-width: 77%;
}

.followerrightdivbg {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
}

.followerrighttopup {
    width: 100%;
    padding: 15px 26px;
    border-bottom: 1px solid #ccc;
}

.followtoppppxyu {
    color: #000;
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
    font-family: poppinslight;
}

.followerrighttopbtm {
    width: 100%;
}

.followerrighttopbtmden {
    width: 100%;
    padding: 7px 15px;
    border-bottom: 1px solid #ccc;
}

.followerrighttopbtmdwnl {
    padding: 15px;
}

.followwerssectionmain .react-tabs__tab-list {
    border-bottom: 0px !important;
    margin: 0 0 0px !important;
}

.followwerssectionmain .react-tabs__tab--selected {
    color: #000000 !important;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    font-family: poppinslight;
    border: double 2px transparent !important;
    border-radius: 40px !important;
    background-origin: border-box !important;
    background-image: rgb(76, 59, 146) !important;
    background-image: linear-gradient(white, white),
        radial-gradient(circle at top left, #ed3b4b, #4c3b92) !important;
    background-clip: padding-box, border-box !important;
    -webkit-background-clip: padding-box, border-box !important;
    padding: 9px 20px;
    margin-bottom: 5px;
    position: relative;
}

.followwerssectionmain .react-tabs__tab {
    border: 0px solid transparent;
    border-bottom: 0;
    color: #000;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    font-family: poppinslight;
    padding: 9px 20px;
    margin-bottom: 5px;
    position: relative;

}

.profilefollowimg {
    width: 100%;
    max-width: 40px;
    min-width: 40px;
    height: 40px;
}

.profilefollowimg img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.prflimgdtlsfllowdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
}

.followtxtpppnmjl {
    color: #000;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    font-family: poppinslight;
}

.followtxtpppnmjldsgnty {
    color: #000;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    font-family: poppinslight;
}

.prflimgdtlsfllowdivflxflwbtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}

.followingbtndivnm {
    color: #000000 !important;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    font-family: poppinslight;
    border: double 2px transparent !important;
    border-radius: 40px !important;
    background-origin: border-box !important;
    background-image: rgb(76, 59, 146) !important;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #ed3b4b, #4c3b92) !important;
    background-clip: padding-box, border-box !important;
    -webkit-background-clip: padding-box, border-box !important;
    padding: 5px 16px;
    margin-bottom: 5px;
    position: relative;
}

.followingbtndivnm:hover {
    text-decoration: none;
}

.biopdivbmmaibn {
    height: 506px;
    overflow-y: auto;
}

.boiosdkmcubnjkpkk {
    font-size: 14px;
    line-height: normal;
    font-family: poppinslight;
    font-weight: 800;
    color: #000;
    padding-left: 20px;
    padding-top: 10px;
}

.followmodalmain {
    position: fixed;
    top: 0;
    right: 0;
    background-color: #020202a1;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.followmodalmainbg {
    width: 100%;
    max-width: 450px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);

}

.crossflwax i {
    color: red;
    font-size: 16px;
    cursor: pointer;
}

.unfollowcrsdivfkx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.crossflwax {
    margin-left: auto;
}

.unfollowdfpppbk {
    font-size: 18px;
    color: #000;
    font-family: notosans;
    font-weight: 600;
    line-height: 24px;
}

.unfoolowttxyupdiv {
    padding: 10px;
}

.unfoolowttxyupllktxt {
    color: #000;
    font-size: 18px;
    line-height: 20px;
    font-weight: 300;
    font-family: poppinslight;
}

.unfoolowttxyupdivdicflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
}

.canclebtbndiv {
    color: #000000 !important;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    font-family: poppinslight;
    border: double 2px transparent !important;
    border-radius: 40px !important;
    background-origin: border-box !important;
    background-image: rgb(76, 59, 146) !important;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #ed3b4b, #4c3b92) !important;
    background-clip: padding-box, border-box !important;
    -webkit-background-clip: padding-box, border-box !important;
    padding: 5px 16px;
    /* margin-bottom: 5px; */
    position: relative;
}

.unfolowbtndiv {
    background: linear-gradient(90deg, rgba(237, 59, 75, 1) 0%, rgba(76, 59, 146, 1) 54%);
    padding: 10px 15px;
    color: #fff;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    font-family: poppinslight;
    border: none;
    outline: 0;
    padding: 7px 16px;
    border-radius: 40px;
}

.canclebtbnunfolowbtndivflx {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    padding-top: 10px;
}

.prflimgdtlsfllowdivflxflwbtn:last-child {
    padding-bottom: 0px;
}

.bottomareadibvmain {
    height: 35vh;
    overflow-y: auto;
    padding-bottom: 5px;
}

.bottomareadibvmainbg {
    border-bottom: 1px solid #ccc;
    margin: 5px 10px;
    padding: 5px 0px;
}

.bottomareadibvmainbg:last-child {
    border-bottom: 0px solid #ccc;
}

.bhsbvbbshhhtghpppn {
    font-size: 14px;
    line-height: 17px;
    color: #000;
    font-weight: 500;
    font-family: notosans;
    padding-bottom: 5px;
}

.bhsbvbbshhhtghpppn span {
    font-size: 12px;
    line-height: 16px;
    margin-left: 5px;
}

.hiringdetialsmainsection {
    padding: 120px 0px 60px 0px;
}

.hiringdetialsleft {
    width: 100%;
    max-width: 21%;
}

.bottomareadibvmaindiv {
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 5px;
}

.viewdbhjntxtydivflkx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.viewbtnnndiv {
    font-family: NotoSans;
    border: none;
    box-shadow: 0px 0px 5px 0px #adadad;
    padding: 3px 6px;
    color: #000;
    font-size: 10px;
    /* line-height: 12px; */
    font-weight: 700;
    border-radius: 5px;
    background-color: #ffffff;
}

.hiringdetialsrightdib {
    width: 100%;
    max-width: 70%;
}

.bg-grydivmainhj {
    background-color: #ccc;
    border-radius: 2px;
    padding: 5px;
    width: 100%;
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    column-gap: 20px;
    margin-bottom: 10px;
}

.hjkshhvvsuhikdojhjokklo {
    font-family: "Mandaufont";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    color: #040c10;
    text-align: end;
}

.hjkshhvvsuhikdojhjokklo span {
    font-size: 19px;
    line-height: 20px;
    margin-left: 5px;
    color: #010507;
}

.hiringdetialsdivflx {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    column-gap: 40px;

}

.hririnhjjhdibnmon {
    width: 100%;
    max-width: 20%;
}

.bottomareadibvmainhiring {
    height: 100vh;
    overflow-y: auto;
}
.profilepagedivbjsgbflsx{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    column-gap: 30px;
}
/* Followers design End */

@media (max-width: 1499px) {
    .mySocalCmtHead {
        font-size: 15px;
    }

    .prflimgfh {
        width: 100%;
        max-width: 60px;
        height: 59px;
        min-width: 60px;
    }

    .thounsabpp {
        color: red;
        font-size: 22px;
        line-height: 26px;
        font-weight: 700;
        font-family: 'NoirProLight';
    }

    .bghhkpp {
        font-size: 16px;
        line-height: 17px;
    }

    .clbpp {
        font-size: 16px;
        line-height: 20px;

    }

    .plthclbtx {
        height: 30%;
        padding-right: 20px;
        padding-top: 0px;
    }

    .tstdfghmrl {
        margin-top: 17px;
    }

    .pointshttx {
        color: #000;
        font-size: 11px;
        font-weight: 500;
        font-family: 'NotoSans';
    }

    .rateMySkillDiv .profile_img {
        border-radius: 50%;
        max-width: 83px;
        height: 78px;
        border-radius: 50%;
        overflow: hidden;
        width: 89px;
        height: 79px;
        max-width: 83px;
    }

    /* .hobbyAspHead {
        font-size: 18px;
    } */

    .wrkHobbyText {
        font-size: 14px;
    }

    .aspHobClrDivs {
        padding: 15px 18px;
        border-radius: 7px;
    }

    .rateMyInp {
        font-size: 22px;
    }

    .rateMyFig {
        width: 60px;
        height: 60px;
        min-width: 60px;
        margin: 0 20px 0 0;
    }

    /* .rateMyFigInpDiv {
        margin-bottom: 6px;
        margin-top: 6px;
    
    } */

    .rateSkillFig {
        width: 25px;
        height: 25px;
    }

    .rateSkillText {
        font-size: 14px;
    }

    .giveCoinFig {
        width: 21px;
        height: 26px;
    }

    .giveCoinText {
        font-size: 14px;
    }

    .writeTestFig {
        width: 24px;
        height: 25px;
    }

    .writeTestText {
        font-size: 14px;
    }

    .wrGiRaBtn {
        font-size: 16px;
        padding: 6px 30px;
    }

    .rateMyInnerDiv {
        width: 48%;
    }

    .rateMyNtrkInnerDiv {
        width: 48%;
    }

    .rateMyInnerDivParent {
        column-gap: 0;
        justify-content: space-between;
    }

    .tabListPartDiv .react-tabs__tab {
        font-size: 13px;
    }

    .rateJavaHead {
        font-size: 18px;
    }

    .rateLinkedinBtn {
        font-size: 21px;
    }

    .tabRateIconFig {
        width: 16px;
        height: 16px;
        margin: 3px 0 0 12px;
    }

    #rating .rating_text {
        padding-top: 22px;
    }

    /* #rating .rating_text h2 {
        font-size: 35px;
    } */

    #rating .comment_section {
        padding-top: 15px;

    }

    #rating .rating_btn {
        padding-top: 25px;
    }

    .myJourTabListDiv .react-tabs__tab {
        font-size: 13px;
    }

    .myVetTestHead {
        font-size: 21px;
    }

    .founder-lft {
        width: 50px;
        min-width: 50px;
        height: 50px;
    }

    .mentProConnecFigsDiv {
        column-gap: 6px;
    }

    /* .thought_area_mxh-leftprfl {
        width: 17%;
        left: 125px;
    } */
/* 
    .thought_area_mxh-midprfl {
        width: 44%;
    } */

    /* .thought_area_mxh_rightprfl {
        right: 125px;
        width: 17%;
    } */
}

@media (max-width: 1365px) {
    .mySocalCmtHead {
        font-size: 14px;
    }

    .mySocialAllBtn {
        font-size: 11px;
    }
}

@media (max-width: 1199px) {
    .empDegId {
        justify-content: flex-start;
        column-gap: 20px;
    }

    /* .thought_area_mxh-leftprfl {
        height: 73vh;
        margin-left: 0px;
        width: 19%;
        left: 95px;
    } */
/* 
    .thought_area_mxh-midprfl {
        width: 41%;
    } */

    /* .thought_area_mxh_rightprfl {
        height: 73vh;
        margin-right: 0px;
        width: 19%;
        right: 89px;
    } */

    .prflimgbrdr {
        border: 4px solid #ffffff;
        /* width: 100%; */
        /* max-width: 33%; */
        /* min-width: 33%; */
        /* height: 78px; */
        border-radius: 50%;
        /* margin-left: 30px; */
        /* margin-top: -46px; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .user_aboutnbet {
        width: 100%;
        max-width: 100%;
        padding-bottom: 10px;
    }

    .wrkHobbyText {
        font-size: 16px;
    }

    /* .hobbyAspHead {
        font-size: 20px;
    } */
    /* 
    .myAffityHead {
        font-size: 18px;
    } */

    .myAffSeeAllBtn {
        font-size: 13px;
    }

    .mySocalCmtHead {
        font-size: 18px;
    }

    .mySocialAllBtn {
        font-size: 13px;
    }

    .rateMyFig {
        width: 70px;
        height: 70px;
        min-width: 70px;
    }

    .rateMyInp {
        font-size: 26px;
    }

    .rateSkillFig {
        width: 30px;
        height: 30px;
    }

    .rateSkillText {
        font-size: 16px;
    }

    .giveCoinFig {
        width: 26px;
        height: 32px;
    }

    .giveCoinText {
        font-size: 16px;
    }

    .writeTestFig {
        width: 25px;
        height: 30px;
    }

    .writeTestText {
        font-size: 16px;
    }

    .wrGiRaBtn {
        font-size: 21px;
        padding: 8px 35px;
    }

    .rateMyInnerDiv {
        width: 31%;
    }

    .rateMyNtrkInnerDiv {
        width: 31%;
    }

    .rateMyInnerDivParent {
        column-gap: 10px;
        justify-content: flex-start;
    }

    .myVetTestHead {
        font-size: 24px;
    }

    .tabListPartDiv .react-tabs__tab {
        font-size: 15px;
    }

    .rateJavaHead {
        font-size: 20px;
    }

    .rateJavaHead {
        font-size: 20px;
    }

    .rateLinkedinBtn {
        font-size: 23px;
    }

    .tabRateIconFig {
        width: 18px;
        height: 18px;
        margin: 5px 0 0 12px;
    }

    #rating .comment_section {
        padding-top: 35px;
    }

    .jourEliteCardFig {
        width: 48%;
        height: 304px;
        margin: 16px auto 0 auto;
        order: 1;
    }

    .champMainDivParent {
        width: 100%;
        order: 3;
    }

    .mentConnecDivWrap {
        width: 48%;
        order: 2;
    }

    .jourChampDiv {
        display: flex;
        flex-wrap: wrap;
        column-gap: 25px;
    }

    .mentProConnecFigsDiv {
        column-gap: 10px;
    }

    .mentProConnecFigInnerDiv {
        width: 18%;
    }

    .myJourDiv {
        margin-top: 30px;
        margin-bottom: 15px;
    }
}

@media(max-width:1080px) {
    /* .thought_area_mxh-leftprfl {
        height: 73vh;
        margin-left: 0px;
        width: 20%;
        left: 30px;
    } */

    /* .thought_area_mxh-midprfl {
        padding-bottom: 37px;
        width: 50%;
    } */

    /* .thought_area_mxh_rightprfl {
        height: 73vh;
        margin-right: 0px;
        width: 20%;
        right: 30px;
    } */
}

@media (max-width: 991px) {
    .mentProConnecFigsDiv {
        max-height: 150px;
    }

    /* .thought_area_mxh-leftprfl {
        display: none
    } */

    /* .thought_area_mxh-midprfl {
        width: 97%;
    } */

    /* .thought_area_mxh_rightprfl {
        display: none;
    } */

    .hirimngspclleft {
        display: none;
    }

    .jourEliteCardFig {
        height: 235px;
    }

    .mentProConnecFigInnerDiv {
        width: 22%;
    }

    #rating {
        padding: 26px 40px 35px 40px;
    }

    .proCvrTagLine {
        bottom: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -49%);
    }

    .profileCvrPicDiv {
        width: 100%;
        height: 250px;
    }

    .tabListPartDiv::-webkit-scrollbar {
        display: none;
    }

    #rating .comment_right p {
        font-size: 12px;
        line-height: 16px;
    }

    #rating .comment_right .star {
        margin-left: 14px;
        white-space: nowrap;
    }

    .rateidivc {
        width: 100%;
        max-width: 100%;
    }

    .prflimgbrdr {
        border: 4px solid #ffffff;
        /* width: 100%;
        max-width: 15%;
        min-width: 16%;
        height: 17%; */
        border-radius: 50%;
        /* margin-left: 30px; */
        margin-top: -42px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tstdfghmrl {
        margin-top: 3px;
    }
}

@media (max-width: 767px) {
    .rateMyFig {
        width: 60px;
        height: 60px;
        min-width: 60px;
        margin: 0 15px 0 0;
    }

    .prflimgbrdr {
        border: 4px solid #ffffff;
        /* width: 100%; */
        /* max-width: 21%;
        min-width: 21%;
        height: 17%; */
        border-radius: 50%;
        /* margin-left: 30px; */
        margin-top: -42px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .proCvrTagLine {
        font-size: 40px;
    }

    .hbtskiltserdiv {
        flex-wrap: wrap;
    }

    .hobyagfondiv {
        width: 100%;
        max-width: 100%;
    }

    .testdivbfkd {
        width: 100%;
        max-width: 100%;
    }

    .champMainDivParent {
        width: 100%;
        order: 3;
        padding-bottom: 25px;
    }

    .rateMyInp {
        font-size: 20px;
    }

    .rateMyInnerDiv {
        width: 48%;
    }

    .rateMyNtrkInnerDiv {
        width: 48%;
    }

    .rateMyInnerDivParent {
        column-gap: 0;
        justify-content: space-between;
    }

    .myVetTestHead {
        font-size: 21px;
    }

    .tabListPartDiv .react-tabs__tab {
        font-size: 13px;
    }

    #rating .rating_text {
        padding-top: 0;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 10px;
    }

    #rating .rating_text h3 {
        padding-top: 0;
    }

    #rating .comment_right p {
        margin-left: 0px;
    }

    #rating .person_description p {
        margin: 0 0 5px 0;
    }

    .jourEliteCardFig {
        height: 185px;
    }

    .mentProConnecFigsDiv {
        height: 98px;
        column-gap: 8px;
    }

    .jourChampDiv {
        column-gap: 21px;
    }

    #rating .progress {
        height: 10px;
    }

    .jourDescription {
        font-size: 14px;
    }

    .foundCeoHead {
        font-size: 16px;
    }

    .jourDecText {
        margin: 0 0 5px 0;
    }

    #rating .rating_btn .review_btn {
        width: 290px;
        height: 50px;
        font-size: 17px;
        line-height: normal;
    }

    .myJourTabListDiv .react-tabs__tab-list {
        overflow-x: auto;
    }

    .myJourTabListDiv .react-tabs__tab-list::-webkit-scrollbar {
        display: none;
    }

    .myJourTabListDiv .react-tabs__tab {
        text-wrap: nowrap;
    }

    #rating .comment_section .comment_top {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: baseline;
    }

    #rating {
        padding: 22px 28px 20px 22px;
    }
}

@media (max-width: 575px) {
    .proCvrTagLine {
        font-size: 32px;
    }

    /* .prflimgbrdr {
        max-width: 26%;
        min-width: 28%;
        height: 17%;
    } */

    .profileCvrPicDiv {
        height: 225px;
    }

    .proEditFigText {
        width: 182px;
    }

    .proEditCamFig {
        width: 28px;
        height: 22px;
    }

    .proEditText {
        font-size: 12px;
    }

    .hobbyPersoDiv {
        margin-top: 12px;
    }

    .hobbyAspHead {
        font-size: 18px;
    }

    .wrkHobbyText {
        font-size: 14px;
    }

    .rateSkillFig {
        width: 22px;
        height: 24px;
    }

    .rateSkillText {
        font-size: 11px;
    }

    .giveCoinFig {
        width: 19px;
        height: 26px;
    }

    .giveCoinText {
        font-size: 11px;
    }

    .writeTestFig {
        width: 20px;
        height: 26px;
    }

    .writeTestText {
        font-size: 11px;
    }

    .wrGiRaBtn {
        font-size: 14px;
        padding: 5px 15px;
    }

    #rating {
        padding: 25px;
        margin-bottom: 25px;
    }

    .rateJavaHead {
        font-size: 17px;
    }

    .rateLinkedinBtn {
        font-size: 21px;
    }

    .tabRateIconFig {
        width: 16px;
        height: 16px;
        margin: 3px 0 0 12px;
    }

    #rating .rating_text h2 {
        font-size: 35px;
    }

    #rating .rating_text p {
        font-size: 17px;
        line-height: normal;
        padding-top: 0;
    }

    #rating .rating_text h2 {
        font-size: 35px;
    }

    #rating .rating_text h3 {
        padding-top: 0;
    }

    .totalFiveText {
        margin-bottom: 5px;
    }

    #rating .comment_section {
        padding-top: 30px;
    }

    #rating .comment_section .comment_person {
        width: 40px;
        height: 40px;
    }

    #rating .comment_right h5 {
        font-size: 13px;
    }

    #rating .comment_right .star {
        margin-left: 15px;
    }

    #rating .comment_right i {
        font-size: 12px;
    }

    #rating .comment_right p {
        font-size: 12px;
    }

    #rating .comment_section .comment_right {
        padding-left: 10px;
    }

    #rating .rating_btn {
        padding-top: 15px;
    }

    #rating .rating_btn .review_btn {
        width: 230px;
        height: 45px;
        font-size: 14px;
    }

    .myJourTabListDiv .react-tabs__tab {
        font-size: 11px;
    }

    .jourAddInfoBtn {
        font-size: 14px;
        padding: 9px 18px;
    }

    .jourEliteCardFig {
        width: 99%;
        height: auto;
        margin: 0px auto 0 auto;
    }

    .mentConnecDivWrap {
        width: 100%;
        order: 3;
    }

    .champMainDivParent {
        order: 2;
    }

    .mentProConnecFigInnerDiv {
        width: 18%;
    }

    .mentProConnecFigsDiv {
        max-height: 217px;
        height: auto;
        column-gap: 10px;
    }

    .champFig2 {
        width: 105px;
        margin: 0 7px 0 0;
    }

    .founder-lft {
        width: 40px;
        min-width: 40px;
        height: 40px;
    }

    .bghhkpp {
        font-size: 20px;
        line-height: 22px;
    }

    .clbpp {
        font-size: 21px;
        line-height: 23px;
    }

    /* .tabListPartDiv {
        padding-left: 101px;
    } */
}

@media (max-width: 479px) {
    .rateMyFig {
        width: 50px;
        height: 50px;
        min-width: 50px;
        margin: 0 7px 0 0;
    }

    .rateMyInp {
        font-size: 16px;
    }

    .rateMySkillDiv {
        padding: 25px 18px;
    }

    .rateSkillFig {
        margin-bottom: 2px;
    }

    .rateSkillText {
        text-align: center;
    }

    .giveCoinFig {
        margin-bottom: 2px;
    }

    .giveCoinText {
        text-align: center;
    }

    .writeTestFig {
        margin-bottom: 2px;
    }

    .writeTestText {
        text-align: center;
    }

    .rateSkillDiv {
        flex-direction: column;
    }

    .wrGiRaBtn {
        font-size: 12px;
        padding: 5px 10px;
    }

    .rateMyInnerDiv {
        width: 100%;
        margin-bottom: 12px;
    }

    .rateMyNtrkInnerDiv {
        width: 100%;
    }

    .rateMySkillDiv {
        margin-bottom: 12px;
    }

    .myVetTestHead {
        font-size: 18px;
    }

    .mentProConnecFigInnerDiv {
        width: 22%;
    }

    .proCvrTagLine {
        font-size: 22px;
    }

    .profileCvrPicDiv {
        height: 175px;
        margin-bottom: 12px;
    }

    .tab-buttons {
        column-gap: 17px;
    }

    .proEditCamFig {
        width: 20px;
        height: 17px;
        margin-top: -7px;
    }

    .proEditText {
        font-size: 11px;
    }

    /* 
    .prflimgbrdr {
        max-width: 35%;
        min-width: 35%;
        height: 31%;
    } */

    #rating .comment_section .comment_person {
        width: 30px;
        height: 30px;
    }

    #rating .comment_section .comment_left {
        width: 12%;
    }

    #rating .comment_section .comment_right {
        width: 86%;
        padding-left: 5px;
    }

    #rating .comment_right i {
        font-size: 9px;
    }

    #rating .comment_right .star {
        margin-left: 15px;
    }


    #rating .comment_right h5 {
        font-size: 11px;
        margin-top: 1px;
    }

    #rating .comment_right p {
        font-size: 9px;
        margin-left: 15px;
    }

    #rating .comment_section {
        width: 100%;
    }

    #rating .person_description p {
        margin-left: 0;
        font-size: 10px;
    }

    .proEditFigText {
        width: 162px;
        right: 8px;
        bottom: 10px;
    }

    .tabListAddBtnDiv {
        justify-content: space-between;
        align-items: baseline;
        padding: 12px 12px 0 12px;
    }

    .tabListPartDiv {
        width: 75%;
        padding-left: 177px;
    }

    .addSkillsTabBtn {
        width: 20%;
        padding: 5px 8px;
    }

    .addSkillsTabBtn span {
        font-size: 10px;
    }

    .tabListPartDiv .react-tabs__tab {
        font-size: 12px;
        text-wrap: nowrap;
    }

    #rating .rating_text h2 {
        font-size: 27px;
    }

    #rating .rating_text h3 {
        font-size: 25px;
        line-height: normal;
    }

    .rateJavaHead {
        font-size: 15px;
    }

    .foundCeoHead {
        font-size: 14px;
    }

    .fractFullText {
        font-size: 12px;
    }

    .jourDecText {
        font-size: 12px;
    }

    .jourDescription {
        font-size: 11px;
        margin-bottom: 3px;
    }

    .jourSeeMore {
        font-size: 13px;
    }

    #rating .rating_btn .review_btn {
        width: 175px;
        height: 35px;
        font-size: 12px;
        border-radius: 5px;
    }

    .champFig {
        width: 37px;
        height: 35px;
    }

    .hobbyAspHead {
        font-size: 15px;
    }

    .wrkHobbyText {
        font-size: 12px;
    }

    .myAffityHead {
        font-size: 15px;
    }

    .myAffSeeAllBtn {
        font-size: 12px;
    }

    .hubEvCrePara {
        font-size: 13px;
    }

    .mySocalCmtHead {
        font-size: 15px;
    }

    .mySocialAllBtn {
        font-size: 12px;
    }

    .proAboutSelf {
        font-size: 13px;
        margin: 0 0 17px 0;
    }
}

@media (max-width: 359px) {
    .tabListPartDiv {
        padding-left: 208px;
    }
}