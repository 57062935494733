@font-face {
    font-family: NotoSansbold;
    src: url(../../Fonts/Noto_Sans/static/NotoSans-Bold.ttf);
}

.attendencemainsection {
    padding: 120px 0px 50px 0px;
    width: 100%;
    height: auto;
}

.attendencedivflx {
    display: flex;
    justify-content: space-between;
    column-gap: 36px;
}

.attendencedivleft {
    width: 100%;
    max-width: 72%;
}

.managercardbgdivmaiunmain {
    width: 100%;
    max-width: 48%;
}

.attendencedivright {
    width: 100%;
    max-width: 25%;
}

.Projectviewemplymanagermaindiv {
    padding-top: 30px;
}

.calenderdivmainattendence .react-calendar {
    padding: 10px;
}

.calenderdivmainattendence .react-calendar__navigation {
    display: flex;
    height: 27px !important;
    margin-bottom: 15px !important;
    justify-content: space-between;
}

.calenderdivmainattendence .react-calendar__navigation button {
    height: 30px !important;
    font-size: 30px !important;
    color: #000;
    font-weight: 600;
}

.attrendencedivdatediv {
    width: 100%;
    max-width: 23%;
}

.txtppdatenmkk {
    font-size: 18px;
    color: #00000080;
    font-weight: 600;
    font-family: noirprolight;
    line-height: 17px;
    text-align: center;
}

.lastdwekdivbtn {
    padding: 5px 15px;
    color: #fff;
    background-color: #ccc;
    border-radius: 20px;
    border: 1px solid #ccc;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: #000;
    font-family: poppinslight;
}

.lastweekmangereview {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
}

.attendencedatewismasnfdviewdivflc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0px 10px 25px;
}

.calenderdivmainattendence .react-calendar__navigation button:nth-child(3) {
    font-family: Poppinslight !important;
    font-size: 18px !important;
    line-height: 18px !important;
    font-weight: 600 !important;
    text-align: left;
}

.calenderdivmainattendence .react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: transparent !important;
}

.calenderdivmainattendence .react-calendar {
    width: 100% !important;
    border: 0;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.06);
    font-family: 'poppinslight' !important;
}

.calenderdivmainattendence .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    line-height: 16px;
    font-size: 12px;
    font-weight: 600;
    font-family: NotoSansbold;
}

/* .rbc-calendar {
    font-family: sans-serif;
  } */

.calenderdivmainattendence .react-calendar__tile--active {
    background: linear-gradient(90deg, rgba(113, 193, 229, 1) 19%, rgba(38, 138, 204, 1) 82%) !important;
    color: #fff !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
    padding: 2px;
    border-radius: 50%;
    width: 1005;
    width: 100%;
    max-width: 33px;
    min-width: 33px;
    height: 33px;
    font-family: notosans !important;
}

.calenderdivmainattendence .react-calendar__tile--now {
    background: linear-gradient(90deg, rgba(113, 193, 229, 1) 19%, rgba(38, 138, 204, 1) 82%) !important;
    color: #fff !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
    padding: 2px;
    border-radius: 50%;
    font-family: notosans !important;
    width: 100%;
    max-width: 33px;
    min-width: 33px;
    height: 33px;
}

.projectaddrihtdivbg {
    width: 100%;
    max-width: 100%;
    background: linear-gradient(214deg, rgba(38, 138, 204, 1) 70%, rgb(85 168 204) 97%);
    padding: 10px;
    border-radius: 10px;
}

.addattendencetxtpp {
    font-size: 15px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-family: noirprolight;
    line-height: 18px;
    margin-bottom: 8px;
}

.calenderdivrightmain {
    margin-bottom: 20px;
}

.inptsrcchdivmain {
    width: 100%;
    position: relative;
}

.inptsrcchdivmain input {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 40px 10px 10px;
    border: 1px solid #fff;
}

.srchimhgdiv {
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
    position: absolute;
    top: 8px;
    right: 12px;
}

.srchimhgdiv img {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100%;
}

.attendencedivrightul {
    list-style-type: circle;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    font-family: poppinlight;
    line-height: 16px;
    margin-bottom: 5px;
    margin-top: 5px;

}

.attendencedivrightul {
    margin-left: 10px;
}

.sbmtdivbtnasl {
    padding: 5px 10px;
    background-color: #94d9f7;
    border-radius: 5px;
    border: 1px solid #94d9f7;
    font-size: 14px;
    line-height: 16px;
    color: #000;
    font-family: poppinslight;
    font-weight: 800;
}

.sbmtdivbtnaslmain {
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 10px 0px 0px 0px;
}

.projecttimesheetdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
}

.projecttimesheetleavedivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 0px 0px;
}

.tabattendiv {
    border: 2px solid#65c9f8;
    border-radius: 40px;
    padding: 7px 20px;
    color: #000;
    font-size: 14px;
    line-height: 16px;
    font-family: poppinslight;
    font-weight: 700;
}

.tabattendiv.actv {
    background-color: #65c9f8;
    padding: 7px 20px;
    color: #fff;
}

.managervewbtnamincnn {
    border: 2px solid#65c9f8;
    border-radius: 40px;
    font-size: 14px;
    line-height: 16px;
    font-family: poppinslight;
    font-weight: 700;
    background-color: #65c9f8;
    padding: 7px 20px;
    color: #fff;
}

.managervewbtnamnomddivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.tabledivattendivmain {
    width: 100%;
    max-width: 100%;
}

.addimgspiroject {
    border-collapse: collapse;
    width: 100%;
}

.addimgspiroject {
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
}

.addimgspiroject img {
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.inputattendencediv {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
}

.totalattendencediv {
    background-color: #94d9f7;
    padding: 10px;
    border-radius: 10px;
}

.totalattendencediv.actv {
    background-color: #0a7b9f;
}

.totalrightyaxisattendencediv {
    background-color: #94d9f7;
    padding: 10px;
    border-radius: 10px;
}


.inputattendencediv.actv {
    background-color: #ccc;
}

.inputattendencediv.actvtotal {
    background-color: #65c9f8;
}

.elutitxtppsmop {
    font-size: 14px;
    color: #00000080;
    font-weight: 600;
    font-family: noirprolight;
    line-height: 17px;
}

.calenderdivmainattendence .react-calendar__navigation__prev2-button {
    display: none;
}

.calenderdivmainattendence .react-calendar__navigation__next2-button {
    display: none;
}

.calenderdivmainattendence .react-calendar__navigation__label {
    order: 1;
}

.calenderdivmainattendence .react-calendar__navigation__prev-button {
    order: 2;
}

.calenderdivmainattendence .react-calendar__navigation__next-button {
    order: 3;
}

.inputattendencetdm {
    padding: 10px 15px;
}

.inputattendencetdmnewalada {
    padding: 10px 0px 10px 30px;

}

.inputattendencetdm:first-child {
    padding: 0px;
}

.attendencedaydiv {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
}

.attendencedaydivnewalada {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
    text-align: end;
    font-size: 12px;
    font-weight: 800;
}

.attendencedaydiv:last-child {
    font-weight: 800;
}

.timeworktxtpp {
    font-size: 14px;
    color: #000;
    font-weight: 600;
    font-family: notosans;
    line-height: 17px;
    text-align: center;
}

.elutitxtppsmoptptal {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
    text-align: center;
    font-size: 12px;
    font-weight: 800;
}

.tabledivattendivtablediv {
    padding: 20px 0px 20px 0px;
}

.tableattentdtoptoatlbrdr {
    border-bottom: 1px solid #000;
}

.editworkmodalmainbg {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: #020202a1;
}

.editworkmodalbg {
    background-color: #fff;
    width: 100%;
    max-width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    padding: 15px 20px;
    border-radius: 10px;
    border: 3px solid rgba(38, 138, 204, 1);
}

.editxtpworklctntxp {
    font-size: 16px;
    color: #000;
    font-weight: 800;
    font-family: Notosans;
    line-height: 18px;
    margin-bottom: 35px;
    text-align: center;
}

.tableworklocatiomdedt {
    width: 100%;
    max-width: 100%;
}

.tableworklocatiomth {
    width: 100%;
    max-width: 20%;
    text-align: center;
}

.tableworklocatiomtd {
    width: 100%;
    max-width: 20%;
    padding: 10px 15px;
}

.intputworktimetxtdiv {
    width: 100%;
}

.intputworktimetxtdiv input {
    width: 100%;
    background-color: #94d9f7;
    padding: 3px;
    border: none;
    border-radius: 5px;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    font-family: notosans;
    text-align: center;
}

.editwrksbmtbtn {
    background: linear-gradient(41deg, rgba(38, 138, 204, 1) 97%, rgba(113, 193, 229, 1) 80%);
    border: none;
    padding: 7px 25px;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    font-family: 'NoirProLight';
    font-weight: 600;
    border-radius: 5px;
}

.editwrksbmtbtnmainbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 35px 0px 0px 0px;
}

.myleaveduvmain {
    margin-bottom: 20px;
}

.myleaverightsecdiv {
    width: 100%;
    max-width: 100%;
    background: linear-gradient(214deg, rgba(38, 138, 204, 1) 70%, rgb(85 168 204) 97%);
    padding: 20px 10px;
    border-radius: 10px;
}

.mylvetxtpp {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    font-family: noirprolight;
    line-height: 24px;
    margin-bottom: 25px;
    color: #fff;
}

.leacvebalncediv {
    width: 100%;
    max-width: 100%;
    border: 2px solid #fff;
    border-radius: 10px;
    background-color: #88c8e4;
}

.leaveblancpcrntdaydivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.leaveblancetxtp {
    font-size: 14px;
    color: #000;
    font-weight: 600;
    font-family: noirprolight;
    line-height: 14px;
}

.crntdaystxtp {
    font-size: 12px;
    color: #000;
    font-weight: 400;
    font-family: noirprolight;
    line-height: 14px;
}

.crntdaystxtp span {
    font-weight: 600;
    font-size: 20px;
}

.tableleavebalance {
    width: 100%;
    max-width: 100%;
}

.tableleavebalancetr:first-child {
    background-color: #fff;
}

.tableleavebalancetr {
    background-color: transparent;
}

.tableleavebalanceth {
    width: 100%;
    max-width: 26%;
    padding: 10px;
}

.tableleavebalancethtxt {
    font-size: 12px;
    color: #000;
    font-weight: 600;
    font-family: noirprolight;
    line-height: 14px;
    text-align: center;
}

.tableleavebalancetd {
    width: 100%;
    max-width: 26%;
    padding: 10px;
}

.tableleavebalancetdtxt {
    font-size: 12px;
    color: #000;
    font-weight: 600;
    line-height: 14px;
    text-align: center;
    font-family: notosans;
}

.tableleavebalancetrbrdr {
    border-bottom: 2px solid #fff;
}

.mylvetxtppdivmain {
    margin-top: 20px;
}

.leavealltyeheadingtxtpp {
    font-size: 14px;
    color: #fff;
    font-family: notosans;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 5px;
}

.leavealltyeheadivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.leavealltyeheadivflx label {
    margin-bottom: 0px;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    font-family: notosans;
}

.leavealltyeheadingttimepp {
    font-size: 14px;
    color: #fff;
    font-family: notosans;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 10px;
}

.timedatadivmain {
    padding-top: 10px;
}

.leavealltyeheadingttimedivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
}

.leavealltyeheadingttimedivright {
    width: 100%;
    max-width: 48%;
}

.leavealltyeheadingttimedivleft {
    width: 100%;
    max-width: 48%;
}

.dateinpuytdivmain {
    width: 100%;
    max-width: 100%;
}

.dateinpuytdivmain input {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 7px 7px;
    border: none;
    font-family: notosans;
}

.leavealltyeheadingttimediv {
    width: 100%;
    max-width: 100%;
}

.dateinpuytdivmaintime {
    width: 100%;
    max-width: 100%;
}

.dateinpuytdivmaintime input {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 7px 7px;
    border: none;
}

.dateslevetstxtppdivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.dateslevetstxtpp {
    font-size: 14px;
    color: #fff;
    font-family: notosans;
    font-weight: 600;
    line-height: 16px;

}

.leavereqtxtpp {
    font-size: 14px;
    color: #fff;
    font-family: notosans;
    font-weight: 600;
    line-height: 16px;
}

.leavereqtxtppdivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.leavereqtxtppdislct {
    width: 100%;
    max-width: 42%;
}

.leavereqtxtppdislct select {
    border: none;
    width: 100%;
    max-width: 100%;
    padding: 5px 10px;
    border-radius: 10px;
    font-family: 'NotoSans';
}

.leavereqtxtppdiv {
    padding-top: 10px;
}

.reasontxtarediv {
    width: 100%;
    max-width: 100%;
}

.reasontxtarediv textarea {
    width: 100%;
    max-width: 100%;
    resize: none;
    height: 70px;
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
}

.mangerviewsectionmain {
    padding: 120px 0px 50px 0px;
}

.managerdivcardmain {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;
}

.managerviewbrnmaonleftbshdk {
    width: 100%;
    max-width: 18%;
}

.managercardbgdivmaiun {
    width: 100%;
    max-width: 38%;
}

/* .managercardbgdivmaiunmainsection{
    width: 100%;
    max-width: 32%;
} */
.Projectviewemplymanagerdibspclfkx {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 30px;
    row-gap: 30px;
    flex-wrap: wrap;
}

.projectviewemplrdivmain {
    padding: 30px 0px 0px 0px;
}

.attrendencedivdatedivaladapading {
    padding: 0px 0px 0px 24px;
}

.managercfdivrihght {
    width: 100%;
    max-width: 72%;
}

.managercfdivleft {
    width: 100%;
    max-width: 25%;
}

.managerviwebtnmain {
    padding: 7px 20px;
    background: linear-gradient(214deg, rgba(38, 138, 204, 1) 70%, rgb(85 168 204) 97%);
    border-radius: 22px;
    border: 1px solid #94d9f7;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    font-family: poppinslight;
    font-weight: 800;
    margin-bottom: 40px;
    position: relative;
}

.managerviwebtnmaindtlsdivmainkkkp {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px;
    position: absolute;
    top: 37px;
    /* right: 0; */
    left: 0;
    width: 100%;
    max-width: 200px;
    min-width: 182px;
    height: 31vh;
    overflow-y: auto;
}

.prokekctviewtxtpppli {
    list-style-type: none;
    text-align: left;
    margin-bottom: 5px;
}

.prokekctviewtxtppul {
    margin-bottom: 0px;
}

.prokekctviewtxtpp {
    color: #000;
    font-size: 15px;
    font-family: notosans;
    text-align: left;
    line-height: 18px;
}

.managercardbgdiv {
    background-color: #94d9f782;
    padding: 10px;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px #ccc;
}

.mangercarddivflxmain {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.projectnamecardtxt {
    font-size: 16px;
    font-weight: 600;
    font-family: notosans;
    line-height: 18px;
    color: #00000080;
}

.projecttitletxtxcdrtxt {
    color: #349aec;
    font-size: 25px;
    font-weight: 800;
    font-family: 'NotoSansbold';
    line-height: 41px;
}

.projectnamecprojecttitldiv {
    margin-bottom: 5px;
}

.projectstarttxtppcrdt {
    font-size: 13px;
    font-weight: 600;
    font-family: notosans;
    line-height: 17px;
    color: #00000080;
}

.projectstartdatetxtcarddatetyr {
    color: #349aec;
    font-size: 12px;
    font-weight: 600;
    font-family: 'NotoSansbold';
    line-height: 16px;
}

.mangercarddivrightdata {
    width: 100%;
    max-width: 45%;
}

.mangercarddivleftdata {
    width: 100%;
    max-width: 48%;
}

.breakupcheckdib {
    background-color: #ccc;
    width: 100%;
    max-width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.breakupcheckdib i {
    font-size: 12px;
    color: #fff;
}

.viewbrancktcrpp {
    font-size: 12px;
    font-weight: 400;
    font-family: notosans;
    line-height: 16px;
    color: #00000080;
}

.breakupchviewbrancktcrsivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
}

.managercardbgdivmaiundivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 30px;
    row-gap: 30px;
    flex-wrap: wrap;
    padding-top: 25px;
}

.projectviewdetailamainsection {
    width: 100%;
    height: auto;
    padding: 120px 0px 50px 0px;
}

.projectviewdetailsdivright {
    width: 100%;
    max-width: 72%;
}

.projectviewdetailsdivleft {
    width: 100%;
    max-width: 25%;
}

.projectviewdivprjjectchartdivflx {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    column-gap: 16px;
}

.projectchartdivmain {
    width: 100%;
    max-width: 76%;
}

.projectchartdivmainbg {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 15px;
}

.projectviewbtnmiaonvd {
    width: 100%;
    max-width: 22%;
}

.managercardbgdiprojectdiv {
    width: 100%;
    max-width: 100%;
}

.managercardbgdiprojectdivmaonnj {
    margin-bottom: 20px;
}

.workdetailsdivmainslide {
    width: 100%;
    max-width: 100%;
}

.workdetailsdivmainslidebg {
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
}

.wrklcntnrxrtxtppwrlk {
    font-size: 18px;
    color: #000;
    font-weight: 600;
    text-align: center;
    font-family: notosans;
    padding-bottom: 20px;
}

.inputattendencedivworkdtls {
    background-color: #94d9f7;
    padding: 5px 10px;
    border-radius: 7px;
}

.tableworklocatiomthwrklctn {
    width: 100%;
    max-width: 9%;
    text-align: center;
}

.tableworklocatiomtdwrklctn {
    width: 100%;
    max-width: 9%;
    padding: 10px 10px;
}

.elutitxtppsmoplctnwrktxtp {
    font-size: 11px;
    color: #00000080;
    font-weight: 600;
    font-family: noirprolight;
    line-height: 17px;
    text-align: center;
}

.txtppdatenmkkwrlklcth {
    font-size: 20px;
    color: #00000080;
    font-weight: 600;
    font-family: notosanas;
    line-height: 17px;
    text-align: center;
    margin-bottom: 20px;
}

.workdetailsdivmainslide .slick-prev {
    left: 1px;
}

.workdetailsdivmainslide .slick-next {
    right: 1px;
}

.workdetailsdivmainslide .slick-prev {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 11%;
    width: 22px;
    height: 23px;
    background: #ccc !important;
    z-index: 4 !important;
    border-radius: 50% !important;

}

.workdetailsdivmainslide .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 11%;
    width: 22px;
    height: 23px;
    z-index: 4 !important;
    background: #ccc !important;
    border-radius: 50% !important;
}

.workdetailsdivmainslide .slick-prev:before {
    content: '\f104' !important;
    font-family: FontAwesome !important;
    font-size: 16px !important;
    line-height: 1;
    opacity: 1 !important;
    color: rgb(0 0 0) !important;
    display: flex;
    background-color: #ccc;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
}

.workdetailsdivmainslide .slick-next:before {
    content: '\f105' !important;
    font-family: FontAwesome !important;
    font-size: 16px !important;
    line-height: 1;
    opacity: 1 !important;
    color: rgb(0 0 0) !important;
    display: flex;
    background-color: #ccc;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
}

.Worklocationtabledivmaion {
    padding-bottom: 20px;
}

.ProjectWorktabletadbmainsectdiv {
    padding-top: 42px;
}

.attrendencedivdatediv .slick-prev {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    width: 22px;
    height: 23px;
    background: #ccc !important;
    z-index: 4 !important;
    border-radius: 50% !important;

}

.attrendencedivdatediv .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    width: 22px;
    height: 23px;
    z-index: 4 !important;
    background: #ccc !important;
    border-radius: 50% !important;
}

.attrendencedivdatediv .slick-prev:before {
    content: '\f104' !important;
    font-family: FontAwesome !important;
    font-size: 16px !important;
    line-height: 1;
    opacity: 1 !important;
    color: rgb(0 0 0) !important;
    display: flex;
    background-color: #ccc;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
}

.attrendencedivdatediv .slick-next:before {
    content: '\f105' !important;
    font-family: FontAwesome !important;
    font-size: 16px !important;
    line-height: 1;
    opacity: 1 !important;
    color: rgb(0 0 0) !important;
    display: flex;
    background-color: #ccc;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
}

.prthsttxttapppggjyuu8qokk {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    font-family: noirprolight;
    line-height: 18px;
}

@media(max-width:1399px) {

    .tableleavebalanceth {
        width: 100%;
        max-width: 17%;
        padding: 6px;
        min-width: 17%;
    }

    .leavereqtxtpp {
        font-size: 12px;
        color: #fff;
        font-family: notosans;
        font-weight: 600;
        line-height: 16px;
    }
    .tableleavebalancetd {
        width: 100%;
        max-width: 20%;
        min-width: 20%;
        padding: 5px;
    }
   

}

@media(max-width:1240px) {
    .leaveblancetxtp {
        font-size: 12px;
        color: #000;
        font-weight: 600;
        font-family: noirprolight;
        line-height: 14px;
    }

    .leaveblancpcrntdaydivflx {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
    }
    .tableleavebalancetdtxt {
        font-size: 10px;
        color: #000;
        font-weight: 600;
        line-height: 12px;
        text-align: center;
        font-family: notosans;
    }

    .crntdaystxtp {
        font-size: 11px;
        color: #000;
        font-weight: 400;
        font-family: noirprolight;
        line-height: 14px;
    }
}

@media(max-width:1199px) {
    .attendencedivleft {
        width: 100%;
        max-width: 70%;
    }

    .mylvetxtpp {
        font-size: 17px;
        text-align: center;
        font-weight: 600;
        font-family: noirprolight;
        line-height: 24px;
        margin-bottom: 25px;
        color: #fff;
    }

    .leavereqtxtppdislct {
        width: 100%;
        max-width: 100%;
        margin-top: 10px;
    }

    .leavereqtxtppdivflx {
        flex-wrap: wrap;
    }

    .attendencedivright {
        width: 100%;
        max-width: 26%;
    }

    .txtppdatenmkk {
        margin-bottom: 0px;
    }

    .attrendencedivdatediv .slick-prev {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 25%;
        width: 22px;
        height: 23px;
        background: #ccc !important;
        z-index: 4 !important;
        border-radius: 50% !important;
    }

    .attrendencedivdatediv .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 22%;
        width: 22px;
        height: 23px;
        z-index: 4 !important;
        background: #ccc !important;
        border-radius: 50% !important;
    }
    .attendencedivflx {
        flex-wrap: wrap;
    }
    .attendencedivleft {
        width: 100%;
        max-width: 100%;
    }

    .attendencedivright {
        width: 100%;
        max-width: 100%;
    }
}

@media(max-width:991px) {
 

    .managercfdivrihght {
        width: 100%;
        max-width: 100%;
    }

   

    .leavealltyeheadingttimedivright {
        width: 100%;
        max-width: 100%;
    }

    .leavereqtxtppdislct {
        width: 100%;
        max-width: 75%;
    }

    .managercfdivleft {
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: 30px;
        margin-top: 20px;
    }

    .managercardbgdivmaiundivflx {
        column-gap: 25px;
        row-gap: 25px;
    }

    .calenderdivrightmain {
        margin-bottom: 20px;
        width: 100%;
        max-width: 48%;
    }

    .projectaddrihtdivbg {
        width: 100%;
        max-width: 100%;
        background: linear-gradient(214deg, rgba(38, 138, 204, 1) 70%, rgb(85 168 204) 97%);
        padding: 10px;
        border-radius: 10px;
    }

    .projectaddrihtdiv {
        width: 100%;
        max-width: 48%;
    }

    .managercardbgdivmaiun {
        width: 100%;
        max-width: 48%;
    }
}

@media(max-width:767px) {
    .managercardbgdivmaiundivflx {
        column-gap: 20px;
        row-gap: 20px;
    }

    .managercardbgdivmaiun {
        width: 100%;
        max-width: 100%;
    }

    .leavereqtxtppdivflx {
        flex-wrap: wrap;
    }

    .leavereqtxtppdislct {
        width: 100%;
        max-width: 100%;
        margin-top: 10px;
    }

    .managercfdivleft {
        flex-wrap: wrap;
    }

    .calenderdivrightmain {
        margin-bottom: 20px;
        width: 100%;
        max-width: 100%;
    }

    .projectaddrihtdiv {
        width: 100%;
        max-width: 100%;
    }
}