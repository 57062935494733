.aligeneratedivbgmainactv{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #020202a1;
    z-index: 999;

}

.aligeneratedivbgmain{
    width: 100%;
    max-width: 612px;
    min-width: 612spx;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 22;
    background-color: #fff;
    border-radius: 15px;
}
.aigeneratedimgdiv{

    width: 100%;
    max-width: 100%;
    height: 230px;
    position: relative;
}
.aigeneratedimgdiv img{
    width: 100%;
    height: 100%;
    border-radius: 15px;
}
.welaigebenttettxt{
    font-size: 19px;
    line-height: 35px;
    color: #fff;
    font-weight: 500;
    font-family: notosans;
    margin-bottom: 10px;
}
.welaigsertxctnttettxt{
    font-size: 26px;
    line-height: 25px;
    color: #fff;
    font-weight: 500;
    font-family: notosans;
    margin-bottom: 10px;
    letter-spacing: 2px;
}
.aigeneratedtextdiv{
    position: absolute;
    top: 20%;
    left: 0%;
    transform: translateY(-37%);
    /* transform: translate(0%, -21%); */
    z-index: 99;
    width: 100%;
    padding: 30px 50px;
}
.inputaingenertsteddiv{
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -75%);
    z-index: 99;
    width: 100%;
    padding: 30px 50px;
}
.inputaingenertsteddiv input{
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    border: none;
    outline: 0;
    border-radius: 40px;
    padding: 10px 40px;
}
.serchimngsdiv{
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
    position: absolute;
    top: 38px;
    right: 63px;
}
.serchimngsdiv img{
width: 100%;
height: 100%;
}
.aigenertatabchatetxt{
    font-size: 15px;
    color: #ccc;
    font-family: notosans;
    font-weight: 500;
    line-height: 18px;
    padding-bottom: 15px;
}
.aigeneratedimgdivbottom {
    padding: 16px 47px;
    height: 331px;
    overflow-y: auto;
}
.aigenertatabchatetxthtrtstpp{
    font-size: 15px;
    color: #000000;
    font-family: notosans;
    font-weight: 500;
    line-height: 18px;
    padding-bottom: 15px;   
}
.sertcstresulutul{
    list-style-type: none;
}
.sertcstresulutli{
    border: 1px solid #ccc;
    padding: 10px 10px;
    margin-bottom: 15px;
    border-radius: 8px;  
}
.sertcstresulutultettxtpp{
    font-size: 13px;
    color: #000000;
    font-family: notosans;
    font-weight: 500;
    line-height: 18px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    height: 16px;
    margin-bottom: 1px;
  
}