.Grivencesuggestiondivmain{
    padding: 120px 0px 80px 0px;
}
.grivenceprorotyttx{
    color: #000;
    font-family: noirprolight;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    margin-bottom: 5px;
}
/* .manndgfacctdibn{
    margin-left: auto;
}
.fggdjjhafcsghlop{
    width: 100%;
    max-width: 640px;
} */
.grivenceprorotyttxspn{
    color: #000;
    font-family: noirprolight;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    /* margin-bottom: 10px; */
}
.notiPersonImggroven{
    width: 100%;
    max-width: 60px;
    min-height: 60px;
    height: 60px;
}
.notiPersonImggroven img{
    width: 100%;
    max-width: 100%;
    height: 100%;
    border-radius: 50%;
}
.tablsdibmagroveng{
    width: 100%;
}
.notiPersonImggrovendivflx{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 20px;
}
.fggdjjhanotiPersonIm{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 10px;
    width: 100%;
    max-width: 80%;
}
/* .grivenceprorotyttxdivmain{
    padding: 10px 0px;
} */
.srchggrivenvbhdiv{
    width: 100%;
    max-width: 87%;
    position: relative;

}
.srchggrivenvflrtdivflx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    column-gap: 20px;
}
.filterdivimg{
    position: relative;
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
}
.filterdivimg img{
    width: 100%;
    max-width: 100%;
    height: 100%;
}
.hoighmdijhdbnmfkx{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 5px;
    column-gap: 10px;
}
.hghtxtpopgjhqqw{
    font-size: 14px;
    font-family: poppinslight;
    font-weight: 400;
    color: #000;
    line-height: 16px;
}
.srchggrivenvbhdiv input{
    border: none;
    border-radius: 4px;
    box-shadow: 0 4px 9px 0 #ccc;
    margin: 10px 0;
    width: 100%;
    padding: 10px;
    position: relative;
}
.filterdivimghjgblowhj{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px;
    position: absolute;
    top:27px;
    right: 0;

}
.searchdinsgrgvc{
    position: absolute;
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
    top: 14px;
    right: 18px;

}
.searchdinsgrgvc img{
width: 100%;
max-width: 100%;
height: 100%;
}
.btnacttkndivbtn {
    background-color: #d3a145;
    border: 0;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-family: notosans;
    font-size: 15px;
    font-weight: 500;
    margin: 10px 0;
    padding: 5px 12px;
    text-align: center;
}
.btnactbndivbtn {
    color: #fff;
    background-color: #dc3545;
    border: 0;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-family: notosans;
    font-size: 15px;
    font-weight: 500;
    margin: 10px 0;
    padding: 5px 12px;
    text-align: center;
}

.notiPersonImggrovendivfmainboc{
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0px 20px 0px;
}

.Grivencesuggsbdivbghj {
    width: 100%;
    max-width: 84%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 30px;
}
.grovencedivmabdivpading{
    padding: 10px 20px ;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
}
.grovencedivmabdivpadingbtm{
    padding: 10px 20px ;   
}
.GrivenceCelldivimh{
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
}
.GrivenceCelldivimh img{
    width: 100%;
    max-width: 100%;
    height: 100%;

}

@media(max-width:991px){
    .fggdjjhanotiPersonIm {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: 10px;
        width: 100%;
        max-width: 70%;
    }
    .Grivencesuggsbdivbghj {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 30px;
    }
}
@media(max-width:767px){
    .notiPersonImggrovendivflx {
       flex-wrap: wrap;
    }
    .fggdjjhanotiPersonIm {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: 10px;
        width: 100%;
        max-width: 100%;
    }
}

@media(max-width:480px){
    .grivenceprorotyttxspn {
        font-size: 13px;
        
    }
    .manndgfacctdibn{
        margin: auto;
    }
    .notiPersonImggroven {
        width: 100%;
        max-width: 40px;
        min-height: 40px;
        height: 40px;
    }
    .grivenceprorotyttx {
        font-size: 13px;
       
    }
}