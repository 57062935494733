.hallOfFrmeModal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #00000069;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hallOfFrameInner {
    max-width: 500px;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    min-height: 200px;
}

.newBulleBrodHead {
    color: #263238;
    font-family: 'Plus Jakarta Sans';
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bulleBrodCloseBtn {
    background-color: transparent;
    border: 0;
    color: red;
    font-size: 25px;
    position: absolute;
    top: 20px;
    right: 20px;
}

.emplListDiv {
    /* margin-top: 0;
    margin-bottom: 0px;
    */
    /* display: flex; */
    width: 100%;
    margin-left: 5px;
    /* margin-left: 5px; */
    /* align-items: baseline; */
}

.empListSubHead {
    font-size: 13px;
    font-weight: 500;
    font-family: 'Plus Jakarta Sans';
    margin: 0 0 5px 0;
    width: 70%;
}

.empListSelect {
    width: 100%;
    font-size: 10px;
    font-weight: 600;
    font-family: 'Plus Jakarta Sans';
    outline: none;
    border: 1px solid #00000070;
    border-radius: 5px;
    padding: 0px;
    height: 34px;
}

.empListSelectdiv {
    width: 100%;
    font-size: 13px;
    font-weight: 600;
    font-family: 'Plus Jakarta Sans';
    outline: none;
    border: 1px solid #00000070;
    border-radius: 5px;
    padding: 4px;
    height: 30px;
}

.empListTextArea {
    width: 100%;
    resize: none;
    border: 1px solid #00000070;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
}

.viedetailsmain {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 999;
    background-color: #00000069;
    position: fixed;
}

.viewdetailscontent {
    width: 100%;
    max-width: 45%;
    position: absolute;
    z-index: 2;
    background-color: #fff;
    /* box-shadow: 0px 0px 5px 0px #ccc; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* padding: 10px; */
    border-radius: 10px;
}

.inititaiveheadingmodaltxtmainhhh {
    font-size: 18px;
    color: #000;
    font-weight: 600;
    font-family: noirproregular;
    line-height: 20px;
}

.closemarkdst {
    position: absolute;
    top: 5px;
    right: 8px;
    z-index: 5;
}

.afntytxtpp {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-weight: 700;
    font-family: Mandaufont;
}

.closemarkdst i {
    font-size: 14px;
    color: rgb(231, 0, 0);
    cursor: pointer;
}

.askmodal {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    position: fixed;
    background-color: #00000069;
    z-index: 99;
}

.askmodalcontent {
    width: 100%;
    max-width: 35%;
    background-color: #fff;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    /* padding: 20px; */
}

.askhdtxt h4 {
    font-size: 18px;
    font-weight: 800;
    color: rgb(0, 0, 0);
    text-align: center;
    text-transform: uppercase;
    padding: 10px;
    border-bottom: 1px solid rgba(198, 198, 210, 0.48);
}

.closemarkivpnj {
    position: absolute;
    z-index: 99;
    top: 10px;
    right: 10px;
}

.closemarkivpnj i {
    color: red;
    font-size: 14px;
}

.askquestion {
    /* border: 1px solid #000; */
    /* border: 1px solid #00000078; */
    padding: 10px 15px;
    margin: 10px 11px;

}

.mofalthgfhtre {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 13px;
}

.asktxtpop p {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    line-height: 20px;
    padding-bottom: 10px;
}

.asktstxarea {
    width: 100%;
    padding-bottom: 15px;
}

.asktstxarea textarea {
    padding: 10px;
    width: 100%;
    resize: none;
    border: none;

}

.sbmtbtnsbmt {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bec3c961;
    padding: 10px;
    border-radius: 10px;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
    border: none;
}

.flxdivbgth {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
}

.connrtybhr {
    width: 100%;
    max-width: 13%;
    min-width: 14%;
    height: 59px;

}

.connrtybhr img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

.asktstxarea ::placeholder {
    color: #d3a145;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

.myafinitydivpading {
    padding-bottom: 12px;
}

.homePgModInnerInpDivs {
    padding-bottom: 10px;
}

.frmhgth {
    height: 500px;
    overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.frmhgth::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.frmhgth {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.psttxtdiv {
    color: "#000";
    font-Size: 13px;
    font-weight: 600;
    line-height: 18px;
}

.mainsurveysuggestion {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 99;
    position: fixed;
    background-color: #00000070;
}

.surveysuggestioncontent {
    width: 100%;
    max-width: 50%;
    background-color: #fff;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    border-radius: 10px;

}

.crsicon {
    position: absolute;
    top: 10px;
    right: 11px;
    z-index: 5;
}

.crsicon i {
    font-size: 18px;
    color: rgb(235, 3, 3);
}

.contentdivmai {
    height: 100%;
    overflow-y: auto;
    padding: 30px 30px 10px 30px;
}

.contentdivmai::-webkit-scrollbar {
    display: none;
}

.tesxtdivmain {
    width: 100%;
    height: 100%;
    /* padding: 10px 0px 0px 0px; */
    border: 1px solid #d3a145;
    padding: 10px;
    margin: 10px 0px;
    border-radius: 10px;
}

.tesxtdivmain textarea {
    width: 100%;
    resize: none;
    border: none;
}

.txtdivrtyar::placeholder {
    color: #d3a14569;
    font-family: 'NoirProLight';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 34px;
    cursor: pointer;
}

.opentxtdiv {
    /* font-weight: 600; */
    /* color: #000; */
    /* line-height: 30px; */
    /* font-family: "NoirProRegular"; */
    padding-bottom: 0px;
    font-family: NoirProRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #263238;
    margin-left: 8px;
}
.isHighLightedRadiodivnew {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start
}
.isHighLightedRadiodivnew:last-child{
    margin-bottom: 0px;
}
.isHighLightedRadiodivnew input {
    width: 22px;
    height: 17px;
}
.isHighLightedRadiodivnewdiv{
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px;
    margin: 10px 0px 0px 0px;
}
.vdoulddivpbupldgnewmmll{
    width: 100%;
    max-width: 100%;
}
.isHighLightedRadiodivnew label {
    margin: 0px;
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-weight: 500;
    font-family: noirprolight;
}
.sgstyytxt {
    font-size: 20px;
    font-weight: 500;
    color: #000;
    line-height: 25px;
    font-family: 'NoirProLight';
}

.diijfkxkldiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    border-top: 1px solid #00000060;
    padding: 9px;
}

.hriningmoadl {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    position: fixed;
    background-color: #00000069;
    z-index: 99;
}

.hringmodalcontent {
    width: 100%;
    max-width: 43%;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    position: absolute;
    /* height: 20vh; */
    border-radius: 10px;
}

.hiringclse {
    position: absolute;
    top: 25px;
    right: 18px;
    z-index: 6;
    cursor: pointer;
}

.hiringclse i {
    color: red;
    font-size: 18px;
}

.cntntmodaltxt {
    padding: 20px;
}

.inpttxtdiv p {
    font-size: 14px;
    margin: 5px 0px;
    color: #000;
    font-weight: 700;
    font-family: "NotoSans";
    line-height: 18px;
}

.jobpostdtls {
    width: 100%;
    padding: 10px 10px;
    border: none;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 10px;
}

.inpttxtdiv {
    width: 100%;
}

.cntmodalscrl {
    height: 440px;
    overflow-y: auto;
    padding: 0px 10px;
}

.textaredescrtv p {
    font-size: 14px;
    line-height: 18px;
    margin: 5px 0px;
    color: #000;
    font-weight: 700;
    font-family: "NotoSans";
}

.jobpostdtlsare {
    width: 100%;
    height: 74px;
    resize: none;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 10px;
    border: none;
    padding: 10px 10px;
}

.rsttxtfrt {
    font-size: 18px;
    margin: 5px 11px;
    color: #000;
    font-weight: 700;
    font-family: "NoirProRegular";
}

.rewarddivmain {
    width: 100%;
    height: 100%;
    background-color: #00000069;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
}

.rewarddivcontent {
    width: 100%;
    max-width: 33%;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rwdtxtpp {
    color: #000;
    font-size: 21px;
    line-height: 26px;
    font-family: 'NoirProLight';
    font-weight: 700;
    letter-spacing: 1px;
    text-align: start;
}

.closedivred i {
    color: red;
    font-size: 14px;
}


.btndivcl {
    color: red;
    /* display: flex; */
    /* justify-content: flex-end; */
    /* align-items: center; */
    position: fixed;
    top: 5%;
    right: 13px;
    z-index: 99;
    cursor: pointer;
}

.brdrbtmdivsup {
    border-bottom: 1px solid #ccc;
    padding: 6px 10px;
}

.btndivcl i {
    font-size: 19px;
}

.rwddiov {
    padding: 4px 0px;
}

.rwddiovmain {
    /* margin: 5px 0px; */
    border-bottom: 1px solid #00000069;
    padding: 5px;
}

/* .rewardtm{
    padding: 10px 10px;
} */
.txtrwddibpp {
    color: #000;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    font-family: Notosans;
    padding: 5px 0px;
}

/* width */
.cntmodalscrl::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.cntmodalscrl::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
}

/* Handle */
.cntmodalscrl::-webkit-scrollbar-thumb {
    background: #FFBE98;
    border-radius: 5px;
}

/* Handle on hover */
.cntmodalscrl ::-webkit-scrollbar-thumb:hover {
    background: #FFBE98;
}

.hghtscrldiv {
    height: 100%;
    max-height: 500px;
    overflow-y: auto;
}

.viewdetailscontenthiring {
    width: 100%;
    max-width: 50%;
    position: absolute;
    z-index: 2;
    background-color: #fff;
    /* box-shadow: 0px 0px 5px 0px #ccc; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
}

.psttxtppdiv {
    font-size: 25px;
    line-height: 32px;
    font-family: Notosans;
    color: #000;
    font-weight: 500;
}

.shareusereventflx {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: row;
    /* row-gap: 33px; */
    column-gap: 10px;
}

.dshimgtxt {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0px;
    gap: 2%;
}

.imhtnch {
    width: 25px;
    height: 25px;
}

.imhtnch img {
    width: 100%;
    height: 100%;
}

.dstgytxy {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-family: Notosans;
    font-weight: 500;
}

.jntxtdivhrin {
    font-size: 20px;
    line-height: 25px;
    font-family: Noirprolight;
    font-weight: 700;
    color: #000;
    padding: 10px 0px;
}

.optxtvn {
    font-size: 17px;
    line-height: 21px;
    font-family: Noirprolight;
    font-weight: 700;
    color: #000;
}

.inpuytboxdyflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
    padding: 10px 0px;
}

.bg-brdrdiv {
    border-bottom: 2px solid #000000a1;
}

.refermodaldiv {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #00000069;
    z-index: 999;
    top: 0;
    right: 0;
}

.refermodaldivcontent {
    position: absolute;
    z-index: 22;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 40%;
    background-color: #fff;
    border-radius: 10px;
}

.refermpodalflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #ccc;
    margin: 10px 0px;

}

.crssdicv {
    margin-left: auto;
}

.crssdicv i {
    position: fixed;
    top: 14px;
    right: 10px;
    z-index: 99;
    color: red;

}

.rfrtxtpp {
    width: 100%;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    font-family: Noirprolight;
    color: #000;
    padding-bottom: 10px;
    font-weight: 600;
}

.selectdivmain {
    padding: 10px 15px;
}

.emplselect {
    font-size: 17px;
    line-height: 20px;
    font-family: Notosans;
    font-weight: 500;
    color: #000;
}

.selectmaindiv {
    padding: 10px 0px 0px 0px;
}

.referbtnflx {
    width: 150px;
    background-color: rgb(255, 190, 152);
    height: 50px;
    border: none;
    border-radius: 10px;
    display: flex;
    padding: 10px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}
.tstxguiuhosbj{
    font-size: 15px;
    line-height: 20px;
    font-family: Notosans;
    font-weight: 700;
    color: #000;    
}
.tstxguiuhosbjdivmain{
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.accptrrjctrdivmsindiv{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
}
.mandatorymodalmain {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #00000069;
    z-index: 99;
}

.mandatorymodalmaincontent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    background-color: #fff;
    width: 100%;
    max-width: 40%;
    padding: 10px;
}

.vdeoimgmain {
    width: 100%;
    height: 300px;
    position: relative;
}

.vdeoimgmain::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #0000005d;
    z-index: 99;
}

.vdeoimgmain img {
    width: 100%;
    height: 100%;
}

.videoimgdiv {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
}

.videoimgdiv img {
    width: 100%;
    height: 100%;
}

.elitedevloptxt {
    font-size: 20px;
    line-height: 24px;
    font-family: noirprolight;
    color: #000;
    font-weight: 600;
    padding: 5px 0px;
}

.divenrolflx {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.divenallbootcmp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
    width: 100%;
    max-width: 70%;
}

.divstarimgmainimg {
    width: 25px;
    height: 25px;
}

.divstarimgmainimg img {
    width: 100%;
    height: 100%;
}

.divstarimgmain {
    /* width: 100%; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 6px;
}

.enrolllnowdiv {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    background-color: #d3a145;
    padding: 5px 20px;
    letter-spacing: 1px;
    font-family: notosans;
    border-radius: 5px;
    text-align: center;
    margin-top: 10px;
    border: none;
}

.decrtxtpp {
    font-size: 18px;
    line-height: 22px;
    font-family: notosans;
    color: #000;
    font-weight: 600;
}

.descriptiondetails {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-weight: 300;
    font-family: noirprolight;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    margin: 10px 0px;
}

.decrtxtppdiv {
    padding: 10px 0px 0px 0px;
}

.showdescripttext {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    font-weight: 500;
    font-family: Notosans;
    color: #fff;
    cursor: pointer;
    background-color: #d3a145;
    width: 100%;
    max-width: 20%;
    margin: auto;
    padding: 6px;
    border-radius: 8px;
}

.seemoredivmain {
    position: fixed;
    background-color: #00000069;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 99;
}

.seemoredivmaincontent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 45%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px;
    z-index: 2;
    padding: 2%;
}

.purpsetextpp {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-weight: 400;
    font-family: noirprolight;
}

.createmodalnewdiv {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #000000a6;
}

.createmodalnewdivcontent {
    max-width: 590px;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bulletintextclose {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 10px;
    border-bottom: 1px solid #DDDDDD;
}

.bulletinimageflxdivmodal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

/* .homePageCreModaldivfrm>div {
    padding: 10px 20px;
    border-bottom: 1px solid #DDDDDD;
 
} */
.homePgModInnerInpDivsmain {
    display: flex;
    justify-content: flex-start;
    column-gap: 5px;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 14px 10px;
}

.homePgModInnerInpDivsupldflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    padding: 8px 10px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

}


.homePgModInnerInpDivsupldflx p {
    font-size: 15px;
    line-height: 18px;
    color: #000;
    /* font-weight: 700; */
    font-family: noirprolight;
    width: 100%;
    max-width: 20%;
    display: flex;
    column-gap: 5px;
    font-weight: 500;
}

.bguploadbulletinmdbrdr {
    border: 1px solid #fac00d;
    width: 100%;
    max-width: 77%;
    padding: 5px;
    border-radius: 5px;
}

.bgcrdbulletin {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    padding: 5px;
}

.rwddiov {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.homePgModInnerInpDivsmain p {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-weight: 500;
    font-family: noirprolight;
    width: 100%;
    max-width: 22%;
    display: flex;
    column-gap: 4px;
}

.homePgModInnerInpDivsmain input {
    border: 1px solid #fac00d;
    width: 100%;
    max-width: 77%;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 18px;
    font-family: NoirProLight;
}

.homePgModInnerInpDivsmain .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    max-width: 77%;
    /* border: 1px solid #fac00d; */
    padding: 5px;
    border-radius: 5px;
}

.homePgModInnerInpDivsmain textarea {
    width: 100%;
    max-width: 80%;
    border: 1px solid #fac00d;
    border-radius: 11px;
    outline: none;
    color: #263238;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    resize: none;
    background-color: transparent;
    padding: 5px 10px;
    height: 75px;

}

.bgcontainerupldfilediv {
    width: 100%;
    max-width: 112px;
    min-width: 112px;
    height: 100px;
    background-color: #dedede;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
}

.bgcontainerupldfiledivabslt {
    position: absolute;
    /* bottom: 188px; */
}

.upldimgdivbulletin {
    width: 100%;
    max-width: 85px;
    min-width: 85px;
    height: 75px;
    position: relative;
}

.upldimgdivbulletin img {
    width: 100%;
    height: 100%;
}

.clsoedivmainrd {
    position: absolute;
    top: -11px;
    right: -4px;
    z-index: 7;
    color: red;
    font-size: 18px;
    cursor: pointer;
}

.bulletinsbmtbtn {
    border: 0;
    /* background: #0E24CC; */
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    padding: 5px 12px;
    border-radius: 8px;
    font-family: notosans;
    text-align: center;
    cursor: pointer;
    margin: 10px 0px;
    background-color: #d3a145;
}

.bulletinsbmtbtndivv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
}

.homePgModInnerInpDivsmain select {
    border: 1px solid #fac00d;
    width: 100%;
    max-width: 77%;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 18px;
    font-family: NoirProLight;
}

.logoupldbulletin {
    width: 50px;
    height: 50px;
}

.logoupldbulletin img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.homePgModInnerInpDivsmainholo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    padding-bottom: 10px;
}

.homePgModInnerInpDivsmainholo p {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-weight: 500;
    font-family: noirprolight;
    width: 100%;
    max-width: 25%;
}

.formdivpading {
    padding: 10px;
}

.homePgModInnerInpDivsmainholo textarea {
    width: 100%;
    resize: none;
    border: 1px solid #fac00d;
    border-radius: 5px;
    padding: 10px;
    /* font-size: 15px; */
    /* font-weight: 500; */
    font-size: 14px;
    line-height: 18px;
    font-family: 'NoirProLight';
}

.homePgModInnerInpDivsmainholo select {
    width: 100%;
    max-width: 80%;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Noirprolight';
    outline: none;
    border: 1px solid #fac00d;
    border-radius: 5px;
    padding: 0px;
    height: 34px;
}

.homePgModInnerInpDivsmain .form-select.slct_frm {
    border: 1px solid #fac00d;
}

.css-13cymwt-control {
    border-color: #fac00d !important;
}

.formhgfthdivmain {
    height: 440px;
    overflow-y: auto;
}

.eventmodalyesornodivflx {
    display: flex;
    justify-content: flex-start;
    column-gap: 5px;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 8px 10px;
}

.eventmodalyesornodivflx p {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-weight: 500;
    font-family: noirprolight;
    width: 100%;
    max-width: 25%;
}

.isHighLightedRadiodiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

.isHighLightedRadiodiv label {
    margin: 0px;
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-weight: 500;
    font-family: noirprolight;
}

.isHighLightedRadiodiv input {
    width: 22px;
    height: 17px;
}

.homePgModInnerInpDivsmainmyafinity {
    display: flex;
    justify-content: flex-start;
    column-gap: 5px;
    align-items: center;
    border-top: 1px solid #ccc;
    padding: 8px 10px;
}

.homePgModInnerInpDivsmainmyafinity p {
    font-size: 15px;
    line-height: 18px;
    color: #000;
    font-weight: 700;
    font-family: noirprolight;
    width: 100%;
    max-width: 30%;
}

.homePgModInnerInpDivsmainmyafinity input {
    border: 1px solid #fac00d;
    width: 100%;
    max-width: 68%;
    padding: 5px;
    border-radius: 5px;
}

.homePgModInnerInpDivsmainmyafinity .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    max-width: 68%;
    /* border: 1px solid #fac00d; */
    padding: 5px;
    border-radius: 5px;
}

.bguploadbulletinmdbrdrdiv {
    border: 1px solid #fac00d;
    width: 100%;
    max-width: 68%;
    padding: 5px;
    border-radius: 5px;
}

.homePgModInnerInpDivsupldflxdiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    padding: 8px 10px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.homePgModInnerInpDivsupldflxdiv p {
    font-size: 15px;
    line-height: 18px;
    color: #000;
    font-weight: 700;
    font-family: noirprolight;
    width: 100%;
    max-width: 30%;
}

.starmandatort {
    font-size: 30px;
    color: #fac00d;

}

.bulllwetindetlsrxty {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    font-family: noirprolight;
    line-height: 18px;
    padding: 10px;
}

.initiativetextdtlsmodaltxt {
    font-size: 13px;
    line-height: 18px;
    font-family: 'Mandaufont';
    color: #000;
    font-weight: 500;
}

.inititaiveheadingmodaltxt {
    font-size: 14px;
    color: #000;
    font-weight: 700;
    font-family: noirproregular;
    line-height: 18px;
}

.initiativetctcrsth {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px;
}

.inititaiveheadingmodaltxtmaindiv {
    /* border-bottom: 1px solid #1d1717; */
    /* padding: 22px; */
}

.closetmodalinitive i {
    color: red;
    font-size: 14px;
    cursor: pointer;
}

.jnbtndivinititve {
    border: none;
    background-color: #c39845;
    padding: 5px 30px;
    border-radius: 10px;
    color: #fff;
    line-height: 18px;
    font-size: 14px;
    font-weight: 500;
}

.jnbtndivinititvemarginmainduiv {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
}

.shareimgmn {
    width: 20px;
    height: 20px;
}

.shareimgmn img {
    width: 100%;
    height: 100%;
}

.handleserachmain {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #000000cc;
    top: 0;
    right: 0;
    z-index: 10;
}

.handlesrchbg {
    position: absolute;
    z-index: 11;
    background-color: #fff;
    width: 100%;
    max-width: 600px;
    /* height: 50px; */
    top: 21%;
    left: 50%;
    transform: translate(-50%, -21%);
    border-radius: 5px;
    padding: 10px;
}

.searchdivmain input {
    width: 100%;
    height: auto;
    padding: 10px;
    border: none;
    background-color: #ccccccbf;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 5px;
}

.editimagemain {
    width: 19px;
    height: 20px;
}

.editimagemain img {
    width: 100%;
    height: 100%;
}

.singledatappp {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-weight: 500;
    font-family: noirprolight;
}

.bulllwetindetlsrty {
    padding-bottom: 10px;
}

.singlebulletinflx {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    column-gap: 20px;
}

.singleimagemaindivmain {
    width: 100%;
    max-width: 100%;
}

.bulletindetilasbg {
    background-color: #fff;
    width: 100%;
    height: auto;
    max-width: 80%;
    /* margin: auto; */
    padding: 20px;
    position: relative;
    left: 50%;
    transform: translatex(-50%);
    /* bottom: -10%; */
    margin-top: -84px;
    /* box-shadow: 0px 0px 5px 0px #ccc; */
    border: 1px solid #ccc;
}

.bulletindetails {
    width: 100%;
    max-width: 48%;
    background-color: #fff;
}

.bulletinflxdibmm {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    padding: 10px;
}

.blrnmtxtpp {
    font-size: 18px;
    line-height: 22px;
    color: #000;
    font-weight: 400;
    font-family: noirprolight;
}

.crossicongrovence i {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: red;
    font-size: 14px;
    cursor: pointer;
}

.opentxtdivwhstl {
    padding-bottom: 0px;
    font-family: NoirProRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    color: #263238;
    margin-left: 0px;
}

.sgstyytxtwhstl {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    line-height: 22px;
    font-family: 'NoirProLight';
    padding-top: 10px;
}

.pstyflxgrvnc {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    column-gap: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.hgbtnnmgnwhstl {
    border: none;
    background-color: rgb(234, 235, 251);
    padding: 5px;
    border-radius: 5px;
    color: #000;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 15px; */
    font-family: 'NotoSans';
    width: 100%;
}

.grvncdivmain {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #d3a145;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 5px 9px;
    max-width: 12%;
    margin: auto;
    column-gap: 10px;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    font-family: 'NoirProLight';
    cursor: pointer;
    border: 1px solid #ccc;
    margin-bottom: 5px;
}

.grvncdivmainimg {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.grvncdivmainimg img {
    width: 100%;
    height: 100%;
}

.allcardmodalbgmain {
    width: 100%;
    height: 100%;
    background-color: #00000060;
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
}

.allcardbigbg {
    width: 100%;
    max-width: 955px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    border-radius: 10px;
    padding: 10px;
}

.allcardsectionflx {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    padding-left: 5px;
    padding-top: 19px;
    padding-bottom: 19px;
}

.allcardsectionbg {
    width: 100%;
    max-width: 47%;
}

.thankyoucrdbg {
    width: 100%;
    height: 277px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 8px 10px;
    position: relative;
    border-radius: 10px;

}

.profilebgthanky {
    background-color: #d3a145;
    width: 100%;
    max-width: 100px;
    height: 100px;
    max-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    align-self: center;
    margin: auto;
    margin-top: 10px;
}

.profilebgthankyimg {
    width: 100%;
    max-width: 80px;
    min-width: 80px;
    height: 80px;
}

.profilebgthankyimg img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.thankyoutxtppcrd {
    font-size: 13px;
    color: green;
    font-weight: 500;
    font-family: notosans;
    text-align: center;
    padding: 5px 51px;
}

.thankyubrdrdiv {
    width: 100%;
    border: 1px solid #c0852f;
    padding: 5px;
    border-radius: 30px;
    text-align: center;
    margin: 9px 0px;
    max-width: 38%;
    margin: auto;
    margin-top: 13px;
    font-size: 14px;
    color: #000;
    font-weight: 800;
    font-family: 'NotoSans';
}

.profilebgthankywh {
    background-color: #ffffff;
    width: 100%;
    max-width: 96px;
    height: 96px;
    min-width: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    align-self: center;
    margin: auto;
    margin-bottom: 6px;
}

.happybrthdivmain {
    padding: 20px 0px 4px 0px;
    text-align: center;
}

.happytxtcrdpp {
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    font-family: notosans;
    line-height: 25px;
    text-transform: uppercase;
}

.birthtxtcrdpp {
    font-size: 30px;
    color: #fff;
    font-weight: 800;
    font-family: notosans;
    line-height: 35px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
}

.happytxtcrdppname {
    font-size: 22px;
    color: #fff;
    font-weight: 500;
    font-family: notosans;
    line-height: 22px;
    letter-spacing: 0.4px;
}

.birthtxtcrdpphead {
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    font-family: notosans;
    line-height: 18px;
    padding-top: 10px;
}

.wlcmcrdtxtpp {
    font-size: 20px;
    color: #fff;
    font-weight: 400;
    font-family: notosans;
    line-height: 15px;
    padding-bottom: 15px;
}

.wlcmcrdname {
    font-size: 23px;
    color: #fff;
    font-weight: 500;
    font-family: notosans;
    line-height: 29px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.wlcmcrdtitle {
    font-size: 34px;
    color: #fff;
    font-weight: 800;
    font-family: notosans;
    line-height: 35px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding-top: 8px;
}

.textimagemainwelcmdivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    padding: 80px 0px 0px 0px;
}

.algnonbrddivmainwh {
    width: 100%;
    max-width: 48%;
    margin-left: 14px;
}

.algnonbrddivmainimg {
    width: 100%;
    max-width: 48%;
}

.happyannyversyimg {
    width: 100%;
    max-width: 124px;
    min-width: 124px;
    height: 100px;
    transform: skew(359deg, 360deg);
}


.happyannyversyimg img {
    width: 100%;
    height: 100%;
}

/* .happyannyversyimgmain {
    position: absolute;
    top: 32%;
    left: 6%;
    transform: skew(9deg, 353deg);
} */

/* .txthappyanniversydiv {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end
} */

.fiveyrsrtxtpp {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    font-family: notosans;
    color: #fd8f6c;
    text-align: center;
}

.fiveyrsrtxtppcmplt {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    font-family: notosans;
    color: #fd8f6c;
    text-align: center;
}

.happyannyvrtxtpp {
    font-weight: 500;
    line-height: 26px;
    font-family: notosans;
    color: #fd8f6c;
    /* text-align: end; */
    letter-spacing: 0.6px;
    text-align: center;
}

.happyannyvrtxtppmanme {
    font-size: 22px;
    font-weight: 800;
    line-height: 26px;
    font-family: notosans;
    color: #fd8f6c;
    text-align: center;
    letter-spacing: 0.6px;
}

.happyannyversyimgmain {
    width: 100%;
    max-width: 38%;
    background-color: #fd8f6c;
    transform: skew(15deg, 348deg);
    padding: 10px;
}

.happyannyversytxtppflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    padding-top: 51px;
    padding-left: 17px;
    padding-right: 15px;
}

.txthappyanniversydiv {
    width: 100%;
    max-width: 56%;
}

.wishyutxtpgdpp {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-family: notosans;
    color: #fd8f6c;
    /* text-align: end; */
    letter-spacing: 0.6px;
}

.yrscmpltdivmain {
    padding-bottom: 10px;
}

.happypritydivtxt {
    padding-bottom: 10px;
}

.wishyutxtpgdppsrpp {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-family: notosans;
    color: #fd8f6c;
    /* text-align: end; */
    letter-spacing: 0.6px;
    text-align: center;
}

.allcardmodalonebgmain {
    width: 100%;
    height: 100%;
    background-color: #00000060;
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
}

.allcardonebigbg {
    width: 100%;
    max-width: 425px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    border-radius: 10px;
    padding: 10px;
}

.allcardsectionbgonecard {
    width: 100%;
    max-width: 100%;
}

.toinpyrgpltpp {
    width: 100%;

}

.toinpyrgpltpp input {
    width: 100%;
    padding: 10px;
    background-color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px #ccc;
    margin-bottom: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
}

.sndbtnmaibbg {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border: 1px solid #ccc;
    width: 100%;
    max-width: 20%;
    margin: auto;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-family: noirprolight;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.companylitlleimg {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 14px;
}

.companylitlleimg img {
    width: 100%;
    height: 100%;
}

.commenteditmodalmainbg {
    width: 100%;
    height: 100%;
    background-color: #00000069;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
}

.coomenteditbgmain {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9;
    background-color: #fff;
    width: 100%;
    max-width: 716px;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}

.crsicondelte i {
    font-size: 20px;
    color: red;
    cursor: pointer;
}

.commentedittxtdeleteicontop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
}

.surecommntedit {
    color: #263238;
    font-family: 'Noirprolight';
    font-size: 21px;
    font-style: normal;
    font-weight: 700;

}

.commentprofiledeletesectionbtm {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    padding: 5px 10px;
}

.initialsingle{
    padding: 100px 50px 100px 50px;
    margin: 35px 0px 40px 0px;
}
.initialsinglecontainer{
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
}
.initialdetails{
    padding: 40px 60px 40px 60px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px #ccc;
}
.inititaiveheadingmodaltxtmaindiv{
    padding: 20px 0px;
}
.jnbtndivinititveinitiatv{
    padding: 6px 30px;
    background-image: linear-gradient(#ed3b4b, #4c3b92);
    border-radius: 10px;
    color: #fff;
    font-family: noirprolight;
    font-size: 16px;
    line-height: normal;
    border: none;
}
.bgeventscaleder{
    width: 100%;
    height: 100%;
    background-color: #020202a1;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99;
}
.bgeventscaledermainbgwht{
    width: 100%;
    max-width: 650px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    border-radius: 10px;
}
.evnttxtppmdlpp{
    font-size: 16px;
    color: #000;
    font-weight: 600;
    font-family: Noirprolight;
    line-height: 20px;
}
.closedibiocn i {
   color: red;
    font-size: 15px;
}
.evnttxtppmddivflx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;

}
.event_imgmodalsiv{
    width: 100%;
    max-width: 80px;
    min-width: 80px;
    height: 80px;
}
.event_imgmodalsiv img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.evtnttitletxtpphjtxp{
    font-size: 14px;
    color: #000;
    font-weight: 500;
    font-family: Noirproregualr;
    line-height: 18px;
}
.evtnttitletxtpphjtxp span{
    font-size: 14px;
    color: #000;
    font-weight: 800;
    font-family: Poppinslight;
    line-height: 18px;  
    margin-left: 5px;
}
.startdatetxtppp{
    font-size: 14px;
    color: #000;
    font-weight: 500;
    font-family: Noirproregualr;
    line-height: 18px;
}
.startdatetxtppp span{
    font-size: 14px;
    color: #000;
    font-weight: 500;
    font-family: Noirproregualr;
    line-height: 18px; 
}
.notextstxtiippshi{
    font-size: 14px;
    color: #000;
    font-weight: 500;
    font-family: Noirproregualr;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-bottom: 5px; 
}
.event_imgmodalsivdivflx{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 10px;
}
.event_imgmodalsivdivmainbfg{
    padding: 10px 20px;
}
.sreposrevnstdjhkk{
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
}
.sreposrevnstdjhkk img{
    width: 100%;
    height: 100%;
}
.event_imgmodalsdivnblflxnnm{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}
.threedoteasisedivebentjoindivflx{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}
.usemodaleventprflnewchnageimg{
    width: 100%;
    max-width: 20px;
    min-width: 20px;
    height: 20px;
}
.usemodaleventprflnewchnageimg img{
    width: 100%;
    height: 100%;
}
.threedotsfrrepostsharedivbg{
    position: relative;
}
.threedotsfrrepostsharedivbg i{
    font-size: 18px;
    color: #000;
    position: relative;
}
.shrerepostweventnodaldivbg{
    position: absolute;
    border: 1px solid #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 2px 9px;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    right: -10px;
    top: 28px;
    border-radius: 6px;
    align-items: center;
}
.sreposrevnstdjhkknewchnf{
    width: 100%;
    max-width: 18px;
    min-width: 18px;
    height: 18px;
}
.sreposrevnstdjhkknewchnf img{
    width: 100%;
    max-width: 100%;
    height: 100%;
}
.nndjduporethhshrxt{
    margin-bottom: 6px;
}
@media(max-width:1199px) {
    .empListSubHead {
        font-size: 16px;
        font-weight: 500;
        font-family: 'Plus Jakarta Sans';
        margin: 0 0 5px 0;
        width: 96%;
    }
}
@media(max-width:991px){
    .allcardbigbg {
        max-width: 845px;
        overflow-x: auto;
        width: 100%;
    }
    .allcardsectionflx {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .allcardsectionbg {
        max-width: 450px;
        min-width: 450px;
        width: 100%;
    }
}

@media(max-width:767px) {
    .showdescripttext {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        font-weight: 500;
        font-family: Notosans;
        color: #fff;
        cursor: pointer;
        background-color: #d3a145;
        width: 100%;
        max-width: 30%;
        margin: auto;
        padding: 6px;
        border-radius: 8px;
    }
}

@media (max-width: 575px) {
    .hallOfFrmeModal {
        padding: 0 15px;
    }
}

