.traingmain {
    width: 100%;
    height: auto;
    padding: 145px 0px 30px 0px;
}

.traingsectionmainflx {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 2%;
}

.trainingsectionfirst {
    width: 100%;
    max-width: 22%;
}
.feedbnckjhsdivul{
    padding-left: 20px;
}
.feedbnckjhsdivli{
    list-style-type: decimal;
    padding-left: 8px;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 10px;
    font-family: 'NoirProRegular';
}
.sbmtdbtbhmaiondiv{
    background-color: #d3a145;
    padding: 10px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    font-family: Noirprolight;
    border: none;
    border-radius: 5px;
    margin: auto;
    display: flex;
    justify-content: center;
}
.trainingsectionsecond {
    width: 100%;
    max-width: 77%;
}

.trainingsectionrightfrst {
    width: 100%;
    max-width: 72%;
}

.trainingsectionrightscnd {
    width: 100%;
    max-width: 30%;
}

.trainingsectionbothright {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    gap: 2%;
}

.mndlrning {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #00000029;
    height: auto;
    padding: 10px;
}

.mndtxtpp {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    font-family: 'NotoSans';
    letter-spacing: 1px;
    text-align: center;
    font-weight: 500;

}

.mandtxtbg {
    background-color: blue;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
}

.recomendtxt {
    text-align: center;
    color: #000000a1;
    font-size: 18px;
    font-family: NoirProRegular;
    line-height: 22px;
    font-weight: 700;
    padding: 20px 0px;
}

.skltxtpp {
    font-family: NoirProRegular;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
}

.skildivmain {
    padding: 0px 0px;
}

.skldiv {
    display: flex;
    align-items: center;
    gap: 2%;
    padding-top: 10px;
    padding-bottom: 5px;
}

.skldiv label {
    margin-bottom: 0px;
    color: #263238;
    font-family: Noirprolight;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
}


.boxchckdiv {
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 5%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.assmntdiv {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 10px;
    border: 1px solid #00000029;
    position: relative;
}

.videomaindiv video {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
}

.assmntimg {
    width: 31px;
    height: 30px;
}

.assmntimg img {
    width: 100%;
    height: 100%;
}

.assntxt {

    margin-bottom: 0px;
    color: #263238;
    font-family: Noirprolight;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
}


.shwtxtpp {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    color: #000;
    font-family: NoirProRegular;
    width: 100%;
}

.shwarw {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 10px 0px 5px 0px;
}

.arrwdiv {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.lrmtxtpp {
    margin-bottom: 0px;
    color: #263238;
    font-family: Noirprolight;
    font-size: 17px;
    font-weight: 400;
    line-height: 21px;
}

.assnmgttxtimg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
}

.assnmgttxtimgarw {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.lrmtxtppeducate {
    color: #263238;
    font-family: Noirprolight;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 78px;
    min-width: 78px;
    padding: 5px 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

/* .rts___btn {
    cursor: pointer;
    background-color: white !important;
    border-radius: 50% !important;
    border: 2px solid #fff;
    width: 40px !important;
    height: 40px !important;
} */
.trainingsectionsecond .trainingsectionrightfrst .rts___tabs___container {
    display: flex;
    overflow: auto;
    align-items: center !important;
}

.trainingsectionsecond button.rts___tab {
    background-color: transparent !important;
    border: none;
    white-space: nowrap;
    vertical-align: middle;
    text-align: start !important;
    margin: 0px 0px !important;
    padding: 0px 20px 5px 20px !important;
    color: #00000096;
    font-size: 15px !important;
    font-family: 'NoirProlight';
}

.trainingsectionrightfrst .rts___tabs {

    padding: 0px 0px;
}

.trainingsectionrightfrst button.rts___tab___selected {
    background-color: transparent !important;
    color: #000;
    box-shadow: none !important;
    border-bottom: 1px solid #000;
    /* border: 0px !important; */
}

.trainingsectionrightfrst .rts___nav___btn {
    color: #0009;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: 0px 0px 5px 0px #ccc;
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
    margin: 4%;
}

.trainingsectionsecond .rts___btn {
    border-radius: 0px !important;
}

.bg-whitediv {
    background-color: #fff;
    width: 100%;
    max-width: 47%;
    height: auto;
    border-radius: 10px;

}

.videomaindiv {
    width: 100%;
    height: 163px;
    position: relative;
}

.timeperiodvdo {
    position: absolute;
    background-color: #0E24CC;
    padding: 5px 10px;
    top: 85%;
    right: 0%;
}

.twhhrs {
    font-size: 14px;
    line-height: 18px;
    font-family: 'NotoSans';
    color: #fff;
    font-weight: 400;
    letter-spacing: 1px;
}

.videomaindiv iframe {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
}

.taxtionimgflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.taxtionimg {
    width: 21px;
    height: 20px;
    margin-left: auto;

}

.taxtionimg img {
    width: 100%;
    height: 100%;
}

.txattxt {
    font-size: 18px;
    font-weight: 700;
    font-family: notosans;
    line-height: 22px;
    color: #000;
}

.taxtiondivmain {
    padding: 15px;
}

.upgrdrrtxt {
    color: #000000bf;
    font-size: 17px;
    line-height: 21px;
    font-family: 'NoirProLight';
    font-weight: 500;
    /* padding: 10px 0px 0px 0px; */
}

.ovrtxt {
    color: #000000;
    font-size: 14px;
    line-height: 20px;
    font-family: 'NoirProLight';
    font-weight: 500;
    margin: 10px 0px;
    /* padding: 5px 0px; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 45px;
}

.vdo_btndiv {
    position: absolute;
    z-index: 7;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.btn_maindiv {
    width: 41px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff7d;
    border-radius: 50%;
}

.vdo_btndiv .btn_maindiv i {
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.rtngtxstp {
    font-size: 14px;
    color: #000;
    font-family: NoirproLight;
    line-height: 19px;
    font-weight: 700;
}

.rtngstrflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
}

.rngdivgh i {
    color: yellow;
    font-size: 14px;
    margin-right: 2px;
}

.bg-whitedivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 30px;
    padding: 20px 0px;
}

.upgrdtxt {
    padding: 25px 0px 0px 0px;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    font-family: Noirprolight;
}

.recomendeddiv {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #00000029;
    height: auto;
    padding: 10px;
}

.rcttxtpp {
    font-size: 18px;
    font-family: 'NoirProLight';
    font-weight: 600;
    line-height: 22px;
    color: #000;
    text-align: center;
}

.bg-coloradvnc {
    width: 100%;
    height: auto;
    border-radius: 5px;
    padding: 15px 10px;
    margin: 10px 0px;
}

.advnctxtimgflx {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.exclimgdivmain {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #FFF;
    padding: 2%;
    box-shadow: 0px 0px 5px 0px #ccc;
}

.exclimgdiv {
    width: 100%;
    height: 100%;
}

.exclimgdiv img {
    width: 100%;
    height: 100%;
}

.advnctxt {
    color: #000000bf;
    font-size: 17px;
    font-weight: 600;
    font-family: Noirprolight;
    line-height: 20px;
}

.prgssxtyflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0px;
}

.prgstxtpp {
    color: #000000bf;
    font-size: 15px;
    line-height: 18px;
    font-family: Noirprolight;
    font-weight: 800;
}


.w-border {
    width: 100%;
    border: 1px solid #fff;
    border-radius: 10px;
    background-color: #fff;
    height: 11px;
    overflow: hidden;
}

.prgsrdivmain {
    padding: 10px 0px;
}

.bg-clorrnd {
    width: 23px;
    height: 23px;
    background-color: #037F4C;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1%;
}

.bg-clorrnd i {
    font-size: 11px;
    color: #fff;
}

.ssntxyt {
    color: #000;
    font-size: 14px;
    line-height: 18px;
    font-family: Noirprolight;
    font-weight: 600;
}

.bgclorssntxtflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
    padding: 10px 0px 0px 0px;
}

.crstxtpp {
    color: #0000006c;
    font-size: 14px;
    font-weight: 600;
    font-family: Noirprolight;
    line-height: 18px;
}

.crstxtppdiv {
    padding: 10px 0px;
}

.btnstart {
    background-color: #E7BF55;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-family: Noirprolight;
    line-height: 18px;
}

/* Trainingmain page Css Start */
/* .trainingmain {
    width: 100%;
    height: auto;
    padding: 140px 0px 20px 0px;
}

.trainmgmainsectionpart {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    column-gap: 20px;
}

.trainingmainsectionleft {
    width: 100%;
    max-width: 72%;
}

.trainingmainsectionright {
    width: 100%;
    max-width: 28%;
}

.trainingproductdesignerdiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
}

.trainingproductbg {
    width: 100%;
    max-width: 33%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 10px;
    padding: 10px;
}

.traingimgtxtflx {
    display: flex;
    justify-content: flex-start;
}

.traingmainimg {
    width: 100%;
    max-width: 26%;
    min-width: 26%;
    height: 51px;
}

.traingmainimg img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.prdctdsg {
    color: #171725;
    font-size: 13px;
    line-height: 19px;
    font-family: Notosans;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    height: 22px;
}

.dotsdiv {
    background-color: red;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: block;
}

.dotstxtpp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #00000081;
    font-size: 12px;
    line-height: 16px;
    font-family: Noirprolight;
    align-items: center;
    column-gap: 4px;
    padding: 16px 0px 0px 0px;
}

.dnprgstt {
    color: #171725;
    font-size: 14px;
    line-height: 16px;
    font-family: Mandaufont;
    font-weight: 600;
    padding-bottom: 7px;
}

.borderprgs {
    width: 100%;
    border: 1px solid #ccc !important;
    background-color: #ccc;
    height: 7px;
    border-radius: 10px;
}

.brdrgrey {
    background-color: #138BFB;
    border-radius: 10px;
    overflow: hidden;
}

.prgrstbar {
    padding: 15px 0px 0px 0px;
}

.prdctdivgp {
    margin-left: 10px;
}

.mandatryskldivmainflx {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    column-gap: 20px;
    margin: 20px 0px 0px 0px;
}

.mandatrybg {
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 10px;
    padding: 20px;
}

.mandtxt {
    font-size: 20px;
    line-height: 24px;
    font-family: NotoSans;
    color: #000;
    font-weight: 700;
}

.mandtrttxtimgflx {
    display: flex;
    justify-content: flex-start;

    column-gap: 10px;
    padding: 20px 0px;
    border-bottom: 1px solid #E2E2ED;
}

.mastervdotxt {
    color: #000;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    font-family: Noirprolight;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

}

.frmtxtcrspp {
    color: #000;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    font-family: Noirprolight;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 7px 0px;
}

.htrtstxtp {
    color: #000;
    font-size: 13px;
    line-height: 17px;
    font-weight: 600;
    font-family: Noirprolight;
    margin: 5px 0px 0px 0px;
}

.mandrtdivmain {
    width: 100%;
    max-width: 27%;
    min-width: 27%;
}

.mandrtdiv {
    width: 100%;
    height: 73px;
    max-width: 100%;
}

.mandrtdiv img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.mandatrybgmain {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.mandtrtxt {
    width: 100%;
}

.prdctdesignerdiv {
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px;
    border-radius: 10px;
    margin: 10px 0px;
}

.productmainimg {
    width: 100%;
    height: 200px;
}

.productmainimg img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
}

.prdctstpp {
    font-size: 16px;
    line-height: 22px;
    color: #000;
    font-family: Notosans;
    font-weight: 800;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 8px 0px;
}

.imgtxtdivmaindddflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px;
}

.imgtxtdivmainddd {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    column-gap: 10px;
}

.tsgyutppp {
    font-size: 13px;
    color: #000;
    font-weight: 600;
    font-family: 'NotoSans';
    line-height: 16px;
}

.txtdibbrdrmain {
    padding: 0px 21px;
}

.imgtxtflxdibprdc {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hannimgdiv {
    width: 25px;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
}

.hannimgdiv img {
    width: 100%;
    height: 100%;
}

.nametxty {
    color: #44444F;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    font-family: Noirprolight;
}

.incmtxtyt {
    color: #000000;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    font-family: notosans;
}

.imgtxtdivflxmammn {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    column-gap: 10px;
}

.imgtxtflxdibprdcmain {
    padding: 15px 0px 10px 0px;
    border-top: 1px solid #E2E2ED;
}

.txtimgstrtadivbnn {
    padding: 0px 0px 15px 0px;
}

.connectiondiv {
    width: 100%;
    max-width: 91%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;

    border-radius: 10px;
    margin: 20px 15px 10px 15px;
}

.connectiontxtsee {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E2E2ED;
    padding: 5px 12px 5px 12px;
}

.imgicondiv {
    width: 25px;
    height: 25px;
}

.imgicondiv img {
    width: 100%;
    height: 100%;
}

.cntstypp {
    font-size: 15px;
    font-family: NoirProRegular;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #263238;
    padding: 0;
    margin: 0;
}

.connectionimgflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    row-gap: 10px;
    column-gap: 10px;

}

.connectionimgtxtpp {
    width: 100%;
    max-width: 22%;
    min-width: 22%;
}

.connectionimgmn {
    width: 100%;
    max-width: 100%;
    height: 62px;
}
.connectionimgmn img{
    width: 100%;
    height: 100%;
}
.txtcnttxt{
    font-family: noirproregular;
    font-weight: 500;
    font-size: 9px;
    color: #0F044C;
    line-height: 16px;
    margin: 2px 0px 0px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.statusdivmain{
    padding: 73px 0px 11px 0px;
} */
/* Trainingmain page Css End */

/* Tarining main page again design start */
.educatemain {
    width: 100%;
    height: auto;
    padding: 120px 0px 15px 0px;
}

.educatemainflx {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.educatemainleft {
    width: 100%;
    max-width: 76%;
}

.educatemainright {
    width: 100%;
    max-width: 21%;
}

.educatemainthreedivs {
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 0 5px 0 #ccc;
    height: auto;
    padding: 10px;
}

.educatemainbg {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 2px;
    width: 100%;
    max-width: 35%;
    border-radius: 12px;
}

.trainingimg {
    width: 100%;
    height: auto;
    position: relative;
}

.trainingimg img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.trainingimgaddnew {
    position: absolute;
    top: 9px;
    right: 10px;

}

.trainingimgdivnew {
    background-color: red;
    padding: 3px 15px;
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    border-radius: 5px;
}

.educatetrainingtext {
    color: #000;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    font-family: noirprolight;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 7px 3px;
    height: 36px;
}

.imgtxtdivmaindddflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 8px;
}

.imgtxtdivmainddd {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    column-gap: 2px;
}

.strimgdiv {
    width: 25px;
    height: 25px;
}

.strimgdiv img {
    width: 100%;
    height: 100%;
}

.tsgyutppp {
    font-size: 11px;
    color: #000;
    font-weight: 600;
    font-family: 'NotoSans';
    line-height: 15px;
    align-self: center;
}


.statusdivmaineducate .bg-userdtwht {
    width: 100%;
    height: 185px;
    margin-top: 0px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.statusdivmaineducate .plthclbtx {
    height: 30%;
    padding-right: 19px;
    padding-top: 5px;
}

.statusdivmaineducate .bghhkpp {
    font-family: 'NoirProRegular';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 22px;
    color: #ffffff;
    /* text-align: left; */
    padding-top: 4px;
    margin: 0;
    text-align: end;
    letter-spacing: 1px;
}

.statusdivmaineducate .clbpp {
    font-family: 'NoirProLight';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    padding-top: 1px;
    margin: 0;
    text-align: end;
    letter-spacing: 1px;
}

.statusdivmaineducate .divpaddingghh {
    padding: 0px 20px;
}

.statusdivmaineducate .prflimgdiv {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 6%;
    align-items: flex-end;
}

.statusdivmaineducate .prflimgbrdr {
    border: 4px solid #ffffff;
    border-radius: 50%;
    margin-left: -10px;
    margin-top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.statusdivmaineducate .prflimgfh {
    width: 100%;
    max-width: 80px;
    height: 82px;
    min-width: 80px;
}

.statusdivmaineducate .prflimgfh img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.statusdivmaineducate .thounsabpp {
    color: red;
    font-size: 23px;
    line-height: 28px;
    font-weight: 700;
    font-family: 'NoirProLight';
}

.statusdivmaineducate .pointshttx {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    font-family: 'NotoSans';
}

.statusdivmaineducate .tstdfghmrl {
    margin-top: 20px;
}

.statusdivmaineducate .txtppght {
    color: #000;
    font-size: 18px;
    font-weight: 800;
    font-family: "NoirProRegular";
    line-height: 22px;
}

.statusdivmaineducate .dvjknjpp {
    color: #000;
    font-size: 17px;
    font-weight: 500;
    font-family: "NoirProRegular";
    line-height: 20px;
}

.statusdivmaineducate .statusdivmain {
    width: 100%;
    max-width: 100%;
    margin-bottom: 8px;
}

.mandatorydiv {
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 5px 0px #ccc;
    background-color: #fff;
    border-radius: 12px;
    padding: 10px;
}

.mandatoryicontxtflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icontxtdivmain {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
}

.mandatoryimg {
    width: 25px;
    height: 25px
}

.mandatoryimg img {
    width: 100%;
    height: 100%;
}
.educatedivjdjbrdrbgmainb{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 30px;
    margin-top: 15px;
    padding: 0px 16px;
    margin-bottom: 20px;
    /* flex-wrap: wrap; */
    row-gap: 20px;
}
.mandatorttxtnewlihgjtxtpp{
    font-family: NoirProRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #263238;
    padding: 0;
    margin: 0;
    margin-left: 16px;
    white-space: nowrap;
}

.mandatorttxt {
    font-family: NoirProRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #263238;
    padding: 0;
    margin: 0;
    margin-left: 6px;
    white-space: nowrap;
}

.Expandimg {
    width: 25px;
    height: 25px;
}
.Expandimg img {
    width: 100%;
    height: 100%;
}
.mandatorydivbg {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 12px;
}
.traingmaindivmain {
    width: 100%;
    height: 170px;
    /* background-color: #fff; */
    /* box-shadow: 0px 0px 5px 0px #ccc; */
    border-radius: 10px;
    /* padding: 2%; */
    margin-bottom: 10px;
}

.traingmaindivmain img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.statusdivbgdivnm {
    /* padding-top: 73px; */
    padding-bottom: 20px;
}

.mandttxtpp {
    color: #000;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    font-family: noirprolight;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 7px 3px;
    height: 37px;
}

.writertxtpp {
    color: #000;
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    font-family: noirprolight;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 7px 3px;
}

.mandatorydivbtm {
    margin: 15px 0px;
}

.mandatorydivbtm .slick-prev {
    line-height: 0;
    position: absolute;
    padding-top: 11px !important;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 4;
    top: 30%;
    left: -1.5%;
    width: 40px;
    height: 40px;
}

.mandatorydivbtm .slick-next {
    line-height: 0;
    position: absolute;
    padding-top: 11px !important;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 4;
    top: 30%;
    right: -2%;
    width: 40px;
    height: 40px;
}

.mandatorydivbtm .slick-prev:before {
    content: '\f104' !important;
    font-family: FontAwesome !important;
    font-size: 27px !important;
    line-height: 1;
    opacity: 1 !important;
    color: rgb(255, 255, 255) !important;
    display: flex;
    background-color: #d3a145;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
}

.mandatorydivbtm .slick-disabled {
    opacity: 0;
}

.mandatorydivbtm .slick-next:before {
    content: '\f105' !important;
    font-family: FontAwesome !important;
    font-size: 27px !important;
    line-height: 1;
    opacity: 1 !important;
    color: rgb(255, 255, 255) !important;
    display: flex;
    background-color: #d3a145;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
}

.mandatorydivbtm .slick-slider {
    position: relative;
    display: block;
    /* padding-top: 35px;
  padding-bottom: 10px; */
}

.mandatorydivbtm .slick-slide {
    padding: 0px 16px;
}

.mandatorycoursemaindiv {
    padding: 20px 0px 0px 0px;
}

.videoongoing {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    margin-bottom: 16px;
    margin-top: 0px;
    padding-bottom: 10px;
}

.videoongoing .top {
    width: 100%;
    height: auto;
    padding: 15px 20px 10px 20px;
    border-bottom: 1px solid rgba(198, 198, 210, 0.26);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.videoongoing .bulleBoardFig {
    width: 25px;
    height: 25px;
    /* margin: 0 3px 0 0; */
}

.videoongoing .bulleBoardHead {
    display: flex;
    align-items: center;
}

.videoongoing .bulleBoardFig img {
    width: 100%;
    height: 100%;
}

.videoongoing .top .head h4 {
    font-family: NoirProRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
    color: #263238;
    padding: 0;
    margin: 0;
    
}

.videoongoing .add_seeallflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5%;
}

.videoongoing .tyEvMainDiv {
    margin-right: 7px;
}

/* 
.videoongoing .bulleBoardFig {
    width: 25px;
    height: 25px;
} */

.video_onflx {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 6px;
}

.trainingmainimgdiv {
    width: 100%;
    min-width: 75px;
    max-width: 75px;
    height: 62px;
    overflow: hidden;
}

.trainingmainimgdiv img {
    width: 100%;
    height: 100%;
    border-radius: 10px;

}
.trainingmainimgdivnesdsgn{
    width: 100%;
    max-width: 100%;
    height: 150px;
}
.trainingmainimgdivnesdsgn img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.videoongoinddivnejbhj {
    width: 100%;
    max-width: 32%;
    min-width: 32%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 5px;
}
.SaveTrainingCardbtbdiv{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;
    padding: 20px;
    flex-wrap: wrap;
}
.playtxtflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 12px;
}
.lrmtxtppfgvffggdg{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #263238;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;
    font-family: NoirProLight;
    margin-bottom: 10px;
    height: 50px;
}

.bg-play {
    background-color: #d3a145;
    padding: 3px 11px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    font-family: Notosans;
    font-weight: 400;
    border-radius: 5px;
    border: none;
}

.lrmtxtpp {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    font-family: Noirprolight;
    line-height: 18px;
}

.videoongoing .bottom {
    padding: 0px 10px;
    height: 289px;
    overflow-y: auto;


}

.bottomdivongong {
    height: 100%;
    max-height: 293px;
    overflow-y: auto;
}

.dotstxtpp {
    color: #00000081;
    font-size: 13px;
    line-height: 17px;
    font-family: Noirprolight;
    align-items: center;
    width: 100%;
    max-width: 300px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}
.bgcontainerupldchmageredif{
    display: none;
}
.imageuplddivredifald{
    width: 100%;
    max-width: 70px;
    min-width: 70px;
    height: 70px;
}
.imageuplddivredifald img{
    width: 100%;
    max-width: 100%;
    border-radius: 50%;
    height: 100%;
}

.dotsdiv {
    background-color: red;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: block;
}

.prgrsbartxtdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.imgtxtppdivprgrs {
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
    column-gap: 5px;
}

.prgrstextper {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-family: notosans;
    align-self: center;
}

.prgrsdivimg {
    width: 25px;
    height: 25px;
}

.prgrsdivimg img {
    width: 100%;
    height: 100%;
}

.borderprgs {
    width: 100%;
    border: 1px solid #ccc !important;
    background-color: #ccc;
    height: 7px;
    border-radius: 10px;
    margin-top: 5px;
}

.brdrgrey {
    background-color: #d3a145;
    border-radius: 10px;
    overflow: hidden;
}

.prgrsbartxtdivpading {
    padding: 8px 0px 0px 0px;
}

.videoongoinddiv {
    padding: 10px 0px;
    margin-bottom: 8px;
    border-bottom: 1px solid #ccc;
}


.video_onflxdiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.trainingnewdiv {
    background-color: #d3a145;
    width: 100%;
    padding: 10px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    font-family: Noirprolight;
    border: none;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 3px;
}

.playtextbtnvwwh {
    width: 100%;
}

.strimgfrmt {
    width: 25px;
    height: 25px;
}

.strimgfrmt img {
    width: 100%;
    height: 100%;
}

.bottomrepoflx {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 17px;
    border-bottom: 1px solid #ccc;
    /* padding: 10px 0px; */
    margin: 5px 0px;
    padding: 6px 0px;
}

.purchaseimgdiv {
    width: 100%;
    max-width: 13%;
    height: 28px;
    min-width: 13%;
}

.purchaseimgdiv img {
    width: 100%;
    height: 100%;
}

.Purchasedivflx {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
}

.arttopsubtopic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;
    padding-top: 9px;
    width: 100%;
    border-top: 1px solid #ccc;
}

.purtxt {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    font-family: noirprolight;
    color: #000;
    text-align: center;
    /* border-bottom: 1px solid #ccc; */
    width: 100%;
    padding-bottom: 4px;
    width: 100%;
    max-width: 95px;
    min-width: 95px;
}

.purtxtdiv {
    width: 100%;
}

.artcl {
    text-align: center;
    font-size: 13px;
    line-height: 16px;
    font-family: notosans;
    color: #000;
}

.artctext {
    font-size: 10px;
    line-height: 14px;
    font-family: noirproheavy;
    color: #000;
    width: 100%;

}

.createbtnnewdiv {
    background-color: #d3a145;
    width: 100%;
    padding: 10px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    font-family: Noirprolight;
    border: none;
    border-radius: 5px;
    margin-bottom: 7px;
    margin-top: 23px;
}

/* Tarining main page again design end  */
.singlemandatorysection {
    width: 100%;
    height: auto;
    padding: 120px 0px 20px 0px;
}

.singleeducatemainflx {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.singlemandatoryleft {
    width: 100%;
    max-width: 21%;
}

.singlemandatoryright {
    width: 100%;
    max-width: 76%;
    /* padding-top: 7px; */
}

.singlemandatorydivbg {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 10px;
}

.mandatorydivbgflxdiv {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 7px;
}

.mandatorywidthdiv {
    width: 100%;
    max-width: 22%;
    margin: 10px;
}

/* Single Ongoing Page Start */
.singleongoingmain {
    width: 100%;
    height: auto;
    padding: 120px 0px 20px 0px;
}

.ongoingbgdiv {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px 5px #ccc;
    border-radius: 5px;
    width: 100%;
}

.ongoingbgdiv .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 5px 10px 5px 10px;
}

.ongoingbgdiv .bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    flex-wrap: wrap;
    padding: 10px;

}

.ongoingbgdiv .bottom .videoongoinddiv {
    width: 100%;
    max-width: 32%;
}

.bulleBoardHead h4 {
        font-family: NoirProRegular;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: normal;
        color: #263238;
        padding: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: normal;
        color: #263238;
        padding: 0;
        margin: 0;
    }


/* Single Ongoing Page End */

/* Course Preview Page Start */
.corsepreviewmain {
    width: 100%;
    height: auto;
    padding: 120px 0px 20px 0px;
}

.cousrepreviewbuttmdivlast {
    padding-top: 15px;
}

.corsepreviewmainleft {
    height: 83vh;
    overflow-y: auto;
    width: 100%;
    max-width: 20%;
    position: fixed;
    left: 15px;
    top: 120px;
    z-index: 7;
    margin-left: 77px;
    scrollbar-width: none;
}

.corsepreviewmainright {
    width: 100%;
    max-width: 20%;
    position: fixed;
    right: 92px;
    top: 121px;
    z-index: 7;
    height: 83vh;
    scrollbar-width: none;
    overflow-y: auto;
}

.coursepreviewrecomended {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    margin-bottom: 25px;
    margin-top: 0px;
}

.coursepreviewrecomended .top {
    width: 100%;
    height: auto;
    padding: 8px 12px 8px 12px;
    border-bottom: 1px solid rgba(198, 198, 210, 0.26);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.toprecomendedimg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 2px;
}

.skillimgrecomended {
    width: 25px;
    height: 25px;
}

.skillimgrecomended img {
    width: 100%;
    height: 100%;
}

.recomendedcrstxt {
    font-family: NoirProRegular;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: normal;
    color: #263238;
    padding: 0;
    margin: 0;
    margin-left: 10px;
}

.expamdimgdivrecomnd {
    width: 25px;
    height: 25px;
}

.expamdimgdivrecomnd img {
    width: 100%;
    height: 100%;
}

.rtrainingmainrecomended {
    width: 100%;
    height: 112px;
}

.rtrainingmainrecomended img {
    width: 100%;
    height: 100%;
}

.recomdedtxytppsngl {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    font-family: noirprolight;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 5px 0px;
}

.rtrainingmainrecomendeddivbg {
    background-color: rgb(242, 233, 220);
    padding: 10px;
    margin-bottom: 8px;
}

.bottomsinglediv {
    padding: 10px;
}

.corsepreviewmainmiddle {
    padding-top: 1px;
    margin: 0 auto;
    width: 100%;
    max-width: 53%;
}

.corsepreviewmainmiddlebg {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
    padding: 10px;
}

.trainingdivmainedt {
    width: 100%;
    max-width: 100%;
    height: 340px;
    position: relative;
}

.trainingdivmainedt .reactdivimgmainplrt {
    width: 100% !important;
    height: 100% !important;

}

.videodivimgicon {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 7;
}

.videodivimgicon img {
    width: 100%;
    height: 100%;
}

.textimeevntvdo {
    color: #000;
    background-color: #d3a145;
    padding: 2px 13px;
    font-size: 14px;
    font-family: 'NotoSans';
    font-weight: 500;
}

.textimeevntvdodiv {
    position: absolute;
    z-index: 99;
    right: 0px;
    bottom: 0px;
}

.vdodescrptntxtppdiv {
    font-size: 20px;
    line-height: 25px;
    font-family: notosans;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 10px 0px 10px 25px;
}

.dotdivbgvdo {
    position: relative;
}

.dotdivbgvdo::before {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    background-color: #d3a145;
    border-radius: 50%;
    top: 3px;
}

.emplyetextppcrse {
    font-size: 14px;
    line-height: 20px;
    color: #000;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 8px 0px;
}

.trainerdetailsdownloadshreflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.trainerprfltrainerdtls {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.prfledtslbrdrtrainer {
    width: 100%;
    max-width: 47px;
    min-width: 47px;
    height: 47px;
    border: 3px solid #fac00d;
    border-radius: 50%;
}

.prfledtslbrdrtrainerimg {
    width: 100%;
    height: 100%;
    max-width: 100%;
    min-width: 100%;
}

.prfledtslbrdrtrainerimg img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.trainermanetxt {
    font-size: 14px;
    color: #000;
    font-weight: 500;
    font-family: noirprolight;
    line-height: 18px;
}

.trinrdtlstxt {
    font-size: 14px;
    color: #000;
    font-weight: 500;
    font-family: noirprolight;
    line-height: 18px;
}

.dwnldpdfflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
}

.downloadimgdivtrnr {
    width: 25px;
    height: 25px;
}

.downloadimgdivtrnr img {
    width: 100%;
    height: 100%;
}

.shredivimgmain {
    width: 25px;
    height: 25px;
}

.shredivimgmain img {
    width: 100%;
    height: 100%;
}

.dwnldtrainerflx {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
}

.dwldtxtppodf {
    font-size: 12px;
    line-height: 15px;
    color: #000;
    font-family: notosans;
    font-weight: 500;
}

.trainerrtngtxt {
    font-size: 14px;
    line-height: 15px;
    color: #000;
    font-weight: 500;
    font-family: notosans;
    padding-top: 10px;
}

.trainerdtlsbrdrtop {
    border-top: 1px solid #ccc;
}

.trainerrtngtxtbrdr {
    padding-bottom: 20px;
}

.crtdtxtppspcl {
    font-size: 14px;
    line-height: 18px;
    font-family: notosans;
    font-weight: 500;
    color: #000;
    padding-bottom: 5px;
}

.empytxtppeng {
    font-size: 14px;
    line-height: 18px;
    font-family: notosans;
    font-weight: 500;
    color: #000;
    padding-bottom: 5px;
}

.dsrtptxtpp {
    font-size: 14px;
    line-height: 18px;
    font-family: notosans;
    font-weight: 500;
    color: #000;
    padding-bottom: 5px;
}

.keywrdtagpp {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    font-family: noirprolight;
    color: #000;
}

/* Course Preview Page End */

/* Course Video Player Start */
.coursevideoplayermain {
    width: 100%;
    height: auto;
    padding: 120px 0px 15px 0px;
}

.coursedivplrtdiv {
    width: 100%;
    max-width: 65%;
}

.coursedivplrtdivbgintr {
    width: 100%;
    max-width: 30%;
}

.coursevideoplayerflxdiv {
    display: flex;
    justify-content: space-between;
    /* align-items:center; */
}

.coursecntntdiv {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px;
    border-radius: 5px;
}

.crstntpptxtp {
    font-size: 16px;
    line-height: 20px;
    font-family: notosans;
    font-weight: 600;
    color: #000;
}

.introbgpdf {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 10px;
    padding: 10px;
    margin: 15px 0px 7px 0px;
}

.intrdivppptxts {
    font-size: 16px;
    line-height: 20px;
    color: #000;
    font-weight: 600;
    font-family: notosans;
}

.Introductiontxtangle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.Introductiontxtangle span {
    font-size: 17px;
    font-weight: 700;
    color: #000;
}

.closedropdownactd {
    display: none;
    animation: growDown 300ms ease-in-out
}

.opendropdownactd {
    display: block;
    animation: growDown 300ms ease-in-out
}

@keyframes growDown {
    0% {
        transform: scaleY(0)
    }

    /* 80% {
        transform: scaleY(1.1)
    } */
    100% {
        transform: scaleY(1)
    }
}

.contetxtxttimeflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
}

.undrstndgppp {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-family: notosans;
    font-weight: 500;
    padding: 4px 0px;
}

.timeintrotxt {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-family: notosans;
    font-weight: 400;
    margin-left: auto;
}

.introvdotxt {
    font-size: 15px;
    line-height: 18px;
    color: #000;
    font-family: noirprolight;
    font-weight: 400;
}

.drpopfdivbhjkl {
    padding-top: 6px;
}

/* Course Video Player End */

/* Feedbackquize Start */
.feedbackquizesection {
    width: 100%;
    height: auto;
    padding: 120px 0px 20px 0px;
}

.feedbackquizesectionflx {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    column-gap: 10px;
}

.feedbackquizesectionleft {
    width: 100%;
    max-width: 74%;
}

.cmpltquzwdivbg {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
}

.cmpltquzwdivbg .top {
    width: 100%;
    padding: 6px;
    border-bottom: 1px solid #ccc;
}

.mandatorydivimgquz {
    width: 25px;
    height: 25px;
}

.mandatorydivimgquz img {
    width: 100%;
    height: 100%;
}

.quizeimgtxtflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.cmpltquzetxt {
    font-family: NoirProRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: normal;
    color: #263238;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: normal;
    color: #263238;
    padding: 0;
    margin: 0;
    /* margin-left: 10px; */
}

.quizeanswersflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 10px; */
    padding: 10px 0px;
}

.quizedivwidthf {
    background-color: #fff;
    box-shadow: 0px 0pc 5px 0px #ccc;
    padding: 9px;
    border-radius: 5px;
}

.quizeanswersdiv {
    width: 100%;
    max-width: 68%;

}

.quizeanswersdiv textarea {
    width: 100%;
    height: 50px;
    background-color: #ccc;
    border-radius: 5px;
    border: none;
    padding: 5px;
    resize: none;
}

.questiontxtquize {
    font-size: 15px;
    line-height: 20px;
    color: #000;
    font-family: notosans;
    font-weight: 600;
}

.cmpltquzwdivbg .bottom {
    padding: 10px;
    height: 394px;
    overflow-y: auto;
}
.congratsdivcard{
    position: relative;
    display: flex;
    width: 100%;
    max-width: 450px;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #d2d2dc;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px rgb(161, 163, 164);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    border-radius: 10px;
}
.sbmtdbtbhmaiondivdivflx{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    justify-content: center;
}
.sbmtdbtbhmaiondivyes{
    background-color: rgb(0 167 0);
    padding: 5px 10px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    font-family: Noirprolight;
    border: none;
    border-radius: 5px;
    /* margin: auto; */
    display: flex;
    justify-content: center;
}
.sbmtdbtbhmaiondivno{
    background-color: rgb(222, 11, 11);
    padding: 5px 10px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    font-family: Noirprolight;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}
.quizebtnvisble {
    color: #ffffff;
    font-size: 16px;
    padding: 7px 20px;
    font-family: noirprolight;
    line-height: 18px;
    border: none;
    border-radius: 10px;
    margin: 10px 0px;
    text-align: left;
    font-weight: 800;
    background: linear-gradient(181deg, rgba(237, 59, 75, 1) 14%, rgba(76, 59, 146, 1) 92%);
    text-align: center;
}
.trphyimhgmain{
    width: 100%;
    max-width: 241px;
    min-width: 241px;
    height: 152px;
    margin: auto;
}
.trphyimhgmain img{
    width: 100%;
    height: 100%;
}
.savebtnchngbtn {
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #ccc;
    color: #000;
    font-size: 14px;
    line-height: 18px;
    font-family: noirprolight;
}
.congarttscyypp{
    color: #000;
    font-size: 18px;
    line-height: 22px;
    font-family: poppinslight;
    text-align: center;
    font-weight: 600;
    padding-bottom: 10px;
    letter-spacing: 0.5px;
}
.ablernytxtpp{
    color: #000;
    font-size: 16px;
    line-height: 20px;
    font-family: poppinslight;
    text-align: center;
    font-weight: 600;
    padding-bottom: 20px;   
    text-align: center;
}
.congarttscyyppdiv{
    padding-top: 20px;
}
.trphyimhgmaindivmnain{
    padding: 20px;
}

.inputchckbtndivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inpttxtchcktxt label {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    font-family: notosans;
    color: #000;
    margin-left: 10px;
    margin-bottom: 0px;
}

.textmuchppstsfy {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    font-family: notosans;
    color: #000;
}

.sessionflxdivstr {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.starpppicon i {
    font-size: 14px;
    color: #fac00d;

}

.quizebtnmaion {
    background-color: #d3a145;
    width: 100%;
    padding: 10px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    font-family: Noirprolight;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 7px;
}

.quizebtnmaiondiv {
    width: 100%;
    max-width: 30%;
    margin: auto;
}

.certificatediv {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
}

.certificatediv .top {
    width: 100%;
    padding: 6px;
    border-bottom: 1px solid #ccc;
}

.certificatedivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.certificatediv .buttom {
    padding: 10px;
}

.certificatedivimage {
    width: 100%;
    height: 142px;
}

.certificatedivimage img {
    width: 100%;
    height: 100%;
}

.badgedivsectn {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
}

.badgedivsectn .top {
    width: 100%;
    padding: 6px;
    border-bottom: 1px solid #ccc;
}

.badgedivsectnflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.badgedivsectn .buttom {
    padding: 10px;
}

.badgedivmaintwflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.badgedivmainbrdr {
    width: 100%;
    max-width: 34%;
    min-width: 34%;
    height: 90px;
    border-radius: 50%;
    border: 4px solid #fac00d;
    display: flex;
    justify-content: center;
    align-items: center;
}

.badgedivmainbrdrdiv {
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.badgedivmainbrdrdiv img {
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.Rewardplatiinvimg {
    width: 100%;
    max-width: 37%;
    min-width: 37%;
}

.Rewardplatiinvimg img {
    width: 100%;
    height: 100%;
}

.upnextdiv {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
}

.upnextdiv .top {
    width: 100%;
    padding: 6px;
    border-bottom: 1px solid #ccc;
}

.upnextdivsectnflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.upnextdiv .buttom {
    padding: 10px;
}

.upnextdivmaintwflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.upnextdiv .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.expandimgdiv {
    width: 25px;
    height: 25px;
}

.expandimgdiv img {
    width: 100%;
    height: 100%;
}

/* Feedbackquize End */
/* EnlargednewEnrollment start */
.EnlargednewEnrollmentsection {
    width: 100%;
    height: auto;
    padding: 128px 0px 50px 0px;
}

.webdevloperenrollflx {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.ratingclckrewardflx {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    column-gap: 20px;
    padding: 10px 0px;
}

.developerbootcampdetails {
    width: 100%;
    max-width: 60%;
}

.developerbootcampvdo {
    width: 100%;
    max-width: 38%;
}

.developertxtppp {
    font-size: 25px;
    line-height: 30px;
    color: #000;
    font-family: notosans;
    font-weight: 600;
    letter-spacing: 1px;
    word-spacing: 2px;
    padding: 10px 0xp;
}

.engagetxtppenrl {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color: #000;
    font-family: noirprolight;

}

.trainingmaindivboot {
    width: 100%;
    height: 100%;
}

.trainingmaindivboot img {
    width: 100%;
    height: 100%;
}

.enrowlbtn {
    background-color: #d3a145;
    width: 100%;
    padding: 10px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    font-family: Noirprolight;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 7px;
}

.enrolldivnowbtn {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    background-color: #d3a145;
    padding: 5px 20px;
    letter-spacing: 1px;
    font-family: notosans;
    border-radius: 5px;
    text-align: center;
    width: 100%;
    border: none;
    margin: 10px 0px;
}

.destxtption {
    font-size: 21px;
    line-height: 23px;
    font-weight: 800;
    color: #000;
    font-family: 'NoirProRegular';
}

.lastmonthtxtdestxt {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: #000;
    font-family: noirprolight;
    padding-top: 10px;
}

.newenrollmentdivlrninclentnflx {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding-top: 20px;
}

.newlerndivmainwidth {
    width: 100%;
    max-width: 68%;
}

.lrnuniquetxt {
    font-size: 21px;
    line-height: 23px;
    font-weight: 800;
    color: #000;
    font-family: 'NoirProRegular';
}

.descriptionanswerdiv {
    padding-top: 10px;
}

.wiillrndivflcx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    column-gap: 10px;
    padding-bottom: 10px;
}

.wiillrndivflcxdiv {
    padding: 10px 0px;
}

.lrntxtqunirpppp {
    margin-left: 21px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: #000;
    font-family: noirprolight;
}

.lrntxtqunirppppdiv {
    position: relative;
    width: 100%;
    max-width: 31%;
}

.lrntxtqunirppppdiv::before {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background-color: rgb(233, 210, 0);
    border-radius: 50%;
    top: 6px;
}
.leavealltyeheadivflxflxmain{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
}

.coursrincldflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
}

.devloperbtcmptxt {
    font-size: 30px;
    line-height: 35px;
    font-family: Notosans;
    color: #000;
    font-weight: 600;
}

.cousredtlstxtppas {
    font-size: 16px;
    line-height: 19px;
    font-family: noirprolight;
    color: #000;
    font-weight: 600;
    margin-left: 15px;
    /* margin-right: 10px; */
    margin-bottom: 5px;
}

.coursrincldflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 2px;
    width: 100%;
    max-width: 32%;
}

.cousrsedidicnimg {
    width: 25px;
    height: 25px;
}

.cousrsedidicnimg img {
    width: 100%;
    height: 100%;
}

.coursecontentflxalll {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 46px;
    padding: 10px 0px;
}

.cousreimgdmainimgnn {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.cousreimgdmain .reactdivimgmainplrt {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;

}

.cousreimgdmain {
    width: 100%;
    height: 60px;
    max-width: 100%;
    min-width: 100%;
}

.cousreimgdmain img {
    width: 100%;
    height: 100%;
}

.lastmnthdesitxtflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 12px;
    width: 100%;
    max-width: 40%;
}

.crsecntntxt {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-family: notosans;
    font-weight: 600;
}

.cusrvideominuteflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 12px;
}

.timeimgdiv {
    width: 25px;
    height: 25px;
}

.timeimgdiv img {
    width: 100%;
    height: 100%;
}

.hrsmnttxtpp {
    font-size: 14px;
    line-height: 18px;
    font-family: noirprolight;
    font-weight: 600;
    color: #000;
}

.newlerndivmainleft {
    width: 100%;
    max-width: 28%;
}

.newlerndivmainleftbrdr {
    width: 100%;
    border: 2px solid rgb(233, 210, 0);
    /* padding: 10px; */
    border-radius: 10px;
    background-color: #FFF;
    padding: 10px;
}

.crsecntnttxtppp {
    font-family: NoirProRegular;
    font-size: 20px;
    line-height: normal;
    padding: 0;
    color: #000000;
    margin: 0;
}

.Introductiontxtanglebgmain {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 10px;
    padding: 10px;
    margin: 15px 0px 8px 0px;
}

/* EnlargednewEnrollment End */
/*Trainingcreatepagemain start */
.trainingcreatepagemain {
    width: 100%;
    height: auto;
    padding: 120px 0px 20px 0px;
}

.trainingcreatepagemainflx {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.trainingcreatepageleft {
    width: 100%;
    max-width: 23%;
}

.trainingcreatepageright {
    width: 100%;
    max-width: 72%;
}

.trainingcreatepageleftbrdr {
    width: 100%;
    border: 2px solid #fac00d;
    height: auto;
    padding: 10px 10px 15px 10px;
    border-radius: 10px;

}

.typstringtxtppcrt {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    font-family: noirprolight;
    color: #000;
}

.imageuplddiv {
    width: 100%;
    max-width: 70px;
    min-width: 70px;
    height: 70px;
    position: relative;
}
.advocacycreatemaindivpadongnew{
    padding-top: 20px;
}

.imageuplddiv img {
    width: 100%;
    height: 100%;
}

.imageupldcrs {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
}

.bgcrddivbg {
    width: 100%;
    height: 100%;
}


.typstrningwhselct {
    width: 100%;
    border: 1px solid #000;
    padding: 4px;
    border-radius: 4px;
    margin: 5px 0px 0px 0px;
}

.trainingpageleftdivmain {
    padding-bottom: 10px;
}

.txtdivallart {
    width: 100%;
    height: 70px;
    resize: none;
    margin: 10px 0px 0px 0px;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 5px 10px;

}
.downladimgmainretraindnmhmmdiv {
    width: 100%;
    max-width: 65px;
    min-width: 65px;
    height: 65px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ddd;
    /* padding-top: 2px; */
    margin: auto;
}

.containerupldimg {
    max-width: 100%;
    width: 100%;
    background: #fff;
    padding: 30px;
    border-radius: 30px;
}

.isinthetraining {
    padding: 10px 0px 0px 0px;
}

.vdoulddivpbflx {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    margin: 5px 0px 0px 0px;
    column-gap: 10px;
}

.vdoulddivpbflxwh {
    width: 100%;
    max-width: 48%;
}

.upldbrdrdivbg {
    width: 100%;
    border: 1px solid #000;
    height: 100px;
    border-radius: 5px;
    background-color: #ccc;
}

.addimgdivriofg {
    width: 100px;
    height: 100px;
    margin: auto;

}

.addimgdivriofg img {
    width: 100%;
    height: 100%;
}

.addmrdibfk {
    background-color: #000000;
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    font-family: notosans;
    font-weight: 500;
}

.texttraingrightdiv {
    width: 100%;
    border: 1px solid #000;
    padding: 4px;
    border-radius: 4px;
    margin: 5px 0px 0px 0px;
}

.trainingrifgtdivgappp {
    padding-bottom: 10px;
    padding-top: 5px;
}

.aaddiconinptfklddibwhhj {
    width: 100%;
}

.quiyzequestixp {
    font-size: 15px;
    line-height: 17px;
    font-weight: 600;
    font-family: noirprolight;
    color: #000;
    padding: 2px 0px 5px 0px;
}

.pdbquioze {
    margin: 5px 0px 0px 0px;
}

.trainingrifgtdivgapppbrdr {
    border: 2px solid #fac00d;
    padding: 10px;
    margin-bottom: 10px;
}

.rsntxrpnewtxt {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    font-family: noirprolight;
    color: #000;
}

.filereltfbhyy {
    position: relative;
    width: 100%;
    height: 100%;
}

.rsntxrpnewtxtupld {
    font-size: 20px;
    line-height: 25px;
    font-weight: 600;
    color: #000;
    font-family: notosans;

}

.vdoulddivpb {
    padding: 10px 0px 0px 0px;
}

.bgcontainerupldbrdr {
    border: 1px solid #000;
    padding: 7px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 5px 0px 0px 0px;
}

.bgcontainerupld {
    width: 100%;
    max-width: 38px;
    min-width: 38px;
    height: 38px;
    background-color: #dedede;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.logoupld {
    width: 28px;
    height: 29px
}

.logoupld img {
    width: 100%;
    height: 100%;
    position: relative;
}

.upldimagediv {
    position: absolute;
    top: 50%;
    z-index: 4;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    /* visibility: hidden; */
}

.imageuplddivvideo {
    position: relative;
    width: 50px;
    height: 50px;
}

.imageuplddivvideo video {
    width: 100%;
    height: 100%;
}

.bgcrd {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    padding: 5px;
}

.upldtxtppdiv {
    font-size: 14px;
    line-height: 17px;
    padding: 0px;
    margin: 5px 0px;
    text-align: center;
    color: #000;
    font-family: 'NotoSans';
}

.videotitledesrtpupldflx {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px 0px 0px;
    column-gap: 10px;
}

.videottldivwhgt {
    width: 100%;
    max-width: 100%;
}

.vdoulddivpbupldg {
    width: 100%;
    max-width: 48%;

}

.bgcontainerupldbrdrdiv {
    border: 1px solid #000;
    padding: 7px;
    width: 100%;
    height: 131px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 5px 0px 0px 0px;
}

.bgcontaineruplddiv {
    width: 100%;
    max-width: 76px;
    height: 100px;
    background-color: #dedede;
    /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
}

.uplddwnlddivbrtnmain {
    width: 100%;
    max-width: 20%;
}

.logouplddiv {
    width: 45px;
    height: 45px;
}

.logouplddiv img {
    width: 100%;
    height: 100%;
}

.videottldescrtflx {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.questionnmbr {
    font-size: 14px;
    font-family: notosans;
    line-height: 18px;
    color: #000;
    font-weight: 500;

}

.inptyflddiccv {
    width: 100%;
    max-width: 95%;
    border: 1px solid #000;
    padding: 4px;
    border-radius: 4px;
    margin: 5px 0px 0px 0px;
    margin-left: 8px;
}

.addimngrtring {
    width: 25px;
    height: 25px;
}

.addimngrtring img {
    width: 100%;
    height: 100%;
}

.trainernameselectflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.anwrdivtyp {
    padding-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.answerdrpdwn {
    width: 100%;
    max-width: 30%;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 4px;
}

.quentionnmbinputqust {
    width: 100%;
    max-width: 100%;
}

.cjhekeddivtxt label {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    font-family: noirprolight;
    color: #000;
    margin-left: 5px;

}

.radiobtntxtpp label {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    font-family: noirprolight;
    color: #000;
    margin-left: 5px;
}

.aaddiconinptfkldimgiconaddflx {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    column-gap: 5px;
    flex-wrap: wrap;
}

.divtrainingmainflx {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.divtraininhfrst {
    width: 100%;
    max-width: 48%;
}

.trainingqustonbtndltflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.btndltimgbgdivdkt {
    background-color: #ffffffa3;
    width: 29px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9%;
    color: red;
    box-shadow: 0px 0px 5px 0px #ccc;
}


.btnalignt {
    position: absolute;
    top: -3px;
    right: 0;

}

.aaddiconinptfkld {
    width: 100%;
    max-width: 100%;
}

.anstxtwrttype {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    font-family: noirprolight;
    color: #000;
}

.uploaddocumentbrdr {
    width: 100%;
    max-width: 100%;
    border: 1px solid #000;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 4px;
    margin: 5px 0px 0px 0px;
}

.bgcrdupload {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    padding: 5px;
    position: relative;
}

.bgcontaineruplddocumnet {
    width: 100%;
    max-width: 42px;
    height: 40px;
    background-color: #dedede;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.addimgicondivgg {
    width: 26px;
    height: 27px;
}

.addimgicondivgg img {
    width: 100%;
    height: 100%;
}

.uploaddocumentbrdraddbtn {
    width: 100%;
    max-width: 100%;
}

.uploaddocumentbrdraddbtnflx {
    display: flex;
    justify-content: flex-start;
    column-gap: 11px;
    flex-wrap: wrap;
}

.viewicontextflxshpwd {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.btnalignt {
    align-self: flex-end;
}

.downladimgmainpl {
    width: 25px;
    height: 25px;
    position: relative;
}

.downladimgmainpl img {
    width: 100%;
    height: 100%;
}

.downladimgmainplmaindiv {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ddd;
    /* padding-top: 2px; */
    margin: auto;
}

.dateshowlinkdiv {
    animation: mymove 5s infinite;
}

.addimgicondivggview {
    width: 25px;
    height: 25px;
    position: relative;
    cursor: pointer;

}

.addimgicondivggview img {
    width: 100%;
    height: 100%;
}

.hivermespaecial {
    display: none;
    position: absolute;
    bottom: 19px;
    left: 0px;
    right: 0px;
    /* background: #333; */
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    white-space: nowrap;
    font-family: notosans;
    transition: all .5s ease-in-out;
}

.addimgicondivggview:hover .hivermespaecial {
    display: block;
}

@keyframes mymove {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.addmirfgghdivny {
    border: none;
    border-radius: 5px;
    background-color: #ccc;
    color: #000;
    font-size: 12px;
    line-height: 17px;
    font-family: notosans;
    outline: 0;
    padding: 2px 6px;
    font-weight: 600;
    margin-top: 4px;
}

.downloaddivmaindiv {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
}

.downloaddivmaindivsize {
    width: 40px;
    height: 40px;
}

.btnalignt i {
    font-size: 12px;
    color: #ff2020;
}

.trainernametextinputdiv {
    width: 100%;
    max-width: 48%;
}

.cjhekeddivtxtdivmain {
    padding-top: 15px;
}

/* .cjhekeddivtxt input{
    width: 100%;
    max-width: 100%;
} */
.addmirfgghdivnydiv {
    display: flex;
    justify-content: flex-end;
    margin-top: 6PX;
}

.cjhekeddivtxtdivmain input {
    margin-left: 10px;
}

.feedbackquizesectionright {
    width: 100%;
    max-width: 24%;
}

.checkbxdivmaingh {
    padding-top: 14px;
}

.textinpurlrnaddflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 13px;
}

.lrnupldinput {
    width: 100%;
    max-width: 100%;
}

/*Trainingcreatepagemain end */

/* Documentrepository start */
.documentrepositorymain {
    width: 100%;
    height: auto;
    padding: 120px 0px 20px 0px;
}

.documentrepositoryflx {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    column-gap: 10px;
}

.documentrepositoryright {
    width: 100%;
    max-width: 71%;
}

.documentrepositoryleftt {
    width: 100%;
    max-width: 27%;
}

.documntrepodivmainsrch {
    width: 100%;
    position: relative;
}

.serchdivdctxtsrc {
    width: 100%;
    border: none;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px 50px 10px 10px;
    border-radius: 10px;
    position: relative;
}

.serchiconimg {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 9px;
    right: 12px;
}

.serchiconimg img {
    width: 100%;
    height: 100%;
}

.documntrepodivmainsrch::placeholder {
    color: #000;
    font-size: 14px;
    font-family: noirprolight;
    font-weight: 600;
    line-height: 18px;
}

.documentbigsldimage {
    padding: 15px 0px 0px 0px;
}

.imgaelitedvbgmainbgaft {
    width: 100%;
    height: 400px;
    position: relative;
    z-index: 2;
}

.imgaelitedvbgmainbgaft img {
    width: 100%;
    height: 100%;
}

.imgaelitedvbgmainbgaft::after {
    position: absolute;
    content: "";
    background-color: #00000087;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 3;
}

.docuemtslidetextp {
    font-family: montheavy;
    font-size: 33px;
    line-height: 42px;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
    word-spacing: 1px;
}

.docuemtslidetextpdiv {
    position: absolute;
    top: 73%;
    left: 50%;
    z-index: 5;
    width: 100%;
    max-width: 80%;
    margin: auto;
    transform: translate(-50%, -50%);
}

.documentRepositorycategoryflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;
    /* padding-top: 15px; */
}

.documentRepositorycategorybg {
    width: 100%;
    max-width: 23.5%;
    min-width: 23.5%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px;
    cursor: pointer;
    border-radius: 15px;

}

.certificateimgdivbg {
    width: 100%;
    height: 80px;
    position: relative;
}

.certificateimgdivbg::before {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #f0e6e6bd;
    content: "";
    z-index: 5;
}

.certificateimgdivbg img {
    width: 100%;
    height: 100%;
}

.itemcategppp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 7;
}

.itemcategppptext {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-family: notosans;
    font-weight: 600;
    letter-spacing: 1px;
}

.topicviweflx {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 30px;
    padding-top: 9px;
}

.texyttpxvwpp {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-family: noirprolight;
    font-weight: 600;
    text-align: center;
}

.createdcocumentdiv {
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 5px;
    margin-bottom: 15px;
}

.createdcocumentop {
    width: 100%;
    padding: 10px;
}

.createdcocumentflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.documentbrdrprfl {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
    height: 59px;
    border-radius: 50%;
    border: 3px solid #fac00d;
}

.documentbrdrprflimg {
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.documentbrdrprflimg img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    border-radius: 50%;
}

.textthghtpp {
    font-size: 18px;
    line-height: 22px;
    color: #000;
    font-weight: 600;
    font-family: noirprolight;
}

.createdcocumenbottom {
    border-top: 1px solid #ccc;
    padding: 10px;
}

.crtdcmntflx {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

.articleiconimgdiv {
    width: 25px;
    height: 25px;
}

.articleiconimgdiv img {
    width: 100%;
    height: 100%;
}

.dcmnttxtpppdf {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-weight: 600;
    font-family: notosnas;
}

.recentdocdiv {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    margin-bottom: 7px;
    margin-top: 0px;
}

.recentdocdiv .top {
    width: 100%;
    height: auto;
    padding: 8px 12px 8px 12px;
    border-bottom: 1px solid rgba(198, 198, 210, 0.26);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.recentdoccdiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.dpocsttxtppp {
    font-family: NoirProRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: normal;
    color: #263238;
    padding: 0;
    margin: 0;
    /* margin-left: 10px; */
}

.recentdocdiv .buttom {
    padding: 11px
}

.docstxtpflxdaa {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    padding-bottom: 15px;
}

.dcmnttxtpp {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-family: noirprolight;
    font-weight: 700;
}

.documentRepositorycategorydiv {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
}

/* Documentrepository end */

/* Article Page Start */
.articlepagemain {
    width: 100%;
    height: auto;
    padding: 120px 0px 20px 0px;
}

.articlepagemainflx {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.articlepagemainleft {
    width: 100%;
    max-width: 71%;
}

.articlepagemainright {
    width: 100%;
    max-width: 27%;
}

.articlepagemainbgmain {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    margin-bottom: 7px;
    margin-top: 0px;
}

.articlepagemainbgmain .top {
    width: 100%;
    height: auto;
    padding: 8px 12px 8px 12px;
    border-bottom: 1px solid rgba(198, 198, 210, 0.26);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.documntrepotxtimgflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.documentRepositoryimgicon {
    width: 25px;
    height: 25px;
}

.documentRepositoryimgicon img {
    width: 100%;
    height: 100%;
}

.prchsetsxppp {
    font-family: NoirProRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: normal;
    color: #263238;
    padding: 0;
    margin: 0;
}

.articlepagemainbgmain .buttom {
    padding: 10px;
}

.dcmntdwnldflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    flex-wrap: wrap;
    row-gap: 10px;
}

.datetxtpp {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-family: notosans;
    font-weight: 700;
    text-align: center;
}

/* Article Page End */

/* Full ArticleView Start */
.fullarticleviewmain {
    width: 100%;
    height: auto;
    padding: 120px 0px 20px 0px;
}

.fullarticleviewmainflx {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    column-gap: 10px;
}

.fullarticleviewmainleft {
    width: 100%;
    max-width: 71%;
}

.fullarticleviewmainright {
    width: 100%;
    max-width: 27%;
}

.fullarticleviewbg {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px;
    border-radius: 10px;
}

.allarticlevwimgdivmai {
    width: 100%;
    max-width: 100%;
    height: 300px;
}

.allarticlevwimgdivmai img {
    width: 100%;
    height: 100%;
}

.purchaseprfolileflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

.articleprofiletextflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.profilearticlebrdr {
    width: 100%;
    max-width: 52px;
    min-width: 52px;
    border: 2px solid #fac00d;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.purcahseprofile {
    width: 100%;
    max-width: 100%;
    height: 46px;
}

.purcahseprofile img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-position: center;
}

.purchseprflnametext {
    font-size: 15px;
    line-height: 18px;
    color: #000;
    font-weight: 700;
    font-family: noirprolight;
}

.purchsedepartmentflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.purchebtn {
    border-radius: 15px;
    border: 1px solid #000;
    color: #000;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    padding: 4px 16px;
}

.departbtn {
    border-radius: 15px;
    border: 1px solid #000;
    color: #000;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    padding: 4px 16px;
}

.articleversntext {
    font-size: 14px;
    color: #000;
    font-weight: 500;
    line-height: 18px;
    font-family: noirprolight;
    padding: 5px 0px 3px 0px;
}

.purchsesrcingtext {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-weight: 500;
    font-family: noirprolight;
}

.Sourcingtextpp {
    font-size: 18px;
    line-height: 22px;
    color: #000;
    font-weight: 800;
    font-family: notosans;
    padding: 5px 0px 10px 0px;
}

.Sourcingtextppdivall {
    padding: 10px 0px 0px 0px;
}

.purchasetextdivppcheckbx label {
    font-size: 14px;
    color: #000;
    font-weight: 600;
    line-height: 18px;
    font-family: noirprolight;
    margin-left: 10px;
    margin-bottom: 0px;
}

.purchasetextdivppcheckbx {
    padding-top: 10px;
}

.completionassenmntflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

.completeaseentbtn {
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #d3a145;
    border: 1px solid #fac00d;
    color: #000;
    font-size: 14px;
    line-height: 18px;
    font-family: notosans;
    padding: 5px;
    border-radius: 10px;
    column-gap: 8px;
}

.Answerquizdivimg {
    width: 25px;
    height: 25px;
}

.Answerquizdivimg img {
    width: 100%;
    height: 100%;
}

/* Full ArticleView End */

/* CreateDocument Repository Form Start */
.createdocumntrepomain {
    width: 100%;
    height: auto;
    padding: 120px 0px 20px 0px;
}

.uploadmanagetextpp {
    font-size: 20px;
    line-height: 25px;
    font-weight: 600;
    font-family: notosans;
    color: #000;
    text-align: center;
    position: relative;
}

.uploadmanagetextpp::after {
    position: absolute;
    width: 266px;
    height: 4px;
    background-color: #d3a145;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 24px;
}

.uploaddivmainoptn select {
    width: 100%;
    max-width: 100%;
    border: 1px solid #000;
    padding: 4px;
    border-radius: 4px;
    margin: 5px 0px 0px 0px;
}

.uploaddoctextpp {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    font-family: noirprolight;
    color: #000;
}

.uploaddivmaindocumentoptndiv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0px;
}

.uploaddivmaindocumentoptndivbrdr {
    width: 100%;
    border: 2px solid #d3a145;
    margin-top: 30px;
}

.frmdivupload {
    width: 100%;
    max-width: 50%;
}

.uploaddivmainoptn input {
    width: 100%;
    max-width: 100%;
    border: 1px solid #000;
    padding: 4px;
    border-radius: 4px;
    margin: 5px 0px 0px 0px;
}

.imageupldcrs i {
    color: red;
    font-size: 14px;
}

.uploaddivmainoptn textarea {
    width: 100%;
    max-width: 100%;
    border: 1px solid #000;
    padding: 4px;
    border-radius: 4px;
    height: 70px;
    margin: 5px 0px 0px 0px;
    resize: none;
}

.uploaddivmainoptn {
    padding-top: 10px;
}
.trainernametextinputdiv .css-b62m3t-container{
    margin-top: 5px;
}

/* CreateDocument Repository Form End */


@media(max-width:1499px) {
    .videoongoing .top {
        padding: 5px 12px 5px 12px;
    }
    .playtxtflx {
        column-gap: 3px;
    }
    .ongoingbgdiv .bottom {
        column-gap: 16px;
        flex-wrap: wrap;

    }
    .mandatorydivbgflxdiv {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 14px;
    }
    .mandatorywidthdiv {
        width: 100%;
        max-width: 21%;
        margin: 10px;
    }

    .articlepagemainbgmain .top {
        padding: 5px 12px 5px 12px;
    }
    .educatemainleft {
        width: 100%;
        max-width: 75%;
    }
    .educatemainright {
        width: 100%;
        max-width: 22%;
    }
    .singlemandatoryright {
        width: 100%;
        max-width: 75%;
        /* padding-top: 7px; */
    }
    .singlemandatoryleft {
        width: 100%;
        max-width: 22%;
    }

    .recentdocdiv .top {
        padding: 5px 12px 5px 12px;
    }

    .mandatorttxt {
        font-size: 14PX;
    }

    .prchsetsxppp {
        font-size: 15px;
    }

    .dpocsttxtppp {
        font-size: 15px;
    }

    .videoongoing .top .head h4 {
        font-size: 14px;
    }

    .bulleBoardHead h4 {
        font-size: 14px;
    }

    .recomendedcrstxt {
        font-size: 14px;
    }

    .cmpltquzetxt {
        font-size: 15px;
    }

    .crsecntnttxtppp {
        font-size: 15px;
    }

    .datetxtpp {
        font-size: 13px;
        line-height: 16px;
        color: #000;
        font-family: notosans;
        font-weight: 700;
        text-align: center;
    }

    .sbmtbtndivmaindcmntdiv {
        border: 0;
        background: #d3a145;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        padding: 7px 15px;
        border-radius: 8px;
        font-family: 'NoirProLight';
        text-align: center;
        cursor: pointer;
        margin: 7px;
    }

    .sbmtbtndivmaindcmnt {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* .corsepreviewmainleft {
        max-width: 20%;
    }

    .corsepreviewmainright {
        max-width:20%;
    }
    .corsepreviewmainmiddle {
        width: 100%;
        max-width: 50%;corsepreviewmainleft
    } */


}

@media(max-width:1264px) {
    .corsepreviewmainleft {
        width: 100%;
        max-width: 20.5%;
    }
    .mandatorydivbgflxdiv {
       
        column-gap: 12px;
    }
    .ongoingbgdiv .bottom {
        column-gap: 13px;
        flex-wrap: wrap;
    }
    .corsepreviewmainmiddle {
        width: 100%;
        max-width: 50%;
    }

    .corsepreviewmainright {
        width: 100%;
        max-width: 20.5%;
    }

    .advocacycreatemaindiv {
        width: 100%;
        padding: 5px 9px;
        column-gap: 5px;
    }

    .crtadvocacytxtypp {
        font-size: 11px;
        line-height: 20px;
    }

}

@media(max-width:1199px) {
    .mndtxtpp {
        font-size: 13px;
        line-height: 14px;
    }

    .educatemainleft {
        width: 100%;
        max-width: 73%;
    }
    .singlemandatoryright {
        width: 100%;
        max-width: 73%;
        /* padding-top: 7px; */
    }
    .singlemandatoryleft {
        width: 100%;
        max-width: 24%;
    }
    .ongoingbgdiv .bottom .videoongoinddiv {
        width: 100%;
        max-width: 48%;
    }

    .educatemainthreedivs {

        column-gap: 24px;
    }

    .educatemainright {
        width: 100%;
        max-width: 24%;
    }
    .mandatorydivbgflxdiv {
        column-gap: 4px;
    }

    .vdeoimgmain {
        width: 100%;
        height: 230px;
        position: relative;
    }

    .videoimgdiv {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70px;
        height: 70px;
    }

    .corsepreviewmainleft {
        width: 100%;
        max-width: 21.5%;
        left: 0px;
    }

    .corsepreviewmainmiddle {
        width: 100%;
        max-width: 49%;
    }

    .corsepreviewmainright {
        width: 100%;
        max-width: 21.5%;
        right: 76px;
    }

    .textthghtpp {
        font-size: 17px;
        line-height: 20px;
        color: #000;
    }

    .documentbrdrprfl {
        height: 50px;
    }

    .recomendtxt {
        font-size: 15px;
        line-height: 17px;
    }

    .boxchckdiv {
        width: 13px;
        height: 13px;
    }

    .devloperbtcmptxt {
        font-size: 27px;
        line-height: 30px;
        font-family: Notosans;
        color: #000;
        font-weight: 600;
    }

    .skldiv label {
        font-size: 14px;
        line-height: 19px;
    }

    .skltxtpp {
        font-size: 15px;
        line-height: 18px;
    }

    .shwtxtpp {
        font-size: 15px;
        line-height: 18px;
    }

    .assntxt {
        font-size: 14px;
        line-height: 19px;
    }

    .videoongoing .top .head h4 {
        font-size: 13px;
    }

    .statusdivmaineducate .prflimgbrdr {
        width: 100%;
        max-width: 40%;
        min-width: 40%;
        height: 87px;
    }

    .statusdivmaineducate .pointshttx {
        color: #000;
        font-size: 11px;
        font-weight: 500;
        font-family: 'NotoSans';
    }

    .educatemain {
        width: 100%;
        height: auto;
        padding: 128px 0px 75px 0px;
    }
    .videoongoinddivnejbhj {
        width: 100%;
        max-width: 48%;
        min-width: 48%;
        background-color: #fff;
        box-shadow: 0px 0px 5px 0px #ccc;
        padding: 5px;
    }
    .singlemandatorysection {
        width: 100%;
        height: auto;
        padding: 128px 0px 75px 0px;
    }


    .trainingmainimgdiv {

        width: 100%;
        max-width: 70px;
        min-width: 69px;
        height: 56px;
    }

    .playtxtflx {
        column-gap: 7px;
    }

    .dotstxtpp {
        display: flex;
        font-size: 12px;
        line-height: 15px;
        column-gap: 4px;
        white-space: nowrap;
    }

    .video_onflx {
        column-gap: 2px;
    }

    .lrmtxtppeducate {
        color: #263238;
        font-size: 12px;
        line-height: 17px;
        white-space: nowrap;
    }

    .bg-play {
        padding: 1px 9px;
        font-size: 12px;
        line-height: 17px;
    }

    .tsgyutppp {
        font-size: 9px;
        line-height: 15px;
        align-self: center;
    }

    .bottomrepoflx {
        column-gap: 6px;
    }

    .mandatorywidthdiv {
        width: 100%;
        max-width: 30%;
        margin: 10px;
    }
}

@media(max-width:1064px) {
    .corsepreviewmainmiddle {
        width: 100%;
        max-width: 48%;
    }

    .corsepreviewmainleft {
        width: 100%;
        max-width: 22.5%;
        left: -14px;
    }

    .corsepreviewmainright {
        width: 100%;
        max-width: 22.5%;
        right: 62px;
    }
}

@media(max-width:991px) {
    .trainmgmainsectionpart {
        flex-wrap: wrap;
    }
    .trainingcreatepagemain {
        padding: 120px 0px 70px 0px;
    }
    .EnlargednewEnrollmentsection {
        padding: 120px 0px 70px 0px;
    }

    .coursrincldflx {
        width: 100%;
        max-width: 37%;
    }
    .ongoingbgdiv .bottom .videoongoinddiv {
        width: 100%;
        max-width: 100%;
    }
    .lrmtxtppeducate {
        color: #263238;
        font-size: 12px;
        line-height: 17px;
        white-space: nowrap;
        width: 100%;
        max-width: 346px;
        min-width: 346px;
        margin-left: 10px;
    }
 

    .singleongoingmain {
        width: 100%;
        height: auto;
        padding: 120px 0px 80px 0px;
    }

    .vdeoimgmain {
        width: 100%;
        height: 170px;
        position: relative;
    }

    .newlerndivmainleft {
        width: 100%;
        max-width: 100%;
    }

    .videoimgdiv {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 55px;
        height: 55px;
    }

    .fullarticleviewmainflx {
        flex-wrap: wrap;
    }

    .mandatorymodalmaincontent {
        width: 100%;
        max-width: 60%;
    }

    .articlepagemainflx {
        flex-wrap: wrap;
    }

    .webdevloperenrollflx {
        flex-wrap: wrap;
    }

    .documentrepositoryflx {
        flex-wrap: wrap;
    }

    .fullarticleviewmainright {
        width: 100%;
        max-width: 100%;
    }

    .fullarticleviewmainleft {
        width: 100%;
        max-width: 100%;
    }

    .articlepagemainleft {
        width: 100%;
        max-width: 100%;
    }

    .articlepagemainright {
        width: 100%;
        max-width: 100%;
    }

    .documentrepositoryright {
        width: 100%;
        max-width: 100%;
    }

    .documentrepositoryleftt {
        width: 100%;
        max-width: 100%;
    }

    .documentbrdrprfl {
        height: 87px;
        max-width: 10%;
        min-width: 11%;
    }

    .developerbootcampdetails {
        width: 100%;
        max-width: 100%;
    }

    .createdcocumentdiv {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .recentdocdiv {
        margin-bottom: 60px;
    }

    .developerbootcampvdo {
        width: 100%;
        max-width: 80%;
        margin: auto;
        padding-top: 20px;
    }

    .newenrollmentdivlrninclentnflx {
        flex-wrap: wrap;
    }

    .newlerndivmainwidth {
        width: 100%;
        max-width: 100%;
    }

    .corsepreviewmainleft {
        position: unset;
        width: 100%;
        max-width: 100%;
        margin-left: 0px;
    }

    .corsepreviewmainmiddle {
        width: 100%;
        max-width: 100%;
        margin: 0px 0px 0px 0px;
    }

    .corsepreviewmainright {
        width: 100%;
        max-width: 100%;
        position: unset;
    }

    .trainingmainsectionleft {
        width: 100%;
        max-width: 100%;
    }

    .trainingmainsectionright {
        width: 100%;
        max-width: 100%;
    }

    .productmainimg {
        width: 100%;
        height: 390px;
    }

    .statusdivmain {
        padding: 20px 0px 11px 0px;
    }

    .connectionimgtxtpp {
        width: 100%;
        max-width: 22.8%;
        min-width: 22.8%;
        background-color: #fff;
        box-shadow: 0px 0px 5px 0px #ccc;
        border-radius: 10px;
        padding: 10px;

    }

    .connectionimgmn {
        width: 100%;
        max-width: 100%;
        height: 106px;
    }

    .connectionimgmn img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .connectionimgflx {
        row-gap: 18px;
        column-gap: 18px;
    }

    .txtcnttxt {
        text-align: center;
        font-size: 14px;
        padding: 5px 0px;
        line-height: 18px;
    }

    .connectiondiv {
        margin: 20px 0px 63px 0px;
        width: 100%;
        max-width: 100%;
    }

    .educatemainflx {
        flex-wrap: wrap;
    }

    .educatemainleft {
        width: 100%;
        max-width: 100%;
        padding-bottom: 22px;
    }

    .videoongoing {
        margin-bottom: 25px;
    }

    .educatemainright {
        width: 100%;
        max-width: 100%;
    }

    .educatemainthreedivs {
        /* flex-wrap: wrap; */
        row-gap: 15px;
    }

    .educatemainbg {
        width: 100%;
        max-width: 100%;
        row-gap: 10px;
    }

    .trainingimg {
        width: 100%;
        height: 130px;
        position: relative;
    }

    .purchaseimgdiv {
        width: 100%;
        max-width: 16%;
        height: 60px;
        min-width: 16%;
    }

    .singleeducatemainflx {
        flex-wrap: wrap;
    }

    .singlemandatoryleft {
        width: 100%;
        max-width: 100%;
    }

    .singlemandatoryright {
        width: 100%;
        max-width: 100%;
        /* padding-top: 7px; */
    }

    .statusdivmaineducate .prflimgbrdr {
        width: 100%;
        max-width: 14%;
        min-width: 12%;
        height: 99px;
    }

    .trainingcreatepagemainflx {
        flex-wrap: wrap;
        row-gap: 10px;
    }

    .trainingcreatepageleft {
        width: 100%;
        max-width: 100%;
    }

    .trainingcreatepageright {
        width: 100%;
        max-width: 100%;
    }
}

@media(max-width:767px) {
    .mandatryskldivmainflx {
        flex-wrap: wrap;
    }
    .mandatorydivbgflxdiv {
        column-gap: 0px;
    }

    .wiillrndivflcx {
        flex-wrap: wrap;
        row-gap: 10px;
    }

    /* .ongoingbgdiv .bottom .videoongoinddiv {
        width: 100%;
        max-width: 48.7%;
    } */

    .coursrincldflx {
        width: 100%;
        max-width: 100%;
    }

    .lrntxtqunirppppdiv {
        position: relative;
        width: 100%;
        max-width: 40%;
    }

    .destxtption {
        font-size: 19px;
        line-height: 21px;
    }

    .lrnuniquetxt {
        font-size: 19px;
        line-height: 21px;
    }


    .descriptiondetails {
        font-size: 14px;
        line-height: 18px;
        color: #000;
        font-weight: 300;
        font-family: noirprolight;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin: 10px 0px;
    }

    .mandatrybgmain {
        margin: 20px 0px 0px 0px;
    }

    .documentRepositorycategorybg {
        width: 100%;
        max-width: 31%;
        background-color: #fff;
        box-shadow: 0px 0px 5px 0px #ccc;
        padding: 10px;
        cursor: pointer;
        border-radius: 15px;
    }

    .trainingproductdesignerdiv {
        flex-wrap: wrap;
    }

    .trainingproductbg {
        width: 100%;
        max-width: 100%;
        margin: 0px 0px 15px 0px;
    }

    .traingmainimg {
        width: 100%;
        max-width: 18%;
        min-width: 18%;
        height: 58px;
        background-color: #fff;
        box-shadow: 0px 0px 5px 0px #ccc;
        padding: 3px;
        border-radius: 5px;
    }

    .connectionimgtxtpp {
        width: 100%;
        max-width: 30.4%;
        min-width: 30.4%;
    }

    .statusdivmaineducate .prflimgbrdr {
        width: 100%;
        max-width: 20%;
        min-width: 20%;
        height: 103px;
    }

    .mandatorywidthdiv {
        width: 100%;
        max-width: 45%;
        margin: 10px;
    }

    .vdoulddivpbflx {
        flex-wrap: wrap;
    }

    .videotitledesrtpupldflx {
        flex-wrap: wrap;
    }

    .vdoulddivpbupldg {
        width: 100%;
        max-width: 100%;
    }

    .vdoulddivpbflxwh {
        width: 100%;
        max-width: 100%;
    }

    .trainernameselectflx {
        flex-wrap: wrap;
    }

    .trainernametextinputdiv {
        width: 100%;
        max-width: 100%;
        padding-top: 10px;
    }

    .purchasetextdivppcheckbx {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 5px;
    }
    .educatedivjdjbrdrbgmainb{
        flex-wrap: wrap;
    }
}


@media(max-width:576px) {
    .connectionimgtxtpp {
        width: 100%;
        max-width: 47.4%;
        min-width: 47.4%;
    }
    .playtxtflx {
        column-gap: 7px;
        row-gap: 14px;
    }
    .bg-play {
       margin-left: 10px;
    }

    .mandatorymodalmaincontent {
        width: 100%;
        max-width: 90%;
    }

    .playtxtflx {
        flex-wrap: wrap;
    }

    .lrmtxtppeducate {
        color: #263238;
        font-size: 11px;
        line-height: 12px;
        white-space: nowrap;
    }

    .documentRepositorycategorybg {
        width: 100%;
        max-width: 48%;
        background-color: #fff;
        box-shadow: 0px 0px 5px 0px #ccc;
        padding: 10px;
        cursor: pointer;
        border-radius: 15px;
    }
    .videoongoinddivnejbhj {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        background-color: #fff;
        box-shadow: 0px 0px 5px 0px #ccc;
        padding: 5px;
    }

    .statusdivmaineducate .prflimgbrdr {
        width: 100%;
        max-width: 35%;
        min-width: 35%;
        height: 91px;
    }

    .ongoingbgdiv .bottom .videoongoinddiv {
        width: 100%;
        max-width: 100%;
    }

    .imgaelitedvbgmainbgaft {
        width: 100%;
        height: 315px;
        position: relative;
        z-index: 2;
    }

    .documentbrdrprfl {
        height: 56px;
        max-width: 11%;
        min-width: 11%;
    }

    .docuemtslidetextp {
        font-family: montheavy;
        font-size: 26px;
        line-height: 32px;
        color: #fff;
        font-weight: 600;
        letter-spacing: 1px;
        word-spacing: 1px;
    }

    .docuemtslidetextpdiv {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 5;
        width: 100%;
        max-width: 80%;
        margin: auto;
        transform: translate(-50%, -50%);
    }

    .allarticlevwimgdivmai {
        width: 100%;
        max-width: 100%;
        height: 180px;
    }

    .purchasetextdivppcheckbx label {
        font-size: 12px;
        color: #000;
        font-weight: 600;
        line-height: 18px;
        font-family: noirprolight;
        margin-left: 10px;
        margin-bottom: 0px;
    }

    .strimgdiv {
        width: 20px;
        height: 20px;
    }

    .devloperbtcmptxt {
        font-size: 16px;
        line-height: 20px;
    }

    .educatemainthreedivs {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .educatemainbg {
        width: 100%;
        max-width: 100%;
        row-gap: 10px;
    }

    .trainingimg {
        width: 100%;
        height: 200px;
        position: relative;
    }

}

@media(max-width:480px) {
    .connectionimgtxtpp {
        width: 100%;
        max-width: 48%;
        min-width: 48%;
    }

    .divenrolflx {
        flex-wrap: wrap;
        row-gap: 10px;
    }

    .coursecontentflxalll {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 10px;
        padding: 6px 0px;
    }

    .cousreimgdmainimgnn {
        width: 100%;
        max-width: 18%;
        min-width: 18%;
    }
    .lrmtxtppeducate {
        color: #263238;
        font-size: 12px;
        line-height: 17px;
        white-space: nowrap;
        width: 100%;
        max-width: 193px;
        min-width: 193px;
        margin-left: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;

    }

    .crsecntntxt {
        font-size: 12px;
        line-height: 16px;
    }

    .lrntxtqunirppppdiv {
        position: relative;
        width: 100%;
        max-width: 100%;
    }

    .wiillrndivflcx {
        flex-wrap: wrap;
        row-gap: 10px;
    }

    .elitedevloptxt {
        font-size: 16px;
        line-height: 19px;
        font-family: noirprolight;
        color: #000;
        font-weight: 600;
        padding: 5px 0px;
    }

    .imgaelitedvbgmainbgaft {
        width: 100%;
        height: 211px;
        position: relative;
        z-index: 2;
    }

    .connectionimgflx {
        row-gap: 9px;
        column-gap: 9px;
    }

    .productmainimg {
        width: 100%;
        height: 257px;
    }

    .mandatorywidthdiv {
        width: 100%;
        max-width: 44%;
        margin: 10px;
    }

    .purchsedepartmentflx {
        flex-wrap: wrap;
    }

    .purchaseprfolileflx {
        flex-wrap: wrap;
        row-gap: 10px;
    }
}

@media(max-width:420px) {
    .productmainimg {
        width: 100%;
        height: 225px;
    }

    .showdescripttext {
        width: 100%;
        max-width: 40%;
    }

    .mandatorywidthdiv {
        width: 100%;
        max-width: 100%;
        margin: 10px;
    }
    .lrmtxtppeducate {
        color: #263238;
        font-size: 12px;
        line-height: 17px;
        white-space: nowrap;
        width: 100%;
        max-width: 156px;
        min-width: 156px;
        margin-left: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
    }

    .completionassenmntflx {
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 10px;
    }
}