.salarytemplatedivleftsidebar {
    background-color: #ffffff;
    height: 100vh;
    left: 0;
    max-width: 222px;
    overflow-y: auto;
    padding: 125px 10px 30px;
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0px 0px 5px 0px #ccc;
}

.salarytemplatedivrightmaincomp {
    padding-left: 135px;
    padding-top: 110px;
}

.crtnetmpletxtppp {
    font-size: 14px;
    color: #8f8f8f;
    font-family: notosans;
    line-height: 17px;
    font-weight: 500;
}

.templatenamedesriptiondiv {
    background-color: #fff;
    padding: 15px 20px;
    border-radius: 10px;
    margin: 20px 0px;
}

.templatenamedesriptiondivdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 43px;
}

.slarytemplatedivcontainer {
    width: 100%;
    max-width: 80%;
    margin: auto;
}

.templatedivright {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.templatedivleft {
    width: 100%;
    max-width: 55%;
    min-width: 55%;
}

.tmepletxtpppnamefrm {
    font-size: 14px;
    color: #242020;
    font-family: notosans;
    line-height: 17px;
    font-weight: 500;
}

.tmepletxtpppnamefrmmaindiv {
    width: 100%;
    max-width: 100%;
}

.tmepletxtpppnamefrmmaindiv input {
    width: 100%;
    max-width: 100%;
    background-color: #cccccc69;
    padding: 7px 15px;
    border: none;
    outline: 0;
    border-radius: 5px;
    margin-top: 10px;
}

.templatedivsalarydetaildiv {
    background-color: #fff;
    padding: 15px 20px;
    border-radius: 10px;
    margin: 20px 0px 0px 0px;
}

.annualtctttsttxctpp {
    font-size: 14px;
    color: #242020;
    font-family: notosans;
    line-height: 17px;
    font-weight: 500;
    margin-right: 60px;
}

.annualysycttdivmaindivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 6px;
}

.annualysycttdivmain {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
    border: 2px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-radius: 5px;
}

.riiuppwttstdiv {
    display: inline;
    border-right: 1px solid #ccc;
    padding: 3px 10px;
}

.ruppeerstrrsymoboltxt {
    font-size: 14px;
    color: #242020;
    font-family: notosans;
    line-height: 17px;
    font-weight: 500;
    display: inline;
}

.ruppeeincondomettxpp {
    font-size: 14px;
    color: #242020;
    font-family: notosans;
    line-height: 17px;
    font-weight: 500;
    margin-right: 20px;
}

.pweryyersttrxtppp {
    font-size: 14px;
    color: #242020;
    font-family: notosans;
    line-height: 17px;
    font-weight: 500;
}

.annualtctttsttxctppdivmain {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.tabledivslipmiantable {
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
}

.slrttxtpppcmpltxp {
    font-size: 12px;
    color: #242020ba;
    font-family: notosans;
    line-height: 14px;
    font-weight: 600;
}

.slrttxtpppcmpltxpnnt {
    font-size: 12px;
    color: #242020ba;
    font-family: notosans;
    line-height: 14px;
    font-weight: 600;
    text-align: right;
}

.slrttxtpppcmpltxpcenter {
    font-size: 12px;
    color: #242020ba;
    font-family: notosans;
    line-height: 14px;
    font-weight: 600;
    text-align: center;
}

.erningtxtpp {
    font-size: 14px;
    color: #242020de;
    font-family: notosans;
    line-height: 14px;
    font-weight: 800;
    padding-top: 30px;
    padding-bottom: 10px;
}

.erningtxtpprightt {
    font-size: 14px;
    color: #242020de;
    font-family: notosans;
    line-height: 14px;
    font-weight: 800;
    /* padding-top: 20px; */
    text-align: right;
    width: 100%;
    max-width: 80%;
    /* margin: auto; */
    padding: 10px;
}

.erningtxtpprighttnew {
    font-size: 14px;
    color: #242020de;
    font-family: notosans;
    line-height: 14px;
    font-weight: 800;
    padding: 10px;

}

.badisbrdrdivmain {
    border: 2px solid #ccc;
    width: 100%;
    max-width: 54%;
    margin: auto;
    border-radius: 5px;
    padding-right: 10px;
}

.badisbrdrdivmainwitho {
    border: 0px solid #ccc;
    width: 100%;
    max-width: 54%;
    margin: auto;
    border-radius: 5px;
}

.fivethsdindtxtpp {
    font-size: 14px;
    color: #242020;
    font-weight: 400;
    font-family: notosans;
    text-align: right;
}
.fivethsdindtxtpptettxnewpp {
    font-size: 14px;
    color: #242020;
    font-weight: 400;
    font-family: notosans;
    text-align: right;
    padding-right: 10px;
}
.fivethsdindtxtppspclnewoine {
    font-size: 14px;
    color: #242020;
    font-weight: 400;
    font-family: notosans;
    text-align: right;
    padding-right: 19px;
}
.fivethsdindtxtppfixed {
    font-size: 14px;
    color: #242020;
    font-weight: 400;
    font-family: notosans;
    text-align: left;
}

.basicdivfrst {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.basicdivscnd {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.basicdivfrth {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.basicdivffth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.brdrprnstctrtcbrdr {
    border: 2px solid #ccc;
    width: 100%;
    max-width: 100%;
    margin: auto;
    border-radius: 5px;

}

.inpusttdivtstrexttsoo {
    width: 100%;
}

.inpusttdivtstrexttsoo input {
    width: 100%;
    max-width: 60%;
    min-width: 60%;
    border: 0px solid #ccc;
    border-right: 2px solid #ccc;
    padding: 1px;
    outline: 0;
    padding: 0px 15px;
}

.perggsggstxrtxpctstpp {
    font-size: 13px;
    text-align: center;
    padding-left: 10px;
    font-weight: 300;
    font-family: 'NotoSans';
}

.babsttgsyyxttxppp {
    font-size: 12px;
    color: #242020;
    font-weight: 400;
    font-family: notosans;
}

.teysvvdrowscnd {
    padding: 10px 0px;
}

.tfootetdivvsgfccfdbg {
    background-color: rgb(116 171 215 / 14%);
    width: 100%;
}

.arrowcrrrsitttpp i {
    margin-left: 12px;
    font-size: 14px;
    font-weight: 400;
    font-family: "fontawesome";
}

.salaryimgmain {
    width: 100%;
    max-width: 85px;
    min-width: 85px;
    height: 85px;
    margin: auto;
    border-radius: 50%;
}

.salaryimgmain img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.nameofjjdhhhfrhhtxtpp {
    font-size: 15px;
    color: #000;
    font-family: notosans;
    line-height: 20px;
    font-weight: 700;
    /* padding-bottom: 1px; */
    text-align: center;
    padding-top: 10px;
}

.nameofhrbtctpp {
    font-size: 13px;
    color: #000;
    font-family: notosans;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
}

.princoiplebtnmainactb {
    border: none;
    border-radius: 5px;
    font-size: 16px;
    line-height: normal;
    padding: 4px 25px;
    text-align: center;
    color: #000000;
    font-weight: 600;
    background-color: #cecece;
}
.salaryimgmainbrdrikkk{
    border-bottom: 1px solid #cccccc85;
    margin-top: 10px;
}

.princoiplebtnmainkk {
    background-color: transparent;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    line-height: normal;
    padding: 4px 25px;
    text-align: center;
    background-color: #fff;
    color: #000000;
    font-weight: 400;
}

.salartydivmaindiv {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}
.salarytemplacomdibvmain{
    width: 100%;
    height: auto;
    padding: 15px 0px 20px 0px;
}
.saralydetailsmainsection{
    width: 100%;
    height: auto;
    padding: 15px 20px 20px 20px;
    background: #fff;
    border-radius: 10px;
    margin: 20px 0px;
}
.organizationtzt{
    font-size: 20px;
    color: #000;
    font-weight: 600;
    font-family: notosans;
    line-height: 18px;
}
.organizatindtls{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.oraganitionidtxtpp{
    font-size: 15px;
    color: #545454f5;
    font-weight: 500;
    font-family: notosans;
    line-height: 18px; 
}
.organizationdetailsmainportin{
    padding-top: 30px;
}
.organizationdtlsheading{
    font-size: 14px;
    color: #423e3ee9;
    font-weight: 500;
    font-family: notosans;
    line-height: 16px;
    margin-bottom: 10px;
}
.organizationlogoimg{
    width: 100%;
    max-width: 80px;
    min-width: 80px;
    height: 80px;
}
.organizationlogoimg img{
    width: 100%;
    height: 100%;
}
.strtimpotatnt i{
    font-size: 8px;
    color: red;
    margin-left: 3px;
}
.organizaiongrppadng{
    padding-bottom: 20px;
}
.organinamebrdr{
    border: 1px solid #ccccccb7;
    padding: 5px 50px 5px 10px;
    border-radius: 5px;
    margin-top: 5px;
    width: 100%;
    max-width: 40%;
    min-width: 40%;
 
}
.organizationdtlstxtpp{
    font-size: 14px;
    color: #100e0ee9;
    font-weight: 500;
    font-family: notosans;
    line-height: 16px;  
    display: inline;
}
.organizationlogoimgbrdr{
    display: inline-block;
    border: 2px dashed #ccc;
    padding: 7px 10px;
}

.adrestext_dtls{
    font-size: 14px;
    color: #100e0ee9;
    font-weight: 500;
    font-family: notosans;
    line-height: 16px;
    margin-bottom: 5px;
    
}
.adrestextadrestext_dtlsdivflx{
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    align-items: center;
    padding-bottom: 10px;
    width: 100%;
    max-width: 50%;
    min-width: 50%;
}
.adresstextmaindivflx{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
}

.salartcomponembtn{
    border: none;
    font-size: 15px;
    color: #000;
    font-weight: 500;
    font-family: notosans;
    outline: 0;
    background-color: #fff;
    /* border: 2px solid #142553; */
    border-radius: 50px;
    border-color: #ccc;
    padding: 5px 25px;
}
.salartcomponembtnmaindiv{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}
.slarycomponentdivbg{
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 0px;
    margin-top: 15px;
}
.thanledytteslartcuiketanle{
    width: 100%;
    border-collapse: collapse;
}
.enarrsadditinntablethead{
    width: 100%;
    border-bottom: 1px solid #ccc;
}
.enarrsadditinntabletheadthone{
    width: 100%;
    max-width: 35%;
    min-width: 35%;
}
.enarrsadditinntabletheadthtwo{
    width: 100%;
    max-width:30%;
    min-width: 30%;
}
.enarrsadditinntabletheadththree{
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}
.enarrsadditinntabletheadthfour{
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}
.enarrsadditinntabletheadthpp{
    font-size: 12px;
    color: #242020de;
    font-family: notosans;
    line-height: 14px;
    font-weight: 600;
    padding: 10px;
}
.enarrsadditinntabletheadthppnew{
    font-size: 12px;
    color: #242020de;
    font-family: notosans;
    line-height: 14px;
    font-weight: 600;
    padding: 10px;
    /* text-align: center;  */
}
.enarrsadditinntabletheadthrightppnew {
    font-size: 12px;
    color: #242020de;
    font-family: notosans;
    line-height: 14px;
    font-weight: 600;
    padding: 10px;
    text-align: center;
}
.eitteebeyhummomg{
    width: 100%;
    max-width: 20px;
    min-width: 20px;
    margin: auto;
}
.eitteebeyhummomg img{
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.enarrsaddwritetetxtetheadthpp{
    font-size: 12px;
    color: #242020de;
    font-family: notosans;
    line-height: 14px;
    font-weight: 400;
    padding: 12px 10px;
}
.enarrsaddwritetetxtetheadthppblue{
    font-size: 12px;
    color: #1faee6de;
    font-family: notosans;
    line-height: 14px;
    font-weight: 400;
    padding: 12px 10px;
}
.enarrsaddwritetetxtetheadivinln{
    display: inline-block;
}
.enarrsaddwritetetxtetheadthppnewttxp{
    font-size: 14px;
    color: #30eb05;
    font-family: notosans;
    line-height: 14px;
    font-weight: 400;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}
.salartcomponembtn.actv{
    border: 2px solid #1faee6de;;
}
.addnewbtnsalatyytemnpbtndivflx{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.addennewtemplatebtn{
    border: 2px double transparent;
    background-color: transparent;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    -webkit-background-clip: padding-box, border-box;
    background-image: linear-gradient(#fff, #fff), radial-gradient(circle at top left, #ed3b4b, #4c3b92);
    padding: 6px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    border-radius: 30px;
}
.addennewtemplatebtnaddimg{
    width: 100%;
    max-width: 20px;
    min-width: 20px;
    height: 20px;
}
.addennewtemplatebtnaddimg img{
    width: 100%;
    height: 100%;
}
.addnewtxtttmplbtn{
    font-size: 12px;
    color: #000;
    font-family: notosans;
    font-weight: 500;
    line-height: 15px;
}
.salarytemplatedivbgmain{
    width: 100%;
    background-color: #fff;
    padding: 10px 0px;
    border-radius: 10px;
    margin-top: 20px;
}
.salarytemplatedivtablemain{
    width: 100%;
    border-collapse: collapse;
}
.salarytemplatedivtablemainthone{
    width: 100%;
    max-width: 32%;
    min-width: 32%;
}
.salarytemplatedivtablemainthtwo{
    width: 100%;
    max-width: 32%;
    min-width: 32%;
}
.salarytemplatedivtablemainththree{
    width: 100%;
    max-width: 32%;
    min-width: 32%;
}
.salarytemplatedivtablemainthead{
    border-bottom: 1px solid #ccc;
}
.templatenametetxpppcomptwotxtpp{
    font-size: 12px;
    color: #242020de;
    font-family: notosans;
    line-height: 14px;
    font-weight: 600;
    padding: 10px;
    text-transform: uppercase;
}
.templatenametetbodytxtppotxtpp{
    font-size: 12px;
    color: #242020de;
    font-family: notosans;
    line-height: 14px;
    font-weight: 400;
    padding: 12px 10px;
}
.inpttstsalryttemplate{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    position: relative;
}
.inpttstsalryttemplate input{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 7px 20px 7px 10px;
    background-color: transparent;
    color: #000;
    font-size: 15px;
    font-family: notosans;
    font-weight: 400;
    margin-bottom: 10px;
    outline: none;
}
.inpttstsalryttemplate input:focus{
    border: 1px solid #ccc;
}
.inpttstsalryttemplate input:focus ~ label{
    top: 0;
    font-size: 14px;
    padding: 0 14px;
    background-color: #fff;
}
.inpttstsalryttemplate label{
    position: absolute;
    top: 38%;
    left: 15px;
    transform: translateY(-38%);
    color: #000;
    pointer-events: none;
    transition: .3s;
}
.slasrtytempdetaolsfdinbg{
    width: 100%;
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.salarttemplatespectdivtable{
    width: 100%;
    border-collapse: collapse;
}
.salarttemplatespectdivtablethone{
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}
.salarttemplatespectdivtablethtwo{
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}
.salarttemplatespectdivtableththree{
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}
.salarttemplatespectdivtablethfour{
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}
.salarttemplatespectdivtablethfive{
    width: 100%;
    max-width: 10%;
    min-width: 10%;  
}
.salarttemplatespectdivtablethead{
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
.templatenametetbodytbtrdr{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px 10px;
    margin:5px 10px ;

}
.templatenametetbodytbtrdrone{
    border: 1px solid #ccc;
    padding: 5px 10px;
    width: 100%;
    max-width: 40%;
    min-width: 40%;
    margin: 5px 0px 5px 26px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.templatenametetbodytbtrdrtwo{
    border: 1px solid #ccc;
    padding: 5px 10px;
    width: 100%;
    max-width: 45%;
    min-width: 45%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.slarttetxtpoisactstdivflx{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
}
.erningtxtpptwteytyw{
    font-size: 14px;
    color: #242020de;
    font-family: notosans;
    line-height: 14px;
    font-weight: 800;
    padding-top: 10px;
    padding-bottom: 10px;
}
.mayasactibeinactvmdl{
    border: 1px solid #ccc;
    padding: 4px 5px;
    background-color: #fff;
    border-radius: 5px;
    flex-direction: column;
    box-shadow: 0px 0px 5px 0px #ccc;
    position: absolute;
    top: 3px;
    right: 49px;
    width: 100%;
    max-width: 145px;
    min-width: 145px;
}
.habsttuul{
    position: relative;
    cursor: pointer;
}
.mayasactibvvestxtpp{
    font-size: 13px;
    color: #ffffff;
    font-family: notosans;
    line-height: 16px;
    font-weight: 500;
    text-align: left;
    cursor: pointer;
    background-color: #2196F3;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
}
.spakokakghbtdrtrssalarttemplatespectdibrd{
    border-bottom: 1px solid #ccc
}
.falataoiaipercnbasic{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;
}
.spakokakghbtdrtrssalarttemplatbody{
    border-bottom: 1px solid #ccc;
}
.falataoiaipercnbasicttxpp{
    font-size: 12px;
    color: #000000de;
    font-family: notosans;
    line-height: 16px;
    font-weight: 500;
    text-align: left;
}
.falataoiaipercnbasicdivflx{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 40px;
}
.falataoiaipercnbasicdivpading{
    padding: 10px;
}
.ceesllsttypammkittxp{
    font-size: 12px;
    color: #000000de;
    font-family: notosans;
    line-height: 16px;
    font-weight: 600; 
    margin-bottom: 5px;
}
.amaiontgtsuvvdsllinmpyut{
    border: 1px solid #ccc;
    display: block;
    width: 100%;
    max-width: 162px;
    min-width: 162px;
    display: flex;
    justify-content: flex-start;
}
.amaiontgtsuvvdsllinmpyutspan {
    font-size: 15px;
    padding: 6px 18px 6px 10px;
    background-color: #ccc;
    overflow: hidden;
    border-left: 1px solid #000;
    text-align: center;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}
.amaiontgtsuvvdsllinmpyut input{
    border: 1px solid #000;   
    width: 100%;
    max-width: 162px;
    min-width: 162px;
    padding: 5px 10px;
}
.sgttstxttaminndtrxpp{
    font-size: 14px;
    color: #000000de;
    font-family: notosans;
    line-height: 16px;
    font-weight: 400; 
  
}
.ajhjsjamamijatpexttrdiv{
    padding-top: 10px;
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.salarttemplatespectdivtabletrthtwo{
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.salarttemplatespectdivtabletrthone{
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}
.namedibbsftxtstinputbox{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 6px 15px;
    width: 100%;
    margin: 10px 20px 10px 11px;
    max-width: 70%;
    min-width: 70%;
}
.Calculationdivbvdksffdlx{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 50px;
}
.amaiontgtsuvvdsllinmpyutspanrifht {
    font-size: 15px;
    padding: 6px 18px 6px 10px;
    background-color: #ccc;
    overflow: hidden;
    border-right: 1px solid #000;
    text-align: center;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}
.mayasactibvvestxtpp:last-child{
    margin-bottom: 0px;
}
.salrytemplatebgmain_bg{
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 15px;
    margin-top: 10px;
}
.brdsrcsalrybrdryrp{
    border: 1px solid #ccc;
    padding: 5px 10px;
    font-size: 14px;
    color: #000;
    font-family: 'NotoSans';
    line-height: 18px;
    border-radius: 5px;
}
.salarttemplatedivflsx{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.salrtytxtppytrtxtp{
    font-size: 14px;
    color: #000000de;
    font-family: notosans;
    line-height: 16px;
    font-weight: 400;
    margin-bottom: 10px;  
}
.salarttemplatedivfrst{
    width: 100%;
    max-width: 48%;
    min-width: 48%;
}
.salarttemplatedivscnd{
    width: 100%;
    max-width: 48%;
    min-width: 48%;  
}
.salrttxtppdtlstxtppincltxtp{
    font-size: 14px;
    color: #000000de;
    font-family: notosans;
    line-height: 16px;
    font-weight: 400;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-top: 10px;
}
.salrttxtppdtlstxtppinbgmain{
    background-color: #fff;
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;
    /* padding: 10px 15px; */
}
.tablesaralydetlksdivtbldmaon{
    width: 100%;
    border-collapse: collapse;
}
.tablesaralydetlksdivtbldiv{
    width: 100%;
    max-width: 100%;
}
.tablesaralydetlksdivtbldmaonthed{
    border-bottom: 1px solid #ccc;
}
.erningtxtpptxtpp{
    font-size: 14px;
    color: #242020de;
    font-family: notosans;
    line-height: 14px;
    font-weight: 800;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 11px;
}
.tablesaralydetlksdivtmainthone{
    width: 100%;
    max-width: 32%;
    min-width: 32%;
}
.tablesaralydetlksdivtmainthtwo{
    width: 100%;
    max-width: 32%;
    min-width: 32%; 
}
.tablesaralydetlksdivtmainththre{
    width: 100%;
    max-width: 32%;
    min-width: 32%;    
}
.enarrsaddwritetetxtetheadthppcompfr{
    font-size: 12px;
    color: #242020de;
    font-family: notosans;
    line-height: 14px;
    font-weight: 400;
    padding: 12px 10px 0px 12px;
}
.enarrsaddwritetetxtetheadthccfrt{
    font-size: 12px;
    color: #ccc;
    font-family: notosans;
    line-height: 14px;
    font-weight: 400;  
    padding: 2px 10px 12px 12px;
}
.cstcmpnytxtpptxtpp{
    font-size: 12px;
    color: #242020de;
    font-family: notosans;
    line-height: 14px;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 11px;
    padding-right: 11px;
}