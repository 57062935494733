.messagesectiondiv {
    width: 100%;
    height: auto;
    padding: 120px 0px 30px 0px;
}

.messagedivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 35px;
}

.msgcontainer {
    width: 100%;
    max-width: 86.6%;
    margin: 0 auto;
}

.messagedivleft {
    width: 100%;
    max-width: 20%;
}

.messagedivright {
    width: 100%;
    max-width: 77%;
}

.messagedivbgg {
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    padding-bottom: 10px;
}

.messagedivlfttop {
    width: 100%;
    padding: 15px 10px;
    border-bottom: 1px solid #ccc;
}

.inptdivtxtdippplcd {
    position: relative;
    width: 100%;
}

.inptdivtxtdippplcd input {
    border: none;
    width: 100%;
    outline: 0;
    padding: 15px 30px 15px 40px;

}

.inptdivtxtdippplcd input:focus {
    outline: 0;
    box-shadow: 0;
    border: none;
}

.srchdivimgicn {
    position: absolute;
    top: 15px;
    left: 0;
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
}

.srchdivimgicn img {
    width: 100%;
    height: 100%;
}

.messagedivlftbottom {
    width: 100%;
    padding: 15px 10px;
    height: 100vh;
    overflow-y: auto;
}

.msgprofiledsgtmflc {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.msgprofjdsgfxl {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;
}

.prfldivimhbg {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: 50px;

}

.prfldivimhbg img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.profilenamemsgtxt {
    width: 100%;
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 14px;
    line-height: normal;
    -webkit-line-clamp: 1;
    font-weight: 500;
    color: #000;
    font-family: noirprolight;
    padding-bottom: 5px;
}

.profilenamemsgdsgtxtp {
    width: 100%;
    max-width: 135px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: #000;
    font-family: noirprolight;
}

.timemsgppp {
    font-size: 12px;
    line-height: normal;
    color: #000;
    font-family: noirprolight;
    font-weight: 500;
}

.timedivhpp {
    align-self: flex-start;
}

.msgprofiledsgtmflc {
    padding-bottom: 20px;
}

.msgprofiledsgtmflc:last-child {
    padding-bottom: 0px;
}

.messagedivrighttop {
    width: 100%;
    padding: 15px 10px;
    border-bottom: 1px solid #ccc;
}

.messagerighttopflxdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.messagedivprfldsgrightflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.messagedivrightbg {
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
}


.profilenamemsgtxtrighttop {
    width: 100%;
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 14px;
    line-height: normal;
    -webkit-line-clamp: 1;
    font-weight: 500;
    color: #000;
    font-family: noirprolight;
    padding-bottom: 5px;
}

.profilenamemsgdsgtxtprightdsgtop {
    width: 100%;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: #000;
    font-family: noirprolight;
}

.infoimgsicvimg {
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
}

.infoimgsicvimg img {
    width: 100%;
    height: 100%;
}

.messagedivrightbottom {
    width: 100%;
    padding: 15px 10px;
    height: 90vh;
    overflow-y: auto;
}

.profileotherdiv {
    display: flex;
    justify-content: flex-start;
    column-gap: 20px;
    padding-bottom: 20px;
}

.profileotherdivbg {
    padding: 10px;
    background-color: #deebf7;
    width: 100%;
    max-width: 500px;
    border-radius: 15px;
}

/* .profileowndivbg {
    padding: 10px;
    background-color: #deebf7;
    width: 100%;
    max-width: 500px;
    border-radius: 15px;
} */

/* .profileotherdivtxt {
    font-size: 15px;
    line-height: 19px;
    color: #000;
    font-family: 'NoirProLight';
    font-weight: 500;

} */


.profileotherdivtxt {
    font-size: 15px;
    line-height: 19px;
    color: #000;
    font-family: 'NoirProLight';
    font-weight: 500;

}

.profileotherdivtxttime {
    font-size: 15px;
    line-height: 19px;
    color: #000;
    font-family: 'NoirProLight';
    font-weight: 500;
    float: right;
    padding-top: 8px;
}

.profileowndiv {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    padding-top: 20px;
    padding-bottom: 40px;
}

.profileowndivbg {
    padding: 10px;
    background-color: #deebf7;
    width: 100%;
    max-width: 500px;
    border-radius: 15px;
    position: relative;
}

.profileotherdivbgsntimg {
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    align-items: flex-end;
}

.snttxtpppcbh {
    font-size: 14px;
    line-height: normal;
    color: #000;
    font-family: 'NoirProLight';
    font-weight: 800;
}

.senncheckdivimg {
    width: 100%;
    max-width: 20px;
    min-width: 20px;
    height: 20px;
}

.senncheckdivimg img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.messagedivrightbottomlastbtm {
    border-top: 1px solid#ccc;
    width: 100%;
    padding: 15px 10px;
}

.messagedivsrchdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
}


.msgicondivallsivtextflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
    width: 100%;
    max-width: 10%;
}

.texticonimgsaa {
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
    position: relative;
    z-index: 2;
}

.texticonimgsaa img {
    width: 100%;
    height: 100%;
}

.inputtypeprfls {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
    z-index: 4;
    opacity: 0;
}

.inputtypeprfls input {
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
}

.cameraspclarea {
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
}

.cameraspclarea img {
    width: 100%;
    height: 100%;
}

.micaaspclarea {
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
}

.micaaspclarea img {
    width: 100%;
    height: 100%;
}

.textmasgdiv {
    width: 100%;
    height: auto;
    position: relative;
}

.textmasgdiv textarea {
    width: 100%;
    padding: 15px 80px 15px 20px;
    background-color: #ccc;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    line-height: 19px;
    color: #000;
    font-family: 'NotoSans';
    resize: none;
    height: 53px;
}

.textmasgdiv textarea:focus {
    outline: 0;
    border: none;
    box-shadow: none;
}

.sendicondivimgbg {
    position: absolute;
    top: 6px;
    right: 10px;
    background: linear-gradient(90deg, rgba(237, 59, 75, 1) 0%, rgba(76, 59, 146, 1) 54%);
    /* padding: 6px; */
    border-radius: 5px;
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.sendicondivimg {
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
}

.sendicondivimg img {
    width: 100%;
    height: 100%;
}

.profilearrowdiv {
    position: absolute;
    top: 0px;
    right: -21px;
    background-color: #deebf7;
    width: 60px;
    height: 13px;
    display: block;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;

}

@media(max-width:1399px) {
    .msgicondivallsivtextflx {
        width: 100%;
        max-width: 12%;
    }

    .messagesectiondiv {
        width: 100%;
        height: auto;
        padding: 120px 0px 90px 0px;
    }
}

@media(max-width:1199px) {
    .messagedivleft {
        width: 100%;
        max-width: 30%;
    }

    .msgicondivallsivtextflx {
        width: 100%;
        max-width: 17%;
    }

    .messagedivright {
        width: 100%;
        max-width: 67%;
    }
}

@media(max-width:991px) {
    .messagedivleft {
        width: 100%;
        max-width: 40%;
    }

    .msgicondivallsivtextflx {
        width: 100%;
        max-width: 25%;
    }

    .messagedivright {
        width: 100%;
        max-width: 55%;
    }
}

@media(max-width:767px) {
    .messagedivleft {
        width: 100%;
        max-width: 100%;
    }

    .messagedivright {
        display: none;
    }
}