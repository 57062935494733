.bg-talent {
    background-color: #cacaca;
    width: 100%;
    padding: 20px 0px;
    box-shadow: 0px 0px 5px 0px #ccc;
}

.bg-talentmain {
    padding-top: 113px;
}

.tlnttxtpp {
    color: #000;
    font-size: 23px;
    line-height: 29px;
    font-family: 'NotoSans';
    font-weight: 600;
    text-align: center;
    letter-spacing: 2px;
}

.jobmaindtxlflx {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.jobbrdrwhdt {
    width: 100%;
    max-width: 23%;
}

.jobdtksvbrdr {
    width: 100%;
    border: 2px solid #fac00d;
    height: auto;
    padding: 10px 10px 15px 10px;
    border-radius: 10px;
}

.jobdtlsmainsection {
    width: 100%;
    height: auto;
    background: #fff;
    padding: 50px 0px;
}

.mrtrdtys label {
    margin: 0px;
    padding: 0px;
}

.required {
    color: #fac00d;
    font-weight: bolder;
    font-size: 20px;
    font-family: 'montheavy';
}

.jibtxt {
    font-size: 15px;
    font-weight: 500;
    font-family: 'NotoSans';
    line-height: 17px;
    color: #000;
}

.jobdtlsdiv {
    width: 100%;
    padding: 5px 0px;
}

.jobtxtinpt {
    width: 100%;
    padding: 0px;
    border: 1px solid #0000004d;
    border-radius: 5px;
    margin: 8px 0px 0px 0px;
    padding: 0px 15px;
}

.frmjobtxtdiv label {
    font-size: 15px;
    font-weight: 600;
    font-family: 'NoirProLight';
    line-height: 17px;
    color: #000;
    margin-bottom: 0px;
}

.frmjobtxtdiv select {
    width: 100%;
    padding: 0px;
    border: 1px solid #0000004d;
    border-radius: 5px;
    margin: 8px 0px 0px 0px;
    padding: 0px 15px;
}

.frmjobtxtdiv {
    width: 100%;
    max-width: 48%;
}

.yxsbnflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 50%;
    padding: 3px 0px;
}

.ysnopst {
    padding: 5px 0px 0px 0px;
}

.mrtrdtys input {
    margin-right: 10px;
}

.hgmdlwflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8%;
    padding: 8px 0px 5px 0px;
}

.gnjhbtn {
    border: 1px solid #fac00d;
    background-color: #fac00d;
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
    font-family: Noirprolight;
    color: #000;
    padding: 3px 10px;
    border-radius: 5px;
}

.jobdtlswh {
    width: 100%;
    max-width: 70%;
}

.dtlskmflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dtlstxtpphh {
    color: #000;
    font-size: 18px;
    line-height: 22px;
    font-family: 'montheavy';
    font-weight: 800;
    letter-spacing: 1px;
}

.rsntxrp {
    font-size: 15px;
    font-weight: 500;
    font-family: 'NotoSans';
    line-height: 17px;
    color: #000;
}

.rsjobtxtdiv {
    width: 100%;
    padding: 0px;
    border: 1px solid #0000004d;
    border-radius: 5px;
    margin: 8px 0px 0px 0px;
    padding: 0px 15px;
    resize: none;
}

.senddivrxr {
    width: 100%;
    padding: 5px 0px;
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.txtinptflx {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.rsntxrpdiv {
    width: 100%;
    max-width: 25%;
}

.rsntxrptxtdiv {
    width: 100%;
    max-width: 100%;
}

.addimg {
    width: 25px;
    height: 25px;

}

.addimg img {
    width: 100%;
    height: 100%;
}

.addimginpt {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.interdivmain {
    width: 100%;
    max-width: 100%;

}

.interdiv {
    width: 100%;
    max-width: 100%;
    padding: 0px;
    border: 1px solid #0000004d;
    border-radius: 5px;
    margin: 8px 0px 0px 0px;
    padding: 0px 15px;
    margin-left: 10px;
}

.hrdivtxtmain {
    width: 100%;
    max-width: 100%;
}

.hrdivtxt {
    width: 100%;
    max-width: 37%;
    padding: 0px;
    border: 1px solid #0000004d;
    border-radius: 5px;
    margin: 8px 0px 0px 0px;
    padding: 0px 15px;
}

.positionwhnflx {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.avgsldiv {
    width: 100%;
    max-width: 45%;
}

.avgdivmain {
    width: 100%;
    max-width: 86%;
}

.avgdiv {
    width: 100%;
    max-width: 100%;
    padding: 0px;
    border: 1px solid #0000004d;
    border-radius: 5px;
    margin: 8px 0px 0px 0px;
    padding: 0px 15px;
}

.addldiv {
    width: 100%;
    max-width: 20%;
    margin-left: 27px;
}

.clndrimg {
    width: 25px;
    height: 25px;
}

.clndrimg img {
    width: 100%;
    height: 100%;
}

.caledricontxtflsx {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
}

.avgdixflx {
    width: 100%;
    max-width: 55%;
    padding: 10px 0px;
    display: flex;
    align-items: center;
}

.whendixflx {
    width: 100%;
    max-width: 45%;
    padding: 10px 0px;
    display: flex;
    align-items: center;
}

.frmtodivflx {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 30px;
}

.frmstrt {
    width: 100%;
    max-width: 48%;
    display: flex;
    align-items: baseline;
}

.frmstrt select {
    width: 100%;
    max-width: 100%;
    border: 1px solid #0000004d;
    border-radius: 5px;
    margin: 8px 0px 0px 0px;
    padding: 4px 0px;
}

.frmstrt label {
    font-size: 15px;
    font-weight: 500;
    font-family: 'NoirProLight';
    line-height: 17px;
    color: #000;
    margin-right: 10px;
}

.frmend label {
    font-size: 15px;
    font-weight: 500;
    font-family: 'NoirProLight';
    line-height: 17px;
    color: #000;
    margin-right: 10px;
}

.frmend select {
    width: 100%;
    max-width: 100%;
    border: 1px solid #0000004d;
    border-radius: 5px;
    margin: 8px 0px 0px 0px;
    padding: 4px 0px;
}

.frmend {
    width: 100%;
    max-width: 48%;
    display: flex;
    align-items: baseline;
}

.frmstrt option {
    font-size: 15px;
    font-weight: 600;
    font-family: 'NoirProLight';
    line-height: 17px;
    color: #000;
}

.frmend option {
    font-size: 15px;
    font-weight: 600;
    font-family: 'NoirProLight';
    line-height: 17px;
    color: #000;
}

.prvdlinkdiv {
    width: 100%;
    max-width: 25%;
    margin-top: 9px;

}

.pstimg {
    width: 25px;
    height: 25px;
}

.pstimg img {
    width: 100%;
    height: 100%;
}

.sbmtdiv {

    width: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 20px;
    border-radius: 10px;
    background-color: #fac00d;
    /* margin-top: 24px; */
}

.btnapprovd {
    border: none;
    background-color: green;
    color: #fff;
    border-radius: 20px;
    padding: 7px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-family: 'NoirProLight';
    line-height: 18px;
}

.btnapprovde {
    border: none;
    background-color: rgb(192, 56, 56);
    color: #fff;
    border-radius: 20px;
    padding: 7px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-family: 'NoirProLight';
    line-height: 18px;
}

.sbmtdivmainnndiv {
    width: 100%;
    max-width: 30%;
    margin: 0px auto 0px auto;
}

.apprdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
}

.sbmtdivmain {
    width: 100%;
    max-width: 25%;
    margin: auto;
    padding: 15px 0px;
}

.hrtxty {
    font-size: 15px;
    font-weight: 600;
    font-family: 'NoirProLight';
    line-height: 17px;
    color: #000;
}

.hrtxtp {
    font-size: 22px;
    line-height: 25px;
    color: #000;
    font-family: 'MANDAUFONT';
    text-align: center;
    font-weight: 500;
    padding: 8px 0px;
    letter-spacing: 1px;
    position: relative;
}

.hrtxtp::after {
    position: absolute;
    content: "";
    width: 10%;
    height: 13%;
    background-color: #fac00d;
    /* bottom: -8px; */
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
    margin-top: 5px;
}

.jobslctdiv {
    width: 100%;
    padding: 3px;
    border: 1px solid #0000004d;
    border-radius: 5px;
    margin: 8px 0px 0px 0px;
    /* padding: 0px 15px; */
}

.txtarfdiv {
    width: 100%;
    padding: 2px 0px 1px 0px;
}

.txtarfdiv textarea {
    width: 100%;
    padding: 0px;
    border: 1px solid #0000004d;
    border-radius: 5px;
    margin: 8px 0px 0px 0px;
    padding: 0px 15px;
    height: 70px;
    resize: none;
}
.plnbtnact {
    border: 1px solid #fac00d;
    background-color: transparent;
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
    font-family: Noirprolight;
    color: #000;
    padding: 3px 12px;
    border-radius: 5px;
    /* width: 136px; */
    cursor: pointer;
    text-align: center;
}

.btnscactb {
    border: 1px solid #fac00d;
    background-color: #fac00d;
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
    font-family: Noirprolight;
    color: #000;
    padding: 3px 10px;
    border-radius: 5px;
    width: 136px;
    cursor: pointer;
    text-align: center;
}

.btnflxmain {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
}

.whyjdiv {
    width: 100%;
    padding: 5px 0px;
}

.txtwhydiv {
    width: 100%;
    padding: 10px 0px;
}

.txtwhydiv textarea {
    width: 100%;
    height: 60px;
    border: 1px solid #0000004d;
    border-radius: 5px;
    margin: 8px 0px 0px 0px;
    padding: 0px 15px;
    resize: none;
}

.rfrlinpflx {
    align-items: center;
    display: flex;
    column-gap: 10px;
    justify-content: flex-start;
}

.opnttxt {
    font-size: 15px;
    font-weight: 600;
    font-family: 'NoirProLight';
    line-height: 17px;
    color: #000;
    margin-right: 15px;
}

.dtlskmflx .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #fac00d;
    border-radius: 0.5rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
}

.dtlskmflx .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #fac00d;
    background-color: #fac00d;
}

.bg-grydiv {
    border-radius: 2px;
    padding: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.downloiaddibimgone{
    width: 100%;
    max-width: 22px;
    min-width: 22px;
}
.downloiaddibimgone img{
    width: 100%;
    height: 100%;
}
.downloiaddibimgoneifdocmnt{
    width: 100%;
    max-width: 22px;
    min-width: 22px;   
    animation: mymoveimgmain 5s infinite;
}
@keyframes mymoveimgmain {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.downloiaddibimgoneifdocmnt img{
    width: 100%;
    height: 100%;
}
.downloiaddibimgonedivmain{
    width: 100%;
    max-width: 33px;
    min-width: 33px;
    height: 33px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.frcttxy {
    font-size: 15px;
    line-height: 20px;
    color: #000;
    font-family: Noirprolight;
    font-weight: 500;
    padding: 0px 0px 5px 0px;
}

.jntxt {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1%;
    padding: 10px 0px;
}

.jndivtxtwhy {
    width: 100%;
    padding: 5px 0px 5px 0px;
}

.qualiaddi {
    display: flex;
    justify-content: space-between;
}

.minimdiv {
    width: 100%;
    max-width: 55%;
    padding: 10px 0px;
    display: flex;
    align-items: center;
}

.addnidiv {
    width: 100%;
    max-width: 45%;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
}

.qulitxtar {
    width: 100%;
    max-width: 86%;
}

.qulitxtardiv {
    width: 100%;
    max-width: 100%;
    padding: 0px;
    border: 1px solid #0000004d;
    border-radius: 5px;
    margin: 8px 0px 0px 0px;
    padding: 10px 15px;
}

.addqtmntxt {
    width: 100%;
    max-width: 46%;
}

.txtlixtli {
    font-size: 15px;
    line-height: 20px;
    color: #000;
    font-family: Noirprolight;
    font-weight: 500;
    padding: 5px 0px;
    position: relative;
    margin-left: 30px;
}

.txtlixtli::before {
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #000;
    top: 12px;
    left: -22px;
}

.txtillsltall {
    margin: 0px;
}

.sndstpp {
    font-size: 20px;
    font-weight: 600;
    font-family: 'NoirProRegular';
    line-height: 25px;
    padding-bottom: 5px;
    padding: 15px 0px;
}

.refershrflx {
    display: flex;
    justify-content: flex-start;
    gap: 2%;
    padding: 10px 0px;
}

.btnmainflx {
    background-color: rgb(211 161 69);
    height: 50px;
    border: none;
    border-radius: 10px;
    display: flex;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnmainflxchnageupdate {
    background-color: rgb(255, 255, 255);
    /* height: 37px; */
    border: none;
    border-radius: 10px;
    display: flex;
    padding: 8px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px 0px #ccc;

}
.dwnsnnjndpdgghstsxtppp{
    font-size: 15px;
    line-height: 18px;
    color: #000;
    font-weight: 300;
    font-family: notosans;
    margin-left: 10px;
}

.shrimg {
    width: 100%;
    height: 19px;
    max-width: 20px;
    min-width: 20px;
}

.shrimg img {
    width: 100%;
    height: 100%;
}

.rfshr {
    font-size: 15px;
    font-weight: 500;
    font-family: 'NotoSans';
    line-height: 20px;
    color: #fff;
    margin-left: 10px;
}

.jobnotificationmain {
    width: 100%;
    height: auto;
    padding: 145px 0px 30px 0px;
}

.hirinfsingle {
    width: 100%;
    padding: 0px;
    border: 1px solid #0000004d;
    border-radius: 5px;
    margin: 8px 0px 0px 0px;
    /* padding: 0px 15px; */
}

.frmtodivmainflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
    padding: 7px 0px 0px 0px;
}

.imgtxtdivmainflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.addimginptdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.inptadddivtxtwh {
    width: 100%;
    padding: 0px;
    border: 1px solid #0000004d;
    border-radius: 5px;
    margin: 8px 0px 0px 0px;
    padding: 0px 15px;
}

.added-txtrmvadd {
    width: 100%;
}

.btnrmvnbmain {
    border: none;
    background-color: red;
    padding: 5px 10px;
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    font-family: Noirprolight;
    outline: 0;
    border-radius: 5px;
    margin: 5px 0px;
}

.desareadiv textarea {
    padding: 10px 0px;
    width: 100%;
    height: 60px;
    resize: none;
    margin: 10px 0px;
    border: 1px solid #0000004d;
    border-radius: 5px;
}

.desareadivmaindiv {
    width: 100%;
    border: 2px solid #fac00d;
    height: auto;
    padding: 10px 10px 15px 10px;
    border-radius: 10px;
}

.btndivmainflx {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4%;
    margin: 15px 0px;
}

.btndivaprrcj {
    padding: 5px 10px;
    background-color: rgb(0, 129, 0);
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    font-family: Notosans;
    border: none;
    outline: 0;
    border-radius: 5px;
}

.btndivaprrcj:nth-child(2) {
    background-color: rgba(255, 0, 0, 0.918);
}

.senddivrxrdiv {
    width: 100%;
    padding: 5px 0px;
    display: flex;
}

.addimginptdivmm {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-wrap: wrap;
    width: 100%;
    gap: 1%;
}

.added-emaildivmain {
    width: 100%;
    max-width: 50%;
}

.interdivmaintxt {
    width: 100%;
    max-width: 100%;
    padding: 0px;
    border: 1px solid #0000004d;
    border-radius: 5px;
    /* margin: 8px 0px 0px 0px; */
    padding: 0px 15px;
    /* margin-left: 10px; */
}

@media(max-width:1199px){
    .jobbrdrwhdt {
        width: 100%;
        max-width: 29%;
    }
    .sbmtdivmain {
        width: 100%;
        max-width: 30%;
        margin: auto;
        padding: 15px 0px;
    }
    .jobdtlswh {
        width: 100%;
        max-width: 67%;
    }
}
@media(max-width:991px){
    .jobmaindtxlflx {
      flex-wrap: wrap;
    }
    .hrdivtxt{
        width: 100%;
        max-width: 100%;
    }
    .jobbrdrwhdt {
        width: 100%;
        max-width: 100%;
    }
    .jobdtlswh {
        width: 100%;
        max-width: 100%;
        padding-top: 10px;
    }
}
@media(max-width:767px){
    .sbmtdivmain {
        width: 100%;
        max-width: 39%;
        margin: auto;
        padding: 15px 0px;
    }
}
@media(max-width:576px){
    .senddivrxr {
        column-gap: 10px;
    }
    .hrdivtxt {
        width: 100%;
        max-width: 100%;
    }
    .sbmtdivmain {
        width: 100%;
        max-width: 50%;
        margin: auto;
        padding: 15px 0px;
    }
    .plnbtnact {
        font-size: 11px;
        line-height: 14px;
        font-weight: 700;
        color: #000;
        padding: 2px 10px;
    }
}
@media(max-width:767px){
    .positionwhnflx {
       flex-wrap: wrap;
    }
    .avgdixflx {
        width: 100%;
        max-width: 100%;
    }
    .whendixflx {
        width: 100%;
        max-width: 100%;
    }
    .addldiv {
        width: 100%;
        max-width: 28%;
        margin-left: 0px;
    }
    .avgsldiv {
        width: 100%;
        max-width: 28%;
    }
    .avgdivmain {
        width: 100%;
        max-width: 100%;
    }
}
@media(max-width:480px){
    .rsntxrp {
        font-size: 12px;
        line-height: 17px;
    }
    .btnflxmain {
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 10px;
    }
    .frmtodivflx {
        flex-wrap: wrap;
    }
    .frmstrt {
        width: 100%;
        max-width: 100%;
    }
    .frmend {
        width: 100%;
        max-width: 100%;
    }
}

@media(max-width:420px) {
    .refershrflx {
        flex-wrap: wrap;
        justify-content: center;
    }
    .senddivrxr {
        column-gap: 32px;
    }
    .sbmtdivmain {
        width: 100%;
        max-width: 70%;
        margin: auto;
        padding: 15px 0px;
    }

    .btnmainflx {
        width: 140px;
        background-color: rgb(255, 190, 152);
        height: 30px;
        border: none;
        border-radius: 10px;
        display: flex;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}