* {
    font-family: 'Raleway', sans-serif;
}

.header {
    width: 100%;
    height: auto;
    background-color: #fff;
    /* padding: 27px 0px; */
    /* padding-top: 28px; */
}

@font-face {
    font-family: montlight;
    src: url(../../Fonts/mont/Mont-ExtraLightDEMO.otf);
}

@font-face {
    font-family: montheavy;
    src: url(../../Fonts/mont/Mont-HeavyDEMO.otf);
}


@font-face {
    font-family: Montserratsemi;
    src: url(../../Fonts/Montserrat/Montserrat-SemiBold.ttf);
}

@font-face {
    font-family: Montserratextra;
    src: url(../../Fonts/Montserrat/Montserrat-ExtraBold.ttf);
}

@font-face {
    font-family: Poppinsitalic;
    src: url(../../Fonts/Poppins/Poppins-MediumItalic.ttf);
}

@font-face {
    font-family: Poppinsregular;
    src: url(../../Fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
    font-family: poppinslight;
    src: url(../../Fonts/Poppins/Poppins-Light.ttf);
}


@font-face {
    font-family: GTSuper;
    src: url(../../Fonts/GT-Super/GT-Super-Display-Super-Trial.otf);
}

@font-face {
    font-family: Urbanist;
    src: url(../../Fonts/Arbast/Urbanist-VariableFont_wght.ttf);
}


.header .navbar-brand {
    width: 201px;
    height: 51px;
}

.header .navbar-brand img {
    width: 100%;
    height: 100%;
}

figure {
    padding: 0;
    margin: 0;
}


.header .nav-item .nav-link {
    color: #141E61;
    font-size: 16px;
    font-weight: 500;
    padding: 25px 40px;
}

.header .nav-item.active .nav-link {
    color: #1D36AD;
}

.header .nav-item .nav-link:hover {
    color: #1D36AD;
}

.loginbtn {
    display: flex;
    width: 105px;
    height: 35px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    /* border: 1px solid #1D36AD; */
    /* margin-right: 30px; */
    background-color: #ffc600;
    color: #000000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    outline: none;
    padding: 5px 15px;
    transition: 0.6s;
    cursor: pointer;
    box-shadow: none;
    text-decoration: none;
    border: none;
}

.loginbtn:hover {
    background: #1D36AD;
    color: #FFF;
}

.signupbtn {
    display: flex;
    width: 142px;
    height: 45px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background-color: #1D36AD;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    outline: 0;
    transition: 0.6s;
    cursor: pointer;
    box-shadow: none;
    text-decoration: none;
    border: none;
}

.signupbtn:hover {
    border: 1px solid #1D36AD;
    background-color: transparent;
    color: #1D36AD;
}

.banner {
    width: 100%;
    height: auto;
    background: rgba(14, 36, 204, 0.08);
    padding: 100px 0;
    overflow: hidden;
}

/* .banner_title_section {
    padding: 100px 20px 100px 77px;
} */
.title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title p {
    color: #222;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.title h4 {
    color: #f0bc55;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
}

.title span {
    color: #0E24CC;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
}

.logoheadertopdiv {
    width: 209px;
    height: 81px;
}

.logoheadertopdiv img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.conten {
    max-width: 473px;
    margin-top: 15px;
}

.conten p {
    color: #222;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.1px;
    text-transform: lowercase;
}

.banner_btn {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: start;
}

.askforbtn {
    display: flex;
    width: 142px;
    height: 45px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid #1D36AD;
    margin-right: 30px;
    background-color: #1D36AD;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    outline: none;
    padding: 5px 15px;
    transition: 0.6s;
    cursor: pointer;
    box-shadow: none;
    text-decoration: none;
}

.askforbtn:hover {
    border: 1px solid #1D36AD;
    background-color: transparent;
    color: #1D36AD;
}

.viewplanbtn {
    display: flex;
    width: 142px;
    height: 45px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid #1D36AD;
    background-color: transparent;
    color: #1D36AD;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    outline: 0;
    transition: 0.6s;
    cursor: pointer;
    box-shadow: none;
    text-decoration: none;
}

.viewplanbtn:hover {
    background: #1D36AD;
    color: #FFF;
}




.banner_img {
    max-width: 768px;
    max-height: 374px;
}

.banner_img img {
    width: 100%;
    height: 100%;
}

.about {
    width: 100%;
    height: auto;
    padding: 130px 0 100px;
}

.about-dtls {
    max-width: 611px;
    padding-left: 12px;
}

.work {
    width: 100%;
    height: auto;
    padding: 70px 0;
}

.work .margintop {
    justify-content: space-around;
    margin: 90px auto 0;
}

.work_title {
    max-width: 581px;
    margin: 0 auto;
}

.work_title p {
    color: #222;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.225px;
}

.work_title span {
    color: #0E24CC;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.225px;
    text-transform: capitalize;
}

.work_sub_title {
    max-width: 486px;
    margin: 0 auto;
    padding-top: 22px;
}

.work_sub_title p {
    color: #222;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.1px;
}

.work_card {
    max-width: 380px;
    height: 345px;
    text-align: center;
    border-radius: 18px;
    border: 1px solid #C0C7FC;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
    margin-bottom: 40px;
}

.sub-card {
    /* max-width: 178px; */
    max-width: 100%;
    height: 155px;
    margin: 0 auto;
    border-radius: 18px;
    background: linear-gradient(180deg, #D7DBFF 0%, #FEFEFF 100%);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
}

.card_img {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    text-align: center;
    padding: 29px 24px;
}

.card_img img {
    width: 100%;
    height: 100%;
}

.card_title p {
    color: #222;
    text-align: center;
    margin-top: 15px;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.145px;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card_dtls {
    margin-top: 25px;
}

.card_dtls p {
    max-width: 297px;
    height: 85px;
    color: #737373;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.08px;
    text-transform: capitalize;
    padding: 7px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/******/
/* 21 sep */
.Benefits {
    width: 100%;
    height: auto;
    padding: 40px 0;
}

.benefit_section {
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
}

.benefit_dtls {
    text-align: center;
    margin-top: 20px;
}

.benefit_dtls p {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}

.myfastSwiper {
    padding-bottom: 20px !important;
}

.myfastSwiper .swiper-horizontal {
    touch-action: pan-y;
    height: 220px;
}

.myfastSwiper .swiper-pagination-bullet-active {
    background-color: #0E24CC;
}

.Associate {
    width: 100%;
    height: auto;
    padding: 50px 0;
}

.associate_section {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
}

.associate_slider {
    margin-top: 60px;
}

.associate_logo {
    max-width: 85px;
    height: auto;
}

.associate_logo img {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
}

/* .owl-item.active .associate_logo img {
    filter: none;
    -webkit-filter: grayscale(0%);
}  */

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
    display: none;
}

.service {
    width: 100%;
    height: auto;
    padding: 50px 0;
}

.work_img {
    max-width: 500px;
    height: auto;
    margin: 0 auto;
}

.work_img img {
    width: 100%;
    height: 100%;
}

.roles {
    width: 100%;
    height: auto;
    padding: 50px 0;
}

/* ...........testimonial............... */
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

#testimonial-area {
    width: 100%;
    height: auto;
    padding: 50px 0;
}

#testimonial-area .section-heading h5 {
    color: #222;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 50px;
}

.star_img {
    max-width: 88px;
    height: auto;
    margin: 0 auto;
}

.star_img img {
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
}

.testi-wrap {
    position: relative;
    height: 575px;
    margin-top: -80px;
}

.client-single {
    margin-top: 20px;
    text-align: center;
    position: absolute;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}

.client-info,
.client-comment {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.client-single {
    &.inactive {

        .client-comment,
        .client-info {
            display: none;
        }

        .client-comment,
        .client-info {
            opacity: 0;
            visibility: hidden;
        }
    }

    &.position-1 {
        -webkit-transform: scale(0.65);
        transform: scale(0.65);
    }

    &.position-2 {
        left: -40px;
        top: 105px;
    }

    &.position-3 {
        left: -60px;
        top: 240px;
        -webkit-transform: scale(0.4) !important;
        transform: scale(0.4) !important;
    }

    &.position-4 {
        left: 55px;
        top: 380px;
    }

    &.position-5 {
        top: 30px;
        right: 55px;
    }

    &.position-6 {
        top: 225px;
        right: -40px;
    }

    &.position-7 {
        top: 400px;
        right: 45px;
        -webkit-transform: scale(0.4) !important;
        transform: scale(0.4) !important;
    }

    &.active {
        top: 10%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        z-index: 10;
        width: 70%;

        .client-comment,
        .client-info {
            -webkit-transition-delay: 0.6s;
            transition-delay: 0.6s;
        }
    }

    &:not(.active) {
        -webkit-transform: scale(0.55);
        transform: scale(0.55);
        z-index: 99;
    }

    &.active .client-img {
        width: 160px;
        height: 160px;
        margin: 0 auto 24px;
        position: relative;


    }

    .client-img img {
        width: 150px;
        border-radius: 50%;
        cursor: pointer;
    }

    &.active .client-img img {
        max-width: 160px;
        margin: 0 auto 24px;
        border: 0;
    }
}

.client-reting {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.client-reting a i {
    text-align: center;
    font-size: 20px;
    color: #ECB904A1;
    padding-right: 5px;
}

.client-comment {
    max-width: 652px;
    margin: 0 auto;
}

.client-comment p {
    color: #787A91;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 138%;
}

.client-comment {
    padding: 0 30px;

    span i {
        font-size: 60px;
        color: #0084ff;
        margin: 40px 0 24px;
        display: inline-block;
    }
}

.client-info {
    h3 {
        color: #000;
        font-weight: 600;
        margin-bottom: 4px;
    }

    p {
        color: #0084ff;
        text-transform: uppercase;
    }
}

/* footer */
.footer {
    width: 100%;
    height: auto;
    background-color: #1D36AD;
}

.footer_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
}

.footer_logo {
    width: 200px;
    height: auto;
}

.footer_logo img {
    width: 100%;
    height: 100%;
}

.followers p {
    color: #FFF;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1.5px;
    line-height: normal;
}

.footer_conten {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

}

.footer_conten a {
    text-decoration: none;
    color: #fff;
    padding-right: 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.socal_media {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.socal_media a i {
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    padding-right: 20px;

}

/* New Landing Page --> Riju */
.landBannerSection {
    width: 100%;
    height: 630px;
    position: relative;
}

.landBanFig {
    width: 100%;
    height: 100%;
    margin: 0;
}

.landBanFig img {
    width: 100%;
    height: 100%;
}

.banTextsDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    width: 50%;
}

.aBettText {
    font-size: 50px;
    font-weight: 600;
    color: #fff;
    word-spacing: 15px;
    letter-spacing: 5px;
    text-align: right;
    width: 73%;
    margin: 0 0 7px 0;
}

.atThecenText {
    color: #fff;
    font-weight: 400;
    font-size: 26px;
    text-align: right;
    margin: 0 0 5px 0;
}

.weHelpText {
    /* border: 2px solid red; */
    color: #fff;
    font-size: 13px;
    margin: 0 0 22px 0;
    text-align: right;
    width: 61%;
}

.getStartBtn {
    background-color: #fff;
    border: 0;
    padding: 10px 12px;
    font-size: 17px;
    border-radius: 7px;
    color: #000;
    font-weight: 500;
    box-shadow: 0 0 10px 0 #4c4949;
}

.getStartBtn span i {
    transform: rotate(35deg);
}

.manageBySection {
    width: 100%;
    height: 630px;
    position: relative;
}



.manageFig {
    height: 100%;
    width: 100%;
    margin: 0;
}

.manageFig img {
    width: 100%;
    height: 100%;
}

.manageTextsDiv {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    width: 55%;
}

.manageByExpText {
    font-size: 45px;
    font-weight: 600;
    color: #000;
    word-spacing: 10px;
    letter-spacing: 5px;
    margin: 0 0 15px 0;
}

.empExpText {
    width: 77%;
    font-size: 17px;
    font-weight: 500;
    margin: 0 0 30px 0;
}

.empExpText span {
    font-weight: 700;
}

.hpyEmpText {
    font-size: 17px;
    font-weight: 500;
    margin: 0;
    width: 71%;
}

/* ***************** */
.containerPageLand {
    width: 80%;
    height: 100%;
    margin: auto;
    position: relative;
}

.landLogoFig {
    width: 75px;
    min-width: 75px;
    height: 65px;
    margin: 0 5px 0 0;
}

.landLogoFig img {
    width: 100%;
    height: 100%;
}

.navLandHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.landLogoDiv {
    display: flex;
    align-items: center;
}

.landLogoText {
    font-size: 17px;
    color: #000;
    font-weight: 500;
}

.landLogoDivAn:hover {
    text-decoration: none;
}

.landHeadUl {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}

.landHeadLi {
    margin-right: 15px;
}

.landHeadLi:last-child {
    margin-right: 0;
}

.landHeadLiAn {
    color: #000;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
}

.landHeadLiAn:hover {
    color: #000;
    text-decoration: none;
}

.landHeadBtns {
    background-color: rgb(31, 78, 121);
    color: #fff;
    padding: 8px 15px;
    border: 0;
    margin-right: 10px;
    text-transform: capitalize;
}

.landHeadBtns:last-child {
    margin-right: 0;
}

.landHeadSection {
    padding: 14px 0;
    box-shadow: 0 0 5px 0 #3b3b3be8;
    background-color: #fff;
    border: 1px solid #3838382c;
}

.blackLandHeadSection {
    background-color: #201d1e;
    padding: 9px 0;
}

.blackLandHeadUl {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
}

.blackLandHeadLi {
    margin-right: 12px;
}

.blackLandHeadLi:last-child {
    margin-right: 0;
}

.blackLandHeadLiAn {
    color: #ffffff9d;
    font-size: 16px;
    transition-property: all;
    transition-duration: 200ms;
}

.blackLandHeadLiAn:hover {
    color: #fff;
    text-decoration: none;
}

.landBannerTextBackDiv {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
}

.banLeftFigDiv {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.landBanInFig {
    width: 100%;
    height: 100%;
    margin: 0;
}

.landBanInFig img {
    width: 100%;
    height: 100%;
}

.employEngageSection {
    width: 100%;
    height: 630px;
    background-color: #262626;
}

.employBgImgFigDiv {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.employBgImgFig {
    width: 100%;
    height: 100%;
    margin: 0;
}

.employBgImgFig img {
    width: 100%;
    height: 100%;
}

.employEngText {
    font-size: 50px;
    font-weight: 600;
    color: #fff;
    word-spacing: 15px;
    letter-spacing: 5px;
    text-align: right;
    margin: 0 0 40px 0;
    line-height: normal;
}

.employHigherText {
    color: #fff;
    font-size: 19px;
    margin: 0 0 40px 0;
    line-height: normal;
}

.emploOurText {
    color: #fff;
    font-size: 19px;
    margin: 0;
    text-align: right;
    line-height: normal;
}

.employAllTextsDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
}

.compreHenSection {
    width: 100%;
    height: 600px;
    position: relative;
}

.compreHenTextLeft {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    width: 35%;
}

.firstCompreText {
    font-size: 25px;
    line-height: 30px;
    color: #000;
    margin: 0 0 20px 0;
    font-family: Montheavy;
    /* padding-left: 30px; */
}

.firstCompreText .firstCompFSpan {
    font-size: 45px;
    font-weight: bolder;
    line-height: normal;
    font-family: Montheavy;
}

.firstCompreText .firstCompSSpan {
    font-size: 40px;
    font-weight: 800;
    font-family: Montlight;
}

.compreTextFig {
    width: 90%;
    height: auto;
    margin: 0 0 20px 0;
}

.compreTextFig img {
    width: 100%;
    height: 100%;
}

.compreNationText {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 1px 0;
    line-height: normal;
    font-weight: 700;
    font-family: monolight;
}

.compreNatioSmallText {
    font-size: 17px;
    font-weight: 500;
    line-height: normal;
    margin: 0 0 1px 0;
}

.compreNatioSmallText:last-child {
    margin: 0;
}

.compreDeskFig {
    width: 50%;
    height: 88%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
}

.compreDeskFig img {
    width: 100%;
    height: 100%;
}



.landChartFig {
    width: 88%;
    height: 70%;
    margin: 0 auto;
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
}

.landingfrst {
    width: 100%;
    height: 100%;
}

.landingfirst {
    width: 100%;
    height: 480px;
}

.landingfirst img {
    width: 100%;
    height: 100%;
}

.landingsecond {
    width: 100%;
    height: 100%;
}

.landingscnd {
    width: 100%;
    height: 480px;
}

.landingscnd img {
    width: 100%;
    height: 100%;
}

.landChartFig img {
    width: 100%;
    height: 100%;
}

.landingfv {
    width: 100%;
    height: 100%;
}

.landingfive {
    width: 100%;
    height: 500px;
}

.landingfive img {
    width: 100%;
    height: 100%;
}

.landingsx {
    width: 100%;
    height: 100%;
}

.landingsix {
    width: 100%;
    height: 510px;
}

.landingsix img {
    width: 100%;
    height: 100%;
}

.landingsvn {
    width: 100%;
    height: 100%;
}

.landingseven {
    width: 100%;
    height: 510px;
}

.landingseven img {
    width: 100%;
    height: 100%;
}

.landingeght {
    width: 100%;
    height: 100%;
}

.landingeight {
    width: 100%;
    height: 510px;
}

.landingeight img {
    width: 100%;
    height: 100%;
}

.landingnin {
    width: 100%;
    height: 100%;
}

.landinine {
    width: 100%;
    height: 510px;
}

.landinine img {
    width: 100%;
    height: 100%;
}

.landingtn {
    width: 100%;
    height: 100%;
}

.landingten {
    width: 100%;
    height: 510px;
}

.landingten img {
    width: 100%;
    height: 100%;
}

.landingelven {
    width: 100%;
    height: 100%;
}

.landingeleven {
    width: 100%;
    height: 510px;
}

.landingeleven img {
    width: 100%;
    height: 100%;
}

.landingtwlv {
    width: 100%;
    height: 100%;
}

.landingtwelve {
    width: 100%;
    height: 510px;
}

.landingtwelve img {
    width: 100%;
    height: 100%;
}

.landingthrtn {
    width: 100%;
    height: 100%;
}

.landingthritten {
    width: 100%;
    height: 510px;
}

.landingthritten img {
    width: 100%;
    height: 100%;
}

.landingfrtn {
    width: 100%;
    height: 100%;
}

.landingfrteen {
    width: 100%;
    height: 510px;
}

.landingfrteen img {
    width: 100%;
    height: 100%;
}

.landingfrtn {
    width: 100%;
    height: 100%;
}

.landingfrteen {
    width: 100%;
    height: 510px;
}

.landingfrteen img {
    width: 100%;
    height: 100%;
}

.slidesectioncontainer {
    width: 100%;
    max-width: 80%;
    margin: 20px auto;
}

.landunfginf {
    width: 100%;
    height: 424px;
}

.landunfginf img {
    width: 100%;
    height: 100%;
}

.slidelandingsection .slick-slide {
    padding: 0px 10px;
}

/* .landingftn{
    background-color: rgb(5 28 52);
    width: 100%;
    height: 100%;
    padding: 40px 0px;
} */
.landingfeetine {
    width: 80%;
    height: 510px;
    margin: auto;
}

.landingfeetine img {
    width: 100%;
    height: 100%;
}

.bglanffrm {
    width: 100%;
    max-width: 30%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 10px;
    object-fit: cover;
}

.cnthjdiv {
    padding: 10px 20px;
}

.topheaderland {
    background-color: transparent;
    width: 100%;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-bottom: 1px solid #d3d3d3;
}

.rtxtpphgt {
    text-align: end;
    font-size: 14px;
    font-family: "NoirPro-Lightitalic";
    color: #000;
    font-weight: 500;
    text-transform: uppercase;
}

.cnthjdiv {
    padding: 30px;
}

.frmldnnflx {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    background-color: rgb(255, 255, 255);
    height: 374px;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 10px;
    /* border: 1px solid #000; */
}

.lsjkk {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
    padding: 10px 0px;
}

.lsjkk img {
    width: 20px;
    height: 16px;
}

.fltuupp {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    font-family: "Noirpro-blod";
    color: #fff;
}

.ttxybjkk {
    font-size: 28px;
    line-height: 34px;
    font-weight: 600;
    font-family: "Noirpro-Bold";
    color: #fff;
    padding-bottom: 10px;
}

.filklltxyt {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: "Noirpro-Light";
    color: #fff;
}

.nametotal {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    gap: 2%;
    padding: 20px 0px 20px 0px;

}

.fhhdiv {
    width: 100%;
    max-width: 100%;
    padding: 20px;
    /* display: flex;
    justify-content: flex-start;
    align-items: center; */
}

.jkjkda {
    padding: 20px 0px;
}

.employAdvoSection {
    width: 100%;
    height: 630px;
    /* position: relative; */
}

.fdrtydiv {
    width: 100%;
    max-width: 49%;
}

.fdrtydiv label {
    width: 100%;
    max-width: 50%;
    margin-bottom: 0px;
    font-size: 14px;
    font-family: "NoirPro-Lightitalic";
}

.dokwebbjh {
    font-size: 18px;
    font-family: "NoirPro-Lightitalic";
    line-height: 22px;
    color: #000;
    font-weight: 600;
    padding-bottom: 10px;
}

.fdrtydiv input {
    border-bottom: 1px solid #000;
    border-top: 0px solid #000;
    border-left: 0px solid #000;
    border-right: 0px solid #000;
    width: 95%;
    /* border: none; */
}

.dubbjradg {
    padding: 20px 0px;
}

.gjhjflxslet {
    display: flex;
    justify-content: flex-start;
    gap: 5%;
}

.jkjkda input {
    border-bottom: 1px solid #000;
    border-top: 0px solid #000;
    border-left: 0px solid #000;
    border-right: 0px solid #000;
    width: 95%;
}

/* .tfggpt input{
    padding: 0px 10px;
} */
.sfllmdbj {
    width: 100%;
    padding: 20px 0px;
}

.jkjkda label {
    width: 100%;
}

.advocacyInFig {
    width: auto;
    height: 100%;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
}

.advocacyInFig img {
    width: 100%;
    height: 100%;
}

.advocacyTextsDiv {
    width: 50%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
}

.advoTextHead {
    font-size: 48px;
    font-weight: 600;
    margin: 0 0 25px 0;
    line-height: normal;
}

.tfggptdiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1%;
    width: 100%;
}

.tfggptdiv label {
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    font-family: "NoirPro-Lightitalic";
    margin-bottom: 0px;
}

.jkjkda label {
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    font-family: "NoirPro-Lightitalic";
}

.advoTextPara {
    font-size: 21px;
    font-weight: 500;
    margin: 0 0 60px 0;
}

.advoTextPara:last-child {
    margin: 0;
}

.networkLandSection {
    width: 100%;
    height: 630px;
    position: relative;
}

.netWrkBackFig {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.netWrkBackFig img {
    width: 100%;
    height: 100%;
}

.netWorkBackTextsDiv {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
}

.networkInFig {
    width: auto;
    height: 100%;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
}

.networkInFig img {
    width: 100%;
    height: 100%;
}

.networkAllTextsDiv {
    width: 50%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
}

.netWrkTextHead {
    font-size: 50px;
    font-weight: 600;
    text-align: right;
    color: #fff;
    margin: 0 0 20px 0;
    line-height: normal;
}

.netWrkSmallTexts {
    font-size: 20px;
    color: #fff;
    margin: 0 0 80px 0;
    text-align: right;
}

.netWrkSmallTexts:last-child {
    margin: 0;
}

.onboardLandSection {
    width: 100%;
    height: 630px;
    position: relative;
}

.onboardFig {
    width: auto;
    height: 100%;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
}

.onboardFig img {
    width: 100%;
    height: 100%;
}

.onboardTextsDiv {
    width: 50%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
}

.onbrdHireHead {
    font-size: 48px;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 20px 0;
}

.onbrdHireTexts {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 60px 0;
}

.onbrdHireTexts:last-child {
    margin: 0;
}

.employVoiceSection {
    width: 100%;
    height: 630px;
    position: relative;
    background-color: #10273c;
}

.personTabSection {
    padding: 40px 0;
}

.landFtLeftFig {
    width: 80%;
    height: auto;
    margin: 0;
}

.landFtLeftFig img {
    width: 100%;
    height: 100%;
}

.landFtRightFig {
    width: 220px;
    min-width: 220px;
    height: auto;
    margin: 0 15px 0 0;
}

.landFtRightFig img {
    width: 100%;
    height: 100%;
}

.landRightFtTextDiv {
    display: flex;
    align-items: center;
    padding: 50px 30px;
    width: 80%;
    border-radius: 20px;
    box-shadow: 0 0 5px 0 #555454d5;
}

.strLandFigDiv {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}

.landFtName {
    font-size: 22px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 10px 0;
    line-height: normal;
}

.landFtPost {
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    margin: 0 0 10px 0;
    line-height: normal;
}

.landFtCmpny {
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    margin: 0;
    line-height: normal;
}

.strcmntFig {
    width: 125px;
    min-width: 125px;
    height: 125px;
    margin: 0 0 0 30px;
}

.strcmntFig img {
    width: 100%;
    height: 100%;
}

.mainLandFtTextDiv {
    display: flex;
    justify-content: center;
}

/* .footerLanding {
    border: 2px solid red;
} */

.firstFooterPart {
    display: flex;
    justify-content: center;
}

.conctStrt {
    width: 40%;
    margin-bottom: 20px;
}

.ftprtcCntcText {
    text-align: center;
    line-height: normal;
    font-size: 28px;
    text-transform: capitalize;
    font-weight: 600;
    margin: 0 0 5px 0;
}

.strtJrnyText {
    font-size: 18px;
    text-align: center;
    line-height: normal;
    font-weight: 600;
    margin: 0 0 5px 0;
}

.iagreePart {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
}

.iagreePart label {
    margin-bottom: 0;
    margin-left: 5px;
    font-size: 16px;
    font-weight: 600;
}

.iagreePart label a {
    color: rgb(255, 192, 0);
}

.iagreePart label a:hover {
    color: rgb(255, 192, 0);
    text-decoration: none;
}

.footLndBtnDiv input {
    width: 90%;
    padding: 6px 10px;
    border-radius: 5px;
    border: 1px solid rgb(31, 78, 121);
}

.footLndBtnDiv button {
    background-color: rgb(246, 174, 30);
    padding: 5px 0;
    border-radius: 5px;
    width: 8%;
    font-size: 20px;
    color: #000;
    border: 0;
}

.footLndBtnDiv button i {
    transform: rotate(50deg);
}

.footLndBtnDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.secondFooterPart {
    width: 100%;
    height: 400px;
    position: relative;
}

.sndFtPrtFig {
    width: 100%;
    height: 100%;
    margin: 0;
}

.sndFtPrtFig img {
    width: 100%;
    height: 100%;
}

.secondInnerFtDiv {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
}

.footerLandInDiv {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 70px;
    margin-bottom: 40px;
}

.footerLandInDiv>div {
    width: 20%;
}

.footLeftInIconsAn {
    color: #ffffffa9;
    font-size: 30px;
    transition-duration: 300ms;
    transition-property: all;
}

.footLeftInIconsAn:hover {
    color: #fff;
    text-decoration: none;
}

.footerLfticonUl {
    display: flex;
    padding: 0;
    margin: 0;
}

.footerLfticonLi {
    margin-right: 15px;
    list-style-type: none;
}

.footerLfticonLi:last-child {
    margin-right: 0;
}

.ftLandFig {
    width: 200px;
    height: auto;
    margin: 0 0 40px 0;
}

.ftLandFig img {
    width: 100%;
    height: 100%;
}

.allFootLandHead {
    font-size: 20px;
    text-transform: capitalize;
    color: #fff;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.footLndOtherUl {
    padding: 0;
    margin: 0;
}

.footLndOtherLi {
    list-style-type: none;
    margin-bottom: 8px;
}

.footLndOtherLi:last-child {
    margin-bottom: 0;
}

.footLndOtherLiAn {
    font-size: 17px;
    font-weight: 700;
    color: #ffffffbd;
    transition-property: all;
    transition-duration: 300ms;
}

.footLndOtherLiAn:hover {
    color: #fff;
    text-decoration: none;
}

.ftrUl {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
}

.ftrLi {
    margin-right: 15px;
}

.ftrLi:last-child {
    margin-right: 0;
}

.ftrLiAn {
    color: #ffffffbd;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 600;
    transition-property: all;
    transition-duration: 300ms;
}

.ftrLiAn:hover {
    color: #fff;
    text-decoration: none;
}

.rescLandHeadBtn {
    background-color: rgb(31, 78, 121);
    border: 0;
    color: #fff;
    font-size: 17px;
    padding: 6px 10px;
    display: none;
}

.sidebarSection {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100vh;
    z-index: 99;
    background-color: rgb(31, 78, 121);
    padding: 20px 0;
    transform: translate(-350px, 0);
    transition-property: all;
    transition-duration: 1s;
}

.sideHeadLogo {
    width: 50px;
    height: 45px;
    margin: 0;
}

.sideHeadLogo img {
    width: 100%;
    height: 100%;
}

.sideHeadDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 25px;
}

.sideHeadCnclBtn {
    background-color: transparent;
    border: 0;
    font-size: 25px;
    color: #fff;
}

.sideBarUl {
    padding: 0;
    margin: 0;
}

.sideBarLi {
    padding: 10px;
    border-top: 1px solid #fff;
}

.sideBarLi:last-child {
    border-bottom: 1px solid #fff;
}

.sideBarLiAn {
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
}

.sbnhj {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 80px 0px 0px 0px;
    gap: 10%;
}

.jjatxtyt i {
    color: #fff;
    font-size: 14px;
}

.sideBarLiAn:hover {
    color: #fff;
    text-decoration: none;
}


/*New Landing Page Design Start */
.landingpagefirstsection {
    width: 100%;
    height: auto;
    background-color: #fbd500;
    /* margin-bottom: 2px; */
}

.landingimagefrstdiv {
    width: 100%;
    height: 435px;
}

.landingimagefrstdiv img {
    width: 100%;
    height: 100%;
}

.landingimagetxtdivfrst {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0px 0px 0px;
    width: 100%;
}

.landingfrstimagediv {
    width: 100%;
    max-width: 45%;
}

.landingfrsttxtdiv {
    width: 100%;
    max-width: 46%;
    margin-bottom: 55px;
}

.crtnewpstimg {
    display: flex;
    justify-content: center;
    align-items: center;
}

.crtnewpstimg img {
    width: 100%;
    height: 100%;
}

.happytxt {
    font-size: 55px;
    font-weight: bolder;
    line-height: 60px;
    font-family: NoirProRegular;
    color: #000;
    text-align: end;
    letter-spacing: 3px;
}

.hepltxt {
    font-size: 17px;
    font-weight: 600;
    font-family: montlight;
    line-height: 25px;
    color: #000;
    line-height: 25px;
    text-align: end;
}

.playttxcy {
    font-size: 55px;
    font-weight: bolder;
    line-height: 54px;
    font-family: Montheavy;
    color: #ffffff;
    /* text-align: end; */
    letter-spacing: 3px;
}

.happytxtdivemp {
    font-size: 55px;
    font-weight: bolder;
    line-height: 54px;
    font-family: Montheavy;
    color: #ffffff;
    text-align: end;
    letter-spacing: 3px;
}

.happytxtdiv {
    width: 100%;
    max-width: 80%;
    margin: 20px 0px;
}

.hepltxtdiv {
    width: 100%;
    max-width: 78%;
}

.crtnewpstimg {
    width: 40px;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.crtnewpstimg img {
    width: 100%;
    height: 100%;
}

.landingpagesecondsection {
    width: 100%;
    height: auto;
    /* padding-bottom:20px ; */
    background-color: #fcbb00;
}

.landingscndimagediv {
    width: 100%;
    max-width: 45%;
}

.landingimagesecnddiv {
    width: 100%;
    max-width: 100%;
    height: 390px;
}

.landingimagesecnddiv img {
    width: 100%;
    height: 100%;
}

.landingmangementtxt {
    width: 100%;
    max-width: 76%;
}

.lamdingemployeetxt {
    width: 100%;
    max-width: 77%;
}

.mangetxtdiv {
    font-size: 46px;
    font-weight: bolder;
    line-height: 54px;
    font-family: Montheavy;
    color: #ffffff;
    /* text-align: end; */
    letter-spacing: 3px;
}

.emplyetxtdiv {
    font-size: 19px;
    font-weight: 600;
    font-family: montlight;
    color: #ffffff;
    line-height: 25px;

}

.landingtxtdivmain {
    width: 100%;
    max-width: 66%;
    margin-bottom: 39px;
}

.lajkmksnddyv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.labdChartImgSection {
    width: 100%;
    height: 100vh;
    background-color: #00bfff;
    position: relative;
}

.sectionfrthflx {
    display: flex;
    justify-content: center;
    align-items: center;
}

.landinghcmptimg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.landinghcmptimg img {
    width: 100%;
    height: 100%;
    margin: auto;
}

.pointtxtdiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8%;
}

.pouimnabstlydiv {
    position: absolute;
    /* top: 30px; */
    left: -26%;
    top: 6%;
}

.pouimnabstlydiv:nth-child(2) {
    position: absolute;
    top: 25%;
    left: -26%;
}

.pouimnabstlydiv:nth-child(3) {
    position: absolute;
    top: 45%;
    left: -26%;
}

.pouimnabstlydiv:nth-child(4) {
    position: absolute;
    top: 65%;
    left: -26%;
}

.pouimnabstlydiv:nth-child(5) {
    position: absolute;
    top: 83%;
    left: -26%;
}

.pouimnabstlyrigtdiv {
    position: absolute;
    right: -28%;
    top: 3%;
}

.pouimnabstlyrigtdiv:nth-child(2) {
    position: absolute;
    right: -28%;
    top: 25%;
}

.pouimnabstlyrigtdiv:nth-child(3) {
    position: absolute;
    right: -28%;
    top: 43%;
}

.pouimnabstlyrigtdiv:nth-child(4) {
    position: absolute;
    right: -28%;
    top: 63%;
}

.pouimnabstlyrigtdiv:nth-child(5) {
    position: absolute;
    right: -30%;
    top: 79%;
}

.txtnmbr {
    font-size: 41px;
    font-weight: 700;
    line-height: 43px;
    color: #fff;
    font-family: montheavy;
}

.txtspltf {
    font-size: 19px;
    font-weight: 700;
    line-height: 23px;
    color: #fff;
    font-family: montlight;
    letter-spacing: 0.5px;
    white-space: nowrap;
}

.landingpagefrthsection {
    width: 100%;
    height: auto;
    /* padding-bottom: 20px; */
    background-color: #6a1f63;
}

.happytxt {
    font-size: 55px;
    font-weight: bolder;
    line-height: 60px;
    font-family: Montheavy;
    color: #000000;
    text-align: end;
    letter-spacing: 3px;
}

.wningtxt {
    font-size: 19px;
    font-weight: 800;
    line-height: 26px;
    font-family: Monoheavy;
    color: #ffffff;
    text-align: end;
}

.eltxtpp {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    font-family: Monolight;
    color: #ffffff;
    text-align: end;
}

.landingpagesxthsection {
    width: 100%;
    height: auto;
    /* padding-bottom: 20px; */
    background-color: #61d5d1;
}

.landingsxthscttmndiv {
    width: 100%;
    max-width: 50%;
    margin-bottom: 40px;
}

.landingsxthimgdiv {
    width: 100%;
    max-width: 49%;
}

.empyeediv {
    width: 100%;
    max-width: 60%;
}

.advcttxt {
    font-size: 48px;
    font-weight: bolder;
    line-height: 60px;
    font-family: Montheavy;
    color: #000000;
    /* text-align: end; */
    letter-spacing: 3px;
}

.msttxtdiv {
    font-size: 19px;
    font-weight: bolder;
    line-height: 27px;
    font-family: Monoheavy;
    color: #000000;
}

.divmainmsttxt {
    width: 100%;
    max-width: 60%;
    margin: 20px 0px;
}

.pltfrmdivxtx {
    width: 100%;
    max-width: 60%;
}

.playttxy {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    font-family: Montlight;
    color: #000000;
}

.landingpageseventhsection {
    width: 100%;
    height: auto;
    background-color: #fe8a00;
}

.poewrrct {
    width: 100%;
    max-width: 72%;
}

.landingimagesvnthdiv {
    width: 100%;
    height: 389px;
}

.landingimagesvnthdiv img {
    width: 100%;
    height: 100%;
}

.landingimgnnth {
    width: 100%;
    height: auto;
    background-color: #91215b;
}

.landingimgtensctyhh {
    width: 100%;
    height: auto;
    background-color: #25e100;
}

.landingimgelevntsctyhh {
    width: 100%;
    height: auto;
    background-color: #a099d3;
    ;
}

.lamndibdivmain {
    width: 100%;
    max-width: 67%;
}

.imghjsgh {
    width: 100%;
    max-width: 100%;
    height: 463px;
}

.imghjsgh img {
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.landingimgtwselvesctyhh {
    width: 100%;
    height: auto;
    background-color: #fedd00;
}

.lanfifhhspghdiv {
    width: 100%;
    max-width: 58%;
    margin-bottom: 55px;
}

.landingtwlvtxtdiv {
    width: 100%;
    max-width: 50%;
}

.landingtwlvimgdiv {
    width: 100%;
    max-width: 50%;
}

.empyeedivtwlxt {
    width: 100%;
    max-width: 74%;
    margin: 15px 0px;
}

.divmainmsttxttwl {
    width: 100%;
    max-width: 74%;
}

.pltfrmdivxtxtwl {
    width: 100%;
    max-width: 74%;
}

.landingimgthrteendiv {
    width: 100%;
    height: auto;
    background-color: #9fd2d4;
}

.perfrmtxt {
    font-size: 55px;
    font-weight: bolder;
    line-height: 60px;
    font-family: Montheavy;
    color: #000000;
    text-align: end;
    letter-spacing: 3px;
}

.celbratrtxt {
    font-size: 23px;
    font-weight: bolder;
    line-height: 27px;
    font-family: Monolight;
    color: #000000;
    text-align: end;
}

.pathtxtort {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    font-family: Monolight;
    color: #000000;
    text-align: end;
}

.prmtxtdiv {
    width: 100%;
    max-width: 74%;
    margin: 15px 0px;
}

.brtytydiv {
    width: 100%;
    max-width: 74%;
}

.tortdiv {
    width: 100%;
    max-width: 74%;
}

.iimagedivman {
    width: 100%;
    max-width: 55%;
}

.txtlanjdimfrst {
    width: 100%;
    max-width: 40%;
}

.landingimgthrfrtenndiv {
    width: 100%;
    height: auto;
    background-color: #818181;
}

.landinbulleuib {
    width: 100%;
    max-width: 46%;
}

.bulleuntxtlandinuimg {
    width: 100%;
    max-width: 50%;
}

.brddiv {
    width: 100%;
    max-width: 60%;
    margin: 15px 0px;
}

.lgfrcmndiv {
    width: 100%;
    max-width: 60%;
}

.buletdiv {
    width: 100%;
    max-width: 60%;
}

.bordtxt {
    font-size: 55px;
    font-weight: bolder;
    line-height: 60px;
    font-family: Montheavy;
    color: #ffffff;
    /* text-align: end; */
    letter-spacing: 3px;
}

.loufcmntxt {
    font-size: 19px;
    font-weight: bolder;
    line-height: 27px;
    font-family: Monoheavy;
    color: #ffffff;
}

.bulletintxt {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    font-family: MonoLight;
    color: #ffffff;
}

.happytxtdivafnty {
    width: 100%;
    max-width: 73%;
    margin: 15px 0px;
}

.poewrrctdiv {
    width: 100%;
    max-width: 73%;
}

.hepltxtdivafnty {
    width: 100%;
    max-width: 73%;
}

.landingafntyyxy {
    width: 100%;
    max-width: 38%;
    margin-bottom: 55px;
}

.landingpageeighthsection {
    width: 100%;
    height: auto;
    background-color: #439b00;
}

.landingempltdyiv {
    width: 100%;
    max-width: 46%;
}

.landingtxtemplt {
    width: 100%;
    max-width: 46%;
}

.emplyrtxt {
    width: 100%;
    max-width: 70%;
    margin: 15px 0px;
}

.giijtxt {
    width: 100%;
    max-width: 70%;
}

.elyuyppytxt {
    width: 100%;
    max-width: 70%;
}

.networkingfrstdiv {
    width: 100%;
    max-width: 42%;
    margin-bottom: 55px;
}

.happytxtdivnet {
    width: 100%;
    max-width: 75%;
    margin: 20px 0px;
}

.poewrrctnet {
    width: 100%;
    max-width: 75%;
}

.hepltxtdivnet {
    width: 100%;
    max-width: 75%;
}

.lanfdingdivlst {
    background-color: rgb(5 28 52);
    width: 100%;
    height: auto;
    padding: 40px 0px;
}

.eliteroyflx {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
}

.incrsemply {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%
}

.ytxtimglandinwhh {
    width: 100%;
    max-width: 24%;
}

.landffttnh {
    width: 100%;
    max-width: 31%;
    min-width: 29%;
    height: 92px;
}

.landffttnh img {
    width: 100%;
    height: 100%;
}

.txtdivlastland p {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
}

.elutyriytxt {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
}

.eliterroy {
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    color: #fff;
    width: 100%;
    max-width: 68%;
}

.txtdivlastland {
    width: 100%;
    max-width: 47%;
}

.landineltmaibtstflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.txtallflxr {
    width: 100%;
    max-width: 44%;
}

.strendp {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: #fff;
    font-family: NoirProLight;
}

.imgeltdiv {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 40%;
}

.emplttys {
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
    color: #fff;
    font-family: NoirProLight;
}

.diveemk {
    width: 100%;
    max-width: 40%;
}

.companynameimgwh {
    width: 100px;
    height: 80px;
}

.companynameimgwh img {
    width: 100%;
    height: 100%;
}

/* 
.compreAllNatioTextsDiv {
    padding-left: 30px;
} */

/*New Landing Page Design End */


/* Final Landing Page Design Start */
.landingheadermain {
    width: 100%;
    background: linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%);
    height: auto;
    position: absolute;
    top: 0;
    z-index: 9;
    top: 0;
    right: 0;
}

.landingheadermainactv {
    width: 100%;
    background: linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%);
    height: auto;
    /* padding: 20px 0px; */
    position: fixed;
    z-index: 9;
    top: 0;
    right: 0;
}

/* .landingheadermain.actv{
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: auto;
    background-color: transparent;
    z-index: 99;
} */

.emplyedivlogoimgdivbg {
    background-color: #fff;
    padding: 10px 40px;
    border-bottom-right-radius: 10px;
}

.emplyedivlogoimg {
    width: 100%;
    max-width: 320px;
    min-width: 320px;
    height: 114px;

}

.emplyedivlogoimg img {
    width: 100%;
    height: 100%;
}

.headelogomenubardivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menudivmainbdr {
    width: 100%;
    max-width: 70%;
    padding: 5px 90px;
    display: flex;
    align-items: baseline;
    column-gap: 14px;

}

.menudivbrdr {
    border: 2px solid #fff;
    border-radius: 20px;
    width: 100%;
    /* padding: 2px 0px; */
}

.landingfistmainfinal {
    position: relative;
    width: 100%;
    height: auto;
    background: linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%);
    padding: 160px 0px 160px 0px;

}

.menulnkhdrdivmn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
    list-style-type: none;
}

.navglnktxtppp {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    font-family: "Montserratsemi";
    line-height: 18px;
    padding: 15px;
}

.navglnktxtppp:hover {
    color: #fff;
}

.navglnktxtppp:hover {
    text-decoration: none;
}

.navglnktxtppp.active {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-family: "Montserratsemi";
    line-height: 13px;
    background-color: #fff !important;
    padding: 9px 20px;
    border-radius: 21px;
}

.landingpageconatiner {
    width: 100%;
    max-width: 86%;
    margin: 0 auto;
}

.landingfinalflxfst {
    display: flex;
    justify-content: flex-start;
    column-gap: 6%;
    align-items: center;
}

.landingfsrtsctiondiv {
    width: 100%;
    max-width: 48%;
    position: relative;
}
.spaclanimationdthrd{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
}

.landingsctionfrstpp {
    font-family: GTSuper;
    font-size: 67px;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    /* width: 100%; */
    /* max-width: 656px; */
    word-spacing: 8px;
    letter-spacing: 1px;
}

.navglnktxtpppbtn {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-family: "Montserratsemi";
    line-height: 13px;
    background-color: #fff !important;
    padding: 10px 20px;
    border-radius: 21px;
    border: none;

}

.landingfsrtsctntxtpp {
    font-size: 19px;
    color: #fff;
    font-family: poppinslight;
    line-height: normal;
    margin: 35px 0px;
    /* width: 100%; */
    /* max-width: 490px; */
    font-weight: 500;
}

.landingscndsctiondiv {
    width: 100%;
    max-width: 45%;
  
}

.landibimhdmmain {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 491px;
}
.manageFigDiv {
    width: auto;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    /* z-index: -1; */
}

.landibimhdmmain img {
    width: 100%;
    height: 100%;
}

.nameemldivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
}

.namedibsbjk {
    width: 100%;
}

.namedibsbjk input {
    width: 100%;
    padding: 8px 30px 8px 10px;
    background-color: #fff;
    border-radius: 5px;
    border: none;
}

.demobtn {
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-weight: bold;
    font-family: Montserratsemi;
    border: none;
    margin: 10px 0px;
    background-color: #ffa810;
    padding: 10px;
    border-radius: 5px;
}

.landingscndmainfinal {
    width: 100%;
    height: auto;
    background: #fff;
    padding: 63px 0px 30px 0px;
    display: block;
}

/* 
.landingsctionscndpagepp {
    font-family: GTSuper;
    font-size: 67px;
    font-weight: 600;
    line-height: normal;
    color: #000000;
    word-spacing: 8px;
    letter-spacing: 1px;
} */

.landingsctionfrstpp span {
    font-family: GTSuper;
    font-size: 67px;
    font-weight: 600;
    line-height: 55px;
    color: #ffa810;
    word-spacing: 8px;
    letter-spacing: 1px;
}

.landingfsrtscndtxtppgb {
    font-size: 17px;
    color: #000000a9;
    font-family: poppinslight;
    line-height: normal;
    margin: 35px 0px;
    font-weight: 600;
}

.landingfsrtscndtxtppgb span {
    font-size: 17px;
    color: #000;
    font-family: poppinslight;
    line-height: normal;
    font-weight: 600;
}

.landingfsrtsctiondiv {
    width: 100%;
    max-width: 52%;
    align-self: end;
    /* position: relative;
    z-index: 5; */
}

.landingthrddmainfinal {
    width: 100%;
    height: auto;
    background: #fff;
    /* padding: 40px 0px; */
    padding: 33px 0px 33px 0px;
    display: block;
}

.landingthrddivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.landingthrddivwhhh {
    width: 100%;
    max-width: 30%;
}

.landingthrddivpppttp {
    font-family: GTSuper;
    font-size: 67px;
    font-weight: 600;
    line-height: normal;
    color: #464646;
    word-spacing: 8px;
    letter-spacing: 1px;
}

.marketvoicedivehh {
    width: 100%;
    max-width: 60%;
}

.mrktvcdib {
    /* border: 1px solid #ccc; */
    box-shadow: 0px 7px 9px 0px #ccc;
    padding: 10px;
    color: #000;
    font-family: notosans;
    font-size: 16px;
    font-weight: 600;
    border-radius: 36px;
    width: 100%;
    max-width: 30%;
    text-align: center;
    margin: auto;
    background-color: #fff;
}

.mrktvcdivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
}

.landingthrddmainfinal text {
    font-size: 15px;
    font-family: Montserratsemi;
    font-weight: 500
}

.mrktvcdivwhfgdfdiv {
    width: 100%;
    max-width: 28%;
}

.mrktcvdtstcp {
    font-size: 13px;
    font-family: Montserratsemi;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin-top: -39px;
}

.mrktvcdivoenghdjh {
    width: 100%;
    max-width: 89%
}

.marketvoicedidvtwodivmain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 43px 0px 0px 0px;
}

.marketdoibvbgyklstbgk {
    background: linear-gradient(90deg, rgba(255, 198, 159, 1) 0%, rgba(255, 168, 27, 1) 54%);
    width: 100%;
    max-width: 48%;
    border-radius: 35px;
    padding: 50px;
}

.marketdivwscvjnkkl {
    background-color: #c9e6fc;
    width: 100%;
    max-width: 48%;
    border-radius: 35px;
    padding: 48px;
}

.marketdivfrsttxtppp {
    font-family: poppinslight;
    font-size: 17px;
    line-height: normal;
    color: #000;
    font-weight: 600;
}

.landingfrthdmainfinal {
    width: 100%;
    height: auto;
    padding: 33px 0px 30px 0px;
    /* background: linear-gradient(90deg, rgba(255, 198, 159, 1) 0%, rgba(255, 168, 27, 1) 54%); */
    background-color: #fff;
}

.landingffthdmainfinalbg {
    width: 100%;
    background: linear-gradient(90deg, rgba(255, 198, 159, 1) 0%, rgba(255, 168, 27, 1) 54%);
    border-radius: 15px;
    padding: 153px 76px 153px 76px;
}

.secondsdivcntinflandib {
    width: 100%;
    max-width: 94%;
    margin: 0 auto;
}

.landingfrthdmainfinalbg {
    width: 100%;
    background: linear-gradient(90deg, rgba(255, 198, 159, 1) 0%, rgba(255, 168, 27, 1) 54%);
    border-radius: 15px;
    padding: 50px 76px 50px 76px;
}

.emplyeexprectunftewrkflx {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 68px;
    padding: 40px 0px 25px 0px;
}

.emplotexpertblubrdrdiv {
    width: 100%;
    max-width: 11%;
    background: linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%);
    border: 3px solid #fff;
    border-radius: 10px;
    padding: 10px;
}

.emplyetxtppdivppp {
    font-family: Montserratextra;
    color: #fff;
    text-align: center;
    font-weight: 500;
    line-height: normal;
    height: 45px;
}

.prcghjjdthvxpp {
    font-family: Montserratextra;
    font-size: 60px;
    line-height: normal;
    font-weight: 700;
    color: #fff;
    text-align: center;
}

.prcghjjdthvxpp span {
    font-size: 24px;
}

.hrldrtuppxgj {
    font-family: Montserratsemi;
    font-size: 14px;
    line-height: normal;
    color: #fff;
    font-weight: 600;
    text-align: center;
}

.chrtptytxtpp {
    text-align: center;
    font-size: 25px;
    color: #000000a9;
    font-weight: 600;
    font-family: Montserratsemi;
    line-height: normal;

}

.landingfifthdmainfimnal {
    width: 100%;
    height: auto;
    padding: 33px 0px 30px 0px;
    background-color: #fff;
}

.landingfifthdmainfinalbg {
    width: 100%;
    background: linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%);
    border-radius: 15px;
    padding: 43px 102px 50px 102px;
}

.landingthrddivpppttpspan {
    font-family: GTSuper;
    font-size: 45px;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    word-spacing: 8px;
    letter-spacing: 1px;
}

.landingsevnthhdmainfimnal {
    width: 100%;
    height: auto;
    padding: 33px 0px 30px 0px;
    background-color: #fff;
}

.landingsixthdmainfinalbg {
    width: 100%;
    background: linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%);
    border-radius: 15px;
    padding: 79px 120px 79px 152px;
}

.landingsixthdivmainflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 7%;
}

.frctlsypptxtjnj {
    font-family: Montserratsemi;
    font-size: 20px;
    line-height: normal;
    font-weight: 500;
    color: #fff;
}

.landingsixtghjtxtyimdivgg {
    width: 100%;
    max-width: 37%;
}

.smrtctpppbhopyyctt {
    font-family: Montserratsemi;
    font-size: 19px;
    line-height: normal;
    font-weight: 600;
    color: #fff;

}

.chrthbopjioxty {
    font-family: Montserratsemi;
    font-size: 25px;
    line-height: normal;
    font-weight: 600;
    color: #fff;
}

.smrtctpppbh {
    padding-top: 82px;
}

.landingssixthleftdivimgui {
    width: 100%;
    max-width: 48%;
    min-width: 48%;
}

.startcementimghjj {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 529px;
}

.startcementimghjj img {
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.ecxpericelanfibhlododivwhtf {
    width: 100%;
    max-width: 37%;
}

.logopmainpagedibjhk {
    width: 100%;
    max-width: 100%;
    height: 185px;
}

.logopmainpagedibjhk img {
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.ecxpericelanfibhlodoftxtyflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 4%;
    padding-top: 30px;
}

.experibvdttxpbj {
    font-family: poppinslight;
    font-size: 17px;
    line-height: 24px;
    color: #fff;
    font-weight: 500;
}

.ecxpericelanfibhlododivtxtppdh {
    width: 100%;
    max-width: 40%;
}

.startddcntbudiv {
    background: linear-gradient(90deg, rgba(255, 198, 159, 1) 0%, rgba(255, 168, 27, 1) 54%);
    color: #000;
    font-size: 16px;
    list-style: normal;
    padding: 5px 42px;
    border-radius: 30px;
    border: none;
    font-weight: 700;
    margin: 10px 0px;
}

.landingeighthhdmainfimnal {
    width: 100%;
    height: auto;
    padding: 33px 0px 60px 0px;
    background-color: #fff;
}

.stryrfvjrtfrmdivflsx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tltxyupghlity {
    font-size: 19px;
    color: #000000a9;
    font-family: poppinslight;
    line-height: normal;
    margin: 35px 0px;
    font-weight: 500;

}

.strtjrnydivbgkphwh {
    width: 100%;
    max-width: 40%;
    align-self: flex-end;
}

.strtjrnydivdfrmnkklk {
    width: 100%;
    max-width: 46%;
}

.manmembldnjwgj {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
}

.manjekjipnpt {
    width: 100%;
}

.manjekjipnpt input {
    width: 100%;
    max-width: 100%;
    padding: 6px 40px 6px 20px;
    border: none;
    background-color: #fff;
    border-radius: 10px;
    margin: 10px 0px;
}

.manjekjipnpt textarea {
    width: 100%;
    max-width: 100%;
    padding: 6px 40px 6px 20px;
    border: none;
    background-color: #fff;
    border-radius: 10px;
    height: 113px;
    resize: none;
    border: none;
    margin: 10px 0px;
}

.bkdibfgbnmrjhk {
    width: 100%;
    background: linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%);
    padding: 14px 40px 14px 20px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    line-height: normal;
    font-family: Montserratsemi;
    border: none;
    border-radius: 10px;
}

.datyrgghtxpgh {
    font-size: 10px;
    font-family: poppinslight;
    line-height: 15px;
    color: #000;
    font-weight: 500;
    padding: 8px 0px;
}

.landingsxthdmainfinal {
    width: 100%;
    height: auto;
    padding: 33px 0px 30px 0px;
    background-color: #fff;
}

.landingsxthdivmainflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 11%;
}

.landingdibfsxthfrstsectn {
    width: 100%;
    max-width: 42%;
    align-self: flex-start;
}

.hgnrtgjkprdtcgjtxtp {
    font-size: 19px;
    color: #000000a9;
    font-family: poppinslight;
    line-height: normal;
    margin: 10px 0px;
    font-weight: 500;
}

.landingdibfsxthscndsectn {
    width: 100%;
    max-width: 47%;
}

.landingrfsrttgriddiv {
    width: 100%;
    max-width: 100%;
    display: grid;
    grid-auto-columns: auto;
    gap: 14px;
    grid-template-areas:
        "box1 box2 box3 box4"
        "box5 box5 box3 box6"
        "box7 box8 box8 box6";
}

.landingsxthhdmainfinalbg {
    width: 100%;
    background: linear-gradient(90deg, rgba(255, 198, 159, 1) 0%, rgba(255, 168, 27, 1) 54%);
    border-radius: 15px;
    padding: 90px 76px 90px 76px;
}

.grditmmain {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 200ms ease;
    border-radius: 20px;
    border: 7px solid #fff;
    /* perspective: 1000px; */
}

.grditmmainonno {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    column-gap: 54px;
    overflow: hidden;
    transition: all 200ms ease;
    border-radius: 20px;
    border: 7px solid #fff;
    /* perspective: 1000px; */
}

.grditmmain:hover {
    transition: all 0.3s linear;
    transform: rotateX(10deg) rotateY(-20deg);
    cursor: pointer;
}

.landinghmsxthgridfrst {
    grid-area: box1;
    padding: 12px 10px 12px 10px;
}

.landinghmsxthgridscnd {
    grid-area: box2;
    padding: 12px 10px 12px 10px;
}

.landinghmsxthgridthrd {
    grid-area: box3;
}

.landinghmsxthgridfrth {
    grid-area: box4;
    padding: 12px 10px 12px 10px;
}

.landinghmsxthgridffth {
    grid-area: box5;
    padding: 30px 10px 30px 10px;
}

.landinghmsxthgridsxth {
    grid-area: box6;
}

.landinghmsxthgridsvnth {
    grid-area: box7;
    padding: 12px 10px 12px 10px;
}

.landinghmsxthgridegth {
    grid-area: box8;
    padding: 30px 24px 30px 24px;
}

.elthlpsprct {
    font-size: 41px;
    font-family: Montserratextra;
    font-weight: 800;
    line-height: normal;
    color: #fff;
    text-align: center;
}

.elthlpsprctonno {
    font-size: 41px;
    font-family: Montserratextra;
    font-weight: 800;
    line-height: normal;
    color: #000;
    text-align: center;
    padding: 30px 0px 60px 0px;
}

.elthlpsprctonnotxt {
    font-size: 41px;
    font-family: Montserratextra;
    font-weight: 800;
    line-height: normal;
    color: #000;
    text-align: center;
    padding: 115px 0px 20px 0px
}

.elcttxthlptxtp {
    font-size: 14px;
    font-family: Montserratsemi;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    text-align: center;
}

.landingexperincehbfrxst {
    width: 100%;
    height: auto;
    padding: 184px 0px 140px 0px;
    background: linear-gradient(138deg, rgba(237, 59, 76, 1) 0%, rgba(113, 48, 160, 1) 73%);
}

.landingexperivhbfrstctiondiv {
    width: 100%;
    max-width: 68%;
}

.landinexperindfrstsecparabtnmain {
    width: 100%;
    max-width: 73%;
}

.landingexperincehbegth {

    width: 100%;
    height: auto;
    padding: 100px 0px 100px 0px;
    background: linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%);

}

.landingexprcnedivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 9%;
}

.landingexprcnrdivsctmnfrst {
    width: 100%;
    max-width: 33%;
}

.landingexprncrdivsctmnscnd {
    width: 100%;
    max-width: 55%;
}

.landibimhdmmainemploiying {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 676px;
}

.landibimhdmmainemploiying img {
    width: 100%;
    height: 100%;
}

.landingexperincehubscnd {
    width: 100%;
    height: auto;
    padding: 100px 0px 100px 0px;
    background: linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%);
}

.landingxeprncscndflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5%;
}

.lamdingexprcefrscsctnbnth {
    width: 100%;
    max-width: 30%;
}

.lamdingexprcefrscsctnbnthimg {
    width: 100%;
    max-width: 67%;
}

.Desktopandphoneimgmaibn {
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.Desktopandphoneimgmaibn img {
    width: 100%;
    max-width: 100%;
}

.landinfgexprncehbbento {
    width: 100%;
    max-width: 100%;
    display: grid;
    grid-auto-columns: auto;
    gap: 14px;
    grid-template-areas:
        "box1 box1 box2 box2"
        "box3 box3 box3 box4"
        "box5 box6 box6 box6";
}

.landinexptnvbentofrst {
    grid-area: box1;
    padding: 24px 26px;
}

.landinexptnvbentoscnd {
    grid-area: box2;
    padding: 24px 26px;
}

.landinexptnvbentothrd {
    grid-area: box3;
    padding: 24px 26px;
}

.landinexptnvbentofrth {
    grid-area: box4;
    padding: 24px 26px;
}

.landinexptnvbentoffth {
    grid-area: box5;
    padding: 24px 26px;
}

.landinexptnvbentosxth {
    grid-area: box6;
    padding: 24px 26px;
}

.bntohubexptbjbent {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 200ms ease;
    border-radius: 20px;
    border: 7px solid #fff;
    /* perspective: 1000px; */
}

.hubsrchtstxbntosbdppp {
    font-size: 14px;
    font-family: Montserratsemi;
    font-weight: 600;
    line-height: normal;
    color: #fff;
}

.landingexperincehubthrd {
    width: 100%;
    height: auto;
    padding: 100px 0px 60px 0px;
    background: linear-gradient(138deg, rgba(237, 59, 76, 1) 0%, rgba(113, 48, 160, 1) 73%);
}

.landingexprncvgthfty {
    width: 100%;
    max-width: 46%;
    margin: 0 auto;
}

.badgeattrctafnttyflx {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 12%;
    padding-top: 25px;

}

.bgbadgettdiv {
    width: 100%;
    max-width: 15%;
    background-color: #fff;
    border-radius: 30px;
    position: relative;
}

.txtpvjlbagtpp {
    font-size: 15px;
    color: #000000;
    font-family: Montserratsemi;
    line-height: normal;
    font-weight: 700;
    text-align: center;
    height: 108px;
}

.bgbadgettdivcrcl {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: 50px;
    background-color: #fff;
    border: 1px solid #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -27px;
    left: 38%;
}

.bgbadgettdivcrclimg {
    width: 25px;
    height: 25px;
}

.bgbadgettdivcrclimg img {
    width: 100%;
    height: 100%;
}

.txtpvjlbagtppmindiv {

    padding: 38px 28px 23px 28px;
}

.txtpvjlbagtppmindivlst {
    padding: 38px 18px 23px 16px;
}

.landingexperincehubthrdwth {
    background-color: #002060;
    width: 100%;
    height: auto;
    padding: 100px 0px 84px 0px;
}

.landingexperincehubthrpdding {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 69%;
    margin: 0 auto;
}

.cndct {
    font-family: Montserratsemi;
    font-size: 14px;
    line-height: normal;
    color: #fff;
    font-weight: 500;
    text-align: center;
}

.cndctjkpbi {
    font-family: Montserratsemi;
    font-size: 25px;
    line-height: normal;
    color: #fff;
    font-weight: 500;
    text-align: center;
}

.nwuyyxjhp {
    font-family: Montserratsemi;
    font-size: 22px;
    line-height: normal;
    color: #fff;
    font-weight: 600;
    text-align: center;
}

.emplyrtxtppnj {
    font-family: Montserratsemi;
    font-size: 38px;
    line-height: normal;
    color: #fff;
    font-weight: 800;
}

.strderbtn {
    padding: 16px 64px;
    background-color: #fff;
    border-radius: 35px;
    color: #002060;
    font-size: 14px;
    font-family: Montserratsemi;
    font-size: 16px;
    border: none;
    font-weight: 800;
}

.landingexperincehubsnvth {
    width: 100%;
    height: auto;
    padding: 100px 0px 60px 0px;
    background-color: #002060;
}

.landingfsrtsctntxtppdiv {
    width: 100%;
    max-width: 43%;
    margin: 0 auto;
}

.landingexperincehubsnvthdwth {
    width: 100%;
    height: auto;
    padding: 45px 0px 25px 0px;
    background: linear-gradient(138deg, rgba(237, 59, 76, 1) 0%, rgba(113, 48, 160, 1) 73%);

}

.bgbadgettdivrpt {
    width: 100%;
    max-width: 19%;
    background-color: #fff;
    border-radius: 30px;
    position: relative;
}

.txtpvjlbagtppmindivrpt {
    padding: 38px 18px 12px 18px;
}

.landingexoerincehubfrth {
    width: 100%;
    height: auto;
    padding: 120px 0px 120px 0px;
    background: linear-gradient(138deg, rgba(237, 59, 76, 1) 0%, rgba(113, 48, 160, 1) 73%);
}

.landingexoerincehubfrthsctnhgi {
    width: 100%;
    max-width: 43%;
    align-self: flex-start;
    padding-top: 62px;
}

.landingexoerincehubfrthsctimg {
    width: 100%;
    max-width: 41%;
}

.SevenEmployeeepehhkimg {
    width: 100%;
    height: 600px;
}

.SevenEmployeeepehhkimg img {
    width: 100%;
    height: 100%;
}

.landingexoerincehubfrthflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.landingexoerincehubffth {
    width: 100%;
    height: auto;
    padding: 100px 0px 100px 0px;
    background: linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%);
}

.landingexoerincehubffthflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 11%;
}

.landingexoerincehubffthsctntxtu {
    width: 100%;
    max-width: 40%;
    align-self: flex-end;
}

.landingexoerincehubffthsctnimg {
    width: 100%;
    max-width: 47%;
}

.bulletinboardimgekljkimhj {
    width: 100%;
    max-width: 100%;
    height: 575px;

}

.bulletinboardimgekljkimhj img {
    width: 100%;
    height: 100%;
}

.lamdimnghubxcginmlnhj {
    color: #fff;
    font-size: 26px;
    line-height: normal;
    font-family: Montserratsemi;
}

.lamdimnghubxcginmlnhj:hover {
    color: #fff;
    text-decoration: none;
}

.lamdimnghubxcginm {
    padding: 0px 0px 57px 0px;
}

.lamdimnghubxcginmuol {
    padding-top: 25px;
    margin: 0px;
}

.landingexoerincehubsxth {
    background: linear-gradient(90deg, rgba(112, 48, 160, 1) 0%, rgba(178, 81, 241, 1) 54%);
    width: 100%;
    height: auto;
    padding: 100px 0px 60px 0px;
}

.landingexoerincehubsxthflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5%;
}

.landingexoerincehubsxmaindivbjhj {
    width: 100%;
    max-width: 43%;
}

.landingexoerincehubsxmaindiimgjb {
    width: 100%;
    max-width: 48%;
}

.Openintervueimgdibimh {
    width: 100%;
    max-width: 100%;
    height: 350px;
}

.Openintervueimgdibimh img {
    width: 100%;
    max-width: 100%;
}

.landingsctionfrstpppop {
    font-family: Urbanist;
    font-size: 67px;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    /* width: 100%; */
    /* max-width: 656px; */
    word-spacing: 8px;
    letter-spacing: 1px;
}

.landingexperincehbnnth {
    width: 100%;
    height: auto;
    padding: 120px 0px 120px 0px;
    background: linear-gradient(138deg, rgba(237, 59, 76, 1) 0%, rgba(113, 48, 160, 1) 73%);
}

.aiimagemainimgsiv {
    width: 100%;
    height: 300px;
}

.aiimagemainimgsiv img {
    width: 100%;
    height: 100%;
}

.landingexperincehbtwlvth {
    width: 100%;
    height: auto;
    padding: 100px 0px 60px 0px;
    background-color: #002060;
}

.landingworkplcecertficafrst {
    background: linear-gradient(140deg, rgba(255, 168, 0, 1) 0%, rgba(237, 60, 75, 1) 86%);
    width: 100%;
    height: auto;
    padding: 184px 0px 130px 0px;

}

.landingworkplcecertrfstsctn {
    width: 100%;
    max-width: 100%;
}

.hgnrtgjkprdtcgjtxtpdiv {
    width: 100%;
    max-width: 68%;
    padding: 33px 0px;
}

.landingworkplcecertrfstsctnsibk {
    width: 100%;
    max-width: 45%;
}

.nmemldivlllj {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.nmemldivllljinktxt {
    width: 100%;
}

.nmemldivllljinktxt input {
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    padding: 8px;
    border-radius: 10px;
    border: none;
}

.nmemldivllljinhhpeml {
    width: 100%;
}

.nmemldivllljinhhpeml input {
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    padding: 8px;
    border-radius: 10px;
    border: none;
}

.strderbtndiojhk {
    margin: 20px 0px;
}

.landingworkplcecertficafscnd {
    width: 100%;
    height: auto;
}

.landibwrkplcedivcltrstrfst {
    width: 100%;
    background-color: #555555;
    padding: 100px 0px 60px 0px;
}

.landibwrkplcedivcltrstrfstflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 4%;
}

.landibwrkplcedivcltrstrfstwhj {
    width: 100%;
    max-width: 48%;
}

.landibwrkplcedivcltrstrftfxtbtnm {
    width: 100%;
    max-width: 45%;
}

.employrycvuujtsypp {
    font-size: 16px;
    font-family: Montserratsemi;
    line-height: normal;
    color: #fff;
    font-weight: 700;
}

.assetghbtn {
    border: none;
    background: linear-gradient(90deg, rgba(248, 203, 173, 1) 0%, rgba(255, 217, 103, 1) 54%);
    width: 100%;
    padding: 14px;
    color: #000;
    font-family: Montserratsemi;
    font-size: 14px;
    line-height: normal;
    border-radius: 20px;
    margin: 52px 0px 0px 0px;
}

.landibwrkplcedivcltdivboxflx {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.landibwrkplpercenycboxcx {
    width: 100%;
    max-width: 11%;
    background-color: #fff;
    border: 4px solid #fff;
    border-radius: 20px;
    /* padding: 10px; */
    padding: 12px 12px 13px 12px;
}

.ctsuhrrdpppnk {
    color: #000;
    font-size: 14px;
    line-height: normal;
    font-family: Montserratextra;
    font-weight: 600;
    text-align: center;
}

.percnythvonpp {
    color: #000;
    line-height: normal;
    font-family: Montserratextra;
    font-size: 62px;
    font-weight: 800;
    text-align: center;
    padding: 6px 0px
}

.percnythvonpp span {
    font-size: 20px;
}

.ghtvvppyuhkk {
    font-size: 12px;
    line-height: normal;
    color: #000;
    line-height: normal;
    font-family: Montserratextra;
    font-weight: 600;
    text-align: center;
}

.landibwrkplcedivcltrstrscnd {
    width: 100%;
    background: linear-gradient(90deg, rgba(248, 203, 173, 1) 0%, rgba(255, 217, 103, 1) 54%);
    padding: 100px 0px 60px 0px;
}

.landibwrkplcedivcthrdhvbfrst {
    width: 100%;
    background: linear-gradient(90deg, rgba(237, 60, 75, 1) 0%, rgba(255, 168, 0, 1) 54%);
    padding: 80px 0px 80px 0px;

}

.mangemntexpjhflxhhvhgj {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5%;
}

.landibwrkplcedivcthrdhvbfrsttxtwhj {
    width: 100%;
    max-width: 40%;
}

.landibwrkplcedivcthrdhvbmwtxtppdiv {
    width: 100%;
    max-width: 58%;
}

.landibwrkplcedivcthrdhvbscnhj {
    width: 100%;
    background-color: #555555;
    padding: 90px 0px 90px 0px;
}

.landibwrkplcedivcthrdhvbscnhjdivmdl {
    width: 100%;
    max-width: 46%;
    margin: 0 auto;
}

.strderbtnmainbtn {
    display: flex;
    justify-content: center;
    padding-top: 43px;
}

.landingworkplcecertficafrth {
    width: 100%;
    height: auto;
    background: linear-gradient(90deg, rgba(248, 203, 173, 1) 0%, rgba(255, 217, 103, 1) 54%);
    padding: 120px 0px 100px 0px;
}

.landingworkplcedivfrst {
    width: 100%;
    max-width: 48%;
}

.landingworkplcedivscngf {
    width: 100%;
    max-width: 48%;
    background-color: #fff;
    padding: 25px 15px;
    border-radius: 20px;
}

.aasemntmethodolgydiv {
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    padding: 25px 54px;
    border-radius: 20px;
}

.assmtnerthotxtpp {
    font-family: Montserratsemi;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    line-height: normal;
}

.assmtnerthotxtppdiv {
    background-color: #fff;
    border-radius: 11px;
    box-shadow: 0px 5px 5px 0px #ccc;
    padding: 13px;
    width: 100%;
    max-width: 68%;
}

.listtypetxtppbkoli {
    list-style-type: decimal;
    font-family: Montserratsemi;
    font-size: 14px;
    line-height: normal;
    font-weight: 600;
    padding: 10px 0px;
}

.aasemntmethodolgydivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.listtypetxtppbkoul {
    margin: 20px 0px;
}

.assmtnerthotxtppdivlistdiv {
    width: 100%;
    max-width: 52%;
}

.stepwiseimageimgmain {
    width: 100%;
    max-width: 43%;
}

.stepwiseimageimg {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 300px;
}

.stepwiseimageimg img {
    width: 100%;
    height: 100%;
}

.evpmaturitymodalbg {
    width: 100%;
    max-width: 100%;


}

.landingworkplcecertficafrthflbbx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 4%;
}

.landingworkplcedivscngfdivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 24px;
}

.evpmaturitymodalbg {
    width: 100%;
    max-width: 55%;
}

.boxspeciallogoimagmain {
    width: 100%;
    max-width: 37%;
}

.boxspeciallogoimg {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100px;
}

.boxspeciallogoimg img {
    width: 100%;
    height: 100%;
}

.sidebar {
    width: 260px;
    height: 100vh;
    background: linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%);
    transition-property: all;
    transition-duration: 1s;
    display: none;

}

.bariconlanding i {
    font-size: 15px;
    color: #fff;
    display: none;
}

.logowthbgimgmain {
    width: 100%;
    max-width: 212px;
    min-width: 213px;
    height: 80px;
}

.logowthbgimgmain img {
    width: 100%;
    height: 100%;
}

.assmmoapstxttp {
    font-size: poppinslight;
    font-size: 16px;
    line-height: normal;
    color: #000;
    font-weight: 500;
    padding: 2px 0xp;
}

.landingworkplcecertficaffth {
    background: linear-gradient(140deg, rgba(255, 168, 0, 1) 0%, rgba(237, 60, 75, 1) 86%);
    width: 100%;
    height: auto;
    padding: 120px 0px 100px 0px;
}

.landingexperincehbntntth {
    width: 100%;
    height: auto;
    padding: 100px 0px 20px 0px;
    background: linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%);
}

.Securityimgiaon {
    width: 100%;
    height: 306px;
    max-width: 950px;
    min-width: 950px;
    margin: 30px auto;
}

.Securityimgiaon img {
    width: 100%;
    height: 100%;
}

.securitydivmainflx {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
    padding-bottom: 30px;

}

.securitydivmainnbg {
    width: 100%;
    max-width: 20%;
    background-color: #fff;
    padding: 17px 20px;
    border-radius: 20px
}

.securityimageimhmaindfkjn {
    width: 100%;
    max-width: 217px;
    min-width: 217px;
    height: 45px;
    margin: auto;
}

.securityimageimhmaindfkjn img {
    width: 100%;
    height: 100%;
}

.roletxtpbjpp {
    font-family: Montserratsemi;
    font-size: 16px;
    line-height: normal;
    color: #000;
    font-weight: 500;
}

.accessimghmain {
    width: 100%;
    height: 55px;
    max-width: 70px;
    min-width: 70px;
}

.accessimghmaintxtflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    padding-bottom: 20px;
}

.accessimghmain img {
    width: 100%;
    height: 100%;
}

.securitybigtxtpp {
    font-size: 14px;
    line-height: normal;
    color: #000;
    font-family: poppinslight;
    font-weight: 500;
    height: 89px;
}

.securityimageimhmaindfkjndiv {
    padding-bottom: 30px;
}

.landingworkplcecertficasvnth {
    width: 100%;
    background-color: #555555;
    padding: 90px 0px 90px 0px;
}

.graphlandingioimg {
    width: 100%;
    height: 670px;
}

.graphlandingioimg img {
    width: 100%;
    height: 100%;
}

.landingworkplcecertficasxth {
    background: linear-gradient(90deg, rgb(248, 203, 173) 0%, rgb(255, 217, 103) 54%);
    width: 100%;
    height: auto;
    padding: 120px 0px 100px 0px;
}

.comparisoncertificate {
    width: 100%;
    max-width: 80%;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 20px;
    padding: 20px;
}

.comparisonbgbox {
    width: 100%;
    padding: 10px;
    text-align: center;
    font-family: Montserratsemi;
    font-size: 22px;
    color: #000;
    font-weight: 600;
    border: 2px solid #555555;
    border-radius: 40px;
    max-width: 286px;
    min-width: 286px;
    margin: 0 auto;
}

.othercerificateimgmain {
    width: 100%;
    max-width: 79px;
    min-width: 80px;
    height: 73px;
}

.othercerificateimgmain img {
    width: 100%;
    height: 100%;
}

.othrttxtpppcrty {
    font-family: Montserratsemi;
    padding: 20px 0px;
    font-size: 14px;
    line-height: normal;
    color: #000;
    font-weight: 500;
}

.othercerificategtxtppflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.othercerificateimgmaininhgm {
    width: 100%;
    max-width: 221px;
    min-width: 220px;
    height: 79px;
}

.othercerificateimgmaininhgm img {
    width: 100%;
    height: 100%;
}

.tablecertificatefvn {
    padding: 30px 129px;
}




.othrttxtpppcrtytxyy {
    font-family: Montserratsemi;
    font-size: 14px;
    line-height: normal;
    color: #000;
    font-weight: 500;
}



.w3-light-grey {
    width: 100%;
    /* padding: 10px; */
    border: 1px solid #ccc;
    border-radius: 33px;
    background-color: #ccc;
}

.w3-grey {
    background-color: #f00;
    width: 171px;
    height: 15px;
    border-radius: 10px;
}

.w3-light-greyscnd {
    width: 100%;
    /* padding: 10px; */
    border: 1px solid #ccc;
    border-radius: 33px;
    background-color: #ccc;
}

/* 
.tdpshvknappnwgth {
    width: 100%;
    max-width: 80%;
} */

.w3-greyscnd {
    background: linear-gradient(90deg, rgba(243, 109, 63, 1) 0%, rgba(254, 165, 18, 1) 54%);
    width: 171px;
    height: 15px;
    border-radius: 10px;
}

.landingexperincehbntfrthn {
    width: 100%;
    height: auto;
    padding: 80px 0px 70px 0px;
    background: linear-gradient(138deg, rgba(237, 59, 76, 1) 0%, rgba(113, 48, 160, 1) 73%);
    display: block;
}

.datasafeflx {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 59px;
    padding-top: 52px;
}

.encryptiondivbng {
    width: 100%;
    max-width: 24%;
    background: linear-gradient(90deg, rgba(159, 196, 230, 1) 0%, rgba(213, 229, 244, 1) 54%);
    border-radius: 20px;
}

.enxcrtoanarstdivbg {
    background-color: #fff;
    width: 100%;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
}

.enxcrtoanarstdivbgtxtimg {
    width: 100%;
    max-width: 48%;
}

.bariconlandingspcl i {
    display: none;
    margin-right: 20px;
    color: #fff;
    font-size: 21px;
}

.sidebarlinjspclhj {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    column-gap: 21px;
    row-gap: 31px;
    padding-top: 22px;
    padding-left: 20px;
}


.encryptiontfrtypp {
    font-family: Montserratsemi;
    font-size: 12px;
    line-height: normal;
    color: #000;
    font-weight: 500;
    text-align: center;
    padding: 0px 0px 10px 0px;
}

.mongoimg {
    width: 100%;
    max-width: 69px;
    min-width: 69px;
    height: 81px;
    margin: auto;
}

.mongoimg img {
    width: 100%;
    height: 100%;
}

.encryptiontfrtyjjhbkpp {
    font-family: Montserratsemi;
    font-size: 16px;
    line-height: normal;
    color: #000;
    font-weight: 500;
    text-align: center;
}

.leveragebbiokkpp {
    font-family: poppinslight;
    font-size: 14px;
    line-height: normal;
    color: #000;
    font-weight: 500;
}

.encryptiontfrtyjjhbkpp {
    padding: 14px 20px 10px 20px;
}

.leveragebbiokkppdiv {
    padding: 33px 18px 15px 18px;
    height: 243px;
}

.securityloxkimg {
    width: 100%;
    height: 117px;
}

.securityloxkimg img {
    width: 100%;
    height: 100%;
}

.encryptiontfrtyjjhbkppmk {
    font-family: Montserratsemi;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    line-height: normal;
}

/* Final Landing Page Design End */

/*  */
.quizesechmaoinbgj {
    width: 100%;
    height: 100%;
    background-color: #000000d1;
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
}

.quizesechmaoinbgjbngujmain {
    width: 100%;
    max-width: 800px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    border-radius: 10px;
}

.logoandcrtgsjkidgftop {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-shadow: 0px 0px 2px 0px #ccc;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.crtsiuoicoim i {
    font-size: 25px;
    color: red;
    cursor: pointer;
}

.crtsiuoicoim {
    align-self: center;
}

.emplyedivlogoimgmain {
    width: 100%;
    max-width: 221px;
    min-width: 222px;
    height: 92px;
}

.emplyedivlogoimgmain img {
    width: 100%;
    height: 100%;
}

.qizesectqstnrtxupp {
    font-size: 19px;
    color: #fff;
    font-family: poppinslight;
    line-height: normal;
    font-weight: 500;
}

.quizesectionbtm {
    padding: 15px;
    /* height: 500px;
    overflow-y: auto; */
}

.qizesectqstndiv {
    background: linear-gradient(90deg, rgba(237, 59, 75, 1) 0%, rgba(76, 59, 146, 1) 54%);
    width: 100%;
    padding: 5px 10px;
    border-radius: 10px;
    margin-bottom: 30px;
}

.bgcoloranswers {
    background-color: #cccccc7a;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 2px 0px #ccc;
    padding: 8px 8px;
    margin: 19px 0px;
    cursor: pointer;
}

.btnscactbamsvd {
    background-color: #cccccc7a;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 2px 0px #ccc;
    padding: 8px 8px;
    margin: 19px 0px;
    cursor: pointer;
    background-color: green;
}

/* .bgcoloranswersnew {
    background-color: rgb(1, 180, 1);
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 8px 8px;
    margin: 10px 0px;
    cursor: pointer;
} */

.bganswersoptnanseflx {
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    align-items: center;
}

.amlsmpptycgp {
    font-size: 15px;
    color: #000;
    font-family: poppinslight;
    line-height: normal;
    font-weight: 500;
}

.quizesectionbtmandk {
    padding-bottom: 15px;
}

.txtcrptyjbpp {
    color: #000;
    font-size: 14px;
    font-family: Montserratsemi;
    line-height: normal;
    padding-bottom: 10px;
}

.txtcrptyjb {
    width: 100%;
    padding: 10px 0px 15px 0px;
}

.txtcrptyjb input {
    border: none;
    border-radius: 10px;
    /* background-color: #fff; */
    padding: 10px 40px 10px 10px;
    background-color: #cccccc7a;
    width: 100%;
}

.sbmyjhvbhvbtynm {
    background: linear-gradient(90deg, rgba(237, 59, 75, 1) 0%, rgba(76, 59, 146, 1) 54%);
    width: 100%;
    padding: 10px 10px;
    border-radius: 10px;
    border: none;
    color: #fff;
    font-size: 16px;
    font-family: poppinslight;
    line-height: normal;
    width: 100%;
    max-width: 22%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.elcttxthlptxtpemnhop {
    font-size: 14px;
    font-family: Montserratsemi;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    text-align: center;
    color: #000;
    padding-Top: 98px;
    padding-Bottom: 11px;
}

.logowthbgimgmainiconcrsflx {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.sidebarclose i {
    color: #fff;
    font-size: 20px;
    margin-right: 9px;
}

.elitevideosectiondiv {
    width: 100%;
    height: 100%;
    position: relative;
}

.elitevideosectiondiv video {
    width: 100%;
    height: auto;
}

.elitevideosectiondivlrft {
    width: 100%;
    max-width: 50%;
}

.elitevideosectiondivrighty {
    width: 100%;
    max-width: 45%;
}
.elitevideosectiondivmain{
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px;
    border-radius: 10px;
}

.elitevideosection {
    width: 100%;
    height: auto;
    background: #fff;
    padding: 63px 0px 30px 0px;
    /* background: linear-gradient(90deg, rgba(255, 198, 159, 1) 0%, rgba(255, 168, 27, 1) 54%); */
}

.bhbsbvidpoim {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 8;
    background-color: #fff0;
    width: 100%;
    /* max-width: 50px; */
    /* min-width: 50px; */
    /* height: 50px; */
    /* border-radius: 50%; */
    border: none;
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addiconimhj {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: 57px;
}

.addiconimhj img {
    width: 100%;
    height: 100%;
}

/* .elitevideosection{
    padding: 120px 0px 0px 0px;
} */



/* Registration Page design  Start*/
.registartionpagebgmain {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, rgba(201, 213, 233, 1) 0%, rgba(224, 230, 245, 1) 42%, rgba(190, 208, 232, 1) 54%);
    padding: 30px;
}

.registrationwrapmain {
    width: 100%;
    max-width: 80%;
    height: auto;
    margin: auto;
    background-color: #fff;
    border-radius: 25px;
    overflow: hidden;
    box-shadow: 0px 0px 5px 0px #2c2c2ccc;
}

.registratrationdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.registratrationdivleft {
    width: 100%;
    max-width: 55%;
}

.registratrationdivright {
    width: 100%;
    max-width: 45%;
}

.registratrationdivleftpading {
    padding: 15px 10px 15px 40px;
}

.LogNewPicdivpmio {
    width: 100%;
    max-width: 100%;
    height: 765px;
    box-shadow: 0px 0px 5px 0px #949494cc;
    border-radius: 25px;
}

.LogNewPicdivpmio img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.registartxtpp {
    font-family: 'NoirProRegular';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 34px;
    background: linear-gradient(181deg, rgba(237, 59, 75, 1) 14%, rgba(76, 59, 146, 1) 92%);
    /* background: linear-gradient(90deg, #ed3b4b, #4c3b92); */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 1px;
    text-align: center;
}

.registartoonfrmdivmain {
    padding: 45px 0px 0px 0px;
}

.inputcompanydtls p {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    padding: 0;
    margin-bottom: 1px;
}
.inputcompanydtls select {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 2px solid #543b8e;
    background-color: transparent !important;
    outline: 0;
    text-decoration: none;
    box-shadow: none;
    padding: 12px 30px 8px 10px;
    width: 100%;
}
.inputcompanydtls {
    width: 100%;
    padding-bottom: 15px;
    position: relative;

}

.inputcompanydtls input {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 2px solid #543b8e;
    background-color: transparent !important;
    outline: 0;
    text-decoration: none;
    box-shadow: none;
    padding: 5px 30px 5px 10px;
    width: 100%
}

.asmbmnbtn {
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    cursor: pointer;
    background: linear-gradient(138deg, rgba(237, 59, 76, 1) 0%, rgba(113, 48, 160, 1) 73%);
    border: none;
    color: #fff;
    width: auto;
    height: auto;
    padding: 6px 20px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.asmbmnbtnmaindiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.companykslogodivmnm {
    width: 100%;
    max-width: 140px;
    min-width: 142px;
    height: 43px;
    margin-left: auto;
}

.companykslogodivmnm img {
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.Hideimgdivicn {
    position: absolute;
    top: 3px;
    right: 2px;
    width: 100%;
    max-width: 20px;
    min-width: 20px;
}

.Hideimgdivicn img {
    width: 100%;
    height: 100%;
}

/* Registration Page design End */
.subscriptionsectionmain {
    min-height: 100vh;
    background: linear-gradient(90deg, rgba(201, 213, 233, 1) 0%, rgba(224, 230, 245, 1) 42%, rgba(190, 208, 232, 1) 54%);
    padding: 30px;
}

.subscriptiondivmain {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
}

.chsesubdcdhppaln {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(181deg, #ed3b4b 14%, #4c3b92 92%);
    -webkit-background-clip: text;
    background-clip: text;
    font-family: GTSuper;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: normal;
    text-align: center;
    padding: 20px 0px 20px 0px;
}

.chsesubdcdhppalntxtp {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(181deg, #ed3b4b 14%, #4c3b92 92%);
    -webkit-background-clip: text;
    background-clip: text;
    font-family: poppinslight;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 25px;
    text-align: center;
}

.subscriptionmaindibflx {
    padding: 30px 0px 30px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
}

.subscriptionmaindibfrst {
    width: 100%;
    max-width: 32%;
    min-width: 32%;
}

.subscriptionmaindibfrstbrdr {
    background-clip: padding-box, border-box;
    -webkit-background-clip: padding-box, border-box;
    background-image: #4c3b92;
    background-image: linear-gradient(#fff, #fff), radial-gradient(circle at top left, #ed3b4b, #4c3b92);
    background-origin: border-box;
    border: 2px double transparent;
    border-radius: 20px;
    padding: 20px;
    background: linear-gradient(138deg, #ed3b4c, #7130a0 73%);
    position: relative;
    cursor: pointer;
    transition: 0.5s all ease-in-out;
}

.subscriptionmaindibfrstbrdr:hover {
    transform: scale(1.1, 1.2);
    transition: all 0.5s ease-in-out;
}
.chskjbhpalnn.activeplkl{
    border-radius: 20px;
    padding: 4px 20px;
    border: 2px solid #fff;
    background: linear-gradient(138deg, #ed3b4c, #7130a0 73%);
    color: #fff;
}
.chskjbhpalnnttxavtvbm{
    font-family: GTSuper;
    color: #ffffff;
}

.basctxtpjkp {
    font-weight: 700;
    letter-spacing: 1.3px;
    text-align: center;
    padding: 20px 0px 0px 0px;
    color: #ffffff;
    font-family: NoirProLight;
    font-size: 24px;
    font-style: normal;
    line-height: 29px;
}

.basctxtpjkpknk {
    letter-spacing: 1px;
    line-height: 16px;
    text-align: center;
    padding: 0px 0px 5px 0px;
    color: #ffffff;
    font-family: Mandaufont;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
}

.priceoplantxtpp {
    color: #fff;
    font-family: Montserratextra;
    line-height: normal;
    text-align: center;
    font-size: 60px;
    font-weight: 700;
}

.priceoplantxtppdlr {
    color: #fff;
    font-family: Montserratextra;
    font-size: 35px;
    font-weight: 500;
}

.priceoplantxtppdlrtcfyhy {
    color: #fff;
    font-family: Montserratextra;
    font-size: 30px;
    font-weight: 500;
}

/* .priceoplantxtpp span {
    font-size: 20px;
    font-weight: 500;
} */
.sbsrptroinnbscd {
    padding: 10px 0px 10px 0px;
}

.sbsrptroinnbscdanb {
    padding: 10px 0px 10px 0px;
}

.sbsrptroinnbscdanbmk {
    padding: 10px 0px 10px 0px;
}

.listdivallmnjkki {
    text-align: center;
    list-style-type: none;
}

.listdivallmnfst {
    position: relative;
    color: #fff;
    font-family: poppinslight;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    padding-bottom: 10px;
    text-align: center;
}

.listdivallmnfst span {
    margin-right: 10px;
}


.chskjbhpalnn {
    border-radius: 20px;
    padding: 4px 20px;
    border: 2px solid #fff;
    background-color: #fff;

}

.chskjbhpalnnttx {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(181deg, #ed3b4b 14%, #4c3b92 92%);
    -webkit-background-clip: text;
    background-clip: text;
    font-family: GTSuper;
    color: #ffffff;
}

.chskjbhpalnndiv {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 10px 0px 20px 10px;
}

.sbmtbtndivkl {
    border-radius: 20px;
    padding: 4px 30px;
    border: 2px solid #fff;
    background-color: #fff;
    background: linear-gradient(181deg, #ed3b4b 14%, #4c3b92 92%);
    color: #fff;
    border: none;
}

.sbmtbtndivklbtn {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 10px 0px 20px 10px;
}

/****Responsive******/

@media (max-width:1499px) {
    .aBettText {
        font-size: 40px;
        word-spacing: 7px;
        width: 100%;
    }

    .ctsuhrrdpppnk {
        font-size: 12px;
    }

    .percnythvonpp {
        font-size: 42px;
    }

    .atThecenText {
        font-size: 23px;
    }

    .manageByExpText {
        font-size: 40px;
        word-spacing: 7px;
    }

    .employEngText {
        font-size: 40px;
        word-spacing: 7px;
        margin: 0 0 22px 0;
    }

    .compreDeskFig {
        width: 50%;
        height: 67%;
    }

    .listdivallmnfst {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-bottom: 10px;
        text-align: center;
    }


    .priceoplantxtpp {
        font-size: 52px;
        font-weight: 700;
    }

    .labdChartImgSection {
        height: 625px;
    }

   .advoTextHead {
        font-size: 40px;
    }

    .netWrkTextHead {
        font-size: 40px;
    }

    .advoTextPara {
        font-size: 18px;
    }

    .netWrkSmallTexts {
        font-size: 18px;
    }

    .onbrdHireHead {
        font-size: 40px;
    }

    .onbrdHireTexts {
        font-size: 18px;
    }

    .employHigherText {
        font-size: 18px;
    }

    .emploOurText {
        font-size: 18px;
    }

    .landRightFtTextDiv {
        width: 100%;
    }

    .landFtRightFig {
        width: 190px;
        min-width: 190px;
    }

    .strcmntFig {
        margin: 0 0 0 40px;
    }

    .ftLandFig {
        width: 160px;
    }

    .secondFooterPart {
        height: 360px;
    }

    .landingsctionfrstpp {
        font-size: 60px;
    }

    .landingsctionfrstpp span {
        font-size: 60px;
    }

    .grditmmainonno {
        column-gap: 45px;
        /* perspective: 1000px; */
    }

    .landingdibfsxthscndsectn {
        width: 100%;
        max-width: 55%;
    }

    .landingdibfsxthfrstsectn {
        width: 100%;
        max-width: 37%;
        align-self: flex-start;
    }

    .bgbadgettdiv {
        width: 100%;
        max-width: 23%;
        background-color: #fff;
        border-radius: 30px;
        position: relative;
    }

}

@media(max-width:1366px) {
    .landingsctionfrstpp {
        font-size: 53px;

    }

    .menudivmainbdr {
        width: 100%;
        max-width: 80%;
        padding: 5px 70px;
    }

    .tablecertificatefvn {
        padding: 30px 40px;
    }

    .landingsctionfrstpp span {
        font-size: 53px;
    }

    .leveragebbiokkpp {
        font-family: poppinslight;
        font-size: 12px;
        line-height: normal;
        color: #000;
        font-weight: 500;
    }
}

@media (max-width: 1199px) {
    .landBannerSection {
        height: 500px;
    }
    .subscriptionmaindibfrst {
        width: 100%;
        max-width: 34%;
        min-width: 34%;
    }

    .emplyrtxtppnj {
        font-family: Montserratsemi;
        font-size: 30px;
        line-height: normal;
        color: #fff;
        font-weight: 800;
    }

    .landibwrkplpercenycboxcx {
        width: 100%;
        max-width: 15%;
        background-color: #fff;
        border: 4px solid #fff;
        border-radius: 20px;
        /* padding: 10px; */
        padding: 12px 12px 13px 12px;
    }
    .listdivallmnfst {
        font-size: 15px;
    }

    .leveragebbiokkppdiv {
        padding: 15px 18px 15px 18px;
        height: 243px;
    }

    .datasafeflx {
        flex-wrap: wrap;
        row-gap: 20px;
    }

    .encryptiondivbng {
        width: 100%;
        max-width: 100%;
    }

    .leveragebbiokkppdiv {
        padding: 15px 18px 15px 18px;
        height: 104px;
    }


    .emplyedivlogoimg {
        width: 100%;
        max-width: 280px;
        min-width: 280px;
        height: 107px;
    }

    .menudivmainbdr {
        width: 100%;
        max-width: 87%;
        padding: 5px 18px;
    }
    .navglnktxtppp {
        padding: 7px;
        font-size: 13px;
    }

    .aBettText {
        font-size: 30px;
        word-spacing: 4px;
        line-height: normal;
    }

    .lgnadivld {
        width: 100%;
        max-width: 100%;
    }

    .title p {
        font-size: 20px;
    }

    .atThecenText {
        font-size: 20px;
    }

    .weHelpText {
        width: 100%;
    }

    .getStartBtn {
        padding: 8px 12px;
        font-size: 15px;
    }

    .manageBySection {
        height: 500px;
    }

    .manageByExpText {
        font-size: 30px;
        line-height: normal;
        word-spacing: 4px;
    }

    .empExpText {
        width: 80%;
        font-size: 15px;
    }

    .hpyEmpText {
        font-size: 15px;
        width: 80%;
    }

    .employEngageSection {
        height: 500px;
    }

    .employEngText {
        font-size: 30px;
        word-spacing: 4px;
    }

    .employHigherText {
        font-size: 15px;
    }

    .emploOurText {
        font-size: 15px;
    }

    .compreHenSection {
        height: 600px;
    }

    .firstCompreText {
        padding-left: 20px;
        font-size: 18px;
    }

    .compreTextFig {
        width: 85%;
    }

    .compreAllNatioTextsDiv {
        padding-left: 20px;
    }

    .compreNationText {
        font-size: 16px;
    }

    .compreNatioSmallText {
        font-size: 15px;
    }

    .firstCompreText .firstCompFSpan {
        font-size: 30px;
    }

    /* .labdChartImgSection {
        height: 500px;
    } */

    .employAdvoSection {
        height: 500px;
    }

    .advoTextHead {
        font-size: 30px;
    }

    .advoTextPara {
        font-size: 15px;
    }

    .networkLandSection {
        height: 500px;
    }

    .netWrkTextHead {
        font-size: 30px;
    }

    .netWrkSmallTexts {
        font-size: 15px;
    }

    .onbrdHireHead {
        font-size: 30px;
    }

    .onbrdHireTexts {
        font-size: 15px;
    }

    .netWrkTextHead {
        font-size: 30px;
    }

    .netWrkSmallTexts {
        font-size: 15px;
    }

    .onbrdHireHead {
        font-size: 30px;
    }

    .onbrdHireTexts {
        font-size: 15px;
    }

    .landFtRightFig {
        width: 155px;
        min-width: 155px;
    }

    .landFtName {
        font-size: 17px;
    }

    .landFtPost {
        font-size: 19px;
    }

    .landFtCmpny {
        font-size: 19px;
    }

    .strcmntFig {
        width: 110px;
        min-width: 110px;
        height: 105px;
    }

    .landPrevFtText {
        font-size: 13px;
    }

    .landRightFtTextDiv {
        padding: 30px;
    }

    .ftprtcCntcText {
        font-size: 20px;
    }

    .strtJrnyText {
        font-size: 16px;
    }

    .iagreePart label {
        font-size: 14px;
    }

    .footLndBtnDiv input {
        width: 88%;
    }

    .footLndBtnDiv button {
        width: 10%;
        font-size: 18px;
    }

    .ftLandFig {
        width: 150px;
    }

    .allFootLandHead {
        font-size: 18px;
    }

    .footLeftInIconsAn {
        font-size: 25px;
    }

    .footLndOtherLi {
        line-height: normal;
        margin-bottom: 5px;
    }

    .footLndOtherLiAn {
        font-size: 16px;
    }

    .ftrUl {
        justify-content: center;
    }

    .ftrLiAn {
        font-size: 16px;
    }

    .footerLfticonLi {
        margin-right: 12px;
    }

    .landLogoFig {
        width: 60px;
        min-width: 60px;
        height: 60px;
    }

    .landLogoText {
        font-size: 15px;
    }

    .landHeadLiAn {
        font-size: 14px;
    }

    .landHeadBtns {
        padding: 7px 15px;
        margin-right: 8px;
    }

    .onboardLandSection {
        height: 500px;
    }

    .employVoiceSection {
        height: 500px;
    }

    .landingsctionfrstpp {
        font-size: 40px;
    }

    .landingfsrtsctiondiv {
        width: 100%;
        max-width: 45%;
        align-self: end;
    }

    .landingscndsctiondiv {
        width: 100%;
        max-width: 45%;
    }

    .landibimhdmmain {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: 444px;
    }

    .landingsctionfrstpp span {
        font-size: 40px;
    }

    .landingthrddivpppttpspan {
        font-size: 30px;
        padding-top: 10px;
    }

    .landingdibfsxthfrstsectn {
        width: 100%;
        max-width: 32%;
        align-self: flex-start;
    }

    .elthlpsprct {
        font-size: 30px;
    }

    .lamdingexprcefrscsctnbnth {
        width: 100%;
        max-width: 38%;
    }



    .elcttxthlptxtp {
        font-size: 11px;
    }

    .prcghjjdthvxpp {
        font-family: Montserratextra;
        font-size: 30px;
        line-height: normal;
        font-weight: 700;
        color: #fff;
        text-align: center;
    }

    .emplyetxtppdivppp {
        font-size: 12px;
    }

    .hrldrtuppxgj {
        font-size: 12px;
    }

    .landingfifthdmainfinalbg {
        padding: 43px 57px 50px 57px;
    }

    .landingsxthhdmainfinalbg {
        padding: 90px 61px 90px 61px;
    }

    .landingsixthdmainfinalbg {
        padding: 79px 60px 79px 60px;
    }

    .landingffthdmainfinalbg {
        padding: 79px 60px 79px 60px;
    }

    .landingsixtghjtxtyimdivgg {
        width: 100%;
        max-width: 40%;
    }

    .landingssixthleftdivimgui {
        width: 100%;
        max-width: 57%;
        min-width: 57%;
    }
}

@media(max-width:1064px) {
    .w3-light-grey {
        width: 89%;
        /* padding: 10px; */
        border: 1px solid #ccc;
        border-radius: 33px;
        background-color: #ccc;
    }

    .w3-light-greyscnd {
        width: 88%;
        /* padding: 10px; */
        border: 1px solid #ccc;
        border-radius: 33px;
        background-color: #ccc;
    }
}

@media(max-width:1050px) {
    .about {
        padding: 100px 0;
    }



    .about-dtls {
        padding: 0px;
    }

    .conten {
        margin-top: 5px;
    }

    .banner_btn {
        margin-top: 13px;
    }

    .title span {
        font-size: 30px;
    }

    /* 
    .title p {
        font-size: 30px;
    } */

    .banner_btn .button1 {
        width: 150px;
        height: 50px;
        font-size: 15px;
    }

    .banner_btn .button2 {
        width: 150px;
        height: 50px;
        font-size: 15px;
    }
}

@media(max-width:991px) {
    .work {
        padding: 70px 0;
    }
    .registratrationdivflx {
        flex-wrap: wrap;
    }
    .asmbmnbtn {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .registratrationdivleft {
        width: 100%;
        max-width: 100%;
    }
    .registratrationdivright {
        width: 100%;
        max-width: 100%;
    }
    .LogNewPicdivpmio {
        width: 100%;
        max-width: 100%;
        height: auto;
        box-shadow: 0px 0px 5px 0px #949494cc;
        border-radius: 25px;
    }
    .registratrationdivleftpading {
        padding: 15px 15px 15px 15px;
    }

    .landingworkplcecertficafrth {
        padding: 60px 0px 60px 0px;
    }
    .registrationwrapmain {
        width: 100%;
        max-width: 95%;
    }

    .landingworkplcecertficaffth {
        padding: 60px 0px 60px 0px;
    }

    .comparisoncertificate {
        width: 100%;
        max-width: 90%;
        background-color: #fff;
        margin: 0 auto;
        border-radius: 20px;
        padding: 20px;
    }

    .othrttxtpppcrty {
        font-family: Montserratsemi;
        padding: 20px 0px;
        font-size: 12px;
        line-height: normal;
        color: #000;
        font-weight: 500;
    }

    .landibwrkplcedivcltdivboxflx {
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 20px;
        row-gap: 20px;
    }

    .landibwrkplcedivcltrstrscnd {
        width: 100%;
        background: linear-gradient(90deg, rgba(248, 203, 173, 1) 0%, rgba(255, 217, 103, 1) 54%);
        padding: 60px 0px 60px 0px;
    }

    .landibwrkplcedivcthrdhvbscnhjdivmdl {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
    }

    .landibwrkplcedivcthrdhvbscnhj {
        padding: 60px 0px 60px 0px;
    }

    .landibwrkplcedivcthrdhvbfrst {
        padding: 60px 0px 60px 0px;
    }

    .strderbtnmainbtn {
        display: flex;
        justify-content: center;
        padding-top: 10px;
    }

    .landingworkplcecertficafrthflbbx {
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 20px;
    }

    .landingworkplcedivfrst {
        width: 100%;
        max-width: 100%;
    }

    .landingworkplcedivscngf {
        width: 100%;
        max-width: 100%;
        background-color: #fff;
        padding: 25px 15px;
        border-radius: 20px;
    }

    .landibwrkplpercenycboxcx {
        width: 100%;
        max-width: 26%;
        background-color: #fff;
        border: 4px solid #fff;
        border-radius: 20px;
        /* padding: 10px; */
        padding: 12px 12px 13px 12px;
    }

    .securityloxkimg {
        width: 100%;
        height: auto;
    }

    .mongoimg {
        width: 100%;
        max-width: 69px;
        min-width: 69px;
        height: auto;
        margin: auto;
    }

    .landingfsrtsctntxtppdiv {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
    }

    .bgbadgettdivrpt {
        width: 100%;
        max-width: 26%;
        background-color: #fff;
        border-radius: 30px;
        position: relative;
    }

    .encryptiontfrtypp {
        font-family: Montserratsemi;
        font-size: 17px;
    }

    .leveragebbiokkpp {
        font-family: poppinslight;
        font-size: 12px;
    }

    .landingffthdmainfinalbg {
        padding: 33px 50px 20px 50px;
    }

    .stryrfvjrtfrmdivflsx {
        flex-wrap: wrap;
    }

    .strtjrnydivbgkphwh {
        width: 100%;
        max-width: 100%;
        align-self: flex-end;
    }

    .strtjrnydivdfrmnkklk {
        width: 100%;
        max-width: 100%;
    }

    .menudivmainbdr {
        width: 100%;
        max-width: 100%;
        padding: 13px 41px;
    }

    .landingfsrtsctiondiv {

        width: 100%;
        max-width: 100%;
        align-self: end;
        padding-top: 0px;

    }

    .logopmainpagedibjhk {
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .headelogomenubardivflx {
        flex-wrap: wrap;
    }



    .landingfifthdmainfinalbg {
        padding: 33px 50px 20px 50px;
    }

    .mrktvcdib {
        box-shadow: 0px 7px 9px 0px #ccc;
        padding: 10px;
        color: #000;
        font-family: notosans;
        font-size: 16px;
        font-weight: 600;
        border-radius: 36px;
        width: 100%;
        max-width: 100%;
        text-align: center;
        margin: auto;
        background-color: #fff;
        padding-top: 17px;
    }

    .elthlpsprctonnotxt {

        padding: 9px 0px 6px 0px;
    }

    .elthlpsprctonno {
        padding: 10px 0px 10px 0px;
    }

    .elcttxthlptxtpemnhop {
        padding: 10px 0px 10px 0px;
    }

    .sub-card {
        max-width: 138px;
        height: 120px;
    }

    .card_img {
        width: 117px;
        height: 122px;
        margin: 0 auto;
        text-align: center;
        padding: 22px 16px;
    }

    .card_title p {
        color: #222;
        padding: 0px 10px;
        text-align: center;
        margin-top: 10px;
        font-size: 17px;
    }

    .card_dtls {
        margin-top: 15px;
    }

    .card_dtls p {
        font-size: 13px;
    }
    .elitevideosectiondivlrft {
        width: 100%;
        max-width: 100%;
    }
    .elitevideosectiondivrighty {
        width: 100%;
        max-width: 100%;
        padding-top: 40px;
    }
    .elitevideosection {
        padding: 35px 0px 0px 0px;
    }

    .work_card {
        max-width: 380px;
        height: 295px;
    }
    .landingfsrtscndtxtppgb {
        margin: 20px 0px;
        font-weight: 600;
    }

    .about {
        padding: 75px 0;
    }

    .banner {
        padding: 75px 0;
    }

    .title span {
        font-size: 30px;
    }

    .grditmmainonno {
        align-items: center;
    }

    .elcttxthlptxtp {
        font-size: 15px;
    }

    /* .title p {
        font-size: 30px;
    } */

    .banner_btn .button1 {
        width: 130px;
        height: 40px;
        font-size: 14px;
    }

    .landingrfsrttgriddiv {
        grid-template-areas:
            "box1 box1 box2 box2"
            "box3 box3 box4 box4"
            "box5 box5 box6 box6"
            "box7 box7 box8 box8";
    }

    .banner_btn .button2 {
        width: 130px;
        height: 40px;
        font-size: 14px;
    }

    .conten p {
        font-size: 12px;
    }

    .banner_btn {
        margin-top: 10px;
    }

    .loginbtn {
        margin-bottom: 10px;
    }

    .header .nav-item .nav-link {
        padding: 10px 15px;
    }

    .banner_img {
        max-width: 400px;
        height: 231px;
        margin: 0 auto;
    }

    .share_event {
        padding: 40px 50px;
    }

    .Associate {
        padding: 40px 0;
    }

    .benefit_dtls p {
        font-size: 14px;
    }

    .service {
        padding: 40px 0;
    }

    .work {
        padding: 40px 0;
    }

    .roles {
        padding: 40px 0;
    }

    .recognition {
        padding: 40px 50px;
    }

    /* 21 sep */
    .service {
        padding: 40px 0;
    }

    .roles {
        padding: 40px 0;
    }

    .benefit_dtls p {
        font-size: 14px;
    }

    .work_title span {
        font-size: 30px;
    }

    .work_title p {
        font-size: 30px;
    }

    .containerPageLand {
        width: 90%;
    }

    .landBannerSection {
        height: 400px;
    }

    .aBettText {
        font-size: 25px;
    }

    .atThecenText {
        font-size: 17px;
    }

    .weHelpText {
        font-size: 11px;
    }

    .manageBySection {
        height: 400px;
    }

    .manageByExpText {
        font-size: 25px;
    }

    .empExpText {
        font-size: 12px;
    }

    .hpyEmpText {
        font-size: 12px;
    }

    .employEngText {
        font-size: 25px;
    }

    .employHigherText {
        font-size: 13px;
    }

    .emploOurText {
        font-size: 13px;
    }

    .firstCompreText .firstCompSSpan {
        font-size: 20px;
    }

    .firstCompreText {
        padding-left: 10px;
        font-size: 15px;
    }

    .compreAllNatioTextsDiv {
        padding-left: 10px;
    }

    .advoTextHead {
        font-size: 25px;
    }

    .advoTextPara {
        font-size: 13px;
    }

    .netWrkTextHead {
        font-size: 25px;
    }

    .netWrkSmallTexts {
        font-size: 13px;
    }

    .onbrdHireHead {
        font-size: 25px;
    }

    .onbrdHireTexts {
        font-size: 13px;
    }

    .employEngageSection {
        height: 400px;
    }

    .compreHenSection {
        height: 500px;
    }

    /* .labdChartImgSection {
        height: 400px;
    } */

    .employAdvoSection {
        height: 400px;
    }

    .networkLandSection {
        height: 400px;
    }

    .onboardLandSection {
        height: 400px;
    }

    .employVoiceSection {
        height: 400px;
    }

    .landFtRightFig {
        width: 105px;
        min-width: 105px;
    }

    .landFtName {
        font-size: 15px;
        margin: 0 0 5px 0;
    }

    .landFtPost {
        font-size: 17px;
        margin: 0 0 5px 0;
    }

    .landFtCmpny {
        font-size: 17px;
    }

    .strcmntFig {
        width: 100px;
        min-width: 100px;
        height: 95px;
    }

    .conctStrt {
        width: 60%;
    }

    .ftprtcCntcText {
        font-size: 18px;
    }

    .strtJrnyText {
        font-size: 14px;
    }

    .iagreePart label {
        font-size: 12px;
    }

    .footLndBtnDiv input {
        font-size: 13px;
    }

    .footLndBtnDiv button {
        padding: 4px 0;
    }

    .footLeftInIconsAn {
        font-size: 23px;
    }

    .ftrLiAn {
        font-size: 15px;
    }

    .landHeadUl {
        display: none;
    }

    .rescLandHeadBtn {
        display: inline-block;
    }

    .sidebarSection {
        display: block;
    }

    .landHeadBtns {
        display: none;
    }

    .quizesechmaoinbgjbngujmain {
        max-width: 700px;
    }

    .landingfinalflxfst {
        flex-wrap: wrap;
    }

    .landingfsrtsctiondiv {
        width: 100%;
        max-width: 100%;
        align-self: end;
    }

    .landingscndsctiondiv {
        width: 100%;
        max-width: 100%;
    }

    .landibimhdmmain {
        height: auto;
    }

    .landingfistmainfinal {
        padding: 200px 0px 80px 0px;
    }

    .landingscndmainfinal {
        padding: 10px 0px 20px 0px;
    }

    .landingsctionfrstpp {
        font-size: 30px;
    }

    .landingexperivhbfrstctiondiv {
        width: 100%;
        max-width: 100%;
    }

    .landinexperindfrstsecparabtnmain {
        width: 100%;
        max-width: 100%;
    }

    .landingexperincehbfrxst {
        width: 100%;
        height: auto;
        padding: 216px 0px 60px 0px;
        background: linear-gradient(138deg, rgba(237, 59, 76, 1) 0%, rgba(113, 48, 160, 1) 73%);
    }

    .landingxeprncscndflx {
        flex-wrap: wrap;
    }

    .lamdingexprcefrscsctnbnth {
        width: 100%;
        max-width: 100%;
    }

    .lamdingexprcefrscsctnbnthimg {
        width: 100%;
        max-width: 100%;
        padding-top: 30px;
    }

    .landingexperincehubscnd {
        padding: 60px 0px 60px 0px;
    }

    .landingexperincehubthrd {
        padding: 40px 0px 60px 0px;
    }

    .bgbadgettdiv {
        width: 100%;
        max-width: 30%;
    }

    .txtpvjlbagtpp {
        font-size: 13px;
    }

    .txtpvjlbagtppmindiv {
        padding: 28px 17px 13px 17px;
    }

    .txtpvjlbagtppmindivlst {
        padding: 32px 15px 20px 13px;
    }


    .landingexprncvgthfty {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
    }

    .landingfsrtsctntxtpp {
        margin: 16px 0px;
    }

    .landingsctionfrstpp span {
        font-size: 30px;
    }

    .landingthrddivflx {
        flex-wrap: wrap;
    }

    .landingthrddivwhhh {
        width: 100%;
        max-width: 100%;
    }

    .landingsixthdivmainflx {
        flex-wrap: wrap;
    }

    .landingsixtghjtxtyimdivgg {
        width: 100%;
        max-width: 100%;
    }

    .landingssixthleftdivimgui {
        width: 100%;
        max-width: 100%;
        padding: 20px 0px;
    }

    .smrtctpppbh {
        padding-top: 20px;
    }

    .startcementimghjj {
        height: auto;
    }

    .landingsixthdmainfinalbg {
        padding: 33px 50px 20px 50px;
    }

    .frctlsypptxtjnj {
        font-family: Montserratsemi;
        font-size: 15px;
        line-height: normal;
        font-weight: 500;
        color: #fff;
    }

    .smrtctpppbhopyyctt {
        font-size: 18px;
    }

    .chrthbopjioxty {
        font-size: 18px;
    }

    .marketvoicedivehh {
        width: 100%;
        max-width: 100%;
        padding: 10px 0px 10px 0px;
    }

    .mrktvcdivoenghdjh {
        width: 100%;
        max-width: 100%;
    }

    .marketvoicedidvtwodivmain {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .marketdoibvbgyklstbgk {
        width: 100%;
        max-width: 100%;
    }

    .marketdivwscvjnkkl {
        width: 100%;
        max-width: 100%;
    }

    .landingthrddmainfinal {
        padding: 15px 0px 20px 0px;
    }

    .landingfrthdmainfinal {
        padding: 15px 0px 20px 0px;
    }

    .landingfifthdmainfimnal {
        padding: 15px 0px 15px 0px;
    }

    .landingsxthdmainfinal {
        padding: 15px 0px 15px 0px;
    }

    .landingsevnthhdmainfimnal {
        padding: 15px 0px 15px 0px;
    }

    .landingeighthhdmainfimnal {
        padding: 15px 0px 15px 0px;
    }

    .emplyeexprectunftewrkflx {
        flex-wrap: wrap;
        margin: 10px 0px;
    }

    .emplotexpertblubrdrdiv {
        width: 100%;
        max-width: 100%;
        margin: 10px 0px;
    }

    .emplyeexprectunftewrkflx {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 68px;
        padding: 5px 0px 5px 0px;
    }

    .landingthrddivpppttpspan {
        font-size: 20px;
        padding-top: 10px;
    }



    .ecxpericelanfibhlododivwhtf {
        width: 100%;
        max-width: 100%;
    }

    .ecxpericelanfibhlododivtxtppdh {
        width: 100%;
        max-width: 100%;
    }

    .ecxpericelanfibhlodoftxtyflx {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .landingsxthhdmainfinalbg {
        padding: 33px 50px 20px 50px;
    }

    .landingsxthdivmainflx {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .landingdibfsxthfrstsectn {
        width: 100%;
        max-width: 100%;
        align-self: flex-start;
    }

    .landingworkplcecertficasxth {
        padding: 60px 0px 60px 0px;
    }

    .landingworkplcecertficasvnth {
        padding: 60px 0px 60px 0px;
    }

    .landingdibfsxthscndsectn {
        width: 100%;
        max-width: 100%;
    }

    .landingfrthdmainfinalbg {
        padding: 33px 50px 20px 50px;
    }

    .landingexperincehubthrpdding {
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 40px;
    }

    .landingexperincehubthrdwth {
        padding: 20px 0px 20px 0px;
    }

    .emplyrtxtppnj {
        font-size: 28px;
    }

    .strderbtn {
        /* padding: 10px 0px; */
        margin: 22px 0px;
    }

    .landingexoerincehubfrthflx {
        flex-wrap: wrap;
    }

    .landingexoerincehubfrthsctnhgi {
        width: 100%;
        max-width: 100%;
    }

    .landingexoerincehubfrthsctimg {
        width: 100%;
        max-width: 100%;
    }

    .SevenEmployeeepehhkimg {
        width: 100%;
        height: auto;
    }

    .landingexoerincehubfrth {
        padding: 60px 0px 60px 0px;
    }

    .landingexoerincehubfrthsctnhgi {
        width: 100%;
        max-width: 100%;
        align-self: flex-start;
        padding-top: 0px;
    }

    .landingexoerincehubffthflx {
        flex-wrap: wrap;
    }

    .landingexoerincehubffthsctntxtu {
        width: 100%;
        max-width: 100%;
    }

    .landingexoerincehubffthsctnimg {
        width: 100%;
        max-width: 100%;
    }

    .bulletinboardimgekljkimhj {
        height: auto
    }

    .landingexoerincehubffth {
        padding: 60px 0px 60px 0px;
    }

    .landingexoerincehubsxthflx {
        flex-wrap: wrap;
    }

    .landingexoerincehubsxmaindivbjhj {
        width: 100%;
        max-width: 100%;
    }

    .landingexoerincehubsxmaindiimgjb {
        width: 100%;
        max-width: 100%;
    }

    .Openintervueimgdibimh {
        height: auto;
    }

    .landingexprcnedivflx {
        flex-wrap: wrap;
    }

    .landingexprcnrdivsctmnfrst {
        width: 100%;
        max-width: 100%;
    }

    .landingexprncrdivsctmnscnd {
        width: 100%;
        max-width: 100%;
    }

    .landibimhdmmainemploiying {
        height: auto;
    }

    .landingexperincehbegth {
        padding: 60px 0px 60px 0px;
    }

    .landingexperincehbnnth {
        padding: 60px 0px 60px 0px;
    }

    .Securityimgiaon {
        width: 100%;
        height: auto;
        max-width: 100%;
        min-width: 100%;
        margin: 30px auto;
    }

    .securitydivmainflx {
        flex-wrap: wrap;
        row-gap: 10px;
    }

    .securitydivmainnbg {
        width: 100%;
        max-width: 48%;
    }

    .landingsctionfrstpppop {
        font-family: Urbanist;
        font-size: 45px;
    }

    .landingexoerincehubsxth {
        padding: 30px 0px 60px 0px;
    }

    .aiimagemainimgsiv {
        height: auto;
    }

    .landingworkplcecertrfstsctnsibk {
        width: 100%;
        max-width: 100%;
    }

    .landingworkplcecertficafrst {

        padding: 210px 0px 39px 0px;
    }

    .landibwrkplcedivcltrstrfst {
        width: 100%;
        background-color: #555555;
        padding: 41px 0px 40px 0px;
    }

    .landibwrkplcedivcltrstrfstflx {
        flex-wrap: wrap;
    }

    .landibwrkplcedivcltrstrfstwhj {
        width: 100%;
        max-width: 100%;
    }

    .landibwrkplcedivcltrstrftfxtbtnm {
        width: 100%;
        max-width: 100%;
        padding-top: 20px;
    }
    .subscriptionmaindibflx{
        flex-wrap: wrap;
        row-gap: 30px;
        column-gap: 30px;
    }
    .subscriptionmaindibfrst {
        width: 100%;
        max-width: 48%;
        min-width: 48%;
    }
}

@media(max-width:767px) {
    .work {
        padding: 60px 0;
    }

    .tablecertificatefvn {
        padding: 30px 40px;
        width: 100%;
        min-width: 288px;
        overflow-x: auto;
    }
    .subscriptionmaindibfrst {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .bgbadgettdivrpt {
        width: 100%;
        max-width: 44%;
        background-color: #fff;
        border-radius: 30px;
        position: relative;
    }

    .lamdimnghubxcginm {
        padding: 0px 0px 36px 0px;
    }

    .lamdimnghubxcginmlnhj {
        color: #fff;
        font-size: 18px;
        line-height: normal;
        font-family: Montserratsemi;
    }

    .landingsctionfrstpppop {
        font-family: Urbanist;
        font-size: 36px;
    }

    .badgeattrctafnttyflx {
        flex-wrap: wrap;
        row-gap: 30px;
    }


    .landingrfsrttgriddiv {
        grid-template-areas:
            "box1 box1 box1 box1"
            "box2 box2 box2 box2"
            "box3 box3 box3 box3"
            "box4 box4 box4 box4"
            "box5 box5 box5 box5"
            "box6 box6 box6 box6"
            "box7 box7 box7 box7"
            "box8 box8 box8 box8";
    }

    .grditmmainonno {
        align-items: center;
        justify-content: center;
    }

    .forms .form-content .input-box {
        display: flex;
        align-items: center;
        height: 37px;
        width: 100%;
        margin: 10px 0;
        position: relative;
    }

    .banner {
        padding: 60px 0;
    }

    .about {
        padding: 60px 0;
    }

    .banner_img {
        max-width: 500px;
        margin: 0 auto;
    }

    .banner_img_section {
        margin-top: 30px;
    }

    .title p {
        font-size: 17px;
    }

    .container-parent {
        padding: 15px;
    }

    .title span {
        font-size: 30px;
    }

    .forms {
        padding: 9px 9px;
        height: 480px;
    }

    .conten {
        max-width: 100%;
    }

    .conten p {
        font-size: 15px;
    }

    .about-dtls {
        margin-top: 30px;
    }

    .responsive {
        flex-direction: column-reverse;
    }

    .share_event {
        padding: 30px 40px;
    }

    .Associate {
        padding: 30px 0;
    }

    .work {
        padding: 30px 0;
    }

    .service {
        padding: 30px 0;
    }

    .roles {
        padding: 30px 0;
    }

    .recognition {
        padding: 40px;
    }

    .Dialoguein_section {
        width: 100%;
        height: auto;
        padding: 30px 77px;
    }

    /* 21 sep */
    .associate_slider {
        margin-top: 35px;
    }

    .footer_head {
        flex-wrap: wrap;
    }

    .footer_main_conten {
        margin-bottom: 20px;
    }

    .footer_conten {
        margin-top: 15px;
    }

    .responsive {
        flex-direction: column-reverse;
    }

    .service {
        padding: 30px 0;
    }

    .banTextsDiv {
        width: 64%;
    }

    .manageTextsDiv {
        width: 80%;
    }

    .employAllTextsDiv {
        width: 70%;
    }

    .advocacyTextsDiv {
        width: 56%;
    }

    .landBannerSection {
        height: 300px;
    }

    .aBettText {
        font-size: 20px;
        word-spacing: 0px;
    }

    .atThecenText {
        font-size: 15px;
    }

    .getStartBtn {
        font-size: 12px;
    }

    .manageBySection {
        height: 300px;
    }

    .manageByExpText {
        font-size: 20px;
        word-spacing: 0px;
        margin: 0 0 15px 0;
    }

    .employEngageSection {
        height: 300px;
    }

    .employEngText {
        font-size: 20px;
        word-spacing: 0;
        margin: 0 0 22px 0;
    }

    .employHigherText {
        font-size: 13px;
    }

    .compreHenSection {
        height: 400px;
        padding: 10px 0;
    }

    .compreNationText {
        font-size: 14px;
    }

    .compreNatioSmallText {
        font-size: 13px;
    }

    .compreAllNatioTextsDiv {
        padding-left: 10px;
    }

    .firstCompreText {
        padding-left: 10px;
        font-size: 15px;
    }

    /* .labdChartImgSection {
        height: 300px;
    } */

    /* .labdChartImgSection {
        height: 300px;
    } */

    .landFtLeftFig {
        width: 25%;
    }

    .conctStrt {
        width: 80%;
    }

    .footerLandInDiv>div {
        width: 40%;
        margin-bottom: 25px;
    }

    .secondFooterPart {
        height: 445px;
    }

    .footerLandInDiv {
        padding-top: 30px;
        margin-bottom: 25px;
    }

    .ftrLiAn {
        font-size: 13px;
    }

    .landRightFtTextDiv {
        padding: 20px 25px;
    }

    .landHeadBtns {
        padding: 5px 11px;
        margin-right: 4px;
        font-size: 15px;
    }

    .landLogoText {
        font-size: 13px;
    }

    .landHeadSection {
        padding: 10px 0;
    }

    .rescLandHeadBtn {
        font-size: 14px;
    }

    .mrktvcdivflx {
        flex-wrap: wrap;
    }

    .mrktvcdivwhfgdfdiv {
        width: 100%;
        max-width: 38%;
        margin: auto;
    }

    .mrktcvdtstcp {
        font-size: 11px;
        font-family: Montserratsemi;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        margin-top: -39px;
    }

    .emplyedivlogoimg {
        width: 100%;
        max-width: 223px;
        min-width: 223px;
        height: 88px;
    }

    .menudivmainbdr {
        width: 100%;
        max-width: 100%;
        padding: 9px 3px;
    }


}


@media(max-width:579px) {
    .work {
        padding: 60px 0;
    }
    .chsesubdcdhppaln {
        font-size: 34px;
    }

    .navglnktxtppp {
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        font-family: "Montserratsemi";
        line-height: 18px;
        padding: 7px;
    }

    .emplyrtxtppnj {
        font-size: 20px;
    }

    .navglnktxtppp.active {
        color: #000;
        font-size: 12px;
        font-weight: 500;
        font-family: "Montserratsemi";
        line-height: 13px;
        background-color: #fff !important;
        padding: 5px 12px;
        border-radius: 21px;
    }

    .menudivbrdr {
        border: none;
        border-radius: 20px;
        width: 100%;
        /* padding: 2px 0px; */
    }

    .navglnktxtpppbtn {
        color: #000;
        font-size: 12px;
        font-weight: 500;
        font-family: "Montserratsemi";
        line-height: 13px;
        background-color: #fff !important;
        padding: 6px 12px;
        border-radius: 21px;
        border: none;
    }






    .banner {
        padding: 50px 0;
    }

    .about {
        padding: 50px 0;
    }

    .share_event {
        padding: 30px;
    }

    .Associate {
        padding: 30px 0;
    }

    .service {
        padding: 30px 0;
    }

    .roles {
        padding: 30px;
    }

    .Benefits {
        padding: 30px;
    }

    .Dialoguein_section {
        padding: 30px;
    }

    .recognition {
        padding: 30px;
    }

    /* 21 sep */
    .followers p {
        font-size: 20px;
    }

    .footer_conten a {
        font-size: 13px;
    }

    .socal_media a i {
        font-size: 16px;
    }

    .footer_logo {
        width: 150px;
    }

    .Benefits {
        padding: 30px;
    }

    .advoTextHead {
        font-size: 20px;
        margin: 0 0 20px 0;
    }

    .employAdvoSection {
        height: 300px;
    }

    .networkLandSection {
        height: 300px;
    }

    .netWrkTextHead {
        font-size: 20px;
        margin: 0 0 15px 0;
    }

    .netWrkSmallTexts {
        margin: 0 0 20px 0;
    }

    .onboardLandSection {
        height: 300px;
    }

    .onbrdHireHead {
        font-size: 20px;
        margin: 0 0 15px 0;
    }

    .onbrdHireTexts {
        margin: 0 0 20px 0;
    }

    .employVoiceSection {
        height: 300px;
    }
}

@media (max-width: 575px) {
    .margintop {
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-left: 65px;
    }
    .subscriptiondivmain {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
    }

    .bgbadgettdiv {
        width: 100%;
        max-width: 70%;
    }

    .securitydivmainnbg {
        width: 100%;
        max-width: 90%;
    }

    .badgeattrctafnttyflx {
        flex-wrap: wrap;
        row-gap: 46px;
    }

    .mrktvcdivflx {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 0%;
    }

    .margintop::-webkit-scrollbar {
        display: none;
    }

    .divfrmabs {
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
        width: 100%;
    }

    .bariconlanding i {
        font-size: 15px;
        color: #fff;
        display: block;
    }

    .sidebar {
        display: block;
        transform: translate(-350px, 0);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }

    .work_title span {
        font-size: 20px;
    }
    .subscriptionmaindibflx {
        flex-wrap: wrap;
        row-gap: 70px;
        column-gap: 30px;
    }

    .work_title p {
        font-size: 20px;
    }

    .ftrLiAn {
        font-size: 11px;
    }

    .ftrLi {
        margin-right: 10px;
    }

    .leveragebbiokkppdiv {
        padding: 15px 18px 15px 18px;
        height: auto
    }

    .aasemntmethodolgydivflx {
        flex-wrap: wrap;
    }

    .assmtnerthotxtppdivlistdiv {
        width: 100%;
        max-width: 100%;
    }

    .stepwiseimageimgmain {
        width: 100%;
        max-width: 100%;
    }

    .mangemntexpjhflxhhvhgj {
        flex-wrap: wrap;
        column-gap: 15px;
    }

    .landibwrkplcedivcthrdhvbfrsttxtwhj {
        width: 100%;
        max-width: 100%;
    }

    .landibwrkplcedivcthrdhvbmwtxtppdiv {
        width: 100%;
        max-width: 100%;
    }

    .landingworkplcecertficafrst {
        padding: 150px 0px 39px 0px;
    }

    .strderbtndiojhk {
        margin: 10px 0px;
    }

    .landingexperincehbfrxst {
        padding: 150px 0px 39px 0px;
    }

    .landingfistmainfinal {
        padding: 150px 0px 39px 0px;
    }

    .menudivmainbdr {
        display: none;
    }

    .bariconlandingspcl i {
        display: block;
    }
    .priceoplantxtpp {
        font-size: 40px;
        font-weight: 700;
    }
    .priceoplantxtppdlrtcfyhy {
        color: #fff;
        font-family: Montserratextra;
        font-size: 26px;
        font-weight: 500;
    }
    .priceoplantxtppdlr {
        color: #fff;
        font-family: Montserratextra;
        font-size: 26px;
        font-weight: 500;
    }
    .listdivallmnfst {
        font-size: 13px;
    }
}


@media(max-width:480px) {
    .work {
        padding: 30px 0;
    }

    /* .sidebar {
        display: block;
        transform: translate(-350px, 0);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    } */

    .landingworkplcedivscngfdivflx {
        flex-wrap: wrap;
        column-gap: 10px;
    }

    .landibwrkplpercenycboxcx {
        width: 100%;
        max-width: 100%;
        background-color: #fff;
        border: 4px solid #fff;
        border-radius: 20px;
        /* padding: 10px; */
        padding: 12px 12px 13px 12px;
    }

    .evpmaturitymodalbg {
        width: 100%;
        max-width: 100%;
    }

    .boxspeciallogoimagmain {
        width: 100%;
        max-width: 100%;
    }

    .landingsctionfrstpp span {
        font-size: 24px;
        line-height: 39px;
    }

    .landingsxthhdmainfinalbg {
        padding: 33px 25px 20px 25px;
    }

    .emplyrtxtppnj {
        font-size: 17px;
    }

    .landingfrthdmainfinalbg {
        padding: 33px 25px 20px 25px;
    }

    .strderbtn {
        padding: 11px 44px;
        background-color: #fff;
        border-radius: 35px;
        color: #002060;
        font-size: 14px;
        font-family: Montserratsemi;
        font-size: 16px;
        border: none;
        font-weight: 800;
    }

    .landingfifthdmainfinalbg {
        padding: 33px 25px 20px 25px;
    }

    .landingsixthdmainfinalbg {
        padding: 33px 25px 20px 25px;
    }

    .landingffthdmainfinalbg {
        padding: 33px 25px 20px 25px;
    }

    .bgbadgettdivrpt {
        width: 100%;
        max-width: 100%;
        background-color: #fff;
        border-radius: 30px;
        position: relative;
    }

    .mrktvcdivwhfgdfdiv {
        width: 100%;
        max-width: 80%;
        margin: auto;
    }

    .sub-card {
        max-width: 110px;
        height: 85px;
    }

    .landingsctionfrstpp {
        font-size: 23px;
        line-height: 29px;
    }

    .mrktvcdivoenghdjh svg {
        width: 100%;
    }

    /* .mrktvcdivwhfgdfdiv {
        width: 100%;
        max-width: 54%;
        margin: auto;
    } */

    .logoheadertopdiv {
        width: 163px;
        height: 81px;
    }

    .card_img {
        width: 90px;
        height: 86px;
    }

    .card_title p {
        font-size: 14px;
    }

    .work_card {
        max-width: 380px;
        height: 258px;
    }

    .card_dtls {
        margin-top: 5px;
    }

    .card_dtls p {
        font-size: 11px;
    }

    .about {
        padding: 30px 0;
    }

    .banner {
        padding: 30px 0;
    }

    .title p {
        font-size: 20px;
    }

    .title span {
        font-size: 20px;
    }

    .banner_btn .button1 {
        width: 109px;
        height: 38px;
        font-size: 12px;
        margin-right: 20px;
    }

    .banner_btn .button2 {
        width: 109px;
        height: 38px;
        font-size: 12px;
    }

    .conten p {
        font-size: 13px;
    }

    .service {
        padding: 15px 0;
    }

    .title {
        margin-bottom: 11px;
    }

    .share_event {
        padding: 15px;
    }

    .Associate {
        padding: 15px 0;
    }

    .roles {
        padding: 15px 0;
    }

    .roles_point {
        padding: 0px 30px;
    }

    .Dialoguein_section {
        width: 100%;
        height: auto;
        padding: 15px;
    }

    .recognition {
        padding: 15px;
    }

    .about-dtls {
        margin-top: 10px;
    }

    .recognition {
        padding: 20px 15px;
    }

    /* 21 sep */
    .swiper-horizontal {
        touch-action: pan-y;
        height: 175px;
    }

    .footer_conten a {
        padding-right: 10px;
    }

    .title {
        margin-bottom: 11px;
    }

    .share_event {
        padding: 15px;
    }

    .roles_point {
        padding: 0px 30px;
    }
}

@media (max-width: 479px) {
    .margintop {
        padding-left: 50px;
    }

    .landHeadBtns {
        padding: 5px 6px;
        margin-right: 3px;
        font-size: 10px;
    }

    .landLogoFig {
        width: 45px;
        min-width: 45px;
        height: 43px;
    }

    .landLogoText {
        font-size: 11px;
    }

    .banTextsDiv {
        width: 100%;
    }

    .aBettText {
        font-size: 19px;
    }

    .weHelpText {
        margin: 0px 0 10px 0;
    }

    .landBannerSection {
        height: 250px;
    }

    .manageTextsDiv {
        width: 100%;
    }

    .manageBySection {
        height: 280px;
    }

    .manageByExpText {
        font-size: 14px;
        letter-spacing: 3px;
        margin: 0 0 15px 0;
    }

    .landinexptnvbentoffth {
        grid-area: box5;
        padding: 23px 10px;
    }

    .empExpText {
        font-size: 12px;
        width: 100%;
    }

    .hpyEmpText {
        font-size: 12px;
        width: 100%;
    }

    .employEngText {
        font-size: 18px;
        margin: 0 0 13px 0;
    }

    .employAllTextsDiv {
        width: 100%;
    }

    .employHigherText {
        font-size: 12px;
        margin: 0 0 15px 0;
    }

    .emploOurText {
        font-size: 12px;
    }

    .employEngageSection {
        height: 250px;
    }

    .compreHenTextLeft {
        position: relative;
        top: 0;
        transform: translate(0, 0);
        width: 100%;
    }

    .compreHenSection {
        height: auto;
        padding: 10px 0;
    }

    .compreDeskFig {
        width: 100%;
        height: auto;
        position: relative;
        top: 0;
        transform: translate(0, 0);
        margin-top: 15px;
    }

    /* 
    .labdChartImgSection {
        height: 225px;
    } */

    .landChartFig {
        width: 85%;
    }

    .advocacyTextsDiv {
        width: 100%;
    }

    .advoTextPara {
        margin: 0 0 20px 0;
    }

    .employAdvoSection {
        height: 250px;
    }

    .networkLandSection {
        height: 250px;
    }

    .networkAllTextsDiv {
        width: 100%;
    }

    .onboardTextsDiv {
        width: 100%;
    }

    .onboardLandSection {
        height: 250px;
    }

    .employVoiceSection {
        height: 250px;
    }

    .landRightFtTextDiv {
        padding: 15px 12px;
    }

    .landRightFtTextDiv {
        flex-direction: column;
    }

    .conctStrt {
        width: 92%;
    }

    .conctStrt {
        width: 92%;
    }

    .ftprtcCntcText {
        font-size: 15px;
    }

    .strtJrnyText {
        font-size: 13px;
    }

    .iagreePart label {
        font-size: 11px;
    }

    .footLndBtnDiv button {
        font-size: 15px;
    }

    .footLndBtnDiv input {
        font-size: 11px;
    }
}

@media (max-width: 420px) {

    /* 21 sep */
    .associate_logo {
        max-width: 60px;
    }

    .footer_conten a {
        font-size: 11px;
        padding-right: 6px;
    }

    .followers p {
        font-size: 16px;
    }

    .footer_logo {
        width: 130px;
    }

    .socal_media {
        margin-top: 7px;
    }

    .footer_conten {
        margin-top: 9px;
    }

    .askforbtn {
        font-size: 14px;
    }

    .viewplanbtn {
        font-size: 14px;
    }
}

@media (max-width: 359px) {
    .header .navbar-brand {
        width: 165px;
        height: 45px;
    }

    .custContain .navbar-toggler-icon {
        width: 1.1em;
        height: 1.3em;
    }
}

/* Testimonial Javascript Testimonial */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    #testimonial-area .section-heading h2 {
        font-size: 30px;
    }

    .client-comment h3 {
        font-size: 18px;
        line-height: 28px;
    }

    .client-single.active {
        width: 60%;
    }

    .client-single:not(.active) {
        -webkit-transform: scale(0.55);
        transform: scale(0.35);
    }

    .client-single.position-3,
    .client-single.position-7 {
        -webkit-transform: scale(.30) !important;
        transform: scale(.30) !important;
    }

    .client-single.active .client-img img {
        max-width: 100px;
    }

    .client-single.active .client-img::before {
        padding: 5px;
        width: 108px;
        height: 108px;
        top: -4px;
        left: 6px;
    }

    .client-single.active .client-img {
        width: 120px;
        height: 100px;
    }

    .testi-wrap {
        height: 580px;
    }

    #testimonial-area {
        padding: 40px 0 0;
    }

    #testimonial-area .section-heading h5 {
        margin-bottom: 25px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    #testimonial-area .section-heading h2 {
        font-size: 30px;
    }

    .client-comment h3 {
        font-size: 14px;
        line-height: 26px;
    }

    .client-single.active {
        width: 60%;
    }

    .client-comment p {
        font-size: 15px;
    }

    &.active {
        top: 18%;
    }

    .star_img {
        max-width: 60px;
    }

    #testimonial-area .section-heading h5 {
        margin-bottom: 20px;
        font-size: 28px;
    }

    #testimonial-area {
        padding: 30px 20px;
    }

    .client-comment span i {
        font-size: 40px;
    }

    .client-single:not(.active) {
        -webkit-transform: scale(0.55);
        transform: scale(0.35);
    }

    .client-single.position-5,
    .client-single.position-7 {
        right: 0;
    }

    .client-single.position-4 {
        left: 0;
    }

    .client-single.position-3,
    .client-single.position-7 {
        -webkit-transform: scale(.30) !important;
        transform: scale(.30) !important;
    }

    .client-single.active .client-img img {
        max-width: 80px;
    }

    .client-single.active .client-img::before {
        padding: 5px;
        width: 88px;
        height: 88px;
        top: -4px;
        left: 16px;
    }

    .client-single.active .client-img {
        width: 120px;
        height: 100px;
    }

    .testi-wrap {
        height: 630px;
    }
}

@media only screen and (min-width: 360px) and (max-width: 479px) {

    #testimonial-area {
        padding: 20px 15px;
    }

    #testimonial-area .section-heading h5 {
        font-size: 27px;
        margin-bottom: 15px;
    }

    .star_img {
        max-width: 50px;
    }

    .client-comment h3 {
        font-size: 14px;
        line-height: 26px;
    }

    .client-comment p {
        font-size: 16px;
    }

    &.active {
        top: 20%;
    }

    .client-single.active {
        width: 100%;
    }

    .client-comment span i {
        font-size: 40px;
    }

    .client-single:not(.active) {
        -webkit-transform: scale(0.25);
        transform: scale(0.25);
    }

    .client-single.position-5,
    .client-single.position-7,
    .client-single.position-6 {
        right: -70px;
    }

    .client-single.position-4 {
        left: -60px;
    }

    .client-single.position-3 {
        left: -75px;
    }

    .client-single.position-3,
    .client-single.position-7 {
        -webkit-transform: scale(.25) !important;
        transform: scale(.25) !important;
    }

    .client-single.active .client-img img {
        max-width: 80px;
    }

    .client-single.active .client-img::before {
        padding: 5px;
        width: 88px;
        height: 88px;
        top: -4px;
        left: 16px;
    }

    .client-single.active .client-img {
        width: 120px;
        height: 100px;
    }

    .testi-wrap {
        height: 600px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 359px) {
    #testimonial-area {
        padding: 40px 15px;
    }

    #testimonial-area .section-heading h5 {
        font-size: 22px;
        margin-bottom: 15px;
    }

    .star_img {
        max-width: 50px;
    }

    .client-comment h3 {
        font-size: 14px;
        line-height: 26px;
    }

    .client-comment p {
        font-size: 16px;
    }

    &.active {
        top: 20%;
    }

    &.position-2 {
        left: -76px;
    }

    .client-reting a i {
        font-size: 14px;
    }

    .client-comment h3 {
        font-size: 14px;
        line-height: 26px;
    }

    .client-single.active {
        width: 100%;
    }

    .client-comment span i {
        font-size: 40px;
    }

    .client-single:not(.active) {
        -webkit-transform: scale(0.25);
        transform: scale(0.25);
    }

    .client-single.position-5,
    .client-single.position-7,
    .client-single.position-6 {
        right: -70px;
    }

    .client-single.position-4 {
        left: -60px;
    }

    .client-single.position-3 {
        left: -75px;
    }

    .client-single.position-3,
    .client-single.position-7 {
        -webkit-transform: scale(.25) !important;
        transform: scale(.25) !important;
    }

    .client-single.active .client-img img {
        max-width: 80px;
    }

    .client-single.active .client-img::before {
        padding: 5px;
        width: 88px;
        height: 88px;
        top: -4px;
        left: 16px;
    }

    .client-single.active .client-img {
        width: 120px;
        height: 100px;
    }

    .testi-wrap {
        height: 550px;
    }
}