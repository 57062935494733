.ftr_section_main {
    background: #FFFFFF;
    box-shadow: 0px -29px 56px rgba(0, 0, 0, 0.25);
    position: fixed;
    z-index: 9;
    width: 100%;
    height: auto;
    bottom: 0;
    right: 0;
    left: 0;
    padding-top: 5px;
    display: none;
}

.ftr_section_main .navbar-nav {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    justify-content: space-around;
    width: 100%;
}

.ftr_section_main .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25px;
    height: 25px;
    position: relative;
    margin-bottom: 10px;
}

.icon i {
    width: 22px;
    height: 15px;
    color: #000000cf;
}

.ftr_section_main .name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #787A91;
}

.ftr_section_main .icon .red_dot {
    position: absolute;
    top: 3px;
    right: 4px;
    background-color: crimson;
    padding: 4px;
    border-radius: 50%;
}

.lstnv_mn .navbar-nav .nav-link .icon .activeHeadIcon {
    display: none;
}

.lstnv_mn .navbar-nav .nav-link .name {
    font-size: 12px;
    font-weight: 600;
}

.lstnv_mn .navbar-nav .active .icon .normalHeadIcon {
    display: none;
}

.lstnv_mn .navbar-nav .active .icon .activeHeadIcon {
    display: block;
}

.lstnv_mn .navbar-nav .active .icon i {
    color: #1D36AD;
}

.lstnv_mn .navbar-nav .active .name {
    color: #1D36AD;
}

@media(max-width:1199px) {
    .ftr_section_main {
        display: block;
    }

    .thought_area_mxh {
        /* height: 100vh;
        overflow-y: auto; */
        padding-bottom: 20px;
    }
}

@media (max-width: 479px) {
    .lstnv_mn .navbar-nav .nav-link .name {
        display: none;
    }
}

@media(max-width:420px) {
    .ftr_section_main .name {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 9px;
        line-height: 15px;
        color: #787A91;
    }
}