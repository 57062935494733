.thought_area_anlytics_left {
    height: 83vh;
    overflow-y: auto;
    width: 19%;
    position: fixed;
    left: 99px;
    top: 120px;
    z-index: 7;
    animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.thought_area_anlytics-mid {
    margin: 0 auto;
    padding-top: 119px;
    width: 47%;
}

.thought_area_anlytics_right {
    height: 83vh;
    overflow-y: auto;
    width: 19%;
    position: fixed;
    top: 120px;
    right: 96px;
    z-index: 7;
    animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}


.employeedemo {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 10px;
}

.emplyeedemotxt {
    font-family: NoirProRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #263238;
}

.gendragetxtpp {
    font-family: NoirProLight;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #263238;
    letter-spacing: 2px;
}

.genderageflx {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding: 20px 0px;
}

.malefmltxtpp {
    font-family: NoirProLight;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #263238;
    writing-mode: vertical-lr;
}

.genderdiv {
    width: 100%;
    max-width: 45%;
}

.agediv {
    width: 100%;
    max-width: 55%;
    text-align: end;
}

.maledivwdthflx {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px 0px 0px;
}

.apexchartlfnddiv .apexcharts-legend {
    display: none !important;
}

.engmntscrtxtp {
    font-size: 111px;
    line-height: 120px;
    font-family: Notosans;
    font-weight: 800;
    color: #142553;
    letter-spacing: 1px;
}

.ntinpblttstpp {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-family: noirprolight;
    font-weight: 500;
}

.thoghtmidanalytics {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 10px;
}

.employeeengdivflx {
    display: flex;
    justify-content: space-between;
    padding-top: 17px;
    padding-bottom: 17px;
}

.employeeengament {
    width: 100%;
    max-width: 24%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border: 1px solid #ccc;
    padding: 10px;
}

.employeengtxtppm {
    font-size: 12px;
    line-height: 18px;
    color: #000;
    text-align: center;
    font-weight: 500;
    font-family: noirprolight;
    padding-bottom: 5px;
}

.emplytxtpppercnt {
    font-size: 23px;
    line-height: 18px;
    color: #000;
    text-align: center;
    font-weight: 800;
    font-family: 'NoirProRegular';
    padding-bottom: 7px;
    padding-top: 7px;
}

.sprkchrtdiv {
    padding: 5px 0px;
}

.rtingttanlytics {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    font-weight: 500;
    color: aqua;
    font-family: noirprolight;
}

.mnthpretxtp {
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    font-weight: 500;
    color: rgba(68, 68, 68, 0.527);
    font-family: noirprolight;
}

.enpsdistxtp {
    font-size: 20px;
    line-height: 25px;
    font-weight: 600;
    color: #000;
    font-family: noirproregular;
    padding-bottom: 10px;
}

.enpstdistridiv {
    width: 100%;
    max-width: 58%;
}

.engagemntscrtxtntdiv {
    width: 100%;
    max-width: 38%;
}

.traininganalyticsdiv {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 15px 0px;
}

.participanttrainingflxdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.participantdivmain {
    width: 100%;
    max-width: 48%;
}

.trainingpantdivmain {
    width: 100%;
    max-width: 48%;
}

.participanttxtppp {
    font-size: 14px;
    color: aqua;
    font-weight: 600;
    text-align: center;
    font-family: noirprolight;
}

.housetringxttp {
    font-size: 14px;
    color: #ccc;
    font-weight: 600;
    text-align: center;
    font-family: noirprolight;
}

.employeeanalyticsdiv {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 15px 0px;
}

.ratingspecialonbrflx {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.ratingscorediv {
    width: 100%;
    max-width: 48%;
}

.onbordingdiv {
    width: 100%;
    max-width: 48%;
}

.bordrdivanlystd {
    width: 100%;
    border: 2px solid #ccc;
    /* padding: 10px */
}

.envddivmain {
    font-size: 13px;
    font-weight: 700;
    width: 100%;
    max-width: 26%;
    padding: 7px;
}

.envddivmainfrst {
    width: 100%;
    max-width: 15%;
    font-size: 14px;
    font-weight: 700;
    font-family: 'NotoSans';
    padding: 7px;
    text-align: center;
    color: #fff;
}

.envddivmainfrstscr {
    font-size: 13px;
    font-weight: 700;
    width: 100%;
    max-width: 26%;
    color: #000;
    line-height: 16px;
    text-align: center;
}

.envddivmainfrstnmbr {
    width: 100%;
    max-width: 15%;
    font-size: 14px;
    font-weight: 700;
    font-family: 'NotoSans';
    padding: 7px;
    text-align: center;
    color: #000;
}

.gapp {
    height: 10px;
}

.onbrdpptxtm {
    font-size: 15px;
    color: #000;
    font-family: noirprolight;
    font-weight: 700;
    line-height: 18px;
}

.optionmbtdtrdivnp {
    padding-bottom: 10px;
}

.optionmbtdtrdivnpmain {
    padding-top: 10px;
}

.sugstiongrvncedivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.suggestionbox {
    width: 100%;
    max-width: 48%;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
}

.grivencebox {
    width: 100%;
    max-width: 48%;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
}

.engagementdivmain {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 10px;
}

.employeeengdivflxtm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 10px;
    row-gap: 15px;
}

.employeeengamentengmntdiv {
    width: 100%;
    max-width: 48%;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 5px;

}

.rewrddivpnts {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 15px 0px;
}

.educationdivmain .apexcharts-legend {
    display: none !important;
}

.rewatddivmain .apexcharts-legend {
    display: none !important;
}

.enpstdistridiv .apx-legend-position-right {
    position: absolute !important;
    left: auto;
    top: 55% !important;
    right: 37px !important;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
}

.enpstdistridiv .apexcharts-legend-marker {
    background: rgb(20, 37, 83) !important;
    color: rgb(0, 143, 251);
    height: 9px !important;
    width: 30px !important;
    left: 0px;
    top: 0px;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-radius: 0px !important;
}

.optionmbtdtrdivnp .apexcharts-legend-marker {
    background: rgb(0, 143, 251) !important;
    color: rgb(0, 143, 251);
    height: 10px !important;
    width: 17px !important;
    left: 0px;
    top: 0px;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-radius: 0px !important;
}

.SuggestionReceiveddiv .apexcharts-legend-marker {
    background: rgb(0, 143, 251) !important;
    color: rgb(0, 143, 251);
    height: 10px !important;
    width: 15px !important;
    left: 0px;
    top: 0px;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-radius: 0px !important;
}

.GrivenceReceiveddiv .apexcharts-legend-marker {
    background: rgb(0, 143, 251) !important;
    color: rgb(0, 143, 251);
    height: 10px !important;
    width: 15px !important;
    left: 0px;
    top: 0px;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-radius: 0px !important;
}

.apexcharts-legend-text {
    color: #000 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 600 !important;
    font-family: noirprolight !important;
    margin-left: 5px !;
}

.initiativediv .apexcharts-legend {
    height: 230px;
    position: absolute;
    left: auto;
    top: 60px !important;
    right: 39px !important;
}

.employeehring {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
}

.bvjhgjhptcafchjnouy {
    font-size: 20px;
    line-height: normal;
    color: #000;
    font-family: noirprolight;
    font-weight: 500;
    padding: 5px 0px;
}

.bvjhgjhptcafchjnouydiv {
    padding: 10px 0px;
}

.expndimgdiv {
    width: 22px;
    height: 22px;
}

.expndimgdiv img {
    width: 100%;
    height: 100%;
}

.analyticsghedrtxticnflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hirerefralfillngnewflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 10px;
}

.newhiriesdiv {
    width: 100%;
    max-width: 48%;
    /* padding-bottom: 10px; */
}

.hringdivmann {
    background-color: rgb(20, 37, 83);
    width: 100%;
    padding: 6px;
    font-size: 13px;
    line-height: 16px;
    color: #fff;
    font-family: noirprolight;
    font-weight: 500;
    text-align: center;
}

.hritxtppngf {
    text-align: center;
    font-size: 29px;
    line-height: normal;
    color: #000;
    font-family: noirprolight;
    font-weight: 600;
    padding: 5px 0px;
}

.apexcharts-menu-icon {
    display: none;
}

.optionshringdivmaindiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    margin-left: -18px;
    /* height: 89px; */

}

.optionghringdivfst {
    width: 100%;
    max-width: 30%;
    height: 137px;
}

.optionghringdivsscnd {
    width: 100%;
    max-width: 30%;
    height: 137px;
}

.iconupgrntxt {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
}

.inldmghtyn i {
    color: green;
    font-size: 18px;
}

.plujbhgtnpp {
    font-size: 14px;
    line-height: 18px;
    color: green;
    font-family: noirprolight;
    font-weight: 500;
}

.hiringiocnspclhowndiffflas {
    margin-top: -42px;
    margin-left: 20px;
}

.hghjbbcvrtpp {
    font-size: 11px;
    line-height: 14px;
    color: #000;
    width: 100%;
    white-space: nowrap;
    font-family: 'NoirProRegular';
}

.hringtxtpptxtd {
    text-align: center;
    font-size: 18px;
    line-height: normal;
    color: #000;
    font-family: noirprolight;
    font-weight: 600;
    padding: 0px 0px;
}

/* 
.preyrvarinvbdivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -42px;
    margin-left: 50px;
} */

/* .optionshringdivmaindiv {
    padding: 10px 0px;
} */

.hranalyticssubsectoion {
    padding: 103px 0px 0px 0px;
}

.hranylytcicsdivflx {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    column-gap: 25px;
}

.hranylytcicsdivright {
    width: 100%;
    max-width: 240px;
    align-self: baseline;
    height: 90vh;
    overflow-y: auto;
    position: fixed;
    top: 102px;
    z-index: 7;
    background-color: #fff;
}

.hranylytcicsdivleft {

    margin: auto;
    max-width: 85%;
    width: 100%;
}



.hranylytcicsdivleftdibvmain {

    padding-left: 181px;
    padding-top: 10px;
}

.tab-titlesdiv {
    padding: 7px 0px 20px 0px;
}

.activechngtabnotchng {
    padding: 15px 17px;
    background-color: #ccc;
    margin: 20px 0px;
}

.activechngtabnotchng:first-child {
    padding: 15px 17px;
    background-color: #ccc;
    margin: 0px 0px;
}

.activechngtabnotchng:last-child {
    padding: 15px 17px;
    background-color: #ccc;
    margin: 0px 0px;
}

.activechng {
    padding: 15px 17px;
    background-color: #142553;
    margin: 20px 0px;
    color: #fff;
}

.activechng:first-child {
    padding: 15px 17px;
    background-color: #142553;
    margin: 0px 0px;
    color: #fff;
}

.activechng:last-child {
    padding: 15px 17px;
    background-color: #142553;
    margin: 0px 0px;
    color: #fff;
}

.advocacyanalyticssectionall {
    padding: 17px 0px 60px 0px;
}

.advovcaytimeslotdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 30px;
    /* padding: 20px 0px 0px 10px; */
}

.advovcaytimeslotmainflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 15px;
    background-color: #fff;
    border-radius: 30px;
    column-gap: 10px;
}

.infodivimg {
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
}

.infodivimg img {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100%;
}

.lstppxtpphj {
    font-size: 14px;
    color: #000;
    font-family: NoirProLight;
}

.advovcaycontentdivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 30px;
    padding: 10px 0px 10px 0px;
}

.advocntntdiv {
    width: 100%;
    max-width: 32%;
    min-width: 32%
}

.advocntntdivbg {
    width: 100%;
    max-width: 100%;
    background-color: #ffffff;
    padding: 20px 0px 0px 0px;
    border-radius: 20px;
}

.advocntntdivbgfrst {
    width: 100%;
    max-width: 100%;
    background-color: #ffb3b3;
    padding: 20px 0px 0px 0px;
    border-radius: 20px;
}

.advocntntdivbtmfrst {
    width: 100%;
    max-width: 100%;
    background-color: rgb(204, 5, 28);
    padding: 15px 30px 15px 30px;
    border-radius: 20px;
}

.advocntntdivbtm {
    width: 100%;
    max-width: 100%;
    background-color: rgb(20, 37, 83);
    padding: 15px 30px 15px 30px;
    border-radius: 20px;

}

.advocntntdivbgpp {
    color: #000;
    font-family: NoirProLight;
    font-size: 20px;
    font-weight: 300;
    line-height: normal;
    padding: 20px 26px 20px 26px;
}

.advocntntdivbgpptr {
    color: #ffffff;
    font-family: NoirProLight;
    font-size: 20px;
    font-weight: 300;
    line-height: normal;
}

.advocacypblxhednetwrkdidivflx {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    column-gap: 25px;
    padding: 30px 0px 10px 0px;
}

.advcydivcntntrleft {
    width: 100%;
    max-width: 70%;
}

.advcydivcntntrleftbng {
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    border-radius: 20px;
    padding: 10px;
}

.advcydivcntntrright {
    width: 100%;
    max-width: 30%;
}

.advcydivcntntrrightbg {
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    border-radius: 20px;
    padding: 10px;
    height: 253px;

}

.advocntntdivbgpptrtr {
    font-size: 20px;
    line-height: normal;
    font-weight: 500;
    color: #000;
    font-family: Poppinslight;
}

.ViewDetailsbtn {
    width: 100%;
    padding: 10px;
    background-color: #fff;
    border-radius: 30px;
    text-align: center;
    margin: 15px 0px;
}

.ViewDetailsbtnytx {
    color: #000;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    font-weight: 800;
    font-family: noirprolihght;
}

.ViewDetailsbtnytx:hover {
    text-decoration: none;
    color: #000;
    font-size: 16px;


}

.optionmbtdtrdivnpadvocay .apexcharts-legend {
    height: 300px !important;
    position: absolute;
    left: auto;
    top: 74px !important;
    right: 40px !important;
    overflow: visible !important;

}

.tabsadvcyalldiv .react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
    background-color: #fff;
    padding: 10px;
    border: none !important;
}

.tabsadvcyalldiv .react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: black;
    border-radius: 5px 5px 0 0;
    border: 2px solid green !important;
    border-radius: 9px !important;
    color: blue !important;
}

.tabsadvcyalldiv .react-tabs__tab {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    font-family: noirprolight;
    bottom: 0px !important;
    position: unset !important;
}

.tabsadvcyalldiv {
    padding: 30px 0px 10px 0px;
}

.tabsaconstadvcdivbg {
    background-color: #fff;
    border-radius: 20px;
    padding: 10px;
    overflow-x: auto;
    max-width: 100%;
    width: 100%;
}

.tabsadvcyalldiv .react-tabs__tab-panel {
    border-radius: 20px;
    padding: 20px 0px 0px 0px;
}

.imgdivtxtdivadvghhflx {
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    align-items: center;
}

.cdefidhimg {
    width: 100%;
    max-width: 80px;
    min-width: 80px;
    height: 80px;
}

.cdefidhimg img {
    width: 100%;
    height: 100%;
}

.cfreedsproutghbp {
    font-size: 14px;
    font-weight: 700;
    font-family: noirproregular;
    color: #000;
}

.sprtrtsxgpsdspn {
    font-size: 14px;
    font-weight: 400;
    font-family: poppinslight;
    color: #8a8484;
}

.tabsaconstadvcdivbg table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ddd;
    overflow-x: auto;
}

.poimhvhhtxtippadvc {
    font-size: 14px;
    font-weight: 500;
    font-family: poppinslight;
    color: #000;
    text-align: center;
}

.gappvcdivbg {
    height: 10px;
    border-bottom: 1px solid #ccc;
}

.reposnseovralldivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
    padding: 20px 0px 20px 0px;
}

.reposnseovralldivright {
    width: 100%;
    max-width: 30%;
    background-color: #fff;
    border-radius: 20px;
    padding: 10px;
}

.reposnseovralldivleft {
    width: 100%;
    max-width: 67%;
    background-color: #fff;
    border-radius: 20px;
    padding: 10px;
}

.thjhjsvhhjphj {
    font-size: 14px;
    font-weight: 700;
    font-family: poppinslight;
    color: #000;
    text-align: center;
}

.thjhjsvhhjphj span {
    font-weight: 400;
    font-size: 13px;
    font-family: poppinslight;
    margin-left: 8px;
}

.reposnseovralldivleftmain .apexcharts-legend {
    display: none;
}

.progressdiv {
    display: flex;
    height: 23px;
    overflow: hidden;
    line-height: 0;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem;
    margin: 10px;
}

.reposnseovralldivleftmainflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
    padding: 20px 0px 20px 0px;
}

.reposnseovralldivleftmain {
    width: 100%;
    max-width: 40%;
}

.reposnseovralldivleftmainnew {
    width: 100%;
    max-width: 60%;
}

.partienospchngdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    padding: 20px 0px 20px 0px;
}

.partienospchngdifrst {
    width: 100%;
    max-width: 32%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
}

.Participattionbg {
    background-color: #cccccc8c;
    border-radius: 10px;
    padding: 20px;
    margin: 30px;
}

.Participattionbgmaihnj {
    margin: 58px 20px;
}

.partvhhdgbjtxtyy {
    color: #000000aa;
    font-size: 15px;
    font-weight: 600;
    font-family: poppinslight;
    line-height: 28px;
    text-align: center;

}

.fghsbhytsyhgjo {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    font-family: poppinslight;
    line-height: 18px;
    text-align: center;

}

.partvhhdgbjtxtyybgbrdr {
    border-top: 1px solid #ccc;
    padding-top: 10px;
}

.advfhvvimgimghb {
    width: 100%;
    max-width: 40px;
    min-width: 40px;
    height: 40px;
    margin: 0 auto;
    padding-bottom: 10px;
}

.advfhvvimgimghb img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.previejklcurennjdihv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 39px 20px;

}

.exclnjhgimgmain {
    width: 100%;
    max-width: 33px;
    min-width: 33px;
    height: 30px;
}

.exclnjhgimgmain img {
    width: 100%;
    height: 100%;
}

.exclnjhgimgmaindlfx {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    padding-bottom: 10px;
}

.advocacyanalyticssectionall .react-tabs__tab-list {
    border-bottom: none !important;
    margin: 0 0 0px;
    padding: 6px;
}

.advocacyanalyticssectionall .react-tabs__tab {
    background: #fff;
    border-color: #aaa;
    color: black;
    /* border-radius: 5px 5px 0 0; */
    border-radius: 50px;
    margin-right: 20px;
}

.advocacyanalyticssectionall .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 0px !important;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: transparent !important;
}

.advocacyanalyticssectionall .react-tabs__tab--selected {
    border: 2px solid #142553;
}

@media(max-width:1499px) {

    .enpstdistridiv {
        width: 100%;
        max-width: 67%;
    }

    .thought_area_anlytics_left {
        width: 20%;
        left: 80px;
    }

    .thought_area_anlytics_right {
        width: 20%;
        right: 75px;
    }

    .thought_area_anlytics-mid {
        margin: 0 auto;
        padding-top: 119px;
        width: 47%;
    }

    .malefmltxtpp {

        margin-left: 17px;
    }



    .advcydivcntntrright {
        width: 100%;
        max-width: 32%;
    }
}

@media(max-width:1366px) {
    .thought_area_anlytics_left {
        width: 24%;
        position: fixed;
        left: 20px;
        top: 120px;
    }

    .thought_area_anlytics_right {
        width: 24%;
        top: 120px;
        right: 15px;
    }

    .employeeengdivflx {
        column-gap: 21px;
    }
}

@media(max-width:1199px) {
    .advocacypblxhednetwrkdidivflx {
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
    }

    .hranylytcicsdivright {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 100%;
        height: 94px;
    }

    .tab-titlesdiv {
        display: flex;
    }

    .reposnseovralldivflx {
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
    }
    .activechng{
        padding: 15px 17px;
        background-color: #142553;
        margin: 0px 0px;
    }
    .activechngtabnotchng{
        margin: 0px 0px;  
    }
    .hranylytcicsdivleftdibvmain {
        padding-left: 0px;
        padding-top: 96px;
        padding-bottom: 60px;
        background-color: #ffffff85;
        overflow-y: auto;
        height: 500px;
    }

    .thought_areadivflx {
        flex-wrap: wrap;
    }

    .thought_area_anlytics_left {
        margin: auto;
        width: 80%;
        position: relative;
        left: 0px;
        right: 0;
    }

    .maledivwdthflx {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0px 0px 0px;
    }

    .thought_area_anlytics_right {
        margin: auto;
        width: 80%;
        position: relative;
        left: 0px;
        right: 0;
        top: 50px;
        padding-bottom: 55px;
    }

    .reposnseovralldivright {
        width: 100%;
        max-width: 100%;
    }

    .thought_area_anlytics-mid {
        margin: 0 auto;
        padding-top: 140px;
        width: 80%;
    }

    .reposnseovralldivleft {
        width: 100%;
        max-width: 100%;
    }


    .advcydivcntntrleft {
        width: 100%;
        max-width: 100%;
    }

    .advcydivcntntrright {
        width: 100%;
        max-width: 100%;
    }

    .partienospchngdivflx {
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
    }

    .partienospchngdifrst {
        width: 100%;
        max-width: 100%;
    }

    .thought_areadivsectiomnmain {
        padding-bottom: 50px;
    }
}

@media(max-width:991px) {
    .hranylytcicsdivright {
        display: none;
    }

    .ratingspecialonbrflx {
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 20px;
    }

    .ratingscorediv {
        width: 100%;
        max-width: 100%;
    }

    .onbordingdiv {
        width: 100%;
        max-width: 100%;
    }

    .hranylytcicsdivleftdibvmain {
        padding-left: 0px;
        padding-top: 10px;
    }

    .advocacyanalyticssectionall {
        padding: 17px 0px 60px 0px;
    }

    .tabsaconstadvcdivbg table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        border: 1px solid #ddd;
        overflow-x: auto;
    }

}

@media(max-width:767px) {
    .cdefidhimg {
        width: 100%;
        max-width: 60px;
        min-width: 60px;
        height: 60px;
    }

    .employeeengdivflx {
        flex-wrap: wrap;
        row-gap: 30px;
        column-gap: 30px;
    }

    .enpstdistridiv {
        width: 100%;
        max-width: 100%;
    }

    .employeeengament {
        width: 100%;
        max-width: 29%;
    }

    .engagemntscrtxtntdiv {
        width: 100%;
        max-width: 100%;
    }

    .imgdivtxtdivadvghhflx {
        flex-wrap: wrap;
    }

    .reposnseovralldivleftmainnew {
        width: 100%;
        max-width: 100%;
    }

    .reposnseovralldivleftmain {
        width: 100%;
        max-width: 100%;
    }

    .reposnseovralldivleftmainflx {
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
    }

    .advovcaycontentdivflx {
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 20px;
    }

    .advocntntdiv {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .cfreedsproutghbp {
        white-space: nowrap;
    }

    .advovcaytimeslotdivflx {
        flex-wrap: wrap;
        row-gap: 20px;
    }

    .sugstiongrvncedivflx {
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 20px;
    }

    .suggestionbox {
        width: 100%;
        max-width: 100%;
    }

    .grivencebox {
        width: 100%;
        max-width: 100%;
    }
}

@media(max-width:576px) {
    .employeeengament {
        width: 100%;
        max-width: 46%;
    }

    .participanttrainingflxdiv {
        flex-wrap: wrap;
    }

    .participantdivmain {
        width: 100%;
        max-width: 100%;
    }

    .trainingpantdivmain {
        width: 100%;
        max-width: 100%;
    }

}

@media(max-width:480px) {
    .genderageflx {
        flex-wrap: wrap;
    }

    .genderdiv {
        width: 100%;
        max-width: 100%;
    }

    .agediv {
        width: 100%;
        max-width: 100%;
        text-align: left;
    }

    .thought_area_anlytics_left {
        margin: auto;
        width: 90%;
    }

    .thought_area_anlytics-mid {
        margin: 0 auto;
        padding-top: 140px;
        width: 90%;
    }

    .thought_area_anlytics_right {
        margin: auto;
        width: 90%;

    }

    .employeeengament {
        width: 100%;
        max-width: 100%;
    }
}