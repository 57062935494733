:root {
    --primary: #2196f3;
    --primary-md: #42a5f5;
    --primary-lt: #64b5f6;
    --primary-lightest: #e3f2fd;
    --secondary: #ff6f00;
    --gray-200: #eeeeee;
    --gray-300: #e0e0e0;
    --gray-400: #bdbdbd;
    --gray-500: #9e9e9e;
    --gray-700: #616161;
  
    --shadow-2dp: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    --shadow-4dp: 0 4px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
    --shadow-8dp: 0 8px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 12px 2px rgba(0, 0, 0, 0.12);
  }
  
  .inputs {
    height: 50px;
    width: 200px;
  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
    /*   border: 1px solid white; */
  }
  
  .inputs > * {
    margin-bottom: 48px;
  }
  
  .inputs input {
    font-family: 'Nunito';
    font-size: 90%;
    width: 200px;
    height: 40px;
    background-color: white;
    border-radius: 40px;
  }
  
  /* ============================ */
  /* Type 1 */
  /* ============================ */
  
  /* {
  display: flex;
  flex-direction: column;
  } */
  
  .fields {
    display: flex;
  }
  
  .input-wrapper {
    display: inline-block;
  }
  
  .input-wrapper label {
    display: block;
    color: white;
    margin-bottom: 6px;
  }
  
  .input-wrapper input {
    display: inline-block;
    border: none;
    border-radius: 3px;
    width: 200px;
    height: 48px;
  
    margin-right: 16px;
    padding-left: 16px;
    box-shadow: var(--shadow-2dp);
  }
  
  .date-field {
    cursor: pointer;
    text-align: center;
  }
  
  .calendar-widget {
    position: relative;
  }
  
  .calendar-widget:focus {
    outline: none;
  }
  
  .calendar-wrapper {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    padding-top: 8px;
  
    z-index: 2;
  }
  
  .dual-calendar {
    display: flex;
    /*   height: 300px; */
  
    border-radius: 3px;
    padding: 16px;
    box-shadow: var(--shadow-2dp);
    background-color: white;
  }
  .tabkksowmn{
    padding: 20px 0px;
  }
  .user_aboutnbetsrsdivnewmain {
    width: 100%;
    max-width: 28%;
}
  .dual-calendar .calendar:first-child {
    margin-right: 16px;
  }
  
  .calendar {
    width: auto;
  }
  
  .calendar-header {
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .month-text {
    font-family: 'Nunito';
    color: var(--gray-700);
  }
  
  .prev-btn,
  .next-btn {
    cursor: pointer;
    position: absolute;
    top: 50%;
  
    transform: translateY(-50%);
    z-index: 1;
  
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    border: none;
    border-radius: 50px;
    box-shadow: var(--shadow-2dp);
    background-color: var(--primary);
    color: white;
  
    transition: background-color 0.2s, box-shadow 0.2s;
  }
  
  .prev-btn:hover,
  .next-btn:hover {
    box-shadow: var(--shadow-4dp);
    background-color: var(--primary-md);
  }
  
  .prev-btn:active,
  .next-btn:active {
    box-shadow: var(--shadow-8dp);
    background-color: var(--primary-lt);
  }
  
  .prev-btn.disabled,
  .next-btn.disabled {
    cursor: default;
    box-shadow: none;
    background-color: var(--gray-300);
    color: var(--gray-500);
  }
  
  .prev-btn *,
  .next-btn * {
    user-select: none;
  }
  
  .prev-btn {
    left: 0;
  }
  
  .next-btn {
    right: 0;
  }
  
  .date-table-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 8px;
  }
  
  .day {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
  
    width: 40px;
    height: 40px;
  
    font-weight: 700;
  
    color: var(--gray-700);
  }
  
  .day.saturday {
    color: var(--primary);
  }
  
  .day.sunday {
    color: var(--secondary);
  }
  
  /* Date Styling */
  .date-table-row {
    display: flex;
    height: 40px;
    /* justify-content: space-between; */
  }
  
  .date {
    cursor: pointer;
    position: relative;
    /* display: flex;
    flex-direction: column;
    align-items: center;
  
    width: 40px;
    height: 40px; */
    transition: color 0.2s;
  }
  
  /* DO NOT CHANGE THE ORDER */
  
  .date.sunday {
    color: var(--secondary);
  }
  
  .date.today {
    color: var(--primary);
  }
  
  .date.selected {
    color: white;
  }
  
  .date.disabled {
    color: var(--gray-300);
  }
  
  .date.empty {
    cursor: default;
    user-select: none;
  }
  .date * {
    cursor: pointer;
    user-select: none;
  }
  
  .date.disabled * {
    cursor: not-allowed;
  }
  
  .date .help-text {
    position: absolute;
    top: 0;
  
    display: none;
    align-items: flex-start;
    justify-content: center;
  
    width: 100%;
    height: 15px;
    font-size: 10px;
    z-index: 1;
  }
  
  .date .date-text {
    display: flex;
    justify-content: center;
    align-items: center;
  
    width: 100%;
    height: 100%;
  
    font-size: 90%;
  
    z-index: 1;
  }
  .calendermodal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    /* left: 0;
    top: 0; */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    /* overflow: auto;  */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  .date .date-ripple {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
  
    width: 40px;
    height: 100%;
    background-color: transparent;
  
    will-change: transform;
  
    transition: transform 0.18s cubic-bezier(0, 0.75, 0.5, 1), background-color 0.2s;
  }
  
  .date .date-ripple.no-transition {
    transition: none;
  }
  
  .date.hover .date-ripple {
    background-color: var(--gray-300);
    transform: translate(-50%, -50%) scale(1);
  }
  
  .date.in-range .date-ripple {
    background-color: var(--primary-lightest);
    transform: translate(-50%, -50%) scale(1);
  }
  
  .date.selected .date-ripple {
    background-color: var(--primary);
    transform: translate(-50%, -50%) scale(1);
  }
  @media(max-width:1199px){
    .user_aboutnbetsrsdivnewmain {
      width: 100%;
      max-width: 100%;
  }
  }