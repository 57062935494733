.bulleDetail {
    padding: 140px 10px 20px 10px;
    /* margin-bottom: 20px;
    min-height: 97.5vh; */
}

.bulleDetail .bulletin_board .bottom {
    max-height: 100%;
}

.bulleDetail .container {
    padding: 0;
    max-width: auto;
    background-color: transparent;
    box-shadow: none;
}

.bulleDetail .bulletin_board {
    display: block !important;
}

.backHomeBtn {
    color: #1D36AD;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
    display: block;
}

.backHomeBtn img {
    width: 100%;
    height: 100%;
}

.backHomeBtn:hover {
    text-decoration: none;
    color: #1D36AD;
}

/* .bulleDetail #halloof_fame .bottom_area {
    height: auto;
} */

.bulleDetail #halloof_fame {
    display: block;
}

/* .bulleDetail #employees_card .bottom {
    height: auto;
} */

.bulleDetail #employees_card {
    display: block;
}

.bulleDetail #initiative_sec .bottom_ara {
    max-height: 100%;
}

.bulleDetail #initiative_sec {
    display: block;
}

/**** ****/
/* .bulleDetail #GoalAchiver .bottomsustaldiv {
    max-height: max-content;
    height: 100vh;
}

.bulleDetail #GoalAchiver .bottomsustaldiv {
    overflow-y: auto;
} */
#GoalAchiver .bottomsustaldiv {
    width: 100%;
    /* max-height: 250px; */
    padding: 20px 16px 20px 25px;
    /* overflow-y: hidden; */
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    flex-wrap: wrap;
    gap: 1%;
    height: 100vh;
    overflow-y: auto;
}

#GoalAchiver .bottomsustaldiv .mainssutandiv {
    width: 100%;
    max-width: 24%;
    box-shadow: 0px 0px 5px 0px #cccc;
    background: #fff;
    padding: 12px 15px;
    margin-bottom: 12px;
}

/* #GoalAchiver .bottomsustaldiv .mainssutandiv .left {
    display: flex;
    align-items: center;
} */
#GoalAchiver .bottomsustaldiv .mainssutandiv .left .profile {
    width: 100%;
    max-width: 120px;
    min-width: 110px;
    height: 118px;
    margin: auto;
}

#GoalAchiver .bottomsustaldiv .mainssutandiv .left .details h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #263238;
    padding: 0;
    margin: 0 0 4px 0;
    white-space: nowrap;
    text-align: center;
}

#GoalAchiver .bottomsustaldiv .mainssutandiv .left .details p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #7c7979;
    padding-top: 5px;
    margin: 0;
}

#GoalAchiver .bottomsustaldiv .mainssutandiv .details {
    padding-top: 10px;
}

#GoalAchiver .bottomsustaldiv .mainssutandiv .left .profile img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.deleteeditdivbg {
    width: 100%;
    padding: 5px 6px 7px 7px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    /* padding: 4px; */
    position: absolute;
    display: flex;
    top: 25px;
    right: 5px;
    max-width: 53px;
}

.editdivimgmaini {
    width: 100%;
    max-width: 19px;
    min-width: 19px;
    height: 19px;
}

.editdivimgmaini img {
    width: 100%;
    height: 100%;
}

/****  ****/

.bulleDetail #GoalAchiver {
    display: block;
}

.bulleDetail #training_card .bottom {
    max-height: max-content;
    height: 56vh;
}

.bottomtrainin {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 10px 0px 21px;
    gap: 1%;

}

#training_card .bottomtrainin .card_area_dtlstraing {
    width: 100%;
    max-width: 24%;
    height: auto;
    /* background: #ffebeb; */
    background: rgb(242, 233, 220);
    border-radius: 10px;
    padding: 10px;

    /* display: flex;
    align-items: center; */
    /* flex-wrap: wrap; */
}

#training_card .bottomtrainin .card_area_dtlstraing .details {
    width: 100%;
    height: auto;
    padding: 5px 10px 5px 8px;
}

#training_card .bottomtrainin .card_area_dtlstraing .details .up {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

#training_card .bottomtrainin .card_area_dtlstraing .details .date p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #263238;
    padding: 0;
    margin: 0 0 5px 0;
}

#training_card .bottomtrainin .card_area_dtlstraing .image_area {
    max-width: 100%;
    height: 140px;
    width: 100%;
    position: relative;
}

#training_card .bottomtrainin .card_area_dtlstraing .image_area img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

#training_card .bottomtrainin .card_area_dtlstraing .details .up .name h3 {
    width: 100%;
    max-width: 165px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #263238;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 32px;
}

#training_card .bottomtrainin .card_area_dtlstraing .details .date p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #263238;
    padding: 0;
    margin: 0 0 5px 0;
}

#training_card .bottomtrainin .card_area_dtlstraing .details .time p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #263238;
    padding: 0;
    margin: 0;
}

/* .bulleDetail #training_card .bottomtrainin {
    max-height: max-content;
    height: 100vh;
} */
.bulleDetail #jobs_referred {
    display: block;
}

.bulleDetail #jobs_referred .bottom_area {
    max-height: max-content;
    height: 100vh;
}

.bulleDetail #jobs_referred .bottom_area_details {
    display: flex;
    justify-content: flex-start;
    row-gap: 20px;
    column-gap: 22px;
    /* align-items: center; */
    /* gap: 2%; */
    padding: 15px 15px;
    flex-wrap: wrap;
}

.mainjobsreferred {
    width: 100%;
    max-width: 32%;
    background: rgb(255, 190, 152);
    border-radius: 3px;
    border-radius: 5px;
}

.dotflexthree {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 6px;
    padding-top: 10px;
    padding-right: 25px;
    position: absolute;
    top: 0;
    right: 0;
}


.threedotskll i {
    font-size: 14px;
    color: #000000;
    position: relative;
    cursor: pointer;
    top: -128px;
    left: 115px;
}

.editdeltedtdivbgwh {
    background-color: #fff;
    padding: 3px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
    position: absolute;
    top: 42px;
    right: 7px;
    border-radius: 5px;
}

.userplusicondivimg {
    width: 25px;
    height: 25px;
}

.userplusicondivimg img {
    width: 100%;
    height: 100%;
}

#jobs_referred .bottom_area_details .mainjobsreferred .top {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.bulleDetail .myAffitiDiv {
    margin-top: 0;
    height: auto;
    display: block;
}

.eventDetailsSection #eventsBulle .bottom {
    max-height: 100%;
}

.bulleDetail .bullentindetailflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    height: 100vh;
    overflow-y: auto;
    column-gap: 15px;
    row-gap: 15px;
    padding: 10px 10px;
}

.bulleDetail .card_area_bullentindetails {
    /* margin-right: 14px; */
    max-width: 32%;
}

.bulletin_board .bottom .card_area_details {
    width: 100%;
    height: auto;
    background: #eaebfb;
    border-radius: 10px;
    overflow: hidden;
    /* display: flex; */
    /* padding: 10px; */
    align-items: center;
}

.bulletin_board .bottom .card_area_details .event_img {
    width: 100%;
    max-width: 100%;
    height: 150px;
    overflow: hidden;
    position: relative;
}

.bulletin_board .bullentindetailflx .card_area_details .event_img {
    width: 100%;
    max-width: 100%;
    height: 150px;
    overflow: hidden;
    position: relative;
}

.bulletin_board .bottom .card_area_details .event_img img {
    width: 100%;
    height: 100%;
    /* border-radius: 10px; */
    overflow: hidden;
    object-fit: cover;
}

.detialshederdatepdng {
    padding: 10px;
}

.bulletin_board .bullentindetailflx .card_area_details .event_img img {
    width: 100%;
    height: 100%;
    /* border-radius: 10px; */
    overflow: hidden;
    object-fit: cover;
}

.bulletin_board .bottom .card_area_details .head h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #263238;
    width: 100%;
    /* max-width: 170px; */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* height: 35px; */
    margin-bottom: 7px;
    margin-top: 7px;
}

.view_dotsabs {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 7;
    width: 100%;
    height: 100%;
    background-color: #000000b2;
    border-radius: 10px;
}

.vwdnbtn {
    position: absolute;
    bottom: 10px;
    right: 6px;
    z-index: 9;
}

.jobs_referred .head h4 {
    font-size: 14px;
    line-height: 18px;
}

.jobsrfrdtsdiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.hedrtxtdibpp {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #000;
}

.bulleDetailsingleflx {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    column-gap: 10px;
}

.bulleDetailsinglefrst {
    width: 100%;
    max-width: 18%;
    position: fixed;
    height: 83vh;
    overflow-y: auto;
    position: fixed;
    top: 139px;
    z-index: 7;
    left: 120px;
}

.bulleDetailsinglemidle {
    width: 100%;
    /* max-width: 50%; */
    margin: 0 auto;
    width: 45%;
}

.bulleDetailsinglesnd {
    width: 100%;
    max-width: 17%;
    position: fixed;
    height: 83vh;
    overflow-y: auto;
    position: fixed;
    top: 139px;
    z-index: 7;
    right: 125px;
}

.bottom_area_detailsjobsrefrd {
    padding: 15px 15px;
}

.bottom_area_detailsdiv {
    width: 100%;
    max-width: 100%;
    /* background: rgb(255, 190, 152); */
    border-radius: 3px;
    border-radius: 0px;
    border-bottom: 1px solid #ccc;
}

.bottom_area_details .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bottomdivbgonbrdtx {
    width: 100%;
    max-width: 100%;
    /* background: rgb(255, 190, 152); */
    border-radius: 3px;
    border-radius: 0px;
    font-family: NoirProLight;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #263238;
    text-align: center;
    padding: 8px;
    margin: 0px 0px;
    border-bottom: 1px solid #ccc;
}

.jobssingledetailsmain {
    width: 100%;
    height: auto;
    padding: 130px 0px 20px 0px;
}

.jobssingledetailsflx {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 15px;
}

.jobsreferredmainbgdiv {
    width: 100%;
    max-width: 25%;
}

.jobsreferredmainbgsingle {
    width: 100%;
    max-width: 100%;
}

.bottomareadibsingle {
    padding: 10px;
    height: 100%;
    overflow-y: auto;
}

.hiringdivbg {
    width: 100%;
    max-width: 100%;
    border: 2px solid #d3a150;
    padding: 10px;
    border-radius: 12px;
}
.dwnsnnjndpdgghstsxtppp{
    font-size: 14px;
    color: #ffffff;
    font-weight: 500;
    font-family: notosans;
    
}

#jobs_referreddetilas {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
    padding-bottom: 8px;
}

#jobs_referreddetilas .top_area {
    width: 100%;
    height: auto;
    padding: 15px 20px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
}

.jobsappluicantdivright {
    width: 100%;
    max-width: 73%;
}

.jobsappluicantdivrightdivman {
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
}

.applicantdivmain {
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px 30px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.applicantimgdiv {

    width: 100%;
    max-width: 100px;
    min-width: 100px;
    height: 96px;

}

.applicantimgdiv img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.applicantimgdtlsdiflx {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    column-gap: 20px;
}

.downloadimgbamaib {
    width: 100%;
    max-width: 33px;
    min-width: 35px;
    height: 34px;
    background-color: #fff;
    border-radius: 50%;
    padding: 5px;
    position: relative;
    box-shadow: 0px 0px 5px 0px #ccc;
}

.downloadimgbamaib input {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    max-width: 33px;
    min-width: 35px;
    height: 34px;
}

.applicatbstdctxdivmm {
    font-family: NoirProRegular;
    font-style: normal;
    font-weight: 600;
    display: flex;
    column-gap: 5px;
    align-items: center;
    color: #000000;
    font-size: 14px;
    padding-bottom: 5px;
}

.bottom_area_detailsdivdivflx {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 30px;
    padding: 10px;
    margin-bottom: 2px;
}

.jobsredferdrttgdibvmanbhk {
    width: 100%;
    max-width: 480px;
    min-width: 480px;
}

.cmpltdrbtnkbhagn {
    background-color: #d3a145;
    border: none;
    outline: 0;
    padding: 5px 7px;
    font-size: 12px;
    color: #fff;
    font-family: poppinslight;
    line-height: 14px;
    font-weight: 600;
    border-radius: 5px;
}

.applicatgtxyhphjjh {
    font-family: poppinslight;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #263238;
    padding: 0;
    margin: 0;
}

.jobtitleredrdtlsffpprdtxtpp {
    font-family: noirprolight;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #263238;
}

.lbndapplicdtgsiucittxygjhgjh {
    font-size: 13px;
    color: #000;
    font-weight: 500;
    font-family: noirprolight;
    line-height: normal;
    display: flex;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    margin-bottom: 2px;

}

.lbndapplicdtgsiucittxygjhgjh span {
    margin-left: 5px;
    font-size: 14px;
}

.jobsredferdrttgdibsingldivbmah {
    width: 100%;
    max-width: 166px;
}

.applicatgtxyhphjjh span {
    font-size: 16px;
    margin-left: 5px;
    margin-right: 5px;
}

.aaplicanytddtop {
    padding: 2px 5px 5px 5px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}

.viewbtnnndivhajj {
    padding-top: 5px;
}

.listdivnmaonimgbg {
    padding-bottom: 20px;
}

.applicatbstdctxdivmm span {
    font-size: 16px;
    line-height: 24px;
    margin-left: 5px;
    font-weight: 500;
}

.accptrrjctrdivmsin {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    column-gap: 20px;
}

.accprbdinbbtn {
    background-color: green;
    color: #ffffff;
    font-size: 14px;
    padding: 6px 15px;
    font-weight: 500;
    font-family: poppinslight;
    line-height: normal;
    border-radius: 10px;
    border: none;
}

.rjxctgdbdinbbtn {
    background-color: red;
    color: #ffffff;
    font-size: 14px;
    padding: 6px 15px;
    font-weight: 500;
    font-family: poppinslight;
    line-height: normal;
    border-radius: 10px;
    border: none;
}

.aactptdtlbtnndivdlkcfh {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    column-gap: 20px;
}

.downloadimgbamaib img {
    width: 100%;
    height: 100%;
}

#jobs_referreddetilas .top_area .head h4 {
    font-family: NoirProRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #263238;
    padding: 0;
    margin: 0;
}

.bottomdtlsdivare {
    margin: 0px 0px 10px 0px;
}

.headrtbtndivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card_area_bullentindetails .event_img::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #00000057;
    z-index: 1;
}

.bulletindetailsview {
    padding-top: 120px;
    padding-bottom: 60px;
}

.singleimagemaindiv {
    width: 100%;
    height: 350px;
}

.ldestrcjobpojsk {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    font-family: noirprolight;
    line-height: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lbndapplicdtgsiuci {
    font-size: 15px;
    color: #000;
    font-weight: 500;
    font-family: noirprolight;
    line-height: normal;
    margin-bottom: 4px;
}

.jobstrfredisblck {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
    padding-bottom: 8px;
    border: 1px solid #ccc;
}

.jobstrfredisblck .top_area {
    width: 100%;
    height: auto;
    padding: 15px 20px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(198, 198, 210, 0.26);
}

.bottom_area_details {
    padding: 10px;
}

.jobsrefrerdetail {
    padding: 120px 0px 50px 0px;
}

.lbndapplicdtgsiuci span {
    margin-left: 5px;
    font-size: 14px;
}

.viewbtnnnmaindiv {
    margin-top: 5px;
}

.singleimagemaindiv img {
    width: 100%;
    height: 100%;
    position: relative;
}

.jobstrfredisblck .top_area .head h4 {
    font-family: NoirProRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #263238;
    padding: 0;
    margin: 0;
}
.trainingtitlettxpphh{
    color: #263238;
    font-family: Noirprolight;
    font-size: 13px;
    margin-bottom: 3px;
    font-weight: 400;
    line-height: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 78px;
    min-width: 78px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;  
}
.trainingtitlettxpphhdivflx{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* .vsmodalcntnt{
    width: 100%;
    height: 100%;
    background-color: #0000008c;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
}    */

@media (max-width: 1499px) {
    .backHomeBtn {
        font-size: 15px;
    }

    .jobsredferdrttgdibvmanbhk {
        width: 100%;
        max-width: 440px;
        min-width: 440px;
    }

    .jobstrfredisblck .top_area .head h4 {
        font-size: 14px;
        line-height: 18px;
    }

    #jobs_referreddetilas .top_area {
        padding: 5px 12px 5px 12px;
    }

    #jobs_referreddetilas .top_area .head h4 {
        font-size: 15px;
        white-space: nowrap;
        margin-left: 8px;
    }

    .bulleDetailsinglefrst {
        width: 17%;
        left: 120px;
    }

    .jobstrfredisblck .top_area {
        padding: 5px 12px 5px 12px;
    }

    .bulleDetailsinglemidle {
        width: 44%;
    }

    .bulleDetailsinglesnd {
        right: 115px;
        width: 17%;
    }
}

@media(max-width:1243px) {
    .jobsredferdrttgdibvmanbhk {
        width: 100%;
        max-width: 400px;
        min-width: 400px;
    }
}

@media (max-width: 1199px) {
    .backHomeBtn {
        font-size: 13px;
    }

    .bulleDetail .card_area_bullentindetails {
        max-width: 31%;
        margin: 20px 0px;
    }

    .bulletin_board .bottom {
        width: 100%;
        max-height: 113px;
        /* padding: 0px 0px 0px 30px; */
        /* overflow-y: hidden; */
    }

    .bulletin_board .bullentindetailflx {
        width: 100%;
        max-height: 113px;
        padding: 0px 0px 0px 30px;
    }

    .bulleDetailsinglefrst {
        height: 73vh;
        margin-left: 0px;
        width: 19%;
        left: 95px;
    }

    .bulleDetailsinglemidle {
        width: 42%;
    }

    .bulleDetailsinglesnd {
        height: 73vh;
        margin-right: 0px;
        width: 19%;
        right: 92px;
    }

    .jobsredferdrttgdibvmanbhk {
        width: 100%;
        max-width: 300px;
        min-width: 300px;
    }
}

@media (max-width: 1080px) {
    .bulletindtlsdivmainflxpag {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
}


@media(max-width:991px) {
    .bulletin_board .bottom {
        width: 100%;
        max-height: 113px;
        padding: 10px 15px 15px 30px;
        /* overflow-y: hidden; */
    }

    .jobsredferdrttgdibsingldivbmah {
        width: 100%;
        max-width: 613px;
    }

    .cmpltdrbtnkbhagnmain {
        margin-left: auto;
    }

    .jobstrfredisblck {
        display: block;
    }

    .bottomtrainin {
        padding: 16px 10px 0px 21px;
        gap: 1%;
    }

    #training_card .bottomtrainin .card_area_dtlstraing {
        width: 100%;
        max-width: 48%;
    }

    .bulletindtlsdivmainflxpag {
        flex-wrap: wrap;
    }

    .bulleDetail #jobs_referred .bottom_area_details {
        flex-wrap: wrap;
        padding: 15px 15px;
    }

    .bulleDetail {
        padding: 140px 10px 80px 10px;
    }

    .bulleDetail .card_area_bullentindetails {
        margin-right: 14px;
        max-width: 48%;
        margin: 20px 0px;
    }

    .mainjobsreferred {
        width: 100%;
        max-width: 48%;
        background: #ebffe7;
        border-radius: 3px;
        margin-bottom: 15px;
        margin-top: 15px;
        border-radius: 5px;
    }

    #GoalAchiver .bottomsustaldiv {
        flex-wrap: wrap;
        padding: 20px 16px 45px 25px;
    }

    #GoalAchiver .bottomsustaldiv .mainssutandiv {
        width: 100%;
        max-width: 48%;
    }

    .jobssingledetailsflx {
        flex-wrap: wrap;
    }

    .jobsreferredmainbgdiv {
        width: 100%;
        max-width: 100%;
    }

    .jobssingledetailsmain {
        width: 100%;
        height: auto;
        padding: 130px 0px 70px 0px;
    }

    .btnmainflxchnageupdate {
        width: 150px;
        background-color: rgb(255, 190, 152);
        height: 35px;
        border: none;
        border-radius: 10px;
        display: flex;
        padding: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        margin-bottom: 10px;
    }

}

@media(max-width:767px) {
    .bulleDetail .card_area_bullentindetails {
        margin-right: 14px;
        max-width: 47%;
    }

    #training_card .bottomtrainin .card_area_dtlstraing {
        width: 100%;
        max-width: 100%;
    }

    .mainjobsreferred {
        width: 100%;
        max-width: 100%;
        background: #ebffe7;
        border-radius: 3px;
        margin-bottom: 15px;
        margin-top: 15px;
        border-radius: 5px;
    }

    .bulleDetail .card_area_bullentindetails {
        margin-right: 14px;
        max-width: 100%;
    }

    .bulleDetail .bullentindetailflx {
        row-gap: 0px;
    }

    .bottomtrainin {
        flex-wrap: wrap;
    }

    #training_card .bottomtrainin .card_area_dtlstraing {
        width: 100%;
        max-width: 48%;
        height: auto;
        background: #ffebeb;
        border-radius: 10px;
        padding: 10px;
        /* flex-wrap: wrap; */
    }

    #GoalAchiver .bottomsustaldiv .mainssutandiv {
        width: 100%;
        max-width: 100%;
    }

}

@media(max-width:576px) {
    .bulletin_board .bottom {
        width: 100%;
        max-height: 113px;
        padding: 10px 0px 15px 30px;
        /* overflow-y: hidden; */
    }

    .btnmainflxchnageupdate {
        height: 50px;
        padding: 8px;
    }

    .rfshr {
        font-size: 11px;
    }

    #training_card .bottomtrainin .card_area_dtlstraing {
        width: 100%;
        max-width: 100%;
        height: auto;
        background: #ffebeb;
        border-radius: 10px;
        padding: 10px;
        /* flex-wrap: wrap; */
    }
}

@media(max-width:480px){
    .jobsredferdrttgdibvmanbhk {
        width: 100%;
        max-width: 280px;
        min-width: 280px;
    }
    .bottom_area_detailsdivdivflx {
        column-gap: 0px;
    }
    .lbndapplicdtgsiuci {
        font-size: 12px;
        color: #000;
        font-weight: 500;
        font-family: noirprolight;
        line-height: normal;
        margin-bottom: 4px;  
    }
    .lbndapplicdtgsiuci span {
        margin-left: 5px;
        font-size: 12px;
    }
}

@media(max-width:420px) {
    .bulleDetail .card_area_bullentindetails {
        margin-right: 14px;
        max-width: 100%;
    }
    .jobsredferdrttgdibvmanbhk {
        width: 100%;
        max-width: 211px;
        min-width: 211px;
    }

    .bulletin_board .bottom {
        width: 100%;
        max-height: 113px;
        padding: 10px 0px 15px 13px;
        /* overflow-y: hidden; */
    }

    .hedrtxtdibpp {
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        color: #000;
    }

    .refershrflx {
        flex-wrap: wrap;
        justify-content: center;
    }
}