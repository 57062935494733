.chatbotSearchDrop {
    background-color: #f1efef;
    margin-bottom: 2px;
    max-height: 439px;
    overflow: auto;
    text-align: left;
}

.chatbotSearchDrop p {
    color: #000;
    font-size: 14px;
    margin: 4px;
    padding: 6px;
    cursor: pointer;
    font-family: poppinslight;
}

.chatbotSearchDrop p:hover {
    background-color: rgb(146 189 255);
}